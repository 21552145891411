import Cookies from "js-cookie";

export const setAuthentication = (token, expired, role, id, user, email) => {
    Cookies.set('inspire_access_token', token);
    Cookies.set('inspire_expired_token', expired);
    Cookies.set('inspire_role', role);
    Cookies.set('inspire_user_id', id);
    Cookies.set('inspire_user', user);
    Cookies.set('inspire_email', email);
}   

export const getToken = () => Cookies.get('inspire_access_token') || "";
export const getExpired = () => Cookies.get('inspire_expired_token');
export const getRole = () => Cookies.get('inspire_role') || 'default';
export const getUserId = () => Cookies.get('inspire_user_id') || 'default';
export const getUser = () => Cookies.get('inspire_user') || 'default';
export const getEmail = () => Cookies.get('inspire_email') || 'default';

export const removeAuth = () => {
    Cookies.remove('inspire_access_token');
    Cookies.remove('inspire_expired_token');
    Cookies.remove('inspire_role');
    Cookies.remove('inspire_user_id');
    Cookies.remove('inspire_user');
    Cookies.remove('inspire_email');
}