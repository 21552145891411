import axios from "axios";
import { MedsosDSOList, CreateMedsosDSO, DeleteMedsosDSO, UpdateMedsosDSO, MedsosDSODetail, MedsosKolaborasi, getMedsosDsoKPI, getTargetMedsosKPI, getCommunityFollowed, getPostCommunityKPI } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const MedsosDSOListRequest = (page, limit, keyword, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${MedsosDSOList(page, limit, keyword, dsoId)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateMedsosDSORequest = (
    dso,
    name,
    category,
    medsosLink
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateMedsosDSO()}`,
        data: {
            dso,
            name,
            category,
            medsosLink
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteMedsosDSORequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteMedsosDSO(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateMedsosDSORequest = (
    id,
    dso,
    name,
    category,
    medsosLink
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateMedsosDSO(id)}`,
        data: {
            dso,
            name,
            category,
            medsosLink
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const MedsosDSODetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${MedsosDSODetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const MedsosKolaborasiRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${MedsosKolaborasi()}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const getMedsosDsoRequest = (dsoId, year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getMedsosDsoKPI(dsoId, year)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const getTargetMedsosRequest = (dsoId, year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getTargetMedsosKPI(dsoId, year)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const getPostCommunityKPIRequest = (dsoId, year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getPostCommunityKPI(dsoId, year)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const getCommunityFollowedRequest = (dsoId, year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getCommunityFollowed(dsoId, year)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}



