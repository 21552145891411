<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="7" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn class="float-right" depressed color="primary" @click="createEvent" v-if="getRole.role.create" block>
          Buat Kegiatan
        </v-btn>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn class="float-right" @click="download" :loading="isLoadingDownload" depressed color="warning" block>
          Unduh Excel
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12">
        <v-toolbar color="primary" dark flat>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab @click="changeGroup('reguler')"> Event Reguler </v-tab>
            <v-tab @click="changeGroup('lokal')"> Event Lokal </v-tab>
            <v-tab @click="changeGroup('buddy-system')"> Buddy System </v-tab>
            <v-tab @click="changeGroup('community-sharing')"> Sharing antar Komunitas </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <EventList :group="group" ref="list"/>
          </v-tab-item>
          <v-tab-item>
            <EventList :group="group" ref="list"/>
          </v-tab-item>
          <v-tab-item>
            <EventList :group="group" ref="list"/>
          </v-tab-item>
          <v-tab-item>
            <EventList :group="group" ref="list"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <Alert :status="isError && isErrorCode !== 404" icon="error" title="ERROR" :msg="errorCause" />
  </v-container>
</template>
<script>
import EventList from "./event-list";
import { EventControllers } from "../../controllers/EventControllers.js";
import Alert from "../../components/alert";
// import Swal from "sweetalert2";
// import XLSX from "xlsx";
import moment from "moment";

export default {
  name: "event-list",
  data: () => ({
    title: "Kegiatan DSO",
    event: new EventControllers(),
    tab: null,
    dialogDownload: false,

    grup: "reguler",

    startDate: false,
    endDate: false,
    filter: {
      type: "",
      startDate: "",
      endDate: "",
      size: null,
    },

    types: [
      {
        name: "Info Tetangga",
        value: "competitor",
      },
      {
        name: "Info Pasar",
        value: "distribution",
      },
      {
        name: "Info Promo",
        value: "promotion",
      },
      {
        name: "Lainnya",
        value: "other",
      },
    ],
  }),

  computed: {
    getRole() {
      return this.$route.meta;
    },

    dataDownload() {
      return this.event.eventDownload;
    },

    isErrorCode() {
      return this.event.errorCode;
    },

    isError() {
      return this.event.isError;
    },

    errorCause() {
      return this.event.errorCause;
    },

    isLoadingDownload() {
      return this.event.isLoading;
    },
  },

  components: {
    EventList,
    Alert,
  },

  methods: {
    createEvent() {
      this.$router.push({
        name: "Create Event DSO",
        params: {
          group: this.group,
        },
      });
    },

    normalDate(val) {
      return moment(val).format("DD-MM-YYYY h:mm:ss");
    },

    changeGroup(val) {
      this.group = val;
    },

    async download(){
      this.$refs.list.download()
    }

    // async download() {
    //   // await this.event.downloadEventList(this.eventTable.page, this.eventTable.limit, this.eventTable.keyword, this.brand, this.categoryEvent, this.dso);

    //   if(this.dataDownload.length > 0) {
    //     const dataDownload = []
    //     for(const item of this.dataDownload) {
    //         dataDownload.push({
    //           'pengguna': item.user ? item.user.name : '',
    //           'judul': item.title,
    //           'konten': item.content,
    //           'tanggal': item.date,
    //           'dibuat pada': this.normalDate(item.createdAt)
    //       });
    //     }

    //     const data = XLSX.utils.json_to_sheet(dataDownload);
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, data, "data");
    //     XLSX.writeFile(wb, `Report Info Community Tipe.xlsx`);
    //   } else {
    //     Swal.fire({
    //       text: "Data Tidak Ditemukan",
    //       icon: "warning",
    //     });
    //   }
    // }
  }
};
</script>
