import {
    CreateMedsosFollowerRequest,
    DeleteMedsosFollowerRequest,
    UpdateMedsosFollowerRequest,
    MedsosFollowerListRequest,
    MedsosFollowerDetailRequest
} from "../request/MedsosFollowerRequest.js";

export class MedsosFollowerControllers {
    medsosfollowerList = new Array();
    medsosfollowerDetail = new Object();
    medsosfollowerDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getMedsosFollowerList(page, limit, dsoId) {
        this.setLoading(true);
        const resp = MedsosFollowerListRequest(page, limit, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setMedsosFollowerList(response.data.data);
                return true;
            }).catch(err => {
                this.setMedsosFollowerList([])
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getMedsosFollowerDetail(id) {
        this.setLoading(true);
        const resp = MedsosFollowerDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setMedsosFollowerDetail(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadMedsosFollowerList(page, limit, keyword) {
        this.setLoadingDownload(true);
        const resp = MedsosFollowerListRequest(page, limit, keyword)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setMedsosFollowerListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setMedsosFollowerListDownload(data) {
        this.medsosfollowerDownload = data;
    }

    setMedsosFollowerList(data) {
        this.medsosfollowerList = data;
    }

    setMedsosFollowerDetail(data) {
        this.medsosfollowerDetail = data;
    }

    createMedsosFollowerList(
        dso,
        medsos,
        category
    ) {
        this.setLoading(true);
        const resp = CreateMedsosFollowerRequest(
                dso,
                medsos,
                category

            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteMedsosFollower(id) {
        this.setLoading(true);
        const resp = DeleteMedsosFollowerRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateMedsosFollowerList(
        id,
        dso,
        medsos,
        category
    ) {
        this.setLoading(true);
        const resp = UpdateMedsosFollowerRequest(
                id,
                dso,
                medsos,
                category
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}