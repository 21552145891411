import axios from "axios";
import { DRPList, CreateDRP, DeleteDRP, UpdateDRPList, DRPDetail } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const DRPListRequest = (page, limit, keyword, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${DRPList(page, limit, keyword, dsoId)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateDRPRequest = (
    dso,
    areaId,
    name,
    excellence,
    category,
    province,
    district,
    subDistrict,
    village,
    street,
    zipCode
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateDRP()}`,
        data: {
            dso: dso,
            areaId: areaId,
            name: name,
            excellence: excellence,
            category: category,
            address: {
                province: province,
                district: district,
                subDistrict: subDistrict,
                village: village,
                street: street,
                zipCode: zipCode
            }
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteDRPRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteDRP(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateDRPRequest = (
    id,
    dso,
    areaId,
    name,
    excellence,
    category,
    province,
    district,
    subDistrict,
    village,
    street,
    zipCode
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateDRPList(id)}`,
        data: {
            dso: dso,
            areaId: areaId,
            name: name,
            excellence: excellence,
            category: category,
            address: {
                province: province,
                district: district,
                subDistrict: subDistrict,
                village: village,
                street: street,
                zipCode: zipCode
            }
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DRPDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${DRPDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

