<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12" sm="12">
        <v-alert dense outlined type="error">
          Halaman Tidak Ditemukan
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "notfound",
  data: () => ({
    title: "Halaman Tidak Ditemukan",
  }),
};
</script>