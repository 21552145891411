<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
      
              <v-text-field
                v-model="detailBrand.name"
                :rules="brandNameRules"
                label="Nama Brand"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="detailBrand.brandCommunity"
                :items="communitiesDefault"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Komunitas Brand"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-textarea
                v-model="detailBrand.description"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Deskripsi"
                outlined
                required
                dense
              ></v-textarea>

              <v-autocomplete
                v-model="detailBrand.product"
                :items="products"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Produk"
                required
                outlined
                dense
              ></v-autocomplete>

            </v-col>
            <v-col md="4" sm="12" class="p-5" v-if="detailBrand.product === 'Rokok'">
              <v-alert text type="info">
                Informasi SKU
                <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
                <p class="grey--text">
                 Tekan enter jika akan menambahkan banyak SKU setiap selesai ketik
                </p>
              </v-alert>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">SKU</th>
                      <th class="text-right">Hapus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sku, index) in detailBrand.skus" :key="index">
                      <td>{{ sku }}</td>
                      <td>
                        <v-btn
                          small
                          class="float-right"
                          icon
                          color="error"
                          dark
                          @click="deleteSKU(index)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              
              <multiselect
                class="mt-5"
                v-model="tag.value"
                tag-placeholder="Tambah SKU baru"
                placeholder="Cari atau tambah SKU"
                :options="tag.options"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isErrorProduct && isErrorCodeProduct !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseProduct"
    />
  </v-container>
</template>
<script>
import { BrandControllers } from "../../controllers/BrandControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "vue-multiselect";

export default {
  name: "create-brand",
  data: () => ({
    brand: new BrandControllers(),
    title: "Ubah Brand",
    valid: true,
    brandNameRules: [(v) => !!v || "Kolom is required"],
    communitiesDefault: ['Super Friends', 'Bold Riders', 'Heppiii Community', 'Kill The LAst', 'Inmild', 'Others'],

    tableScope: {
      page: 1,
      limit: 1000,
    },
    
    isLoading: false,
    fullPage: true,

    tag: {
      value: [],
      options: [],
    },
  }),

  components: {
    Loading,
    Multiselect,
  },

  created() {
    this.getBrandList();
    this.getProducts();
  },

  computed: {
    // brand length
    brandLength() {
      return this.brand.brandList.length;
    },

    // update brand
    isErrorUpdateBrand() {
      return this.brand.isError;
    },

    errorCauseUpdateBrand() {
      return this.brand.errorCause;
    },

    isLoadingUpdateBrand() {
      return this.brand.isLoading;
    },

    // detail brand
    detailBrand() {
      return this.brand.brandDetail;
    },

    // product
    products() {
      return this.brand.productList;
    },

    isErrorProduct() {
      return this.brand.errorProduct;
    },

    isErrorCauseProduct() {
      return this.brand.errorCauseProduct;
    },

    isErrorCodeProduct() {
      return this.brand.errorCodeProduct;
    },
  },

  methods: {
    async getBrandList() {
      return this.brand.getBrandDetail(
        this.$route.params.id
      );
    },

    getProducts() {
      return this.brand.getProductList();
    },

    back() {
      this.$router.go(-1);
    },

    addTag(newTag) {
      this.tag.value.push(newTag);
      this.tag.options.push(newTag);
    },

    deleteSKU(index) {
      this.detailBrand.skus.splice(index, 1);
    },

    async submitProcess(
      brandName,
      description,
      communityName,
      skus,
      product
    ) {
      return this.brand.updateBrandList(
        this.$route.params.id,
        brandName,
        description,
        communityName,
        skus,
        product
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        if(this.detailBrand.product === 'Rokok') {
          await this.submitProcess(
            this.detailBrand.name,
            this.detailBrand.description,
            this.detailBrand.brandCommunity,
            this.detailBrand.skus !== null ? this.detailBrand.skus.concat(this.tag.value) : this.tag.value,
            this.detailBrand.product
          );
        } else {
          await this.submitProcess(
            this.detailBrand.name,
            this.detailBrand.description,
            this.detailBrand.brandCommunity,
            [],
            this.detailBrand.product
          );
        }
        

        if (this.isErrorUpdateBrand) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "ERROR",
            text: this.errorCauseUpdateBrand,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });

          //this.back();
        }
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>