<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-autocomplete v-model="form.dso" :items="dsos" item-text="name" item-value="id" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="DSO" required outlined dense></v-autocomplete>

              <v-text-field v-model="form.name" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Nama Media Sosial" required outlined dense></v-text-field>

              <v-text-field v-model="form.medsosLink" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Link Media Sosial" required outlined dense></v-text-field>
              <!-- :items="categories" -->
              <v-autocomplete v-model="form.category" :items="categories" item-text="value" item-value="key" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Kategori" required outlined dense></v-autocomplete>
            </v-col>
          </v-row>
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit">
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Brands -->
    <Alert :status="isErrorCreateMedsosDSO && errorCodeCreateMedsosDSO !== 404" icon="error" title="KESALAHAN" :msg="errorCauseCreateMedsosDSO" />

    <!-- Error Categories -->
    <Alert :status="isErrorGetCategories && errorCodeGetCategories !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetCategories" />

    <!-- Error Users -->
    <Alert :status="isErrorGetUsers && errorCodeGetUsers !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetUsers" />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../controllers/UserControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { MedsosDSOControllers } from "../../controllers/MedsosDSOControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-user",
  data: () => ({
    user: new UserControllers(),
    category: new CategoryControllers(),
    medsosdso: new MedsosDSOControllers(),
    valid: true,
    title: "Buat Komunitas",

    isLoading: false,
    fullPage: true,

    form: {
      dso: null,
      name: "",
      medsosLink: "",
      category: "",
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    tag: {
      value: [],
      options: [],
    },
    logoDisplay: null,
    thereIsImage: false,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // medsosdso
    isErrorCreateMedsosDSO() {
      return this.medsosdso.isError;
    },

    errorCauseCreateMedsosDSO() {
      return this.medsosdso.errorCause;
    },

    isLoadingCreateMedsosDSO() {
      return this.medsosdso.isLoading;
    },

    errorCodeCreateMedsosDSO() {
      return this.medsosdso.errorCode;
    },

    // users
    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    // categories
    categories() {
      return this.category.categoryMedsosList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },
  },

  created() {
    this.getUsers();
    this.getCategories();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    addTag(newTag) {
      this.tag.options.push(newTag);
      this.tag.value.push(newTag);
    },

    getUsers() {
      return this.user.getUserAMList("admin.dso", 1, 1000, "");
    },

    getCategories() {
      return this.category.getcategoryMedsosList();
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.logoDisplay = null;
      this.thereIsImage = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submitProcess(dso, name, category, medsosLink) {
      return this.medsosdso.createMedsosDSOList(dso, name, category, medsosLink);
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (validate) {
        this.isLoading = true;
        await this.submitProcess(this.form.dso, this.form.name, this.form.category, this.form.medsosLink);

        if (this.isErrorCreateMedsosDSO) {
          this.isLoading = false;
          this.isErrorCreateMedsosDSO = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateMedsosDSO,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorCreateMedsosDSO = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
