<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="9" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row v-if="!isLoadingEvent">
      <v-col md="12" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Event</th>
                  <th class="text-left">Pengguna</th>
                  <th class="text-left">Tipe</th>
                  <th class="text-left">Verifikasi</th>
                  <th class="text-left">Rincian</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in events" :key="item.id">
                  <td>{{ item.event ? item.event.title : '' }}</td>
                  <td>{{ item.user.name }}</td>
                  <td>{{ item.type }}</td>
                  <td v-if="false">
                    <v-chip color="success"> Terverifikasi </v-chip>
                  </td>
                  <td v-else>
                    <v-btn
                      :disabled="false ? true : false"
                      small
                      outlined
                      color="success"
                      dark
                      @click="verify(item.id)"
                    >
                      Verifikasi
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Detail :isShow="isDetail" :data="eventDetail" @hide="updateModal" />
    <Alert
      :status="isError && errorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventEvidenceControllers } from "../../controllers/EventEvidenceControllers.js";
import Swal from "sweetalert2";
import Detail from "./event-detail";

export default {
  name: "event-evidence-list",
  data: () => ({
    event: new EventEvidenceControllers(),
    title: "Verifikasi Partisipasi Aktif Komunitas",
    eventTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,
    searchField: ""
  }),

  components: {
    Alert,
    Loading,
    Detail
  },

  created() {
    this.getEvents();
  },

  computed: {
    // events
    events() {
      return this.event.eventList;
    },

    errorCode() {
      return this.event.errorCode;
    },

    isError() {
      return this.event.isError;
    },

    errorCause() {
      return this.event.errorCause;
    },

    isLoadingEvent() {
      return this.event.isLoading;
    },

    eventDetail() {
      return this.event.eventDetail;
    },
  },

  methods: {
    async getEvents() {
      return this.event.getEventEvidenceList(
        this.eventTable.page,
        this.eventTable.limit,
        this.eventTable.keyword
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingEvent;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.eventTable.page -= 1;

        await this.getEvents(
          this.eventTable.page,
          this.eventTable.limit,
          this.eventTable.keyword
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingEvent;
      this.pages.start += 10;
      this.pages.end += 10;

      this.eventTable.page += 1;

      await this.getEvents(
        this.eventTable.page,
        this.eventTable.limit,
        this.eventTable.keyword
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    getdetail(id) {
      this.isDetail = true;
      return this.event.getDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.eventTable.page = 1;
      this.eventTable.limit = 10;
      this.eventTable.keyword = this.searchField;

      await this.getEvents(
        this.eventTable.page,
        this.eventTable.limit,
        this.eventTable.keyword
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.eventTable.page = 1;
          this.eventTable.limit = 10;
          this.eventTable.keyword = event;

          await this.getEvents(
            this.eventTable.page,
            this.eventTable.limit,
            this.eventTable.keyword
          );
        }
      }, 500);
    },
  },
};
</script>