import axios from "axios";
import { Login, Logout, ChangePassword } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
const token = `aW5zcGlyZWJhY2tlbmQ6ZGExYzI1ZDgtMzdjOC00MWIxLWFmZTItNDJkZDQ4MjViZmVh`;
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};

export const LoginRequest = (username, password) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${Login()}`,
        data: {
            email: username,
            password: password
        },
        headers: {
            'Authorization': `Basic ${token}`
        }
    }).then((response) => {
        return response;
    });

    return resp;
}

export const LogoutRequest = (
    userId
) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${Logout()}`,
        data: {
            userId: userId
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const ChangePasswordRequest = (
    passwordOld, passwordNew, passwordNew2
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${ChangePassword()}`,
        data: {
            passwordOld : passwordOld, 
            passwordNew : passwordNew, 
            passwordNew2 : passwordNew2
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}