import axios from "axios";
import { BrandList, CreateBrand, DeleteBrand, UpdateBrand, BrandDetail, ProductList } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const BrandListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${BrandList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateBrandRequest = (
    projectId,
    brandName,
    description,
    brandCommunity,
    skus,
    product
) => {
    if(product === 'Rokok') {
        const resp = axios({
            method: 'post',
            url: `${BaseUrl()}${CreateBrand()}`,
            data: {
                projectId: projectId,
                name: brandName,
                description: description,
                brandCommunity: brandCommunity,
                skus: skus,
                product: product
            },
            headers: headers
        }).then((response) => {
            return response;
        });
    
        return resp;
    } else {
        const resp = axios({
            method: 'post',
            url: `${BaseUrl()}${CreateBrand()}`,
            data: {
                projectId: projectId,
                name: brandName,
                description: description,
                brandCommunity: brandCommunity,
                product: product
            },
            headers: headers
        }).then((response) => {
            return response;
        });
    
        return resp;
    }
    
}

export const DeleteBrandRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteBrand(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateBrandRequest = (
    id,
    brandName,
    description,
    brandCommunity,
    skus,
    product
) => {
    if(product === 'Rokok') {
        const resp = axios({
            method: 'put',
            url: `${BaseUrl()}${UpdateBrand(id)}`,
            data: {
                name: brandName,
                description: description,
                brandCommunity: brandCommunity,
                skus: skus,
                product: product
            },
            headers: headers
        }).then((response) => {
            return response;
        });

        return resp;
    } else {
        const resp = axios({
            method: 'put',
            url: `${BaseUrl()}${UpdateBrand(id)}`,
            data: {
                name: brandName,
                description: description,
                brandCommunity: brandCommunity,
                product: product
            },
            headers: headers
        }).then((response) => {
            return response;
        });

        return resp;
    }
}

export const BrandDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${BrandDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const ProductListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${ProductList()}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}



