import axios from "axios";
import { AllNotificationsPreviewList, AllNotificationsList } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const AllNotificationsPreviewListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AllNotificationsPreviewList()}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AllNotificationsListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AllNotificationsList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

