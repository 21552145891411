import axios from "axios";
import { CommunityList, CreateCommunity, DeleteCommunity, UpdateCommunityList, VerifyCommunity, FreezeCommunity, CommunityDetail, getCommunityAchievement, InputCommunity } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const CommunityListRequest = (page, limit, keyword, startDate, endDate, isReport, brand, category, status, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CommunityList(page, limit, keyword, startDate, endDate, isReport, brand, category, status, dsoId)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateCommunityRequest = (
    pic, 
    dso, 
    brandId, 
    name, 
    dob,
    description,
    category,
    joinedAt,
    province, 
    district, 
    subDistrict, 
    village, 
    street, 
    zipCode, 
    logo,
    socialMedias,
    totalMember,
    totalSmoker,
    totalActiveMember,
    totalVillagers,
    rationalConsumptionTarget
) => {
const resp = axios({
    method: 'post',
    url: `${BaseUrl()}${CreateCommunity()}`,
    data: {
        pic: pic,
        dso: dso, 
        brandId: brandId, 
        name: name, 
        dob: dob,
        description: description,
        category: category,
        joinedAt: joinedAt,
        address: {
            province: province, 
            district: district, 
            subDistrict: subDistrict, 
            village: village, 
            street: street, 
            zipCode: zipCode, 
        },
        logo: logo,
        socialMedias: socialMedias,
        totalMember: totalMember,
        totalSmoker: totalSmoker,
        totalActiveMember: totalActiveMember,
        totalVillagers: totalVillagers,
        rationalConsumptionTarget: rationalConsumptionTarget
    },
    headers: headers
}).then((response) => {
    return response;
});

return resp;
}

export const InputCommunityRequest = (
    dataInput
) => {
const resp = axios({
    method: 'post',
    url: `${BaseUrl()}${InputCommunity()}`,
    data: {
        dataInput: JSON.stringify(dataInput),
    },
    headers: headers
}).then((response) => {
    return response;
});

return resp;
}

export const DeleteCommunityRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteCommunity(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateCommunityListRequest = (
    id,
    totalMember,
    totalActiveMember,
    totalSmoker,
    totalVillagers,
    rationalConsumptionTarget,
    pic, 
    dso, 
    brandId, 
    name, 
    dob,
    description,
    category,
    joinedAt,
    province, 
    district, 
    subDistrict, 
    village, 
    street, 
    zipCode, 
    logo,
    socialMedias
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateCommunityList(id)}`,
        data: {
            totalMember: totalMember,
            totalActiveMember: totalActiveMember,
            totalSmoker: totalSmoker,
            totalVillagers: totalVillagers,
            rationalConsumptionTarget: rationalConsumptionTarget,
            pic: pic,
            dso: dso, 
            brandId: brandId, 
            name: name, 
            dob: dob,
            description: description,
            category: category,
            joinedAt: joinedAt,
            address: {
                province: province!=null? province:'', 
                district: district !=null? district:'', 
                subDistrict: subDistrict!=null? subDistrict:'', 
                village: village!=null? village:'', 
                street: street!=null? street:'', 
                zipCode: zipCode!=null? zipCode:'', 
            },
            logo: logo !=null? logo:'',
            socialMedias: socialMedias
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const VerifyCommunityRequest = (
    id
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${VerifyCommunity(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const FreezeCommunityRequest = (
    id
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${FreezeCommunity(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CommunityDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CommunityDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const getCommunityAchievementRequest = (dsoId, year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getCommunityAchievement(dsoId, year)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}




