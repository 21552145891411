<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row>
          <v-col md="6" sm="12">
            <v-autocomplete
              :items="[2019, 2020, 2021, 2022]"
              @change="pickArea"
              item-text="name"
              item-value="id"
              label="Tahun"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12"></v-col>
          <v-col md="2" sm="12">
            <v-btn
              class="success"
              :loading="isLoadingDownload"
              small
              block
              @click="download"
              >Unduh Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" md="12" ms="12" class="mt-n5">
            <h4>{{ title }}</h4>
          </v-col>
          <v-col md="12" ms="12" class="mt-n1" align="center">
            <h2 class="display-1 font-weight-black">
              <b>43</b><span style="font-size: 10px">/50</span>
            </h2>
          </v-col>
          <v-col md="12" ms="12">
            <h4 align="center">KATEGORI EVENT</h4>
          </v-col>
          <v-col md="6" ms="12" class="mt-n5">
            <v-select
              :items="brandCom"
              item-text="name"
              item-value="name"
              v-model="all"
              @input="pickBrand"
              label="Brand Komunitas"
              dense
            ></v-select>
          </v-col>
          <v-col md="6" ms="12" class="mt-n5"></v-col>
          <v-row justify="center">
            <v-col md="12" sm="12" class="mt-n2 pa-0 ms-n1 mb-2 d-flex justify-center">
              <v-responsive max-width="300px">
              <VueApexCharts
                type="pie"
                width="100%"
                :options="chartOptions"
                :series="series"
              />
              </v-responsive>
            </v-col>
          </v-row>
          <v-row justify="left">
            <v-col md="12" ms="12">
              <StackedBar />
            </v-col>
            <v-col md="12" ms="12" class="mt-n10">
              <StackedBarLkk />
            </v-col>
            <v-col md="12" ms="12" class="mt-n10">
              <StackedBarDRPVenue />
            </v-col>
          </v-row>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>

<script>
import Alert from "../../../../components/alert/index";
import { DashboardControllers } from "../../../../controllers/DashboardControllers";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);
import StackedBar from "./stacked-bar-all.vue";
import StackedBarLkk from "./stacked-bar-lkk.vue";
import StackedBarDRPVenue from "./stacked-bar-drp-venue.vue";

export default {
  name: "event-report",
  data: () => ({
    title: "JUMLAH EVENT",
    dashboard: new DashboardControllers(),
    year: 2021,
    years: [2020, 2021, 2022],

    series: [27, 67, 27, 13],
    chartOptions: {
      chart: {
        width: "100%",
        type: "pie",
      },
      labels: ["GATHERING", "SOSIAL", "NOBAR BOLA", "ESPORT"],
      legend: {
        show: false,
        position: "right",
        formatter: function(label, opts) {
          return label + " : " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "middle",
        style: {
          colors: ["#fff"],
        },
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(0) + "%"];
        },
      },
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
    Alert,
    StackedBar,
    StackedBarLkk,
    StackedBarDRPVenue,
  },

  computed: {
    // sales
    sales() {
      return this.dashboard.salesDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    this.getDashboard();
    this.getSeries();
  },

  methods: {
    getDashboard() {
      return this.dashboard.getSalesDashboardList(this.year);
    },

    async getSeries() {
      await this.getDashboard(this.year);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    async pickYear(event) {
      this.series = [
        {
          data: [],
        },
      ];
      this.chartOptions.xaxis.categories = [];

      await this.getDashboard(event);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const sales = [];
      for (const item of this.sales) {
        sales.push({
          "comunity name": item._id,
          jumlah: item.count,
        });
      }
      const data = XLSX.utils.json_to_sheet(sales);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Sales Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>
