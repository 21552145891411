import {
    SalesTargetListRequest,
    CreateSalesTargetRequest,
    DeleteSalesTargetRequest,
    UpdateSalesTargetRequest,
    SetSalesTargetRequest,
    ConfirmSalesRequest,
    SalesDetailRequest,
    SalesTargetPercentageRequest
} from "../request/SalesRequest.js";

export class ConsumptionControllers {
    consumptionList = new Array();
    consumptionData = new Array();
    consumptionDetail = new Object();
    setConsumptionDetail = new Object();
    consumptionDownload = new Array();
    consumptionTargetList = new Array();

    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    isErrorConfirm = false;
    errorCauseConfirm = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getConsumptionList(page, limit, keyword, brand, category, periode, dsoId) {
        this.setLoading(true);
        const resp = SalesTargetListRequest(page, limit, keyword, brand, category, periode, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setConsumptionList(response.data.data);
            }).catch(err => {
                this.setConsumptionList([]);
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getSalesPercentage(targetId) {
        this.setLoading(true);
        const resp = SalesTargetPercentageRequest(targetId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setConsumptionTargetList([response.data.data]);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getSalesDetail(id) {
        this.setLoading(true);
        const resp = SalesDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setConsumptionDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setConsumptionDetail(data) {
        this.consumptionDetail = data;
    }

    setConsumptionTargetList(data) {
        this.consumptionTargetList = data;
    }

    createConsumptionList(
        communityId,
        brandId,
        startDate,
        endDate,
        goal
    ) {
        this.setLoading(true);
        const resp = CreateSalesTargetRequest(
                communityId,
                brandId,
                startDate,
                endDate,
                goal
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getConsumptionDetail(id) {
        this.consumptionDetail = this.consumptionList.find(item => {
            return item.id === id
        });
    }

    getSetConsumptionList(communityId, page, limit, keyword) {
        this.setLoading(true);
        const resp = SetSalesTargetRequest(communityId, page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setConsumptionData(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setConsumptionData(data) {
        this.consumptionData = data;
    }

    setConsumptionList(data) {
        this.consumptionList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteConsumption(id) {
        this.setLoading(true);
        const resp = DeleteSalesTargetRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadConsumptionList(page, limit, keyword, brand, category, periode, dsoId) {
        this.setLoadingDownload(true);
        const resp = SalesTargetListRequest(page, limit, keyword, brand, category, periode, dsoId)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setConsumptionListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setConsumptionListDownload(data) {
        this.consumptionDownload = data;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateConsumptionList(
        id,
        startDate,
        endDate,
        goal
    ) {
        this.setLoading(true);
        const resp = UpdateSalesTargetRequest(
                id,
                startDate,
                endDate,
                goal
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    confirmConsumption(
        id
    ) {
        const resp = ConfirmSalesRequest(
                id
            )
            .then(() => {
                this.setErrorConfirm(false);
            }).catch(err => {
                this.setErrorConfirm(true);
                this.setErrorCauseConfirm(err.response.data.message);
            });

        return resp;
    }

    setErrorConfirm(status) {
        this.isErrorConfirm = status;
    }

    setErrorCauseConfirm(err) {
        this.errorCauseConfirm = err;
    }

    getSetConsumptionDetail(id) {
        this.setConsumptionDetail = this.consumptionData.find(item => {
            return item.id === id
        });
    }
}