import axios from "axios";
import { CategoryVenueList, CategoryDRPList, CategoryCommunityList, CategoryEventList, CategoryMedsosList, BrandCommunityList} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const CategoryVenueListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CategoryVenueList()}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CategoryDRPListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CategoryDRPList()}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CategoryCommunityListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CategoryCommunityList()}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CategoryEventListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CategoryEventList()}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CategoryMedsosListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CategoryMedsosList()}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const BrandListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${BrandCommunityList()}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

