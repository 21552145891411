import axios from "axios";
import { KpiTargetList, CreateKpiTarget, DeleteKpiTarget, UpdateKpiTarget, KpiTargetDetail, getSummaryKpi, InputKPIData } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
  Authorization: `Bearer ${getToken()}`,
};
export const KpiTargetListRequest = (page, limit, keyword, year) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${KpiTargetList(page, limit, keyword, year)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const CreateKpiTargetRequest = (
  dso,
  year,
  communityTarget,
  communitySuperTarget,
  communityKeyTarget,
  eventTarget,
  eventLkkTarget,
  eventWithVenueDrpTarget,
  eventEsportTarget,
  eventEsportSuperTarget,
  eventSuperNonEsportTarget,
  competencyTarget,
  sosmedCommunityTarget,
  sosmedDsoTarget,
  postDsoTarget,
  sosmedFollowerTarget,
  consumptionTarget,
  eventBuddySystemTarget,
  eventRegionalTarget,
  eventSharingTarget,
  eventLocalTarget,
  postCommunityTarget
) => {
  const resp = axios({
    method: "post",
    url: `${BaseUrl()}${CreateKpiTarget()}`,
    data: {
      dso: dso,
      year: year,
      communityTarget: communityTarget,
      communitySuperTarget: communitySuperTarget,
      communityKeyTarget: communityKeyTarget,
      eventTarget: eventTarget,
      eventLkkTarget: eventLkkTarget,
      eventWithVenueDrpTarget: eventWithVenueDrpTarget,
      eventEsportTarget: eventEsportTarget,
      eventEsportSuperTarget: eventEsportSuperTarget,
      eventSuperNonEsportTarget: eventSuperNonEsportTarget,
      competencyTarget: competencyTarget,
      sosmedCommunityTarget: sosmedCommunityTarget,
      sosmedDsoTarget: sosmedDsoTarget,
      postDsoTarget: postDsoTarget,
      sosmedFollowerTarget: sosmedFollowerTarget,
      consumptionTarget: consumptionTarget,
      eventBuddySystemTarget: eventBuddySystemTarget,
      eventRegionalTarget: eventRegionalTarget,
      eventSharingTarget: eventSharingTarget,
      eventLocalTarget: eventLocalTarget,
      postCommunityTarget: postCommunityTarget,
    },
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const DeleteKpiTargetRequest = (id) => {
  const resp = axios({
    method: "delete",
    url: `${BaseUrl()}${DeleteKpiTarget(id)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const UpdateKpiTargetRequest = (
  id,
  dso,
  year,
  communityTarget,
  communitySuperTarget,
  communityKeyTarget,
  eventTarget,
  eventLkkTarget,
  eventWithVenueDrpTarget,
  eventEsportTarget,
  eventEsportSuperTarget,
  eventSuperNonEsportTarget,
  competencyTarget,
  sosmedCommunityTarget,
  sosmedDsoTarget,
  postDsoTarget,
  sosmedFollowerTarget,
  consumptionTarget,
  eventBuddySystemTarget,
  eventRegionalTarget,
  eventSharingTarget,
  eventLocalTarget,
  postCommunityTarget
) => {
  console.log({
    id,
    dso,
    year,
    communityTarget,
    communitySuperTarget,
    communityKeyTarget,
    eventTarget,
    eventLkkTarget,
    eventWithVenueDrpTarget,
    eventEsportTarget,
    eventEsportSuperTarget,
    eventSuperNonEsportTarget,
    competencyTarget,
    sosmedCommunityTarget,
    sosmedDsoTarget,
    postDsoTarget,
    sosmedFollowerTarget,
    consumptionTarget,
    eventBuddySystemTarget,
    eventRegionalTarget,
    eventSharingTarget,
    eventLocalTarget,
    postCommunityTarget
  })
  const resp = axios({
    method: "put",
    url: `${BaseUrl()}${UpdateKpiTarget(id)}`,
    data: {
      dso: dso,
      year: year,
      communityTarget: communityTarget,
      communitySuperTarget: communitySuperTarget,
      communityKeyTarget: communityKeyTarget,
      eventTarget: eventTarget,
      eventLkkTarget: eventLkkTarget,
      eventWithVenueDrpTarget: eventWithVenueDrpTarget,
      eventEsportTarget: eventEsportTarget,
      eventEsportSuperTarget: eventEsportSuperTarget,
      eventSuperNonEsportTarget: eventSuperNonEsportTarget,
      competencyTarget: competencyTarget,
      sosmedCommunityTarget: sosmedCommunityTarget,
      sosmedDsoTarget: sosmedDsoTarget,
      postDsoTarget: postDsoTarget,
      sosmedFollowerTarget: sosmedFollowerTarget,
      consumptionTarget: consumptionTarget,
      eventBuddySystemTarget: eventBuddySystemTarget,
      eventRegionalTarget: eventRegionalTarget,
      eventSharingTarget: eventSharingTarget,
      eventLocalTarget: eventLocalTarget,
      postCommunityTarget: postCommunityTarget,
    },
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const KpiTargetDetailRequest = (id) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${KpiTargetDetail(id)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const kpiSummaryRequest = (page, size, year) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${getSummaryKpi(page, size, year)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const inputKPIDataRequest = (year, dataInput) => {
  console.log(dataInput[0]);
  const resp = axios({
    method: "post",
    url: `${BaseUrl()}${InputKPIData()}`,
    data: {
      year: year.toString(),
      dataInput: JSON.stringify(dataInput),
    },
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};
