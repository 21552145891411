<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="10" sm="12"> {{ title }} </v-col>
          <!--<v-col md="2" sm="12">
            <v-btn class="success" :loading="isLoadingDownload" small block @click="download" :disabled="brand == '' || errorCodeGetReports === 404">Unduh Excel</v-btn>
          </v-col>-->
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row v-if="!isLoadingReports">
          <v-col md="12" sm="12">
            <v-card v-if="errorCodeGetReports === 404">
              <v-alert outlined type="error">Data tidak ditemukan</v-alert>
            </v-card>

            <v-card v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Komunitas</th>
                      <th class="text-left">Score DTK</th>
                      <th class="text-left">Max DTK</th>
                      <th class="text-left">Score KHK</th>
                      <th class="text-left">Max KHK</th>
                      <th class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in reports" :key="index">
                      <td>{{ item.community_name }}</td>
                      <td>{{ item.scoreDTK }}</td>
                      <td>{{ item.maxDTK }}</td>
                      <td>{{ item.scoreKHK }}</td>
                      <td>{{ item.maxKHK }}</td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Reports -->
    <Alert :status="isErrorGetReports && errorCodeGetReports !== 404" icon="error" title="ERROR" :msg="errorCauseGetReports" />
  </div>
</template>
<script>
import Alert from "../../../../../components/alert/index";
import { ReportControllers } from "../../../../../controllers/ReportControllers.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
//import XLSX from "xlsx";

export default {
  name: "report-score",
  props: {
    category: String,
    dso: String,
    brand: String,
    date: String,
    status: String,
  },
  data: () => ({
    title: "",
    report: new ReportControllers(),
    // filter: {
    //   dso: "",
    //   brand: "",
    //   category: "",
    // },

    isLoading: false,
    fullPage: true,

    // mean: [],
    // meanKHK: 0,
    // meanDTK: 0,
    //maxKHK: 0,
    //maxDTK: 0,
    //isLoadingDownload: false,
    //isDownloadClicked: true,
  }),

  components: {
    Alert,
    Loading,
  },

  computed: {
    // reports
    reports() {
      return this.report.reportActivityKeyByDateList;
    },

    errorCodeGetReports() {
      return this.report.errorCode;
    },

    isErrorGetReports() {
      return this.report.isError;
    },

    errorCauseGetReports() {
      return this.report.errorCause;
    },

    isLoadingReports() {
      return this.report.isLoading;
    },
  },

  created() {
    if (this.dso && this.date) {
      this.isDownloadClicked = false;
      this.getReports(this.dso, this.brand, this.category, this.status, this.date);
      this.getSeries();
    }
  },

  watch: {
    category(event) {
      this.reportByCategory(event);
      // this.reportByCategory();
    },
    dso(event) {
      this.reportByDso(event);
      // this.reportByDso();
    },
    brand(event) {
      this.reportByBrand(event);
      // this.getReports();
    },
    status(event) {
      this.reportByStatus(event);
      // this.getReports();
    },
    date(event) {
      this.reportByDate(event);
      // this.getReports();
    },
  },

  methods: {
    async getReports(dso, brand, category, status, date) {
      return this.report.getReportActivityKeyByDateList(dso, brand, category, status, date);
    },

    async getSeries() {
      if (this.report.reportActivityKeyList) {
        let seriesData = [];
        const arr = this.report.reportActivityKeyList.tableOfKisi;
        await arr.map((item) => {
          seriesData.push({
            name: item.name,
            data: [[item.totalKHK, item.maxDTK, item.totalDTK]],
          });
        });
      }
    },

    async reportByDso(event) {
      if (this.dso) {
        this.isDownloadClicked = false;
        await this.getReports(event, this.brand, this.category, this.status, this.date);
        this.getSeries();
      }
    },

    async reportByDate(event) {
      if (this.date) {
        this.isDownloadClicked = false;
        await this.getReports(this.dso, this.brand, this.category, this.status, event);
        this.getSeries();
      }
    },

    async reportByBrand(event) {
        this.isDownloadClicked = false;
        await this.getReports(this.dso, event, this.category, this.status, this.date);
        this.getSeries();
    },

    async reportByCategory(event) {
        this.isDownloadClicked = false;
        await this.getReports(this.dso, this.brand, event, this.status, this.date);
        this.getSeries();
    },

    async reportByStatus(event) {
        this.isDownloadClicked = false;
        await this.getReports(this.dso, this.brand, this.category, event, this.date);
        this.getSeries();
    },

    /*download() {
      this.isLoadingDownload = true;
      const kisi = [];
      for (const item of this.reports) {
        kisi.push({
          name: item.name,
          "total KHK": item.totalKHK,
          "total DTK": item.totalDTK,
          kisi: item.kisi,
        });
      }

      const data = XLSX.utils.json_to_sheet(kisi);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Report Kisi.xlsx");
      this.isLoadingDownload = false;
    },*/
  },
};
</script>
