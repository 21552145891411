import axios from "axios";
import { MemberList } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const MemberListRequest = (communityId, page, limit, keyword, isReport) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${MemberList(communityId, page, limit, keyword, isReport)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}
