<template>
  <div>
    <v-dialog v-model="isShow" width="600" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Rincian Event Evidence
        </v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>ID</v-list-item-title>
              <v-list-item-subtitle>{{ data.id }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Nama Event</v-list-item-title>
              <v-list-item-subtitle>{{ data.event ? data.event.title : '' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item five-line>
            <v-list-item-content>
              <v-list-item-title>Konten</v-list-item-title>
              <v-list-item-subtitle>
                <span v-html="data.content"></span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="data.links">
            <v-list-item-content>
              <v-list-item-title>Link</v-list-item-title>
              <v-list-item-subtitle v-for="(item, index) in data.links" :key="index">
                  <a :href="item.link" target="blank">{{ item.tag }}</a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Tipe</v-list-item-title>
              <v-list-item-subtitle>{{ data.type }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line v-if="data.images">
            <v-list-item-content>
              <v-list-item-title>Gambar</v-list-item-title>
              <v-list-item-subtitle>
                <v-row>
                  <v-col
                    v-for="(img, index) in data.images"
                    :key="index"
                    class="d-flex child-flex"
                    cols="4"
                  >
                    <v-img
                      :src="img"
                      :lazy-src="img"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      style="cursor: pointer;"
                      @click="zoomImage(img)"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-else>
              <v-list-item-content>
                <v-list-item-title>Gambar</v-list-item-title>
                <v-list-item-subtitle>
                    Gambar Tidak Tersedia
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Dibuat Pada</v-list-item-title>
              <v-list-item-subtitle>{{
                data.createdAt | getNormalDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Diperbaharui Pada</v-list-item-title>
              <v-list-item-subtitle>{{
                data.updatedAt | getNormalDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="closeModal"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog v-model="isZoom" width="500" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2"> Gambar </v-card-title>
          <v-img :src="imageZoomed"></v-img>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="isZoom = false"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { formatDate } from "../../external/functions/Utilization.js";
export default {
  name: "user-detail",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data: () => ({
    isZoom: false,
    imageZoomed: ""
  }),

  methods: {
    closeModal() {
      this.$emit("hide", false);
    },

    zoomImage(img) {
      this.isZoom = true;
      this.imageZoomed = img;
    }
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },
};
</script>