<template>
  <v-container class="pt-8">
    <v-row justify="space-between">
      <v-col md="6" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <!-- <v-col v-if="getRole.role.filter" md="3" sm="12">
        <v-autocomplete
          :items="['DSO a', 'DSO b', 'DSO c', 'DSO d']"
          @change="pickArea"
          item-text="name"
          item-value="id"
          label="Pilih DSO"
          dense
          block
          right
        ></v-autocomplete>
      </v-col> -->
      <v-col md="3" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh PDF
        </v-btn>
      </v-col>
      <v-col md="6" sm="12">
        <v-row>
          <v-col md="12" sm="12">
            <v-card>
              <v-card-text class="py-0">
                <CommunityAchievement />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" sm="12">
            <v-card>
              <v-card-text class="py-0">
                <CommunityTotalKey />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="6" sm="12">
        <v-card>
          <v-card-text class="py-0">
            <Event />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CommunityAchievement from "./community-achievement/community-achievement";
import CommunityTotalKey from "./community-total-key/community-total-key";
import Event from "./total-event/event-report";

export default {
  name: "activity-report",
  data: () => ({
    title: "Aktivitas",
  }),
  components: {
    CommunityTotalKey,
    CommunityAchievement,
    Event,
  },
  computed: {
    getRole() {
      return this.$route.meta;
    },
  },
};
</script>