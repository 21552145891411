<template>
  <v-container class="pt-8">
    <v-row justify="space-between">
      <v-col md="6" sm="12">
        <h2>Konsumsi</h2>
      </v-col>
      <v-col v-if="getRole.role.filter" md="3" sm="12">
        <v-autocomplete
          :items="['DSO a', 'DSO b', 'DSO c', 'DSO d']"
          @change="pickArea"
          item-text="name"
          item-value="id"
          label="Pilih DSO"
          dense
          block
          right
        ></v-autocomplete>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh PDF
        </v-btn>
      </v-col>
      <v-col md="6" sm="12">
        <v-card height="515">
          <v-card-text>
            <Overview />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="12">
        <v-card height="515">
          <v-card-text>
            <ConsumptionCom />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ConsumptionCom from "./community-consumption/community-consumption";
import Overview from "./overview/overview";

export default {
  name: "dashboard",
  data: () => ({
    title: "Dashboard",
  }),
  components: {
    ConsumptionCom,
    Overview,
  },
  computed: {
    getRole() {
      return this.$route.meta;
    },
  },
};
</script>