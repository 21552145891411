import Vue from "vue";
import VueRouter from "vue-router";
import { getToken, getExpired, removeAuth, getRole } from "../external/functions/Cookies.js";
import { defineRole } from "../external/functions/Roles";
import moment from "moment";
import Auth from "../views/auth";
import ChangePassword from "../views/auth/change-password";
// import Auth from "../views/maintenance"
import NotFound from "../views/notfound/index.vue";
import Brand from "../views/brand/brand-list.vue";
import CreateBrand from "../views/brand/create-brand.vue";
import Venue from "../views/venue/venue-list";
import CreateVenue from "../views/venue/create-venue.vue";
import InfoCommunity from "../views/info-community/info-community-list";
import Sales from "../views/sales/sales-list";
import CreateSales from "../views/sales/create-sales.vue";
import User from "../views/user/user-list";
// import CreateUser from "../views/user/create-user"
// import UpdateUser from "../views/user/edit-user"
import CommunityList from "../views/community/community-list";
import CreateCommunity from "../views/community/create-community";
import PostList from "../views/post/post-list";
import CreatePostCommunity from "../views/post-community/create-post";
import UpdatePostCommunity from "../views/post-community/post-list";
import PostCommunityList from "../views/post-community/post-list";
import DRPList from "../views/drp/drp-list";
import CreateDRP from "../views/drp/create-drp.vue";
import UpdateSales from "../views/sales/edit-sales";
import EventList from "../views/event-list/event-list";
import EventReview from "../views/event-list/event-review";
import EventEvidence from "../views/event-evidence/event-evidence-list";
import Dashboard from "../views/dashboard/index";
import Project from "../views/project/project-list";
import Subproject from "../views/subproject/subproject-list";
import CreatePost from "../views/post/create-post";
import CreateEvent from "../views/event-list/create-event";
import UpdateEvent from "../views/event-list/edit-event";
import CreateProject from "../views/project/create-project";
import CreateSubProject from "../views/subproject/create-subproject";
import PerformanceList from "../views/community/performance/performance-list.vue";
import CreatePerformance from "../views/community/performance/create-perfromance.vue";
import UpdatePerformance from "../views/community/performance/edit-performance.vue";
import UpdateCommunity from "../views/community/edit-community";
import UpdateBrand from "../views/brand/edit-brand";
import UpdateVenue from "../views/venue/edit-venue";
import UpdateDRP from "../views/drp/edit-drp";
import UpdateProject from "../views/project/edit-project";
import UpdateSubProject from "../views/subproject/edit-subproject";
import Report from "../views/report/index";
import InsertDtkKhk from "../views/report/insert-data";
import UserAM from "../views/am/am-list";
import CreateUserAM from "../views/am/create-am";
import UpdateUserAM from "../views/am/edit-am";
import UserDSO from "../views/dso/dso-list";
import CreateUserDSO from "../views/dso/create-dso";
import UpdateUserDSO from "../views/dso/edit-dso";
import Attribute from "../views/attribute/attribute-list";
import CreateAttribute from "../views/attribute/create-attribute";
import EditAttribute from "../views/attribute/edit-attribute";
import Achievement from "../views/achievement/achievement-list";
import CreateAchievement from "../views/achievement/create-achievement";
import EditAchievement from "../views/achievement/edit-achievement";
import SetSalesTarget from "../views/sales/tables/set-target/set-target-list";
import UserRSO from "../views/rso/rso-list";
import CreateUserRSO from "../views/rso/create-rso";
import UpdateUserRSO from "../views/rso/edit-rso";
import Notification from "../views/notification/notification-list";
import Member from "../views/member/member-list";
import Comments from "../views/post/post-comments";
import ProjectSettings from "../views/project/settings-project";
// Dashboard AM
import CommunityReport from "../views/dashboard-v2/community-report/index";
import MemberReport from "../views/dashboard-v2/member-report/index";
import AchievementReport from "../views/dashboard-v2/achievement-report/index";
import ActivityReport from "../views/dashboard-v2/activity-report/index";
import CommunicationReport from "../views/dashboard-v2/communication-report/index";
// Dashboard KPI
import InsertKPI from "../views/kpi/summary/insert-data";
import SummaryKPI from "../views/kpi/summary/index";
import DetailKPIDSO from "../views/kpi/summary/detail-kpi-dso";
import ActivityReportNew from "../views/kpi/activity-report/index";
import CommunityActivityDetails from "../views/kpi/activity-report/community-total-key/community-details.vue";
import CommunicationReportNew from "../views/kpi/communication-report";
import ConsumptionReportNew from "../views/kpi/consumption-report/index";
// import EventDSOList from "../views/event-dso/event-dso-list"
// import CreateEventDSO from "../views/event-dso/create-event-dso"
// import EditEventDSO from '../views/event-dso/edit-event-dso'
// import ReviewEventDSO from '../views/event-dso/event-dso-review'
import ConsumptionList from "../views/consumption/consumption-list";
import CreateConsumption from "../views/consumption/create-consumption";
import EditConsumption from "../views/consumption/edit-consumption";
import ConsumptionTargetList from "../views/consumption/consumption-target-list";
import InputConsumptionTarget from "../views/consumption/input-consumption";
import SosmedList from "../views/social-media/social-media-list";
import CreateSosmed from "../views/social-media/create-social-media";
import EditSosmed from "../views/social-media/edit-social-media";
import FollowerSosmed from "../views/follower-social-media/follower-social-media-list";
import CreateFollowerSosmed from "../views/follower-social-media/create-follower-social-media";
import EditFollowerSosmed from "../views/follower-social-media/edit-follower-social-media";
import SetSosmedFollower from "../views/follower-social-media/set-medsos-follower/index";
import UserMemberList from "../views/user-member/user-list";
import EditUserMember from "../views/user-member/edit-user";
import UserPICList from "../views/user-pic/user-list";
import CreateUser from "../views/user/create-user";
import InsertUser from "../views/user/insert-user";
import EditUser from "../views/user/edit-user";
// import UserMemberList from "../views/user-member/user-list"
import InsertCommunity from "../views/community/insert-community";
import EventListCommunity from "../views/event-list-community/event-list";
import CreateEventCommunity from "../views/event-list-community/create-event";
import UpdateEventCommunity from "../views/event-list-community/edit-event";
import TargetActivity from "../views/input-target-kpi/target-activity/index";
import CreateTargetCommunity from "../views/input-target-kpi/target-activity/target-community/create-target";
import EditTargetCommunity from "../views/input-target-kpi/target-activity/target-community/edit-target";
import CreateTargetCommunitySuper from "../views/input-target-kpi/target-activity/target-community-super/create-target";
import EditTargetCommunitySuper from "../views/input-target-kpi/target-activity/target-community-super/edit-target";
import CreateTargetCommunityKey from "../views/input-target-kpi/target-activity/target-community-key/create-target";
import EditTargetCommunityKey from "../views/input-target-kpi/target-activity/target-community-key/edit-target";
import CreateTargetEvent from "../views/input-target-kpi/target-activity/target-event/create-target";
import EditTargetEvent from "../views/input-target-kpi/target-activity/target-event/edit-target";
import CreateTargetCompetency from "../views/input-target-kpi/target-activity/target-competency/create-target";
import EditTargetCompetency from "../views/input-target-kpi/target-activity/target-competency/edit-target";
import InputResultCompetency from "../views/input-target-kpi/target-activity/target-competency/input-result";

import TargetCommunication from "../views/input-target-kpi/target-communication/index";
import CreateTargetSosmedDSO from "../views/input-target-kpi/target-communication/target-sosmed-dso/create-target";
import EditTargetSosmedDSO from "../views/input-target-kpi/target-communication/target-sosmed-dso/edit-target";
import CreateTargetSosmedCommunity from "../views/input-target-kpi/target-communication/target-sosmed-community/create-target";
import EditTargetSosmedCommunity from "../views/input-target-kpi/target-communication/target-sosmed-community/edit-target";
import CreateTargetPostDSO from "../views/input-target-kpi/target-communication/target-post-dso/create-target";
import EditTargetPostDSO from "../views/input-target-kpi/target-communication/target-post-dso/edit-target";
import CreateTargetFollower from "../views/input-target-kpi/target-communication/target-follower/create-target";
import EditTargetFollower from "../views/input-target-kpi/target-communication/target-follower/edit-target";

import TargetConsumption from "../views/input-target-kpi/target-consumption/index";
import CreateTargetConsumption from "../views/input-target-kpi/target-consumption/create-target";
import EditTargetConsumption from "../views/input-target-kpi/target-consumption/edit-target";

import ActivityReport76 from "../views/kpi-brand76/activity-report/index";
import CommunicationReport76 from "../views/kpi-brand76/communication-report";
import ConsumptionReport76 from "../views/kpi-brand76/consumption-report/index";
import SummaryDSO from "../views/kpi/summary-dso/index";

import CommunityCategory from "../views/community-category/category-list";
import CreateCategory from "../views/community-category/create-category";
import EditCategory from "../views/community-category/edit-category";

import UserReport from "../views/post-report/user-report/report-list";
import PostReport from "../views/post-report/post-report/report-list";

import EventListDSO from "../views/event-dso/index.vue";
import CreateEventDSO from "../views/event-dso/create-event.vue";
import EditEventDSO from "../views/event-dso/edit-event.vue";
import ReviewEventDSO from "../views/event-dso/event-review.vue";

Vue.use(VueRouter);

const role = getRole();

const routes = [
  {
    path: "/event-list-community",
    name: "Event List Community",
    component: EventListCommunity,
    meta: {
      role: defineRole(role, "eventList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-event-community",
    name: "Create Event Community",
    component: CreateEventCommunity,
    meta: {
      role: defineRole(role, "eventList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-list-community/:id",
    name: "Edit Event Community",
    component: UpdateEventCommunity,
    meta: {
      role: defineRole(role, "eventList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/user-pic-list",
    name: "User PIC",
    component: UserPICList,
    meta: {
      role: defineRole(role, "user"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "user");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-user",
    name: "Create User",
    component: CreateUser,
    meta: {
      role: defineRole(role, "user"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "user");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/insert-community",
    name: "Insert Community",
    component: InsertCommunity,
    meta: {
      role: defineRole(role, "communityList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "communityList");
      if (!access.insert) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/insert-user",
    name: "Insert User",
    component: InsertUser,
    meta: {
      role: defineRole(role, "user"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "user");
      if (!access.insert) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },

  {
    path: "/edit-user/:id",
    name: "Edit User",
    component: EditUser,
    meta: {
      role: defineRole(role, "user"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "user");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/user-member-list",
    name: "User Member",
    component: UserMemberList,
    meta: {
      role: defineRole(role, "user"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "user");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-user-member/:id",
    name: "Edit User Member",
    component: EditUserMember,
    meta: {
      role: defineRole(role, "user"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "user");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/social-media-list",
    name: "Social Media",
    component: SosmedList,
    meta: {
      role: defineRole(role, "sosmed"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sosmed");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-social-media/:id",
    name: "Edit Social Media",
    component: EditSosmed,
    meta: {
      role: defineRole(role, "sosmed"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sosmed");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-social-media",
    name: "Create Social Media",
    component: CreateSosmed,
    meta: {
      role: defineRole(role, "sosmed"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sosmed");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/follower-social-media",
    name: "Follower Social Media",
    component: FollowerSosmed,
    meta: {
      role: defineRole(role, "follower"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "follower");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-follower-social-media/:medfol/:id",
    name: "Edit Follower Social Media",
    component: EditFollowerSosmed,
    meta: {
      role: defineRole(role, "follower"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "follower");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-follower-social-media/:medfol",
    name: "Create Follower Social Media",
    component: CreateFollowerSosmed,
    meta: {
      role: defineRole(role, "follower"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "follower");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/set-sosmed-follower",
    name: "Set Sosmed Follower",
    component: SetSosmedFollower,
    meta: {
      role: defineRole(role, "follower"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "follower");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity",
    name: "Input Target Activity",
    component: TargetActivity,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/target-community",
    name: "Create Target Community",
    component: CreateTargetCommunity,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/target-community-super",
    name: "Create Target Community Super",
    component: CreateTargetCommunitySuper,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/target-community-key",
    name: "Create Target Community Key",
    component: CreateTargetCommunityKey,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/target-event",
    name: "Create Target Event",
    component: CreateTargetEvent,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/target-competency",
    name: "Create Target Competency",
    component: CreateTargetCompetency,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/edit-target-community/:id",
    name: "Edit Target Community",
    component: EditTargetCommunity,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/edit-target-community-super/:id",
    name: "Edit Target Community Super",
    component: EditTargetCommunitySuper,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/edit-target-community-key/:id",
    name: "Edit Target Community Key",
    component: EditTargetCommunityKey,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/edit-target-event/:id",
    name: "Edit Target Event",
    component: EditTargetEvent,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-activity/edit-target-competency/:id",
    name: "Edit Target Competency",
    component: EditTargetCompetency,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/competency-result/:dsoId/:year/:workshop",
    name: "Competency Result",
    component: InputResultCompetency,
    meta: {
      role: defineRole(role, "inputCompetency"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "inputCompetency");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication",
    name: "Input Target Communication",
    component: TargetCommunication,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/target-sosmed-dso",
    name: "Create Target Sosmed DSO",
    component: CreateTargetSosmedDSO,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/target-sosmed-community",
    name: "Create Target Sosmed Community",
    component: CreateTargetSosmedCommunity,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/target-post-dso",
    name: "Create Target Post DSO",
    component: CreateTargetPostDSO,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/target-follower",
    name: "Create Target Follower",
    component: CreateTargetFollower,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/target-sosmed-dso/:id",
    name: "Edit Target Sosmed DSO",
    component: EditTargetSosmedDSO,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/target-sosmed-community/:id",
    name: "Edit Target Sosmed Community",
    component: EditTargetSosmedCommunity,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/target-post-dso/:id",
    name: "Edit Target Post DSO",
    component: EditTargetPostDSO,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/target-follower/:id",
    name: "Edit Target Follower",
    component: EditTargetFollower,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-consumption",
    name: "Input Target Consumption",
    component: TargetConsumption,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-consumption/create-target",
    name: "Create Target Consumption",
    component: CreateTargetConsumption,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/input-target-kpi-communication/edit-target/:id",
    name: "Edit Target Consumption",
    component: EditTargetConsumption,
    meta: {
      role: defineRole(role, "targetKPI"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "targetKPI");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/summary-kpi",
    name: "Summary KPI",
    component: SummaryKPI,
    meta: {
      role: defineRole(role, "dashboardKPIDSO"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPIDSO");
      if (!access.filter) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/insert-kpi",
    name: "Insert KPI",
    component: InsertKPI,
    meta: {
      role: defineRole(role, "dashboardKPIDSO"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPIDSO");
      if (!access.insert) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/detail-kpi-dso/:year/:data",
    name: "Detail KPI DSO",
    component: DetailKPIDSO,
    meta: {
      role: defineRole(role, "dashboardKPIDSO"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPIDSO");
      if (!access.filter) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/kpi-activity",
    name: "Activity Report New",
    component: ActivityReportNew,
    meta: {
      role: defineRole(role, "dashboardKPIDSO"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPIDSO");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/report-activity-detail",
    name: "Community Activity Detail",
    component: CommunityActivityDetails,
    meta: {
      roleKisi: defineRole(role, "reportKisi"),
      roleSummary: defineRole(role, "reportSummary"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "reportSummary");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/kpi-communication",
    name: "Communication Report New",
    component: CommunicationReportNew,
    meta: {
      role: defineRole(role, "dashboardKPIDSO"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPIDSO");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/kpi-consumption",
    name: "Consumption Report New",
    component: ConsumptionReportNew,
    meta: {
      role: defineRole(role, "dashboardKPIDSO"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPIDSO");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/activity-report-76",
    name: "Activity Report 76",
    component: ActivityReport76,
    meta: {
      role: defineRole(role, "dashboardKPI76"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPI76");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/communication-report-76",
    name: "Communication Report 76",
    component: CommunicationReport76,
    meta: {
      role: defineRole(role, "dashboardKPI76"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPI76");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/consumption-report-76",
    name: "Consumption Report 76",
    component: ConsumptionReport76,
    meta: {
      role: defineRole(role, "dashboardKPI76"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPI76");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  // {
  //   path: '/event-list-dso',
  //   name: 'Event List DSO',
  //   component: EventListDSO
  // },
  // {
  //   path: '/event-dso-list',
  //   name: 'Event DSO List',
  //   component: EventDSOList
  // },
  // {
  //   path: '/edit-event-dso',
  //   name: 'Edit Event DSO',
  //   component: EditEventDSO
  // },
  // {
  //   path: '/create-event-dso',
  //   name: 'Create Event DSO',
  //   component: CreateEventDSO
  // },
  // {
  //   path: '/event-dso-review',
  //   name: 'Review Event DSO',
  //   component: ReviewEventDSO
  // },
  {
    path: "/",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/not-found",
    name: "Not Found",
    component: NotFound,
  },
  {
    path: "/brand",
    name: "Brand",
    component: Brand,
    meta: {
      role: defineRole(role, "brand"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "brand");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-brand",
    name: "Create Brand",
    component: CreateBrand,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "brand");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/community-category",
    name: "Community Category",
    component: CommunityCategory,
    meta: {
      role: defineRole(role, "category"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "category");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-category",
    name: "Create Community Category",
    component: CreateCategory,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "category");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-category/:id",
    name: "Edit Community Category",
    component: EditCategory,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "category");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/venue",
    name: "Venue",
    component: Venue,
    meta: {
      role: defineRole(role, "venue"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "venue");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-venue",
    name: "Create Venue",
    component: CreateVenue,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "venue");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/info-community-list",
    name: "Info Komunitas",
    component: InfoCommunity,
    meta: {
      role: defineRole(role, "infoKomunitas"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "infoKomunitas");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/consumption-list",
    name: "Consumption",
    component: ConsumptionList,
    meta: {
      role: defineRole(role, "sales"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-consumption",
    name: "Create Consumption",
    component: CreateConsumption,
    meta: {
      role: defineRole(role, "sales"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-consumption",
    name: "Edit Consumption",
    component: EditConsumption,
    meta: {
      role: defineRole(role, "sales"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },

  {
    path: "/consumption-target-list/:id",
    name: "Consumption Target List",
    component: ConsumptionTargetList,
    meta: {
      role: defineRole(role, "sales"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },

  {
    path: "/input-consumption/:id",
    name: "Input Consumption",
    component: InputConsumptionTarget,
    meta: {
      role: defineRole(role, "sales"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },

  {
    path: "/sales-list/:tab",
    name: "Sales",
    component: Sales,
    meta: {
      role: defineRole(role, "sales"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/user-list",
    name: "User",
    component: User,
    meta: {
      role: defineRole(role, "user"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "user");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  // {
  //   path: '/create-user',
  //   name: 'Create User',
  //   component: CreateUser,
  //   meta: {
  //     role: defineRole(role, 'user')
  //   },
  //   beforeEnter(to, from, next) {
  //     const access = defineRole(role, 'user');
  //     if (!access.create) {
  //       next({
  //         name: 'Not Found'
  //       });
  //     }

  //     next();
  //   }
  // },
  {
    path: "/community-list",
    name: "Community List",
    component: CommunityList,
    meta: {
      role: defineRole(role, "communityList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "communityList");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-community",
    name: "Create Community",
    component: CreateCommunity,
    meta: {
      role: defineRole(role, "communityList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "communityList");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-sales",
    name: "Create Sales",
    component: CreateSales,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/post-list",
    name: "Post",
    component: PostList,
    meta: {
      role: defineRole(role, "post"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "post");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/drp-list",
    name: "DRP",
    component: DRPList,
    meta: {
      role: defineRole(role, "drp"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "drp");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/post-community-list",
    name: "Post Community",
    component: PostCommunityList,
    meta: {
      role: defineRole(role, "post"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "post");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-post-community",
    name: "Edit Post Community",
    component: UpdatePostCommunity,
    meta: {
      role: defineRole(role, "post"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "post");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-post-community",
    name: "Create Post Community",
    component: CreatePostCommunity,
    meta: {
      role: defineRole(role, "post"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "post");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-drp",
    name: "Create DRP",
    component: CreateDRP,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "drp");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-sales/:status/:tab/:id",
    name: "Edit Sales",
    component: UpdateSales,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  // {
  //   path: '/edit-user/:id',
  //   name: 'Edit User',
  //   component: UpdateUser,
  //   meta: {
  //     role: defineRole(role, 'user')
  //   },
  //   beforeEnter(to, from, next) {
  //     const access = defineRole(role, 'user');
  //     if (!access.update) {
  //       next({
  //         name: 'Not Found'
  //       });
  //     }

  //     next();
  //   }
  // },
  {
    path: "/create-event-dso",
    name: "Create Event DSO",
    component: CreateEventDSO,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-event-dso/:group/:id",
    name: "Edit Event DSO",
    component: EditEventDSO,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/review-event-dso/:group/:id",
    name: "Review Event DSO",
    component: ReviewEventDSO,
    meta: {
      role: defineRole(role, "eventList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.review) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/event-dso",
    name: "Event List DSO",
    component: EventListDSO,
    meta: {
      role: defineRole(role, "eventList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/event-list",
    name: "Event List",
    component: EventList,
    meta: {
      role: defineRole(role, "eventList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/event-review/:id",
    name: "Event Review",
    component: EventReview,
    meta: {
      role: defineRole(role, "eventList"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.review) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },

  {
    path: "/event-evidence-list",
    name: "Event Evidence",
    component: EventEvidence,
    meta: {
      role: defineRole(role, "eventEvidence"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventEvidence");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      roleSummary: defineRole(role, "dashboardSummary"),
      roleCommunity: defineRole(role, "dashboardCommunity"),
      roleSales: defineRole(role, "dashboardSales"),
      roleCategory: defineRole(role, "dashboardCategory"),
      roleCategoryClasificationUser: defineRole(role, "dashboardClasificationUser"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardSummary");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/project",
    name: "Project",
    component: Project,
    meta: {
      role: defineRole(role, "project"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "project");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/subproject",
    name: "Subproject",
    component: Subproject,
    meta: {
      role: defineRole(role, "subProject"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "subProject");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-post",
    name: "Create Post",
    component: CreatePost,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "post");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-event",
    name: "Create Event",
    component: CreateEvent,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-event/:id",
    name: "Edit Event",
    component: UpdateEvent,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "eventList");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-project",
    name: "Create Project",
    component: CreateProject,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "project");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-subproject",
    name: "Create Sub Project",
    component: CreateSubProject,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "subProject");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-community/:id",
    name: "Edit Community",
    component: UpdateCommunity,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "communityList");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/community-performance/:id",
    name: "Community Performance",
    component: PerformanceList,
    meta: {
      role: defineRole(role, "medsosPerformance"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "medsosPerformance");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-performance",
    name: "Create Performance",
    component: CreatePerformance,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "medsosPerformance");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/update-performance/:id",
    name: "Update Performance",
    component: UpdatePerformance,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "medsosPerformance");
      if (!access.edit) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-brand/:id",
    name: "Edit Brand",
    component: UpdateBrand,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "brand");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-venue/:id",
    name: "Edit Venue",
    component: UpdateVenue,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "venue");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-drp/:id",
    name: "Edit DRP",
    component: UpdateDRP,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "drp");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-project/:id",
    name: "Edit Project",
    component: UpdateProject,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "project");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-subproject/:id",
    name: "Edit Subroject",
    component: UpdateSubProject,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "subProject");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/report-list",
    name: "Report",
    component: Report,
    meta: {
      roleSummary: defineRole(role, "reportSummary"),
      roleScore: defineRole(role, "reportScore"),
      roleKisi: defineRole(role, "reportKisi"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "reportSummary");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/insert-dtk-khk",
    name: "Insert Data",
    component: InsertDtkKhk,
    meta: {
      roleSummary: defineRole(role, "reportSummary"),
      roleScore: defineRole(role, "reportScore"),
      roleKisi: defineRole(role, "reportKisi"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "reportSummary");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/am-list",
    name: "User AM",
    component: UserAM,
    meta: {
      role: defineRole(role, "userAm"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userAm");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-am",
    name: "Create User AM",
    component: CreateUserAM,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userAm");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-am/:id",
    name: "Edit User AM",
    component: UpdateUserAM,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userAm");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/dso-list",
    name: "User DSO",
    component: UserDSO,
    meta: {
      role: defineRole(role, "userDSO"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userDSO");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-dso",
    name: "Create User DSO",
    component: CreateUserDSO,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userDSO");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-dso/:id",
    name: "Edit User DSO",
    component: UpdateUserDSO,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userDSO");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/attribute-list",
    name: "Attribute",
    component: Attribute,
    meta: {
      role: defineRole(role, "attribute"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "attribute");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },

  {
    path: "/create-attribute",
    name: "Create Attribute",
    component: CreateAttribute,
    meta: {
      role: defineRole(role, "attribute"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "attribute");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },

  {
    path: "/edit-attribute/:id",
    name: "Edit Attribute",
    component: EditAttribute,
    meta: {
      role: defineRole(role, "attribute"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "attribute");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/achievement-list",
    name: "Achievement",
    component: Achievement,
    meta: {
      role: defineRole(role, "achievement"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "achievement");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-achievement",
    name: "Create Achievement",
    component: CreateAchievement,
    meta: {
      role: defineRole(role, "achievement"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "achievement");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-achievement/:id",
    name: "Edit Achievement",
    component: EditAchievement,
    meta: {
      role: defineRole(role, "achievement"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "achievement");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/set-sales-target/:tab/:id",
    name: "Set Sales Target",
    component: SetSalesTarget,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "sales");
      if (!access.set) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/rso-list",
    name: "User RSO",
    component: UserRSO,
    meta: {
      role: defineRole(role, "userRSO"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userRSO");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/create-rso",
    name: "Create User RSO",
    component: CreateUserRSO,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userRSO");
      if (!access.create) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/edit-rso/:id",
    name: "Edit User RSO",
    component: UpdateUserRSO,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "userRSO");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notification,
    meta: {
      role: defineRole(role, "notification"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "notification");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/member/:id",
    name: "Member",
    component: Member,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "member");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/comments/:id",
    name: "Comments",
    component: Comments,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "comments");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/project-settings/:id",
    name: "Project Settings",
    meta: {
      role: defineRole(role, "projectSettings"),
    },
    component: ProjectSettings,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "projectSettings");
      if (!access.update) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/community-report",
    name: "Community Report",
    meta: {
      role: defineRole(role, "communityReport"),
    },
    component: CommunityReport,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "communityReport");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/member-report",
    name: "Member Report",
    meta: {
      role: defineRole(role, "memberReport"),
    },
    component: MemberReport,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "memberReport");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/achievement-report",
    name: "Achievement Report",
    meta: {
      role: defineRole(role, "achievementReport"),
    },
    component: AchievementReport,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "achievementReport");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/summary-kpi-dso",
    name: "Summary KPI DSO",
    meta: {
      role: defineRole(role, "dashboardKPIDSO"),
    },
    component: SummaryDSO,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "dashboardKPIDSO");
      if (!access.summarydso) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/activity-report",
    name: "Activity Report",
    meta: {
      role: defineRole(role, "activityReport"),
    },
    component: ActivityReport,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "activityReport");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/communication-report",
    name: "Communication Report",
    meta: {
      role: defineRole(role, "communicationReport"),
    },
    component: CommunicationReport,
    beforeEnter(to, from, next) {
      const access = defineRole(role, "communicationReport");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },

  {
    path: "/user-report",
    name: "User Report",
    component: UserReport,
    meta: {
      role: defineRole(role, "project"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "project");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
  {
    path: "/post-report",
    name: "Post Report",
    component: PostReport,
    meta: {
      role: defineRole(role, "project"),
    },
    beforeEnter(to, from, next) {
      const access = defineRole(role, "project");
      if (!access.read) {
        next({
          name: "Not Found",
        });
      }

      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  function checkAuth() {
    const token = getToken();
    const tokenExpired = getExpired();
    const tokenOfTimes = moment(tokenExpired).format("DD");
    const currentdate = new Date();
    const datetime = currentdate.getDate();
    return token || datetime < Number(tokenOfTimes);
  }

  const isAuth = checkAuth();

  if (to.name !== "Auth" && !isAuth) {
    removeAuth();
    next({
      name: "Auth",
    });
    return;
  }

  if (to.name === "Auth" && isAuth) {
    if (role == "admin.dso" || role == "admin.dso.low") {
      next("summary-kpi-dso");
      return;
    } else {
      next("summary-kpi");
      return;
    }
  }

  if (!routes.some((route) => route.name === to.name)) {
    next("not-found");
    return;
  }

  next();
});

export default router;
