<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }} 
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-select
                v-model="detailUser.role"
                :items="roles"
                item-text="value"
                item-value="key"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Role/Akses"
                required
                outlined
                dense
              ></v-select>

              <v-autocomplete
                v-model="detailUser.dso.id"
                :items="dsos"
                item-text="name"
                item-value="id"
                label="DSO"
                outlined
                dense
                v-if="getRole.role.dso && detailUser.role === 'admin.inspire'"
              ></v-autocomplete>

              <v-text-field
                v-model="detailUser.email"
                :rules="[
                  (v) => !!v || 'Kolom wajib diisi',
                  (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ]"
                label="Email"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="detailUser.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Pengguna"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="detailUser.gender"
                :items="allGender"
                item-text="genderName"
                item-value="genderName"
                dense
                label="Gender"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                required
                outlined
              ></v-autocomplete>

              <v-text-field
                v-model="detailUser.mobileNumber"
                :rules="[
                  (v) =>
                    Number.isInteger(Number(v)) ||
                    'Isian harus angka',
                  (v) => !!v || 'Kolom wajib diisi',
                ]"
                label="Telepon"
                :maxlength="12"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="detailUser.pob"
                :items="allDistricts"
                item-text="districtName"
                item-value="districtName"
                dense
                label="Tempat Lahir"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                required
                outlined
              ></v-autocomplete>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="detailUser.dob"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="detailUser.dob"
                    label="Tanggal Lahir"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="detailUser.dob" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(detailUser.dob)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          
            <v-col md="4" sm="12" class="p-5">
              <v-alert outlined type="info" v-if="!isAddressEditable">
                Untuk ubah alamat klik
                <strong style="cursor: pointer" @click="editAddress"
                  >Ubah Alamat</strong
                >
              </v-alert>
              <v-alert outlined type="error" v-else>
                Untuk batal ubah alamat klik
                <strong style="cursor: pointer" @click="cancelEditAddress"
                  >Batal Ubah</strong
                >
              </v-alert>
              <template v-if="addressForm">
                <v-select
                  v-model="detailUser.address.province"
                  :items="provinces"
                  item-text="provinceName"
                  item-value="provinceId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Provinsi"
                  required
                  outlined
                  dense
                  @change="getDistrict($event)"
                ></v-select>

                <v-select
                  v-model="detailUser.address.district"
                  :items="districts"
                  item-text="districtName"
                  item-value="districtId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kabupaten/Kota"
                  required
                  outlined
                  dense
                  @change="getSubDistrict($event)"
                ></v-select>

                <v-select
                  v-model="detailUser.address.subDistrict"
                  :items="subDistricts"
                  item-text="subDistrictName"
                  item-value="subDistrictId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kecamatan"
                  required
                  outlined
                  dense
                  @change="getVillages($event)"
                ></v-select>

                <v-select
                  v-model="detailUser.address.village"
                  :items="villages"
                  item-text="villageName"
                  item-value="villageId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kelurahan/Desa"
                  required
                  outlined
                  dense
                  @change="getVillagesValue($event)"
                ></v-select>

                <v-text-field
                  v-model="detailUser.address.street"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Jalan"
                  required
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="detailUser.address.zipCode"
                  :rules="[
                    (v) =>
                      Number.isInteger(Number(v)) ||
                      'Isian harus angka',
                    (v) => !!v || 'Kolom wajib diisi',
                  ]"
                  label="Kode Pos"
                  :maxlength="5"
                  required
                  outlined
                  dense
                ></v-text-field>
              </template>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Provinces -->
    <Alert
      :status="isErrorGetProvinces && errorCodeGetProvinces !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetProvinces"
    />

    <!-- Error Districts -->
    <Alert
      :status="isErrorGetDistricts && errorCodeGetDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDistricts"
    />

    <!-- Error Sub Districts -->
    <Alert
      :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetSubDistricts"
    />

    <!-- Error Villages -->
    <Alert
      :status="isErrorGetVillages && errorCodeGetVillages !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetVillages"
    />

    <!-- Error All Districts -->
    <Alert
      :status="isErrorGetAllDistricts && errorCodeGetAllDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAllDistricts"
    />

    <!-- Error Roles -->
    <Alert
      :status="isErrorRoles && errorCodeRoles !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseRoles"
    />

    <!-- Error Get Users -->
    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../controllers/UserControllers.js";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import { RolesControllers } from "../../controllers/RolesControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-user",
  data: () => ({
    user: new UserControllers(),
    area: new AreaControllers(),
    role: new RolesControllers(),
    valid: true,
    title: "Ubah Pengguna",

    isLoading: false,
    fullPage: true,

    menu: false,

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
      allDistricts: null,
    },

    selectScope: {
      page: 1,
      limit: 1000,
    },

    isAddressEditable: false,
    addressForm: false
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // users length
    userLength() {
      return this.user.userList.length;
    },

    getRole() {
      return this.$route.meta;
    },

    // roles
    roles() {
      return this.role.rolesList;
    },

    isErrorRoles() {
      return this.role.isError;
    },

    errorCauseRoles() {
      return this.role.errorCause;
    },

    errorCodeRoles() {
      return this.role.errorCode;
    },

    // user
    isErrorUpdateUser() {
      return this.user.isError;
    },

    errorCauseUpdateUser() {
      return this.user.errorCause;
    },

    isLoadingUpdateUser() {
      return this.user.isLoading;
    },

    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },

        // Gender
    allGender(){
      return [{genderName:"Laki-laki"},{genderName:"Perempuan"}]
    },

    // all districts
    allDistricts() {
      return this.area.allDistrictList.concat(["JAKARTA"]);
    },

    errorCodeGetAllDistricts() {
      return this.area.errorCodeAllDistrict;
    },

    isErrorGetAllDistricts() {
      return this.area.isErrorAllDistrict;
    },

    errorCauseGetAllDistricts() {
      return this.area.errorCauseAllDistrict;
    },

    // detail user
    detailUser() {
      const user = this.user.userDetail;
      if (user) {
        return {
          ...user,
          gender: user.gender? user.gender: "",
          address: {
            province: user.address ? user.address.province : "",
            district: user.address ? user.address.district : "",
            subDistrict: user.address ? user.address.subDistrict : "",
            village: user.address ? user.address.village : "",
            street: user.address ? user.address.street : "",
            zipCode: user.address ? user.address.zipCode : "",
          },
        };
      }

      return null;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    // dso
    dsos() {
      return this.user.userListAM;
    },
  },

  created() {
    this.getAllDistricts();
    this.getRoles();
    this.getUsers();
    this.getUsersAll();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getUsersAll() {
      return this.user.getUserAMList('admin.dso', 1, 1000, '')
    },

    getUsers() {
      return this.user.getUserDetailEdit(
        this.$route.params.id
      );
    },

    getRoles() {
      return this.role.getRolesList();
    },

    getProvinces() {
      return this.area.getProvinceList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find(
        (item) => item.provinceId === provinceId
      );
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(
        provinceId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find(
        (item) => item.districtId === districtId
      );
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(
        districtId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find(
        (item) => item.subDistrictId === subDistrictId
      );
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(
        subDistrictId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getAllDistricts() {
      return this.area.getAllDistrictList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillagesValue(villageId) {
      const { villageName, postalCode } = this.villages.find(
        (item) => item.villageId === villageId
      );
      this.addressFiltered.village = villageName;
      this.detailUser.address.zipCode = postalCode;
    },

    async submitProcess(
      email,
      name,
      role,
      gender,
      mobileNumber,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode,
      pob,
      dob
    ) {
      return this.user.updateUserList(
        this.$route.params.id,
        email,
        name,
        role,
        gender,
        mobileNumber,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        pob,
        dob
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;

        if (this.isAddressEditable) {
          await this.submitProcess(
            this.detailUser.email,
            this.detailUser.name,
            this.detailUser.role,
            this.detailUser.gender,
            this.detailUser.number,
            this.addressFiltered.province,
            this.addressFiltered.district,
            this.addressFiltered.subDistrict,
            this.addressFiltered.village,
            this.detailUser.address.street,
            this.detailUser.address.zipCode,
            this.detailUser.pob,
            this.detailUser.dob,
          );
        } else {
          await this.submitProcess(
            this.detailUser.email,
            this.detailUser.name,
            this.detailUser.role,
            this.detailUser.gender,
            this.detailUser.number,
            this.detailUser.address.province,
            this.detailUser.address.district,
            this.detailUser.address.subDistrict,
            this.detailUser.address.village,
            this.detailUser.address.street,
            this.detailUser.address.zipCode,
            this.detailUser.pob,
            this.detailUser.dob,
          );
        }

        if (this.isErrorUpdateUser) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateUser,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });

          this.back();
        }
      }
    },

    editAddress() {
      this.isAddressEditable = true;
      this.addressForm = true;
      this.getProvinces();
    },

    cancelEditAddress() {
      this.isAddressEditable = false;
      this.addressForm = false;
    }
  },
};
</script>