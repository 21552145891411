<template>
  <div>
    <v-row>
      <v-col md="12" sm="12">
        <v-alert type="info">
          <div>
            Total komunitas:
            <span v-if="!isLoadingTotal"> {{ totalCommunity }} </span>
            <span v-else>Memuat...</span>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <BarTotalCommunity
      :isLoadingDSOS="isLoadingDSOS"
      :dsos="areaDSOList"
      style="margin-bottom: 50px"
    />
    <PieCharts :dsos="areaDSOList" />
    <Alert
      :status="isErrorTotal"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauaseTotal"
    />
  </div>
</template>
<script>
import BarTotalCommunity from "./right-charts/bar-brand-total-community.vue";
import PieCharts from "./right-charts/pie-charts.vue";
import { mapGetters, mapActions } from "vuex";
import { AreaManagerControllers } from "../../../../controllers/AreaManagerControllers.js";
import { DashboardV2Controller } from "../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../components/alert";

export default {
  name: "community-report-right",
  data: () => ({
    area: new AreaManagerControllers(),
    dashboard: new DashboardV2Controller(),
  }),

  methods: {
    ...mapActions(["getAreaDSO"]),
    async getTotalCommunity() {
      return this.dashboard.GetDashboarTotalCommunity();
    },
  },

  created() {
    this.getAreaDSO();
    this.getTotalCommunity();
  },

  computed: {
    ...mapGetters(["areaDSOList"]),
    isLoadingDSOS() {
      return this.area.isLoading;
    },
    isErrorDSOS() {
      return this.area.isError;
    },
    errorCauseDSOS() {
      return this.area.errorCause;
    },
    errorCodeDSOS() {
      return this.area.errorCode;
    },

    totalCommunity() {
      return this.dashboard.totalComunity.reduce(
        (total, item) => total + item.count,
        0
      );
    },
    isLoadingTotal() {
      return this.dashboard.isLoadingTotalCommunity;
    },
    isErrorTotal() {
      return this.dashboard.isErrorTotalCommunity;
    },
    errorCauaseTotal() {
      return this.dashboard.errorCauseTotalCommunity;
    },
    errorCodeTotal() {
      return this.dashboard.errorCodeTotalCommunity;
    },
  },

  components: {
    BarTotalCommunity,
    PieCharts,
    Alert,
  },
};
</script>