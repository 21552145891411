<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="12" sm="12">
        <v-card v-if="getRole.roleCategoryClasificationUser.read">
          <v-card-text>
            <UserGrowth />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12" sm="12" v-if="getRole.roleCategory.read">
        <v-card>
          <v-card-text>
            <CategoryCommunity />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12" sm="12" v-if="getRole.roleCategory.read">
        <v-card>
          <v-card-text>
            <Engagement />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="12" v-if="getRole.roleCommunity.read">
        <v-card>
          <v-card-text>
            <CommunityGrowth />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="12" v-if="getRole.roleSales.read">
        <v-card>
          <v-card-text>
            <SalesGrowth />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UserGrowth from "./components/user-growth";
import CommunityGrowth from "./components/community-growth";
import SalesGrowth from "./components/sales-growth";
import CategoryCommunity from "./components/community-category";
import Engagement from "./components/engagement-growth";

export default {
  name: "dashboard",
  data: () => ({
    title: "Dashboard",
  }),
  computed: {
    getRole() {
      return this.$route.meta;
    },
  },
  components: {
    UserGrowth,
    CommunityGrowth,
    SalesGrowth,
    CategoryCommunity,
    Engagement
  },
};
</script>