import {
    CreateCompetencyRequest,
    DeleteCompetencyRequest,
    UpdateCompetencyRequest,
    CompetencyListRequest,
    CompetencyDetailRequest,
    getCompetencyKpiRequest, 
    getCompetencyOverviewRequest
} from "../request/CompetencyRequest.js";

export class CompetencyControllers {
    competencyList = new Array();
    competencyDetail = new Object();
    competencyDownload = new Array();
    competencyAchievement = new Object();
    competencyOverview = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorUpdate = false;
    errorCauseUpdate = "";
    errorCodeUpdate = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getCompetencyAchievement(dsoId, year) {
        this.setLoading(true);
        const resp = getCompetencyKpiRequest(dsoId, year)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setCompetencyAchievement(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getCompetencyOverview(page,limit, year) {
        this.setLoading(true);
        const resp = getCompetencyOverviewRequest(page,limit, year)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setCompetencyOverview(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getCompetencyList(dsoId, year, workshop, keyword) {
        this.setLoading(true);
        const resp = CompetencyListRequest(dsoId, year, workshop, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setCompetencyList(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getCompetencyDetail(id) {
        this.setLoading(true);
        const resp = CompetencyDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setCompetencyDetail(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadCompetencyList(page, limit, keyword) {
        this.setLoadingDownload(true);
        const resp = CompetencyListRequest(page, limit, keyword)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setCompetencyListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setCompetencyListDownload(data) {
        this.competencyDownload = data;
    }

    setCompetencyList(data) {
        this.competencyList = data;
    }

    setCompetencyAchievement(data) {
        this.competencyAchievement = data;
    }

    setCompetencyOverview(data) {
        this.competencyOverview = data;
    }

    setCompetencyDetail(data) {
        this.competencyDetail = data;
    }

    createCompetencyList(
        dso,
        name,
        workshop,
        score,
        year
    ) {
        this.setLoading(true);
        const resp = CreateCompetencyRequest(
                dso,
                name,
                workshop,
                score,
                year
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteCompetency(id) {
        this.setLoading(true);
        const resp = DeleteCompetencyRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateCompetencyList(
        id,
        name,
        workshop,
        score,
        year
    ) {
        this.setLoading(true);
        const resp = UpdateCompetencyRequest(
                id,
                name,
                workshop,
                score,
                year
            )
            .then(() => {
                this.setErrorUpdate(false);
            }).catch(err => {
                this.setErrorUpdate(true);
                this.setErrorCauseUpdate(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setErrorUpdate(status) {
        this.isErrorUpdate = status;
    }

    setErrorCauseUpdate(err) {
        this.errorCauseUpdate = err;
    }
}