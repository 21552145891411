<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon @click="back" color="primary">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-autocomplete
                v-model="detailKpiTarget.dso"
                :items="dsos"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="DSO"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="detailKpiTarget.sosmedDsoTarget"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Target"
                required
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Alert from "../../components/alert";
// import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../../../controllers/UserControllers.js";
import { KpiTargetControllers } from "../../../../controllers/KpiTargetControllers.js";
import Swal from "sweetalert2";

export default {
  name: "update-kpitarget",
  data: () => ({
    user: new UserControllers(),
    kpitarget: new KpiTargetControllers(),
    valid: true,
    title: "Edit Target KPI Medsos DSO",

    isLoading: false,
    fullPage: true,

    selectScope: {
      page: 1,
      limit: 600,
    },

    tag: {
      value: [],
      options: [],
    },
    logoDisplay: null,
    thereIsImage: false,
  }),

  // components: {
  //   Loading,
  //   Alert,
  // },

  computed: {
    // kpitarget length
    kpitargetLength() {
      return this.kpitarget.kpitargetList.length;
    },

    // kpitargets
    isErrorUpdateKpiTarget() {
      return this.kpitarget.isError;
    },

    errorCodeUpdateKpiTarget() {
      return this.kpitarget.errorCode;
    },

    errorCauseUpdateKpiTarget() {
      return this.kpitarget.errorCause;
    },

    isLoadingUpdateKpiTarget() {
      return this.kpitarget.isLoading;
    },

    // users
    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    detailKpiTarget() {
      const kpiTarget = this.kpitarget.kpitargetDetail;

      if (kpiTarget) {
        return {
          ...kpiTarget,
          dso: kpiTarget.dso ? kpiTarget.dso.id : '',
          year: kpiTarget.year ? kpiTarget.year : null,
          communityTarget: kpiTarget.communityTarget ? kpiTarget.communityTarget : null,
          communitySuperTarget: kpiTarget.communitySuperTarget ? kpiTarget.communitySuperTarget : null,
          communityKeyTarget: kpiTarget.communityKeyTarget ? kpiTarget.communityKeyTarget : null,
          eventTarget: kpiTarget.eventTarget ? kpiTarget.eventTarget : null,
          eventLkkTarget: kpiTarget.eventLkkTarget ? kpiTarget.eventLkkTarget : null,
          eventWithVenueDrpTarget: kpiTarget.eventWithVenueDrpTarget ? kpiTarget.eventWithVenueDrpTarget : null,
          eventEsportTarget: kpiTarget.eventEsportTarget ? kpiTarget.eventEsportTarget : null,
          eventEsportSuperTarget: kpiTarget.eventEsportSuperTarget ? kpiTarget.eventEsportSuperTarget : null,
          eventSuperNonEsportTarget: kpiTarget.eventSuperNonEsportTarget ? kpiTarget.eventSuperNonEsportTarget : null,
          competencyTarget: kpiTarget.competencyTarget ? kpiTarget.competencyTarget : null,
          sosmedCommunityTarget: kpiTarget.sosmedCommunityTarget ? kpiTarget.sosmedCommunityTarget : null,
          sosmedDsoTarget: kpiTarget.sosmedDsoTarget ? kpiTarget.sosmedDsoTarget : null,
          postDsoTarget: kpiTarget.postDsoTarget ? kpiTarget.postDsoTarget : null,
          sosmedFollowerTarget: kpiTarget.sosmedFollowerTarge ? kpiTarget.sosmedFollowerTarge : null,
          consumptionTarget: kpiTarget.consumptionTarget ? kpiTarget.consumptionTarget : null,
          eventBuddySystemTarget: kpiTarget.eventBuddySystemTarget ? kpiTarget.eventBuddySystemTarget : null,
          eventRegionalTarget: kpiTarget.eventRegionalTarget ? kpiTarget.eventRegionalTarget : null,
          eventSharingTarget: kpiTarget.eventSharingTarget ? kpiTarget.eventSharingTarget : null,
          eventLocalTarget: kpiTarget.eventLocalTarget ? kpiTarget.eventLocalTarget : null,
          postCommunityTarget: kpiTarget.postCommunityTarget ? kpiTarget.postCommunityTarget : null
        };
      }
      return null;
    },
  },

  created() {
    this.getUsers();
    this.getKpiTarget();
  },

  methods: {
    async getKpiTarget() {
      return this.kpitarget.getKpiTargetDetail(this.$route.params.id);
    },
    back() {
      this.$router.go(-1);
    },

    getUsers() {
      return this.user.getUserAMList("admin.dso", 1, 1000, "");
    },

    async submitProcess(
      dso,
      year,
      communityTarget,
      communitySuperTarget,
      communityKeyTarget,
      eventTarget,
      eventLkkTarget,
      eventWithVenueDrpTarget,
      eventEsportTarget,
      eventEsportSuperTarget,
      eventSuperNonEsportTarget,
      competencyTarget,
      sosmedCommunityTarget,
      sosmedDsoTarget,
      postDsoTarget,
      sosmedFollowerTarget,
      consumptionTarget,
      eventBuddySystemTarget,
      eventRegionalTarget,
      eventSharingTarget,
      eventLocalTarget,
      postCommunityTarget
    ) {
      return this.kpitarget.updateKpiTargetList(
        this.$route.params.id,
        dso,
        year,
        communityTarget,
        communitySuperTarget,
        communityKeyTarget,
        eventTarget,
        eventLkkTarget,
        eventWithVenueDrpTarget,
        eventEsportTarget,
        eventEsportSuperTarget,
        eventSuperNonEsportTarget,
        competencyTarget,
        sosmedCommunityTarget,
        sosmedDsoTarget,
        postDsoTarget,
        sosmedFollowerTarget,
        consumptionTarget,
        eventBuddySystemTarget,
        eventRegionalTarget,
        eventSharingTarget,
        eventLocalTarget,
        postCommunityTarget
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailKpiTarget.dso,
          parseFloat(this.detailKpiTarget.year),
          parseFloat(this.detailKpiTarget.communityTarget),
          parseFloat(this.detailKpiTarget.communitySuperTarget),
          parseFloat(this.detailKpiTarget.communityKeyTarget),
          parseFloat(this.detailKpiTarget.eventTarget),
          parseFloat(this.detailKpiTarget.eventLkkTarget),
          parseFloat(this.detailKpiTarget.eventWithVenueDrpTarget),
          parseFloat(this.detailKpiTarget.eventEsportTarget),
          parseFloat(this.detailKpiTarget.eventEsportSuperTarget),
          parseFloat(this.detailKpiTarget.eventSuperNonEsportTarget),
          parseFloat(this.detailKpiTarget.competencyTarget),
          parseFloat(this.detailKpiTarget.sosmedCommunityTarget),
          parseFloat(this.detailKpiTarget.sosmedDsoTarget),
          parseFloat(this.detailKpiTarget.postDsoTarget),
          parseFloat(this.detailKpiTarget.sosmedFollowerTarget),
          parseFloat(this.detailKpiTarget.consumptionTarget),
          parseFloat(this.detailKpiTarget.eventBuddySystemTarget),
          parseFloat(this.detailKpiTarget.eventRegionalTarget),
          parseFloat(this.detailKpiTarget.eventSharingTarget),
          parseFloat(this.detailKpiTarget.eventLocalTarget),
          parseFloat(this.detailKpiTarget.postCommunityTarget)
        );

        if (this.isErrorUpdateKpiTarget) {
          this.isLoading = false;
          this.isErrorUpdateKpiTarget = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateKpiTarget,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diupdate",
          });
          this.back();
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorUpdateKpiTarget = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
