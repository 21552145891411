<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
            <v-btn icon @click="back" color="primary">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          {{title}}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-autocomplete
                v-model="form.dso"
                :items="dsos"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="DSO"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.year"
                :items="years"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tahun"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.competencyTarget"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Target"
                required
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Alert from "../../components/alert";
// import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../../../controllers/UserControllers.js";
import { KpiTargetControllers} from "../../../../controllers/KpiTargetControllers.js";
import Swal from "sweetalert2";

export default {
  name: "update-kpitarget",
  data: () => ({
    user: new UserControllers(),
    kpitarget: new KpiTargetControllers(),
    valid: true,
    title: "Tambah Target KPI Kompetency",

    isLoading: false,
    fullPage: true,

    selectScope: {
      page: 1,
      limit: 600,
    },

    form: {
      dso: null,
      year: null,
      communityTarget: null,
      communitySuperTarget: null,
      communityKeyTarget: null,
      eventTarget: null,
      eventLkkTarget: null,
      eventWithVenueDrpTarget: null,
      eventEsportTarget: null,
      eventEsportSuperTarget: null,
      eventSuperNonEsportTarget: null,
      competencyTarget: null,
      sosmedCommunityTarget: null,
      sosmedDsoTarget: null,
      postDsoTarget: null,
      sosmedFollowerTarget: null,
      consumptionTarget: null
    },

    tag: {
      value: [],
      options: [],
    },
    logoDisplay: null,
    thereIsImage: false,
  }),

  // components: {
  //   Loading,
  //   Alert,
  // },

  computed: {
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },
    
    // kpitargets
    isErrorCreateKpiTarget() {
      return this.kpitarget.isError;
    },

    errorCodeCreateKpiTarget(){
      return this.kpitarget.errorCode;
    },

    errorCauseCreateKpiTarget() {
      return this.kpitarget.errorCause;
    },

    isLoadingCreateKpiTarget() {
      return this.kpitarget.isLoading;
    },

    // users
    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getUsers() {
      return this.user.getUserAMList('admin.dso', 1, 1000, '')
    },

    async submitProcess(
      dso,
      year,
      communityTarget,
      communitySuperTarget,
      communityKeyTarget,
      eventTarget,
      eventLkkTarget,
      eventWithVenueDrpTarget,
      eventEsportTarget,
      eventEsportSuperTarget,
      eventSuperNonEsportTarget,
      competencyTarget,
      sosmedCommunityTarget,
      sosmedDsoTarget,
      postDsoTarget,
      sosmedFollowerTarget,
      consumptionTarget
    ) {
      return this.kpitarget.createKpiTargetList(
        dso,
        year,
        communityTarget,
        communitySuperTarget,
        communityKeyTarget,
        eventTarget,
        eventLkkTarget,
        eventWithVenueDrpTarget,
        eventEsportTarget,
        eventEsportSuperTarget,
        eventSuperNonEsportTarget,
        competencyTarget,
        sosmedCommunityTarget,
        sosmedDsoTarget,
        postDsoTarget,
        sosmedFollowerTarget,
        consumptionTarget
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.dso,
          parseInt(this.form.year),
          parseInt(this.form.communityTarget),
          parseInt(this.form.communitySuperTarget),
          parseInt(this.form.communityKeyTarget),
          parseInt(this.form.eventTarget),
          parseInt(this.form.eventLkkTarget),
          parseInt(this.form.eventWithVenueDrpTarget),
          parseInt(this.form.eventEsportTarget),
          parseInt(this.form.eventEsportSuperTarget),
          parseInt(this.form.eventSuperNonEsportTarget),
          parseInt(this.form.competencyTarget),
          parseInt(this.form.sosmedCommunityTarget),
          parseInt(this.form.sosmedDsoTarget),
          parseInt(this.form.postDsoTarget),
          parseInt(this.form.sosmedFollowerTarget),
          parseInt(this.form.consumptionTarget)
        );

        if (this.isErrorCreateKpiTarget) {
          this.isLoading = false;
          this.isErrorCreateKpiTarget = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateKpiTarget,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.back()
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorCreateKpiTarget = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
