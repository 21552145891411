<template>
<div>
  <v-container class="pt-8">
    <v-row justify="space-between">
      <v-col md="10" sm="12" xs="12" block>
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }} {{ kpiTarget.name }}
        </h2>
      </v-col>
      <v-col md="2" sm="12" xs="12">
        <v-btn
          class="float-right"
          :loading="isLoadingPDF"
          :disabled="isLoadingPDF"
          depressed
          color="error"    
          @click="pdf">
            Export PDF
            <template v-slot:loader>
              <span>Exporting...</span>
            </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <div id="kpi-detail-content-chart">
  <v-container class="pt-3">
    <v-row>
      <v-col md="4" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>KPI Aktivitas</v-card-title>
          <v-btn v-if="!isLoadingPDF" text block right class="font-weight-black" color='primary' @click="toKPIActivity">
          Dashboard Aktivitas <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Jenis KPI</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in kpiTarget.detailSummary.aktivitas" :key="index" >
                  <td>{{index+1}}</td>
                  <td>{{ item.name }}</td>
                  <td v-if="item.achieved === 'ACHIEVED'">
                    <div class="d-flex flex-row green--text font-weight-black align-center">
                    ACHIEVED
                    <img src="../../../assets/achieved.png" alt="" width="20px" height="20px">
                    </div>
                  </td>
                  <td v-else-if="isNaN(item.achieved)">
                    <div class="red--text font-weight-black">
                    {{item.achieved}}
                    </div>
                  </td>
                  <td v-else class="red--text font-weight-black">
                    {{Math.floor(item.achieved || 0)}}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col md="4" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>KPI Komunikasi</v-card-title>
          <v-btn v-if="!isLoadingPDF" text block right class="font-weight-black" color='primary' @click="toKPICommunication">
          Dashboard Komunikasi <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Jenis KPI</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in kpiTarget.detailSummary.komunikasi" :key="index" >
                  <td>{{index+1}}</td>
                  <td>{{ item.name }}</td>
                  <td v-if="item.achieved === 'ACHIEVED' || Math.floor(item.achieved)>100">
                    <div class="d-flex flex-row green--text font-weight-black align-center">
                    ACHIEVED
                    <img src="../../../assets/achieved.png" alt="" width="20px" height="20px">
                    </div>
                  </td>
                  <td v-else-if="isNaN(item.achieved)">
                    <div class="red--text font-weight-black">
                    {{item.achieved}}
                    </div>
                  </td>
                  <td v-else class="red--text font-weight-black">
                    {{Math.floor(item.achieved || 0)}}%
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col md="4" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>KPI Konsumsi</v-card-title>
          <v-btn v-if="!isLoadingPDF" text block right class="font-weight-black" color='primary' @click="toKPIConsumption">
          Dashboard Konsumsi <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Jenis KPI</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in kpiTarget.detailSummary.konsumsi" :key="index" >
                  <td>{{index+1}}</td>
                  <td>{{ item.name }}</td>
                  <td v-if="item.achieved === 'ACHIEVED' || Math.floor(item.achieved)>100">
                    <div class="d-flex flex-row green--text font-weight-black align-center">
                    ACHIEVED
                    <img src="../../../assets/achieved.png" alt="" width="20px" height="20px">
                    </div>
                  </td>
                  <td v-else-if="isNaN(item.achieved)">
                    <div class="red--text font-weight-black">
                    {{item.achieved}}
                    </div>
                  </td>
                  <td v-else class="red--text font-weight-black">
                    {{Math.floor(item.achieved || 0)}}%
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isOldKPI">
      <v-col>
        <v-card>
          <v-card-title>
            CAPAIAN KOMPETENSI
          </v-card-title>
          <Competency :chosenYear="chosenYear" :dso="kpiTarget.id"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </div>
</div>
</template>

<script>
import Competency from './competency.vue'
import {toJpeg} from "html-to-image";
import {jsPDF} from "jspdf";
import {mapActions} from 'vuex'

export default {
  data: () => ({
    title: "Detail KPI",
    kpiTarget: {},
    chosenYear:'2022',
    isOldKPI: false,
    capaian: {
      dso: "DSO Bekasi Test",
      activity: [
        {
          name: "Target Jumlah Komunitas",
          status: true,
        },
        {
          name: "Target Jumlah Komunitas Super",
          status: true,
        },
        {
          name: "Target Event",
          status: false,
        },
        {
          name: "Target Event LKK",
          status: true,
        },
        {
          name: "Target Event dengan Venue dan DRP",
          status: false,
        },
        {
          name: "Target Event E-Sport",
          status: true,
        },
        {
          name: "Target Event E-Sport Super",
          status: true,
        },
        {
          name:
            "Target Melibatkan Brand Super (Bola atau Pecinta Alam) Min. 2 Kali",
          status: true,
        },
        {
          name: "Target Kompetensi",
          status: false,
        },
      ],
      communication: [
        {
          name: "Target Medsos Komunitas",
          status: true,
        },
        {
          name: "Target Medsos DSO",
          status: true,
        },
        {
          name: "Target Konten DSO",
          status: true,
        },
        {
          name: "Target jumlah followers min 50%",
          status: true,
        },
      ],
      consumption: [
        {
          name: "Target Konsumsi",
          status: true,
        },
      ],
    },
    isLoadingPDF: false,
  }),

  created() {
    try {
      this.kpiTarget = JSON.parse(this.$route.params.data)
      console.log(this.kpiTarget)
      this.chosenYear = Number(this.$route.params.year)
      if(Number(this.$route.params.year)<2023){
        this.isOldKPI = true
      }else{
        this.isOldKPI = false
      }
    } catch (err) {
      console.error('error parsing payload')
    }
  },

  methods: {
    ...mapActions({
      setDsoId: 'setDso/setdsoId'
    }),
    pdf() {
      this.isLoadingPDF = true
      const self = this
      setTimeout(() => {
        toJpeg(document.getElementById('kpi-detail-content-chart'), {backgroundColor: "#FFFFFF"})
        .then(function (dataUrl) {
          var doc = new jsPDF();

          doc.setFontSize(24);
          doc.text("Chart KPI KPI Detail", 18, 20);
          doc.setFontSize(8);
          doc.setTextColor("#616161")
          doc.text(`DSO: ${self.kpiTarget.name}`, 19, 28);
          doc.addImage(dataUrl, "JPEG", 15, 40, 180, 200);
          doc.save("chart-kpi-summary-detail.pdf");
          self.isLoadingPDF = false
        });
      }, 500)
    },
    back() {
      this.$router.go(-1);
    },

    toKPIActivity() {
      this.setDsoId(this.kpiTarget.id)
      this.$router.push({
        name: "Activity Report New",
      });
    },
    toKPICommunication() {
      this.setDsoId(this.kpiTarget.id)
      this.$router.push({
        name: "Communication Report New",
      });
    },
    toKPIConsumption() {
      this.setDsoId(this.kpiTarget.id)
      this.$router.push({
        name: "Consumption Report New",
      });
    },
  },

  components:{
    Competency
  }
};
</script>
