import { render, staticRenderFns } from "./bar-brand-member-active.vue?vue&type=template&id=33553c0b&scoped=true&"
import script from "./bar-brand-member-active.vue?vue&type=script&lang=js&"
export * from "./bar-brand-member-active.vue?vue&type=script&lang=js&"
import style0 from "./bar-brand-member-active.vue?vue&type=style&index=0&id=33553c0b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33553c0b",
  null
  
)

export default component.exports