import axios from "axios";
import { ReportScores, ReportKisi, ReportActivityKey, ReportKisiScore, CommunityKeyKPI, InputDtkKhk, ReportActivityKeyByDate } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
  Authorization: `Bearer ${getToken()}`,
};
export const ReportScoreRequest = (dso, brand, category) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${ReportScores(dso, brand, category)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};
export const CommunityKeyKPIRequest = (dsoId, year) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${CommunityKeyKPI(dsoId, year)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const ReportKisiRequest = (dso, brand, category, periodeScoring) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${ReportKisi(dso, brand, category, periodeScoring)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};
export const ReportActivityKeyRequest = (dso, brand, category,  score, periodeScoring) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${ReportActivityKey(dso, brand, category, score, periodeScoring)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const ReportActivityKeyByDateRequest = (dso, brand, category, score, date) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${ReportActivityKeyByDate(dso, brand, category, score, date)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const ReportKisiScoreRequest = (dso, brand, category, periodeScoring) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${ReportKisiScore(dso, brand, category, periodeScoring)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const InputDtkKhkRequest = (year, dataInput) => {
  console.log(year);
  const resp = axios({
    method: "post",
    url: `${BaseUrl()}${InputDtkKhk()}`,
    data: {
      year: year.toString(),
      dataInput: dataInput,
    },
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};
