<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-autocomplete
                v-model="form.dso"
                :items="users"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="DSO"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.areaId"
                :items="allDistricts"
                item-text="districtName"
                item-value="districtName"
                dense
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="ID Area"
                required
                outlined
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.brands"
                :items="brands"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Brand"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Venue"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.excellence"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Keunggulan"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="form.category"
                :items="categories"
                item-text="value"
                item-value="value"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kategori"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Alamat</v-alert>
              <v-autocomplete
                v-model="form.address.province"
                :items="provinces"
                item-text="provinceName"
                item-value="provinceId"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Provinsi"
                required
                outlined
                dense
                @change="getDistrict($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.district"
                :items="districts"
                item-text="districtName"
                item-value="districtId"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kabupaten/Kota"
                required
                outlined
                dense
                @change="getSubDistrict($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.subDistrict"
                :items="subDistricts"
                item-text="subDistrictName"
                item-value="subDistrictId"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kecamatan"
                required
                outlined
                dense
                @change="getVillages($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.village"
                :items="villages"
                item-text="villageName"
                item-value="villageId"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kelurahan/Desa"
                required
                outlined
                dense
                @change="getVillagesValue($event)"
              ></v-autocomplete>

              <v-text-field
                v-model="form.address.street"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Jalan"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.address.zipCode"
                :rules="[
                  (v) =>
                    Number.isInteger(Number(v)) ||
                    'The value must be an integer number',
                  (v) => !!v || 'Kolom wajib diisi',
                ]"
                label="Kode Pos"
                :maxlength="5"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Users -->
    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    />

    <!-- Error Brands -->
    <Alert
      :status="isErrorGetBrands && errorCodeGetBrands !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetBrands"
    />

    <!-- Error Categories -->
    <Alert
      :status="isErrorGetCategories && errorCodeGetCategories !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetCategories"
    />

    <!-- Error Provinces -->
    <Alert
      :status="isErrorGetProvinces && errorCodeGetProvinces !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetProvinces"
    />

    <!-- Error Districts -->
    <Alert
      :status="isErrorGetDistricts && errorCodeGetDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDistricts"
    />

    <!-- Error Sub Districts -->
    <Alert
      :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetSubDistricts"
    />

    <!-- Error Villages -->
    <Alert
      :status="isErrorGetVillages && errorCodeGetVillages !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetVillages"
    />

    <!-- Error All Districts -->
    <Alert
      :status="isErrorGetAllDistricts && errorCodeGetAllDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAllDistricts"
    />
  </v-container>
</template>
<script>
import { VenueControllers } from "../../controllers/VenueControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../controllers/UserControllers.js";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-venue",
  data: () => ({
    venue: new VenueControllers(),
    user: new UserControllers(),
    brand: new BrandControllers(),
    category: new CategoryControllers(),
    area: new AreaControllers(),
    valid: true,
    title: "Buat Venue",

    isLoading: false,
    fullPage: true,

    form: {
      dso: null,
      areaId: "",
      brands: null,
      name: "",
      excellence: "",
      category: null,
      address: {
        province: null,
        district: null,
        subDistrict: null,
        village: null,
        street: "",
        zipCode: "",
      },
    },

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
    },

    selectScope: {
      page: 1,
      limit: 600,
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // venues
    isErrorCreateVenue() {
      return this.venue.isError;
    },

    errorCauseCreateVenue() {
      return this.venue.errorCause;
    },

    isLoadingCreateVenue() {
      return this.venue.isLoading;
    },

    // users
    users() {
      return this.user.userList.filter(item => item.role === 'admin.dso');
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    // brands
    brands() {
      return this.brand.brandList.filter(brand=>brand.product=='Rokok');
    },

    errorCodeGetBrands() {
      return this.brand.errorCode;
    },

    isErrorGetBrands() {
      return this.brand.isError;
    },

    errorCauseGetBrands() {
      return this.brand.errorCause;
    },

    // categories
    categories() {
      return this.category.categoryVenusList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },

    // all districts
    allDistricts() {
      return this.area.allDistrictList;
    },

    errorCodeGetAllDistricts() {
      return this.area.errorCodeAllDistrict;
    },

    isErrorGetAllDistricts() {
      return this.area.isErrorAllDistrict;
    },

    errorCauseGetAllDistricts() {
      return this.area.errorCauseAllDistrict;
    },
  },

  created() {
    this.getUsers();
    this.getBrands();
    this.getCategories();
    this.getProvinces();
    this.getAllDistricts();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getUsers() {
      return this.user.getUserList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getBrands() {
      return this.brand.getBrandList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getCategories() {
      return this.category.getcategoryVenusList();
    },

    getProvinces() {
      return this.area.getProvinceList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find(
        (item) => item.provinceId === provinceId
      );
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(
        provinceId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find(
        (item) => item.districtId === districtId
      );
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(
        districtId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find(
        (item) => item.subDistrictId === subDistrictId
      );
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(
        subDistrictId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillagesValue(villageId) {
      const { villageName, postalCode } = this.villages.find(
        (item) => item.villageId === villageId
      );
      this.addressFiltered.village = villageName;
      this.form.address.zipCode = postalCode;
    },

    getAllDistricts() {
      return this.area.getAllDistrictList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    async submitProcess(
      dso,
      areaId,
      brands,
      names,
      excellence,
      category,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode
    ) {
      return this.venue.createVenueList(
        dso,
        areaId,
        brands,
        names,
        excellence,
        category,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.dso,
          this.form.areaId,
          this.form.brands,
          this.form.name,
          this.form.excellence,
          this.form.category,
          this.addressFiltered.province,
          this.addressFiltered.district,
          this.addressFiltered.subDistrict,
          this.addressFiltered.village,
          this.form.address.street,
          this.form.address.zipCode
        );

        if (this.isErrorCreateVenue) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateVenue,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "SUKSES",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>