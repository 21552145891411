import { SubProjectListRequest, CreateSubProjectRequest, UpdateSubProjectRequest } from "../request/SubprojectRequest.js";

export class SubprojectControllers  {
    subProjectList = new Array();
    isError= false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    constructor() {}

    getSubProjectsList(page, limit) {
        this.setLoading(true);
        const resp = SubProjectListRequest(page, limit)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setSubProjectsList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorcode(err.response.data.code);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setSubProjectsList(data) {
        this.subProjectList = data;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    createSubProjectList(
        project, name, description
    ) {
        this.setLoading(true);
        const resp = CreateSubProjectRequest(
            project, name, description
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    updateSubProjectList(
        id,
        project, name, description
    ) {
        this.setLoading(true);
        const resp = UpdateSubProjectRequest(
            id,
            project, name, description
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

}

