<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>

              <v-autocomplete
                v-model="detailDSO.area.id"
                :items="areas"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Area"
                item-text="name"
                item-value="id"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="detailDSO.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama DSO"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="detailDSO.dso.id"
                :items="dsos"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="DSO"
                item-text="name"
                item-value="id"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="detailDSO.category"
                :items="['DP','LP']"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Area DSO"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                  v-model="detailDSO.heads"
                  :items="heads"
                  filled
                  chips
                  label="Tambah Pimpinan DSO"
                  item-text="name"
                  item-value="id"
                  multiple
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <!-- Error User -->
    <Alert
      :status="isUserListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseCreateAM"
    />

    <!-- Error AM -->
    <Alert
      :status="isErrorLoadAM"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseLoadAM"
    />
  </v-container>
</template>
<script>
import { AreaManagerControllers } from "../../controllers/AreaManagerControllers.js";
import { UserControllers } from "../../controllers/UserControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Alert from "../../components/alert";

export default {
  name: "create-am",
  data: () => ({
    am: new AreaManagerControllers(),
    user: new UserControllers(),
    title: "Ubah DSO",
    valid: true,
   
    tableScope: {
      page: 1,
      limit: 1000,
      keyword: ""
    },

    form: {
      name: "",
      remark: "",
      manager: ""
    },

    isLoading: false,
    fullPage: true,
    tag: {
      value: [],
      options: [],
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // am length
    amLength() {
      return this.am.areaManagerDSOList.length;
    },

    isErrorUpdateAM() {
      return this.am.isError;
    },

    errorCauseUpdateAM() {
      return this.am.errorCause;
    },

    isLoadingUpdateAM() {
      return this.am.isLoading;
    },

    // detail user dso
    detailDSO() {
      const am = this.am.dsoDetail;
      if (am) {
        return {
          ...am,
          area: {
            id: am.area ? am.area.id : "",
          },
          dso: {
            id: am.dso ? am.dso.id : "",
          },
          category: am.category? am.category : "",
          heads: am.heads ? am.heads.map(head=>head.id) : []
        };
      }

      return null;
    },

    // areas
    areas() {
      return this.am.areaManagerAMList;
    },

    isErrorLoadAM() {
      return this.am.isErrorLoadAM;
    },

    errorCauseLoadAM() {
      return this.am.errorCauseLoadAM;
    },

    // get dsos
    dsos() {
      return this.user.userListAM;
    },

    heads(){
      return this.user.userList
    },

    isUserListError() {
      return this.user.isError;
    },

    errorCauseUser() {
      return this.user.errorCause;
    },
  },

  created() {
    this.getAMList();
    this.getUserAM();
    this.getAreasList();
    this.getUser()
  },

  methods: {
    async getAMList() {
      return this.am.getAreaDSODetail(
        this.$route.params.id
      );
    },

    getAreasList() {
      return this.am.getAreaManagerAMList(
        this.tableScope.page,
        this.tableScope.limit,
        this.tableScope.keyword
      );
    },

    getUserAM() {
      return this.user.getUserAMList(
        "admin.dso",
        this.tableScope.page,
        this.tableScope.limit,
        this.tableScope.keyword
      );
    },

    getUser(){
      return this.user.getUserList(1, 999,"", "", "", false,"", "", false,"admin.dso.low")
    },

    back() {
      this.$router.go(-1);
    },

    async submitProcess(areaId, name, dso, head, category) {
      return this.am.updateAreaManagerDSOList(
        this.$route.params.id,
        areaId, name, dso, head, category
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailDSO.area.id,
          this.detailDSO.name,
          this.detailDSO.dso.id,
          this.detailDSO.heads,
          this.detailDSO.category
        );

        if (this.isErrorUpdateAM) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateAM,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });

          this.$refs.observer.reset();
          this.back();
        }
      }
    },
  },
};
</script>