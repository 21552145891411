<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0">
          <v-col md="6" sm="12">
            <v-autocomplete
              :items="[2019, 2020, 2021, 2022]"
              @change="pickArea"
              item-text="name"
              item-value="id"
              label="Tahun"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12">
            <v-btn
              class="success"
              :loading="isLoadingDownload"
              small
              block
              @click="download"
              >Unduh Excel
            </v-btn>
          </v-col>
          <v-col align="center" md="12" ms="12" class="mt-n5">
            <h4>JUMLAH KOMUNITAS KEY</h4>
          </v-col>
          <v-row class="d-flex flex-column">
            <v-row justify="center">
              <v-col md="12" ms="12" class="pa-0 mt-5 d-flex justify-center">
                <v-responsive max-width="300px">
                <VueApexCharts
                  v-if="series.length > 0"
                  type="donut"
                  :options="chartOptions"
                  :series="series"
                  width="100%"
                />
                </v-responsive>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-column mt-7 mb-1">
              <v-row>
                <v-col md="12" sm="12" align="center">
                  <h2 class="font-weight-black text-h3">
                    <b style="border-top: 4px solid #77F0E0;">{{ series[0] }}</b>
                  </h2>
                  <p class="font-weight-black">
                    KOMUNITAS KEY
                  </p>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-row mt-n3" justify="center">
                <v-col md="3" sm="3" align="center">
                  <h1 class="font-weight-black text-h5">
                    <b style="border-top: 4px solid #5A117E;">{{ series[1] }}</b>
                  </h1>
                  <p class="font-weight-black"
                  style="font-size:8px">MAINTENANCE</p>
                </v-col>
                <v-col md="3" sm="4" align="center">
                  <h1 class="font-weight-black text-h5">
                    <b style="border-top: 4px solid #F5763C;">{{ series[2] }}</b>
                  </h1>
                  <p class="font-weight-black"
                  style="font-size:8px">DEVELOPEMENT</p>
                </v-col>
                <v-col md="3" sm="4" align="center">
                  <h1 class="font-weight-black text-h5">
                    <b style="border-top: 4px solid #FC4C52;">{{ series[3] }}</b>
                  </h1>
                  <p class="font-weight-black"
                  style="font-size:8px">OPPORTUNISTIC</p>
                </v-col>
              </v-row>
            </v-row>
          </v-row>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>
<style>
p {
  font-size: 0.8em;
}
</style>
<script>
import VueApexCharts from "vue-apexcharts";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DashboardV2Controller } from "../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../components/alert";

export default {
  name: "total-key",
  props: {
    dsos: Array,
  },
  data: () => ({
    dashboard: new DashboardV2Controller(),
    filter: {
      area: "",
    },

    // charts
    series: [502, 97, 97, 52],
    chartOptions: {
      chart: {
        width: 250,
        type: "donut",
      },
      labels: [
        "KOMUNITAS KEY",
        "KOMUNITAS MAINTENANCE",
        "KOMUNITAS DEVELOPEMENT",
        "KOMUNITAS OPPORTUNISTIC",
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          return val.toFixed(0) + "%"
          },
        style: {
          fontSize: "10px",
        },
      },
      legend: {
        show: false,
        position: "bottom",
        style: {
          fontSize: "10px",
        },
      },
      colors: ["#77F0E0", "#5A117E", "#F5763C", "#FC4C52"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
              style: {
                fontSize: "10px",
              },
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              fontSize: "1rem",
              total: {
                show: true,
                showAlways: true,
                label: "Total",
                fontSize: "1rem",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function(w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    },

    isLoading: false,
    fullPage: true,
  }),

  components: {
    VueApexCharts,
    Loading,
    Alert,
  },

  // computed: {
  //   isError() {
  //     return this.dashboard.errorBrandByAgeDSO;
  //   },
  //   errorCause() {
  //     return this.dashboard.errorCauseBrandByAgeDSO;
  //   },
  //   errorCode() {
  //     return this.dashboard.errorCodeBrandByAgeDSO;
  //   },
  // },

  // methods: {
  //   async pickArea(area) {
  //     this.isLoading = true;
  //     this.series = [];
  //     this.chartOptions.labels = [];
  //     await this.dashboard.GetBrandByAgeDSO(area);
  //     this.dashboard.brandByAgeDSO.map((item) => {
  //       this.series.push(Number(item.value));
  //       this.chartOptions.labels.push(item.title);
  //     })
  //     this.isLoading = false;

  //     if (this.series.length === 0) {
  //       Swal.fire({
  //         icon: "warning",
  //         text: "Data Tidak Ditemukan",
  //       });
  //     }
  //   }
  // }
};
</script>
