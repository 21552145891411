<template>
  <v-container class="pt-8">
    <v-row >
      <v-col md="4" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>KPI Aktivitas</v-card-title>
          <v-btn text block right class="font-weight-black" color='primary' @click="toKPIActivity">
          Dashboard Aktivitas <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Jenis KPI</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in aktivitas" :key="index" >
                  <td>{{index+1}}</td>
                  <td>{{ item.name }}</td>
                  <td v-if="item.achieved === 'ACHIEVED' || Math.floor(item.achieved)>100">
                    <div class="d-flex flex-row green--text font-weight-black align-center">
                    ACHIEVED
                    <img src="../../../assets/achieved.png" alt="" width="20px" height="20px">
                    </div>
                  </td>
                  <td v-else-if="isNaN(item.achieved)">
                    <div class="red--text font-weight-black">
                    {{item.achieved}}
                    </div>
                  </td>
                  <td v-else class="red--text font-weight-black">
                    {{Math.floor(item.achieved || 0)}}%
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col md="4" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>KPI Komunikasi</v-card-title>
          <v-btn text block right class="font-weight-black" color='primary' @click="toKPICommunication">
          Dashboard Komunikasi <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Jenis KPI</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in komunikasi" :key="index" >
                  <td>{{index+1}}</td>
                  <td>{{ item.name }}</td>
                  <td v-if="item.achieved === 'ACHIEVED'">
                    <div class="d-flex flex-row green--text font-weight-black align-center">
                    ACHIEVED
                    <img src="../../../assets/achieved.png" alt="" width="20px" height="20px">
                    </div>
                  </td>
                  <td v-else-if="isNaN(item.achieved)">
                    <div class="red--text font-weight-black">
                    {{item.achieved}}
                    </div>
                  </td>
                  <td v-else class="red--text font-weight-black">
                    {{Math.floor(item.achieved || 0)}}%
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col md="4" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>KPI Konsumsi</v-card-title>
          <v-btn text block right class="font-weight-black" color='primary' @click="toKPIConsumption">
          Dashboard Konsumsi <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Jenis KPI</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in konsumsi" :key="index" >
                  <td>{{index+1}}</td>
                  <td>{{ item.name }}</td>
                  <td v-if="item.achieved === 'ACHIEVED'">
                    <div class="d-flex flex-row green--text font-weight-black align-center">
                    ACHIEVED
                    <img src="../../../assets/achieved.png" alt="" width="20px" height="20px">
                    </div>
                  </td>
                  <td v-else-if="isNaN(item.achieved)">
                    <div class="red--text font-weight-black">
                    {{item.achieved}}
                    </div>
                  </td>
                  <td v-else class="red--text font-weight-black">
                    {{Math.floor(item.achieved || 0)}}%
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props:{
    aktivitas:{
      type: Array(),
      default: []
    },
    konsumsi:{
      type: Array(),
      default: []
    },
    komunikasi:{
      type: Array(),
      default: []
    },
  },
  data: () => ({
    title: "Detail KPI"
  }),

  methods: {
    back() {
      this.$router.go(-1);
    },

    toKPIActivity() {
      this.$router.push({
        name: "Activity Report New",
      });
    },
    toKPICommunication() {
      this.$router.push({
        name: "Communication Report New",
      });
    },
    toKPIConsumption() {
      this.$router.push({
        name: "Consumption Report New",
      });
    },
  },
};
</script>
