<template>
  <v-container class="d-flex justify-space-between ma-0 pa-0" align="center" width='100%'>
    <VueApexCharts
      type="bar"
      height="90px"
      :options="chartOptions"
      :series="series"
      class='ma-n8 pa-0'
    />
  </v-container>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
export default {
  name: "progress-bar",
  props:{
    capaian:Number,
    target:Number,
  },
  data:()=>({
      series: [
      {
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        height: "100%",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "center", // top, center, bottom
          },
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontWeight: 900,
          colors: ["#fff"],
        },
        formatter: function(val) {
          return val + `%`;
        },

      },
      colors:[],
      grid:{
          show:false
      },
      xaxis: {
        categories: ["Capaian"],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 2,
        crosshairs: {
          fill: {
            type: "solid",
          },
        },
        max: 100,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 2,
        labels: {
          show: false,
        },
      },
      tooltip: {
          enabled: false,
        },
      title: {
        show: false,
      },
    },
  }),
  components:{
    VueApexCharts
  },
  created(){
    const persen = Math.round(this.capaian/this.target *100)
    if(persen<70){
      this.chartOptions.colors.push('#fc0000')
    }else if(persen>70 && persen<80){
      this.chartOptions.colors.push('#f5c905')
    }else if(persen>80 && persen<100){
      this.chartOptions.colors.push('#00fcfc')
    }else{
      this.chartOptions.colors.push('#00fc00')
    }
    this.series[0].data.push(persen)
  }
};
</script>