<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="7" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="5" sm="12">
        <v-btn
          v-if="getRole.role.create"
          class="float-right"
          depressed
          color="primary"
          @click="createCategory"
        >
          Tambah Kategori
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="!isLoading">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCodeCategory === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>

        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" colspan="2"></th>
                  <th class="text-center" :colspan="brands.length">Brand</th>
                  <th class="text-center" colspan="2"></th>
                </tr>
                <tr>
                  <th class="text-left">Nama Kategori</th>
                  <th class="text-left">Kode</th>
                  <th class="text-left" v-for="item in brands" :key="item.id">{{item.name}}</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in communityCategories" :key="item.id">
                  <td>{{ item.key }}</td>
                  <td>{{ item.value }}</td>
                  <td v-for="brand in brands" :key="brand.id">
                    <v-icon 
                    v-if="item.brand.includes(brand.id)"
                    small
                    color="success"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon 
                    v-else
                    small
                    color="error">
                      mdi-close-thick
                    </v-icon>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn
                      small
                      icon
                      color="warning"
                      dark
                      @click="edit(item.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deleteBrand(item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isErrorBrand && isErrorCodeBrand !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseBrand"
    />

    <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />
  </v-container>
</template>
<script>
import { BrandControllers } from "../../controllers/BrandControllers.js";
import { CommunityCategoryControllers } from "../../controllers/CommunityCategoryControllers.js";
import { formatDate } from "../../external/functions/Utilization.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  name: "brand-list",
  data: () => ({
    brand: new BrandControllers(),
    communityCategory: new CommunityCategoryControllers(),
    title: "Kategori Komunitas",
    brandTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    search: {
      debounce: null,
    },
    searchField: ""
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    this.getBrandList();
    this.getCommunityCategoryList();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },
    // brands
    brands() {
      return this.brand.brandList.filter(brand => brand.product == 'Rokok');
    },

    isErrorCodeBrand() {
      return this.brand.errorCode;
    },

    isErrorBrand() {
      return this.brand.isError;
    },

    errorCauseBrand() {
      return this.brand.errorCause;
    },

    isLoadingBrand() {
      return this.brand.isLoading;
    },

    // Category
    communityCategories() {
      return this.communityCategory.CommunityCategoryList;
    },

    isErrorCodeCategory() {
      return this.communityCategory.errorCode;
    },

    isErrorCategory() {
      return this.communityCategory.isError;
    },

    errorCauseCategory() {
      return this.communityCategory.errorCause;
    },

    isLoadingCategory() {
      return this.communityCategory.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.communityCategory.isErrorDelete;
    },

    errorCauseDelete() {
      return this.communityCategory.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.communityCategory.isLoading;
    },
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  methods: {
    async getBrandList() {
      return this.brand.getBrandList(
        1,
        50
      );
    },

    async getCommunityCategoryList(){
      return this.communityCategory.getCommunityCategoryList(
        this.brandTable.page,
        this.brandTable.limit,
        this.brandTable.keyword
      )
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingCategory;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.brandTable.page -= 1;

        await this.communityCategory.getCommunityCategoryList(
          this.brandTable.page,
          this.brandTable.limit,
          this.brandTable.keyword
        );
        if (this.isErrorCategory) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingCategory;
      this.pages.start += 10;
      this.pages.end += 10;

      this.brandTable.page += 1;

      await this.communityCategory.getCommunityCategoryList(
        this.brandTable.page,
        this.brandTable.limit,
        this.brandTable.keyword
      );
      if (this.isErrorCategory) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createCategory() {
      this.$router.push({
        name: "Create Community Category",
      });
    },

    deleteBrand(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.communityCategory.deleteCommunityCategory(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.communityCategory.getCommunityCategoryList(
            this.brandTable.page,
            this.brandTable.limit,
            this.brandTable.keyword
          );
        });
    },

    edit(id) {
      this.$router.push({
        name: "Edit Community Category",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>