<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-text-field
                v-model="detailEvent.title"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Judul"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field v-model="detailEvent.totalParticipant" :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Total Partisipan" required outlined dense></v-text-field>

              <v-textarea
                v-model="detailEvent.description"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Deskripsi"
                required
                outlined
                dense
              ></v-textarea>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="detailEvent.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="detailEvent.date"
                    label="Tanggal Event"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="detailEvent.date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(detailEvent.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="detailEvent.time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="detailEvent.time"
                    label="Waktu Event"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  format="24hr"
                  v-model="detailEvent.time"
                  full-width
                  @click:minute="$refs.menu2.save(detailEvent.time)"
                ></v-time-picker>
              </v-menu>

              <v-text-field
                v-model="detailEvent.locationLink"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Link Lokasi"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>



            <v-col md="4" sm="12" class="p-5">
              <v-alert outlined type="info" v-if="!isAddressEditable">
                Untuk ubah alamat klik
                <strong style="cursor: pointer" @click="editAddress"
                  >Ubah Alamat</strong
                >
              </v-alert>
              <v-alert outlined type="error" v-else>
                Untuk batal ubah alamat klik
                <strong style="cursor: pointer" @click="cancelEditAddress"
                  >Batal Ubah</strong
                >
              </v-alert>
              <template v-if="addressForm">
                <v-autocomplete
                  v-model="detailEvent.location.province"
                  :items="provinces"
                  item-text="provinceName"
                  item-value="provinceId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Provinsi"
                  required
                  outlined
                  dense
                  @change="getDistrict($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailEvent.location.district"
                  :items="districts"
                  item-text="districtName"
                  item-value="districtId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kabupaten/Kota"
                  required
                  outlined
                  dense
                  @change="getSubDistrict($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailEvent.location.subDistrict"
                  :items="subDistricts"
                  item-text="subDistrictName"
                  item-value="subDistrictId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kecamatan"
                  required
                  outlined
                  dense
                  @change="getVillages($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailEvent.location.village"
                  :items="villages"
                  item-text="villageName"
                  item-value="villageId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kelurahan/Desa"
                  required
                  outlined
                  dense
                  @change="getVillagesValue($event)"
                ></v-autocomplete>

                <v-text-field
                  v-model="detailEvent.location.street"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Jalan"
                  required
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="detailEvent.location.zipCode"
                  :rules="[
                    (v) =>
                      Number.isInteger(Number(v)) ||
                      'Isian harus angka',
                    (v) => !!v || 'Kolom wajib diisi',
                  ]"
                  label="Kode Pos"
                  :maxlength="5"
                  required
                  outlined
                  dense
                ></v-text-field>
              </template>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Provinces -->
    <Alert
      :status="isErrorGetProvinces && errorCodeGetProvinces !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetProvinces"
    />

    <!-- Error Districts -->
    <Alert
      :status="isErrorGetDistricts && errorCodeGetDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDistricts"
    />

    <!-- Error Sub Districts -->
    <Alert
      :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetSubDistricts"
    />

    <!-- Error Villages -->
    <Alert
      :status="isErrorGetVillages && errorCodeGetVillages !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetVillages"
    />

    <!-- Error All Districts -->
    <Alert
      :status="isErrorGetAllDistricts && errorCodeGetAllDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAllDistricts"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { BigEventControllers } from "../../controllers/BigEventControllers";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import Swal from "sweetalert2";

export default {
  name: "update-event",
  data: () => ({
    event: new BigEventControllers(),
    area: new AreaControllers(),
    valid: true,
    title: "Ubah Event",

    isLoading: false,
    fullPage: true,

    menu: false,
    menu2: false,

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
      allDistricts: null,
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    isAddressEditable: false,
    addressForm: false,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // event length
    eventLength() {
      return this.event.eventList.length;
    },

    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },

    // all districts
    allDistricts() {
      return this.area.allDistrictList;
    },

    errorCodeGetAllDistricts() {
      return this.area.errorCodeAllDistrict;
    },

    isErrorGetAllDistricts() {
      return this.area.isErrorAllDistrict;
    },

    errorCauseGetAllDistricts() {
      return this.area.errorCauseAllDistrict;
    },

    // event update
    isErrorUpdateEvent() {
      return this.event.isError;
    },

    errorCauseUpdateEvent() {
      return this.event.errorCause;
    },

    isLoadingUpdateCreate() {
      return this.event.isLoading;
    },

    // detail event
    detailEvent() {
      const event = this.event.eventDetail;
      if (event) {
        return {
          ...event,
          date: event.date ? event.date.substr(0, 10) : '',
          time: event.date ? event.date.substr(11, 5) : '',
          location: {
            province: event.location ? event.location.province : "",
            district: event.location ? event.location.district : "",
            subDistrict: event.location ? event.location.subDistrict : "",
            village: event.location ? event.location.village : "",
            street: event.location ? event.location.street : "",
            zipCode: event.location ? event.location.zipCode : "",
          },
        };
      }

      return null;
    },
  },

  created() {
    this.getProvinces();
    this.getAllDistricts();
    this.getEvents();
  },

  methods: {
    async getEvents() {
      return this.event.getEventDetailEdit(
        this.$route.params.id
      );
    },

    back() {
      this.$router.go(-1);
    },

    getProvinces() {
      return this.area.getProvinceList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find(
        (item) => item.provinceId === provinceId
      );
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(
        provinceId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find(
        (item) => item.districtId === districtId
      );
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(
        districtId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find(
        (item) => item.subDistrictId === subDistrictId
      );
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(
        subDistrictId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getAllDistricts() {
      return this.area.getAllDistrictList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillagesValue(villageId) {
      const { villageName } = this.villages.find(
        (item) => item.villageId === villageId
      );
      this.addressFiltered.village = villageName;
    },

    async submitProcess(
      title,
      totalParticipant,
      description,
      date,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode,
      locationLink
    ) {
      return this.event.updateEventList(
        this.$route.params.id,
        totalParticipant,
        title,
        description,
        date,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        locationLink
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;

        if (this.isAddressEditable) {
          await this.submitProcess(
            this.detailEvent.title,
            Number(this.detailEvent.totalParticipant),
            this.detailEvent.description,
            `${this.detailEvent.date}T${this.detailEvent.time}:00`,
            this.addressFiltered.province,
            this.addressFiltered.district,
            this.addressFiltered.subDistrict,
            this.addressFiltered.village,
            this.detailEvent.location.street,
            this.detailEvent.location.zipCode,
            this.detailEvent.locationLink
          );
        } else {
          await this.submitProcess(
            this.detailEvent.title,
            Number(this.detailEvent.totalParticipant),
            this.detailEvent.description,
            `${this.detailEvent.date}T${this.detailEvent.time}:00`,
            this.detailEvent.location.province,
            this.detailEvent.location.district,
            this.detailEvent.location.subDistrict,
            this.detailEvent.location.village,
            this.detailEvent.location.street,
            this.detailEvent.location.zipCode,
            this.detailEvent.locationLink
          );
        }

        if (this.isErrorUpdateEvent) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateEvent,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });

          this.back();
        }
      }
    },

    editAddress() {
      this.isAddressEditable = true;
      this.addressForm = true;
      this.getProvinces();
    },

    cancelEditAddress() {
      this.isAddressEditable = false;
      this.addressForm = false;
    },
  },
};
</script>