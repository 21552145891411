<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="10">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-col md="12" sm="12" class="p-5">
      <v-form ref="observer" v-model="valid" lazy-validation>
        <v-row>
          <v-col md="6" sm="12" class="p-5">
            <v-autocomplete v-model="form.year" :items="years" item-text="name" item-value="id" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Tahun" required outlined dense></v-autocomplete>

            <v-autocomplete v-model="form.month" :items="monthOptions" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Bulan" required outlined dense></v-autocomplete>

            <v-text-field v-model="form.postQty" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="PostQty" required outlined dense type="number" min="0"></v-text-field>
          </v-col>
        </v-row>
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit">
          Buat
        </v-btn>
      </v-form>
    </v-col>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isErrorCreatePerfromance && errorCauseCreatePerformance !== 404" icon="error" title="KESALAHAN" :msg="errorCauseCreatePerformance" />
  </v-container>
</template>
<script>
import Alert from "../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { PerformanceControllers } from "../../../controllers/PerformanceControllers";
import Swal from "sweetalert2";

export default {
  name: "create-performance",
  data: () => ({
    performance: new PerformanceControllers(),
    valid: true,
    title: "Buat Performance",

    isLoading: false,
    fullPage: true,

    form: {
      communityId: "",
      year: "",
      month: "",
      postQty: "",
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    isErrorCreatePerfromance() {
      return this.performance.isError;
    },

    errorCauseCreatePerformance() {
      return this.performance.errorCause;
    },

    isLoadingCreatePerformance() {
      return this.performance.isLoading;
    },

    errorCodeCreatePerformance() {
      return this.performance.errorCode;
    },

    years() {
      const start = 2019;
      const end = new Date().getFullYear();

      let year = [];

      for (let i = start; i <= end; i++) {
        year.push(i);
      }

      return year;
    },

    monthOptions() {
      return [
        { text: "Januari", value: 1 },
        { text: "Februari", value: 2 },
        { text: "Maret", value: 3 },
        { text: "April", value: 4 },
        { text: "Mei", value: 5 },
        { text: "Juni", value: 6 },
        { text: "Juli", value: 7 },
        { text: "Agustus", value: 8 },
        { text: "September", value: 9 },
        { text: "Oktober", value: 10 },
        { text: "November", value: 11 },
        { text: "Desember", value: 12 },
      ];
    },
  },
  created() {
    this.communityId = this.$route.params.id;
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    async submitProcess(communityId, year, month, postQty) {
      return this.performance.createPerformance(communityId, year, month, postQty);
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (validate) {
        this.isLoading = true;
        await this.submitProcess(this.$route.params.communityId, this.form.year, this.form.month, this.form.postQty);

        if (this.isErrorCreatePerfromance) {
          this.isLoading = false;
          this.isErrorCreatePerfromance = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreatePerformance,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$router.push({ name: "PerformanceList", params: { id: this.$router.go(-1) } });
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorCreatePerfromance = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
