<template>
  <v-row>
    <v-col md="8" sm="12">
      <h4>{{ title }}</h4>
    </v-col>
    <v-col md="2" sm="12">
      <v-btn
        class="success"
        :loading="isLoadingDownload"
        small
        block
        @click="download"
        >Unduh Excel</v-btn
      >
    </v-col>
    <v-col md="6" sm="6">
      <v-autocomplete
        v-model="filter.area"
        @change="reportByArea"
        :items="areas"
        label="Area"
        item-text="name"
        item-value="name"
        outlined
        dense
      ></v-autocomplete>
    </v-col>
    <v-col md="6" sm="6">
      <router-link to="community-list" style="text-decoration: none"
        >Lihat semua komunitas</router-link
      >
    </v-col>
    <v-col md="12" sm="12">
      <VueApexCharts
        v-if="series.length > 0"
        type="donut"
        :options="chartOptions"
        :series="series"
        height="200"
      />
    </v-col>

    <!-- Error Areas -->
    <Alert
      :status="isErrorGetAreas && errorCodeGetAreas !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAreas"
    />

    <!-- Error Dashboard -->
    <Alert
      :status="isErrorGetDashboard && errorCodeGetDashboard !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDashboard"
    />
  </v-row>
</template>
<script>
import Alert from "../../../components/alert/index";
import { DashboardControllers } from "../../../controllers/DashboardControllers";
import { AreaManagerControllers } from "../../../controllers/AreaManagerControllers.js";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);

export default {
  name: "community-growth",
  data: () => ({
    dashboard: new DashboardControllers(),
    area: new AreaManagerControllers(),
    title: "Persebaran Komunitas",
    filter: {
      area: "",
    },

    scopeTable: {
      page: 1,
      limit: 1000,
      keyword: "",
    },

    series: [],
    chartOptions: {
      chart: {
        width: 380,
        type: "pie",
      },
      colors: ["#000000", "#e2a63d", "#e23d3d", "#d99494", "#34bf41"],
      labels: ['Bold Riders','Heppiii Community','Super Family','Kill The LAst','Others'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                showAlways: true,
                label: "Total",
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
    Alert,
  },

  computed: {
    // areas
    areas() {
      return this.area.areaManagerAMList;
    },

    errorCodeGetAreas() {
      return this.area.errorCode;
    },

    isErrorGetAreas() {
      return this.area.isError;
    },

    errorCauseGetAreas() {
      return this.area.errorCause;
    },

    // reports
    community() {
      return this.dashboard.communityDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    this.getAreas();
    this.reportByArea(this.filter.area)
    // this.getCommunityDashboard();
  },

  methods: {
    getAreas() {
      return this.area.getAreaManagerAMList(
        this.scopeTable.page,
        this.scopeTable.limit
      );
    },

    async getCommunityDashboard() {
      return this.dashboard.getCommunityDashboardList(this.filter.area);
    },

    async reportByArea(event) {
      this.isLoading = true;
      this.series = [];
      await this.getCommunityDashboard(event);
      let dataCommunity = [];
      this.dashboard.communityDashboardList.reduce(function(res, value) {
        if (!res[value.brandCommunity]) {
          res[value.brandCommunity] = { id: value.brandCommunity, count: 0 };
          dataCommunity.push(res[value.brandCommunity])
        }
        res[value.brandCommunity].count += value.count;
        return res;
      }, {});
      this.series = this.chartOptions.labels.map((item) => {
        const dataItem = dataCommunity.find(obj=>obj.id == item);
        if(dataItem != null){
          return dataItem.count;
        }
        return 0;
      });
      this.isLoading = false;
    },

    download() {
      this.isLoadingDownload = true;
      const communities = []
      for(const item of this.community) {
          communities.push({
              "comunity name": item.brand,
              "jumlah": item.count,
              "jumlah dalam persen": item.countPercentage
          });
      }
      const data = XLSX.utils.json_to_sheet(communities);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Persebaran Komunitas Area ${this.filter.area ? this.filter.area : 'Semua'}.xlsx`);
      this.isLoadingDownload = false;
    }
  },
};
</script>