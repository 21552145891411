<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>

              <v-text-field
                v-model="form.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama AM"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.remark"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Catatan"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="form.region"
                :items="regions"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Wilayah"
                item-text="name"
                item-value="id"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.manager"
                :items="managers"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Manajer"
                item-text="name"
                item-value="id"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isUserListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseUser"
    />

    <Alert
      :status="isRegionListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseRegion"
    />
  </v-container>
</template>
<script>
import { AreaManagerControllers } from "../../controllers/AreaManagerControllers.js";
import { UserControllers } from "../../controllers/UserControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Alert from "../../components/alert";

export default {
  name: "create-am",
  data: () => ({
    am: new AreaManagerControllers(),
    user: new UserControllers(),
    title: "Tambah AM",
    valid: true,

    form: {
      name: "",
      remark: "",
      region: "",
      manager: "",
    },

    tableScope: {
      page: 1,
      limit: 1000,
      keyword: ""
    },
    
    isLoading: false,
    fullPage: true,
    tag: {
      value: [],
      options: [],
    },
  }),

  components: {
    Loading,
    Alert
  },

  computed: {
    // created handle
    isErrorCreateAM() {
      return this.am.isError;
    },

    errorCauseCreateAM() {
      return this.am.errorCause;
    },

    isLoadingCreateAM() {
      return this.am.isLoading;
    },

    // get users
    managers() {
      return this.user.userListAM;
    },

    isUserListError() {
      return this.user.isError;
    },

    errorCauseUser() {
      return this.user.errorCause;
    },

    // get regions
    regions() {
      return this.am.areaManagerRSOList;
    },

    isRegionListError() {
      return this.am.isError;
    },

    errorCauseRegion() {
      return this.am.errorCause;
    },
  },

  created() {
    this.getUserAM();
    this.getRegions();
  },

  methods: {
    getUserAM() {
      return this.user.getUserAMList(
        "admin.am",
        this.tableScope.page,
        this.tableScope.limit,
        this.tableScope.keyword
      );
    },

    getRegions() {
      return this.am.getAreaManagerRSOList(
        this.tableScope.page,
        this.tableScope.limit,
        this.tableScope.keyword
      );
    },

    back() {
      this.$router.go(-1);
    },

    async submitProcess(name, remark, region, manager) {
      return this.am.createAreaManagerAMList(
       name, remark, region, manager
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.name,
          this.form.remark,
          this.form.region,
          this.form.manager
        );

        if (this.isErrorCreateAM) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateAM,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>