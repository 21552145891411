<template>
  <v-container class="d-flex justify-space-between ma-0 pa-0" align="center" width='100%'>
    <VueApexCharts
      type="radialBar"
      width="250"
      :options="chartOptions"
      :series="series"
    />
  </v-container>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
export default {
  name: "progress-bar",
  props:{
    capaian:Number
  },
  data:()=>({
    series: [],
    chartOptions: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        width: 250,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              fontSize: "15px",
              fonWeight: 900,
              offsetY: -20,
              color: '#000'
            },
            value: {
              show: false,
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "solid",
        colors: []
      },
      labels: [],
    },
  }),
  components:{
    VueApexCharts
  },
  watch:{
    capaian(){
      this.series = []
      this.chartOptions.fill.colors.pop()
      this.chartOptions.labels.pop()
      this.series.push(this.capaian)
    if(this.series[0]<60){
      this.chartOptions.fill.colors.push('#fc0000')
      this.chartOptions.labels.push(["NOT","ACHIEVED"])
    }else if(this.series[0]>=60 && this.series[0] <70){
      this.chartOptions.fill.colors.push('#f5c905')

      this.chartOptions.labels.push([" ","ALMOST"])
    }else if(this.series[0]>=70 && this.series[0] <80){
      this.chartOptions.fill.colors.push('#00fcfc')
      this.chartOptions.labels.push([" ","GOOD"])
    }else if(this.series[0]>=60 && this.series[0] <100){
      this.chartOptions.fill.colors.push('#0539f5')

      this.chartOptions.labels.push(["VERRY","GOOD"])
    }else if(this.series[0] == 100){
      this.chartOptions.fill.colors.push('#00fc00')
      this.chartOptions.labels.push([" ","PERFECT"])
    }
    }
  },
  created(){
      this.series.push(this.capaian)
    if(this.series[0]<60){
      this.chartOptions.fill.colors.push('#fc0000')
      this.chartOptions.labels.push(["NOT","ACHIEVED"])
    }else if(this.series[0]>=60 && this.series[0] <70){
      this.chartOptions.fill.colors.push('#f5c905')

      this.chartOptions.labels.push([" ","ALMOST"])
    }else if(this.series[0]>=70 && this.series[0] <80){
      this.chartOptions.fill.colors.push('#00fcfc')
      this.chartOptions.labels.push([" ","GOOD"])
    }else if(this.series[0]>=60 && this.series[0] <100){
      this.chartOptions.fill.colors.push('#0539f5')

      this.chartOptions.labels.push(["VERRY","GOOD"])
    }else if(this.series[0] == 100){
      this.chartOptions.fill.colors.push('#00fc00')
      this.chartOptions.labels.push([" ","PERFECT"])
    }
  }
};
</script>