import { CreateKpiTargetRequest, DeleteKpiTargetRequest, UpdateKpiTargetRequest, KpiTargetListRequest, KpiTargetDetailRequest, kpiSummaryRequest, inputKPIDataRequest } from "../request/KpiTargetRequest.js";

export class KpiTargetControllers {
  kpitargetList = new Array();
  kpitargetDetail = new Object();
  kpitargetDownload = new Array();
  kpiSummary = new Array();
  isError = false;
  isLoading = false;
  errorCause = "";
  errorCode = 0;

  isErrorDelete = false;
  errorCauseDelete = "";
  messageDelete = "";

  errorDownload = false;
  errorCodeDownload = "";
  errorCauseDownload = "";
  loadingDownload = false;

  constructor() {}

  getKpiTargetList(page, limit, keyword, year) {
    this.setLoading(true);
    const resp = KpiTargetListRequest(page, limit, keyword, year)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setKpiTargetList(response.data.data);
        return true;
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
        return false;
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getKpiTargetDetail(id) {
    this.setLoading(true);
    const resp = KpiTargetDetailRequest(id)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setKpiTargetDetail(response.data.data);
        return true;
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
        return false;
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  downloadKpiTargetList(page, limit, keyword, year) {
    this.setLoadingDownload(true);
    const resp = KpiTargetListRequest(page, limit, keyword, year)
      .then((response) => {
        this.setErrorDownload(false);
        this.setErrorcodeDownload(response.data.code);
        this.setKpiTargetListDownload(response.data.data);
      })
      .catch((err) => {
        this.setErrorDownload(true);
        this.setErrorCauseDownload(err.response.data.message);
        this.setErrorcodeDownload(err.response.data.code);
      })
      .finally(() => {
        this.setLoadingDownload(false);
      });

    return resp;
  }

  setErrorDownload(status) {
    this.errorDownload = status;
  }

  setErrorcodeDownload(code) {
    this.errorCodeDownload = code;
  }

  setErrorCauseDownload(err) {
    this.errorCauseDownload = err;
  }

  setLoadingDownload(status) {
    this.loadingDownload = status;
  }

  setKpiTargetListDownload(data) {
    this.kpitargetDownload = data;
  }

  setKpiTargetList(data) {
    this.kpitargetList = data;
  }

  setKpiTargetDetail(data) {
    this.kpitargetDetail = data;
  }

  createKpiTargetList(
    dso,
    year,
    communityTarget,
    communitySuperTarget,
    communityKeyTarget,
    eventTarget,
    eventLkkTarget,
    eventWithVenueDrpTarget,
    eventEsportTarget,
    eventEsportSuperTarget,
    eventSuperNonEsportTarget,
    competencyTarget,
    sosmedCommunityTarget,
    sosmedDsoTarget,
    postDsoTarget,
    sosmedFollowerTarget,
    consumptionTarget,
    eventBuddySystemTarget,
    eventRegionalTarget,
    eventSharingTarget,
    eventLocalTarget,
    postCommunityTarget
  ) {
    this.setLoading(true);
    const resp = CreateKpiTargetRequest(
      dso,
      year,
      communityTarget,
      communitySuperTarget,
      communityKeyTarget,
      eventTarget,
      eventLkkTarget,
      eventWithVenueDrpTarget,
      eventEsportTarget,
      eventEsportSuperTarget,
      eventSuperNonEsportTarget,
      competencyTarget,
      sosmedCommunityTarget,
      sosmedDsoTarget,
      postDsoTarget,
      sosmedFollowerTarget,
      consumptionTarget,
      eventBuddySystemTarget,
      eventRegionalTarget,
      eventSharingTarget,
      eventLocalTarget,
      postCommunityTarget
    )
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setLoading(status) {
    this.isLoading = status;
  }

  setError(status) {
    this.isError = status;
  }

  setErrorCause(err) {
    this.errorCause = err;
  }

  setErrorcode(code) {
    this.errorCode = code;
  }

  deleteKpiTarget(id) {
    this.setLoading(true);
    const resp = DeleteKpiTargetRequest(id)
      .then((response) => {
        this.setErrorDelete(false);
        this.setMsgDelete(response.data.message);
      })
      .catch((err) => {
        this.setErrorDelete(true);
        this.setErrorCauseDelete(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setMsgDelete(value) {
    this.messageDelete = value;
  }

  setErrorDelete(status) {
    this.isErrorDelete = status;
  }

  setErrorCauseDelete(err) {
    this.errorCauseDelete = err;
  }

  updateKpiTargetList(
    id,
    dso,
    year,
    communityTarget,
    communitySuperTarget,
    communityKeyTarget,
    eventTarget,
    eventLkkTarget,
    eventWithVenueDrpTarget,
    eventEsportTarget,
    eventEsportSuperTarget,
    eventSuperNonEsportTarget,
    competencyTarget,
    sosmedCommunityTarget,
    sosmedDsoTarget,
    postDsoTarget,
    sosmedFollowerTarget,
    consumptionTarget,
    eventBuddySystemTarget,
    eventRegionalTarget,
    eventSharingTarget,
    eventLocalTarget,
    postCommunityTarget
  ) {
    this.setLoading(true);
    const resp = UpdateKpiTargetRequest(
      id,
      dso,
      year,
      communityTarget,
      communitySuperTarget,
      communityKeyTarget,
      eventTarget,
      eventLkkTarget,
      eventWithVenueDrpTarget,
      eventEsportTarget,
      eventEsportSuperTarget,
      eventSuperNonEsportTarget,
      competencyTarget,
      sosmedCommunityTarget,
      sosmedDsoTarget,
      postDsoTarget,
      sosmedFollowerTarget,
      consumptionTarget,
      eventBuddySystemTarget,
      eventRegionalTarget,
      eventSharingTarget,
      eventLocalTarget,
      postCommunityTarget
    )
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getKpiSummary(page, size, year) {
    this.setLoading(true);
    const resp = kpiSummaryRequest(page, size, year)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setKpiSummary(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setKpiSummary(value) {
    this.kpiSummary = value;
  }

  setEmptyKpi() {
    this.kpiSummary = [];
  }

  inputKPIData(year, dataInput) {
    this.setLoading(true);
    const resp = inputKPIDataRequest(year, dataInput)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }
}
