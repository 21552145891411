<template>
  <VueApexCharts
  ref="BarTargetConsumptionKPIChart"
    type="bar"
    height="100"
    width = "100%"
    :options="chartOptions"
    :series="series"
    class="mt-n4"
  />
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

export default {
  name: "community-achievement",
  props: {
    consumptionKPIChart: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    year: 2021,
    years: [2020, 2021, 2022],
    series: [
      {
        data: [0],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors:['#424c58'],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "center", // top, center, bottom
          },
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontWeight: 900,
          colors: ["#fff"],
        },
      },

      xaxis: {
        categories: ["Capaian"],
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tickAmount: 2,
        crosshairs: {
          fill: {
            type: "solid",
          },
        },
        tooltip: {
          enabled: false,
        },
        max: 0,
      },
      grid: {
        show: false,
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 2,
        labels: {
          show: false,
        },
      },
      title: {
        show: false,
      },
      annotations: {
        position: 'back',
        xaxis: [
          {
            x: 0,
            x2: 0,
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              show: false,
              text: "0",
              offsetY: 1,
              position:'right',
              orientation: 'horizontal',
              borderColor: 'transparent',
              style:{
                background: 'transparent',
                color: 'transparent'
              }
            },
          },
        ],
        yaxis: [
          {
            y: 0,
            y2: "capaian",
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              text: "Target: 0",
              offsetY: 1,
              position:'right',
              orientation: 'horizontal'
            },
          },
        ],
      },
    },

    isLoadingDownload: false,
  }),

  watch: {
    consumptionKPIChart(newVal) {
      this.series = []

      this.series.push({data: [Number(newVal.reach)]})

      const newChartOptions = this.chartOptions
      newChartOptions.xaxis.max = Number(Number(newVal.target) < Number(newVal.reach) ? Number(newVal.reach) : Number(newVal.target))
      newChartOptions.annotations.xaxis[0].x2 = Number(newVal.target)
      newChartOptions.annotations.yaxis[0].label.text = `Target: ${Number(newVal.target)}`
      Object.assign(this.chartOptions, newChartOptions)
      
      this.$refs.BarTargetConsumptionKPIChart.refresh()
    },
  },

  components: {
    VueApexCharts,
  },
};
</script>
