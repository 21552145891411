<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" sm="12" class="p-5" v-if="subProjectLength > 0">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-autocomplete
            v-model="detailSubProject.project.id"
            :items="projects"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Nama Project"
            required
            outlined
            dense
          ></v-autocomplete>

          <v-text-field
            v-model="detailSubProject.name"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Nama Sub Project"
            required
            outlined
            dense
          ></v-text-field>

          <v-textarea
            v-model="detailSubProject.description"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Deskripsi"
            outlined
            required
            dense
          ></v-textarea>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>

      <v-col md="12" sm="12" class="p-5" v-else>
        <v-row>
          <v-col md="4" sm="12" class="p-5">
            <p>Memuat form...</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isProjectListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseProjects"
    />
  </v-container>
</template>
<script>
import { ProjectControllers } from "../../controllers/ProjectControllers.js";
import { SubprojectControllers } from "../../controllers/SubprojectControllers.js";
import Alert from "../../components/alert";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "create-subproject",
  data: () => ({
    project: new ProjectControllers(),
    subproject: new SubprojectControllers(),
    title: "Ubah Sub Project",
    valid: true,
    
    form: {
      project: null,
      name: "",
      description: "",
    },
    projectScope: {
      page: 1,
      limit: 1000,
    },
    isLoading: false,
    fullPage: true,
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    this.getProjects();
    this.getSubProjects();
  },

  computed: {
    // project length
    subProjectLength() {
      return this.subproject.subProjectList.length;
    },

    // project
    projects() {
      return this.project.projectList;
    },

    isProjectListError() {
      return this.project.isError;
    },

    errorCauseProjects() {
      return this.project.errorCause;
    },

    // sub project
    isErrorUpdateSubProject() {
      return this.subproject.isError;
    },

    errorCauseUpdateSubProject() {
      return this.subproject.errorCause;
    },

    isLoadingUpdateSubProject() {
      return this.subproject.isLoading;
    },

    // detail subproject
    detailSubProject() {
      const subproject = this.subproject.subProjectList.find(
        (item) => item.id === this.$route.params.id
      );

      if (subproject) {
        return {
          ...subproject,
          project: {
              id: subproject.project ? subproject.project.id : ""
          }
        };
      }

      return null;
    },
  },

  methods: {
    getProjects() {
      return this.project.getProjectsList(
        this.projectScope.page,
        this.projectScope.limit
      );
    },

    async getSubProjects() {
      return this.subproject.getSubProjectsList(
        this.projectScope.page,
        this.projectScope.limit
      );
    },

    back() {
      this.$router.go(-1);
    },

    async submitProcess(project, name, description) {
      return this.subproject.updateSubProjectList(
        this.$route.params.id,
        project,
        name,
        description
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailSubProject.project.id,
          this.detailSubProject.name,
          this.detailSubProject.description
        );

        if (this.isErrorUpdateSubProject) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateSubProject,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });
          this.back();
        }
      }
    },
  },
};
</script>