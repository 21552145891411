<template>
  <div>
    <v-row>
      <v-col md="2" sm="12" class="mt-3 mb-2">
        <h5 class="filter-title mb-2">Filter DSO</h5>
        <div class="filter">
          <input type="checkbox" @click="checkAll" v-model="isCheckAll" />
          Pilih Semua
          <ul class="filter-dso">
            <li v-for="area in dsos" :key="area.id">
              <input
                type="checkbox"
                v-bind:value="area.id"
                v-model="areaSelected"
                @change="updateCheckall"
              />
              {{ titleCase(area.name) }}
            </li>
          </ul>
        </div>
        <v-btn
          class="primary mt-2"
          :disabled="areaSelected.length <= 0"
          block
          @click="filter"
          small
          >Filter</v-btn
        >
      </v-col>
      <v-col md="10" sm="12">
        <div class="bar-chart">
          <VueApexCharts
            v-if="chartOptions.xaxis.categories.length > 0"
            type="line"
            :width="widthChart"
            height="328"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </v-col>
    </v-row>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauase"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { DashboardV2Controller } from "../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { titleCase } from "../../../../external/functions/Utilization.js"
import _ from "lodash";
import Swal from "sweetalert2";

export default {
  name: "top-achievement-index",
  props: {
    dsos: Array,
  },
  data: () => ({
    dashboard: new DashboardV2Controller(),
    isCheckAll: false,
    areaSelected: [],
    
    // charts
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
      },
      title: {
        text: "Postingan Berdasar DSO",
        align: "left",
      },
      colors:["#000000", "#e2a63d", "#e23d3d", "#d99494", "#34bf41"],
      xaxis: {
        type: "category",
        categories: ["labels"],
        labels: {
          show: true,
          rotate: -45,
          style: {
            colors: [],
            fontSize: "10px",
          },
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "Jumlah",
            style: {
              color: "#008FFB",
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
    // loading
    isLoading: false,
    fullPage: true,

    colorBrand:[
      {
        brand : 'Bold Riders',
        color : "#000000"
      },
      {
        brand : 'Heppiii Community',
        color : "#e2a63d"
      },
      {
        brand : 'Super Family',
        color : "#e23d3d"
      },
      {
        brand : 'Kill The LAst',
        color : "#d99494"
      },
      {
        brand : 'Others',
        color : "#34bf41"
    }
    ],
  }),
  computed: {
    widthChart() {
      return this.chartOptions.xaxis.categories.length > 25 ? 1500 : '100%'
    },
    isError() {
      return this.dashboard.errorPostByDSO;
    },
    errorCauase() {
      return this.dashboard.errorCausePostByDSO;
    },
  },
  methods: {
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.areaSelected = [];
      if (this.isCheckAll) {
        // Check all
        for (var key of this.dsos) {
          this.areaSelected.push(key.id);
        }
      }
    },
    updateCheckall() {
      if (this.areaSelected.length == this.dsos.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    async filter() {
      this.isLoading = true;
      this.series = [];
      let allSeries = [];
      this.chartOptions.xaxis.categories = [];
      const dsos = this.areaSelected
        .map((item, index) => (index === 0 ? `${item}` : `&dso=${item}`))
        .join("");
      await this.dashboard.GetPostByDSORequest(dsos);
      this.chartOptions.xaxis.categories =
        this.dashboard.postByDSO.dsos.map((item) => {
          const data = [];
          if (item !== null) {
            data.push(item.name);
          }

          return data;
        });

      const brands = [];
      for (const item of this.dashboard.postByDSO.dsos) {
        if (item.name) {
          brands.push(item.brands);
        }
      }

      const groupBrands = _.flatten(brands);
      const tempGroupBrands = [];
      const seriesGroup = [];
      for (const item of groupBrands) {
        if (!tempGroupBrands.includes(item.brandCommunity)) {
          seriesGroup.push(item.brandCommunity);
          let brandColor = this.colorBrand.find(datum=> datum.brand == item.brandCommunity)
          if(brandColor){
            this.chartOptions.colors.push(brandColor.color)
          }
        }
        tempGroupBrands.push(item.brandCommunity);
      }

      for (const item of seriesGroup) {
        const counts = [];
        for (const subitem of this.dashboard.postByDSO.dsos) {
          const fnd = subitem.brands.find((e) => e.brandCommunity === item)
          if (fnd) {
            counts.push(fnd.count)
          } else {
            counts.push(0)
          }
        }

        allSeries.push({
          name: item,
          type: "column",
          data: counts,
        });
      }

      allSeries = this.colorBrand.map(brand=>allSeries.find(obj=>obj.name == brand.brand)).filter(datum=>datum!=undefined || datum != null)
      console.log(allSeries)

      this.series = allSeries;

      if(this.series.length === 0) {
        Swal.fire({
          icon: "warning",
          text: 'Data tidak ditemukan',
        });
      }

      this.isLoading = false;
    },

    titleCase(str) {
      return titleCase(str)
    }
  },

  created(){
    this.checkAll();
    this.filter();
  },

  components: {
    VueApexCharts,
    Alert,
    Loading,
  },
};
</script>
<style scoped>
/* Box styles */
.filter {
  border: none;
  padding: 5px;
  width: 100%;
  height: 285px;
  overflow-y: scroll;
  font-size: 11px;
}

.filter-dso {
  display: inline;
}

.filter-dso li {
  padding-top: 5px;
}

.bar-chart {
  border: none;
  padding: 5px;
  width: 100%;
  overflow-x: scroll;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border: 1px solid #bdc3c7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bdc3c7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bdc3c7;
}
</style>

