import axios from "axios";
import { uploadMedia } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};

export const UploadMediaRequest = (
        type,
        image
    ) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${uploadMedia()}`,
        data: {
            type: type,
            images: image
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}
