import axios from "axios";
import { VenueList, CreateVenue, DeleteVenue, UpdateVenue, VenueDetail } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const VenueListRequest = (page, limit, keyword, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${VenueList(page, limit, keyword, dsoId)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateVenueRequest = (
    dso,
    areaId,
    brands,
    name,
    excellence,
    category,
    province,
    district,
    subDistrict,
    village,
    street,
    zipCode
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateVenue()}`,
        data: {
            dso: dso,
            areaId: areaId,
            brandId: brands,
            name: name,
            excellence: excellence,
            category: category,
            address: {
                province: province,
                district: district,
                subDistrict: subDistrict,
                village: village,
                street: street,
                zipCode: zipCode
            }
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteVenueRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteVenue(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateVenueRequest = (
    id,
    dso,
    areaId,
    brands,
    name,
    excellence,
    category,
    province,
    district,
    subDistrict,
    village,
    street,
    zipCode
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateVenue(id)}`,
        data: {
            dso: dso,
            areaId: areaId,
            brandId: brands,
            name: name,
            excellence: excellence,
            category: category,
            address: {
                province: province,
                district: district,
                subDistrict: subDistrict,
                village: village,
                street: street,
                zipCode: zipCode
            }
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const VenueDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${VenueDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}





