<template>
  <v-row>
    <v-col md="4" sm="12">
      <v-alert type="info">
        <div>
          Total Member:
          <span v-if="!isLoading"> {{ cardsMember[0].sumTotalMember }} </span>
          <span v-else>Memuat...</span>
        </div>
      </v-alert>
    </v-col>
    <v-col md="4" sm="12">
      <v-alert type="info">
        <div>
          Total Member Aktif:
          <span v-if="!isLoading"> {{ cardsMember[0].sumTotalActiveMember }} </span>
          <span v-else>Memuat...</span>
        </div>
      </v-alert>
    </v-col>
    <v-col md="4" sm="12">
      <v-alert type="info">
        <div>
          Total Perokok Aktif:
          <span v-if="!isLoading"> {{ cardsMember[0].sumTotalSmoker }} </span>
          <span v-else>Memuat...</span>
        </div>
      </v-alert>
    </v-col>
    <Alert
      :status="isError && errorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-row>
</template>
<script>
import { DashboardV2Controller } from "../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../components/alert";

export default {
  name: "cards-report",
  data: () => ({
    dashboard: new DashboardV2Controller(),
  }),

  components: {
    Alert,
  },

  computed: {
    cardsMember() {
      return this.dashboard.cardsMemberData;
    },

    isLoading() {
      return this.dashboard.loadingMemberData;
    },

    isError() {
      return this.dashboard.errorMemberData;
    },

    errorCause() {
      return this.dashboard.errorCauseMemberData;
    },

    errorCode() {
      return this.dashboard.errorCodeMemberData;
    },
  },

  created() {
    this.GetDashboardCardMember();
  },

  methods: {
    async GetDashboardCardMember() {
      await this.dashboard.GetDashboardCardMember();
    },
  },
};
</script>
