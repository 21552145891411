import { ProjectListRequest, CreateProjectRequest, UpdateProjectRequest, ProjectSettingsRequest, CreateProjectSettingsRequest, UpdateProjectSettingsRequest, DeleteProjectSettingsRequest } from "../request/ProjectRequest.js";

export class ProjectControllers  {
    projectList = new Array();
    projectDetail = new Object();
    isError= false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    loadingProjectSettings = false;
    errorCreateProjectSettings = false;
    errorCauseProjectSettings = "";

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    constructor() {}

    getProjectsList(page, limit) {
        this.setLoading(true);
        const resp = ProjectListRequest(page, limit)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setProjectsList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorcode(err.response.data.code);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getProjectSettingsList(id, page, limit, keyword) {
        this.setLoading(true);
        const resp = ProjectSettingsRequest(id, page, limit, keyword)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setProjectsList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorcode(err.response.data.code);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getProjectSettingsDetail(id) {
        this.projectDetail = this.projectList.find(item => {
            return item.id === id
        });
    }

    setProjectsList(data) {
        this.projectList = data;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    createProjectList(
        name, description
    ) {
        this.setLoading(true);
        const resp = CreateProjectRequest(
            name, description
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }


    updateProjectList(
        id,
        name, description
    ) {
        this.setLoading(true);
        const resp = UpdateProjectRequest(
            id,
            name, description
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    createProjectSettings(
        projectId, key, value
    ) {
        this.setLoadingProjectSettings(true);
        const resp = CreateProjectSettingsRequest(
            projectId, key, value
        )
            .then(() => {
                this.setErrorCreateProjectSettings(false);
            }).catch(err => {
                this.setErrorCreateProjectSettings(true);
                this.setErrorCauseProjectSettings(err.response.data.message);
            }).finally(() => {
                this.setLoadingProjectSettings(false);
            });

        return resp;
    }

    setLoadingProjectSettings(status) {
        this.loadingProjectSettings = status;
    }   

    setErrorCreateProjectSettings(status) {
        this.errorCreateProjectSettings = status;
    }

    setErrorCauseProjectSettings(err) {
        this.errorCauseProjectSettings = err;
    }

    updateProjectSettings(
        projectId, settingId, key, value
    ) {
        this.setLoading(true);
        const resp = UpdateProjectSettingsRequest(
            projectId, settingId, key, value
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    DeleteProjectSettings(projectId, id) {
        this.setLoading(true);
        const resp = DeleteProjectSettingsRequest(projectId, id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    
}

