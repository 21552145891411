import { CategoryVenueListRequest, CategoryDRPListRequest, CategoryCommunityListRequest, CategoryEventListRequest, CategoryMedsosListRequest, BrandListRequest} from "../request/CategoryRequest.js";

export class CategoryControllers  {
    categoryVenusList = new Array();
    categoryDRPList = new Array();
    categoryCommunityList = new Array();
    categoryEventList = new  Array();
    categoryMedsosList = new  Array();
    brandList = new Array();
    isError= false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    constructor() {}

    getcategoryVenusList() {
        const resp = CategoryVenueListRequest()
        .then((response) => {
            this.setCategoryVenueList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        });
       
        return resp;
    }

    getcategoryDRPList() {
        const resp = CategoryDRPListRequest()
        .then((response) => {
            this.setCategoryDRPList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        });
       
        return resp;
    }

    getcategoryCommunityList() {
        const resp = CategoryCommunityListRequest()
        .then((response) => {
            this.setCategoryCommunityList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        });
       
        return resp;
    }

    getBrandList() {
        const resp = BrandListRequest()
        .then((response) => {
            this.setBrandList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        });
       
        return resp;
    }

    getcategoryEventList() {
        const resp = CategoryEventListRequest()
        .then((response) => {
            this.setCategoryEventList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        });
       
        return resp;
    }

    getcategoryMedsosList() {
        const resp = CategoryMedsosListRequest()
        .then((response) => {
            this.setCategoryMedsosList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        });
       
        return resp;
    }

    setCategoryVenueList(data) {
        this.categoryVenusList = data;
    }

    setCategoryDRPList(data) {
        this.categoryDRPList = data;
    }
    
    setCategoryCommunityList(data) {
        this.categoryCommunityList = data;
    }

    setBrandList(data) {
        this.brandList = data;
    }

    setCategoryEventList(data) {
        this.categoryEventList = data;
    }

    setCategoryMedsosList(data) {
        this.categoryMedsosList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }
}

