<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="6" sm="12">
        <h3>{{title}}</h3>
      </v-col>
      <v-col md="3" sm="12">
          <!-- <v-btn
            class="float-right"
            depressed
            color="primary"
            @click="createKpiTarget"
            block
            v-if="isOldKPI"
          >
            Input Target
          </v-btn> -->
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row  v-if="!isLoadingGetKpiTarget">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">AM</th>
                  <th class="text-left">DSO</th>
                  <th class="text-left">Tahun</th>
                  <th class="text-left">Target Post DSO</th>
                  <th class="text-left">Ubah</th>
                  <!-- <th class="text-left">Hapus</th> -->
                </tr>
              </thead>
              <tbody v-for="item in kpitargets" :key="item.id">
                <tr>
                  <td>{{item.am.name}}</td>
                  <td>{{item.dso.name}}</td>
                  <td>{{item.year? item.year : "No Data"}}</td>
                  <td>{{item.postDsoTarget}}</td>
                  <td>
                    <v-btn small icon color="warning" dark @click="edit(item.id)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <!-- <td>
                    <v-btn small icon color="error" dark @click="deleteKpiTarget(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-container>
</template>

<script>
import { KpiTargetControllers } from "../../../../controllers/KpiTargetControllers";
import Alert from "../../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  name: "create-user",
  props:{
    isOldKPI:{
      type: Boolean,
      default: true
    },
    year:{
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data: () => ({
    title: "Target KPI Postingan DSO",
    kpitarget: new KpiTargetControllers(),

    kpitargetTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },

    pages: {
      start: 1,
      end: 10,
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    isLoading: false,
    fullPage: true,

    search: {
      debounce: null,
    },

    searchField: "",
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    this.getKpiTarget();
  },

  watch:{
    year(){
      this.getKpiTarget();
    }
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    kpitargets() {
      return this.kpitarget.kpitargetList;
      // return this.kpitarget.kpitargetList.filter(datum=> datum.postDsoTarget && !isNaN(datum.postDsoTarget));
    },

    isErrorCode() {
      return this.kpitarget.errorCode;
    },

    isError() {
      return this.kpitarget.isError;
    },

    errorCause() {
      return this.kpitarget.errorCause;
    },

    isLoadingGetKpiTarget() {
      return this.kpitarget.isLoading;
    },


    // delete
    isErrorDelete() {
      return this.kpitarget.isErrorDelete;
    },

    errorCauseDelete() {
      return this.kpitarget.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.kpitarget.isLoading;
    },
  },

  methods: {
    async getKpiTarget() {
      return this.kpitarget.getKpiTargetList(
        this.kpitargetTable.page,
        this.kpitargetTable.limit,
        this.kpitargetTable.keyword,
        this.year
      )
    },

    createKpiTarget() {
      this.$router.push({
        name: "Create Target Post DSO",
      });
    },

    edit(id) {
      this.$router.push({
        name: "Edit Target Post DSO",
        params: {
          id: id,
        },
      });
    },

    deleteKpiTarget(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.kpitarget.deleteKpiTarget(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.kpitarget.getKpiTargetList(
            this.kpitargetTable.page,
            this.kpitargetTable.limit,
            "",
            this.year
          );
        });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.kpitargetTable.page = 1;
      this.kpitargetTable.limit = 10;
      this.kpitargetTable.keyword = this.searchField;

      await this.getKpiTarget(
        this.kpitargetTable.page,
        this.kpitargetTable.limit,
        this.kpitargetTable.keyword,
        this.year
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingUser;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.kpitargetTable.page -= 1;

        await this.getKpiTarget(
          this.kpitargetTable.page,
          this.kpitargetTable.limit,
          this.kpitargetTable.keyword,
          this.year
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingUser;
      this.pages.start += 10;
      this.pages.end += 10;

      this.kpitargetTable.page += 1;

      await this.getKpiTarget(
        this.kpitargetTable.page,
        this.kpitargetTable.limit,
        this.kpitargetTable.keyword,
        this.year
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.kpitargetTable.page = 1;
          this.kpitargetTable.limit = 10;
          this.kpitargetTable.keyword = event;

          await this.getKpiTarget(
            this.kpitargetTable.page,
            this.kpitargetTable.limit,
            this.kpitargetTable.keyword,
            this.year
          );
        }
      }, 500);
    },
  },
}
</script>
