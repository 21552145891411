<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 pt-4">
          <v-col md="6" sm="12">
            <v-autocomplete :items="years" v-model="chosenYear" label="Tahun" dense></v-autocomplete>
          </v-col>
          <v-col md="5" sm="8">
            <v-btn class="primary" :loading="isLoadingDownload" small block @click="detailActivity">Details</v-btn>
          </v-col>
          <v-col align="center" md="12" ms="12">
            <h4>JUMLAH KOMUNITAS KEY</h4>
          </v-col>
          <v-row class="d-flex flex-column mt-10" justify="center">
            <v-row>
              <v-col md="12" ms="12" class="pa-0" align="center">
                <VueApexCharts v-if="series.length > 0" type="donut" :options="chartOptions" :series="series" width="100%" />
              </v-col>
            </v-row>
            <v-row class="d-flex flex-column mt-10 mb-1">
              <v-row>
                <v-col md="12" sm="12" align="center">
                  <h2 class="font-weight-black text-h3">
                    <b style="border-top: 4px solid #77F0E0;">{{ series[0] }}</b>
                  </h2>
                  <p class="font-weight-black">
                    KOMUNITAS KEY
                  </p>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-row mt-n3 mb-5 " justify="center">
                <v-col md="3" sm="3" align="center">
                  <h1 class="font-weight-black text-h5">
                    <b style="border-top: 4px solid #5A117E;">{{ series[1] }}</b>
                  </h1>
                  <p class="font-weight-black" style="font-size:7px">MAINTENANCE</p>
                </v-col>
                <v-col md="3" sm="4" align="center">
                  <h1 class="font-weight-black text-h5">
                    <b style="border-top: 4px solid #F5763C;">{{ series[2] }}</b>
                  </h1>
                  <p class="font-weight-black" style="font-size:7px">DEVELOPEMENT</p>
                </v-col>
                <v-col md="3" sm="4" align="center">
                  <h1 class="font-weight-black text-h5">
                    <b style="border-top: 4px solid #FC4C52;">{{ series[3] }}</b>
                  </h1>
                  <p class="font-weight-black" style="font-size:7px">OPPORTUNISTIC</p>
                </v-col>
              </v-row>
            </v-row>
          </v-row>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>
<style>
p {
  font-size: 0.8em;
}
</style>
<script>
import VueApexCharts from "vue-apexcharts";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DashboardV2Controller } from "../../../../controllers/DashboardV2Controller.js";
import { ReportControllers } from "../../../../controllers/ReportControllers.js";
import Alert from "../../../../components/alert";

export default {
  name: "total-key",
  props: {
    dso: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dashboard: new DashboardV2Controller(),
    report: new ReportControllers(),
    filter: {
      area: "",
    },
    chosenYear: new Date().getFullYear(),

    // charts
    series: [0, 0, 0, 0],
    chartOptions: {
      chart: {
        width: "100%",
        type: "donut",
      },
      labels: ["KOMUNITAS KEY", "KOMUNITAS MAINTENANCE", "KOMUNITAS DEVELOPMENT", "KOMUNITAS OPPORTUNISTIC"],
      dataLabels: {
        enabled: true,
        formatter(val) {
          return val.toFixed(0) + "%";
        },
        style: {
          fontSize: "10px",
        },
      },
      legend: {
        show: false,
        position: "bottom",
        style: {
          fontSize: "10px",
        },
      },
      colors: ["#77F0E0", "#5A117E", "#F5763C", "#FC4C52"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              fontSize: "1rem",
              total: {
                show: true,
                showAlways: true,
                label: "Total",
                fontSize: "1rem",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function(w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    },

    isLoading: false,
    fullPage: true,
  }),

  components: {
    VueApexCharts,
    Loading,
    Alert,
  },

  watch: {
    dso() {
      this.getCommunityKey();
    },
    chosenYear() {
      this.getCommunityKey();
    },
    matrixKisiComputed(newVal) {
      if (newVal) {
        this.series = [];

        const baseLabels = this.chartOptions.labels.map((name) => ({ name: name.replace(/KOMUNITAS /, ""), value: 0 }));
        for (const val of newVal) {
          const iExists = baseLabels.findIndex((label) => label.name === val.key);
          baseLabels[iExists].value = val.value;
        }
        Object.assign(
          this.series,
          baseLabels.map((label) => label.value)
        );
      }
    },
  },

  computed: {
    years() {
      const start = 2019;
      const end = new Date().getFullYear();

      let year = [];

      for (let i = start; i <= end; i++) {
        year.push(i);
      }

      return year;
    },

    isOldKPI(){
      return this.chosenYear < 2023;
    },

    matrixKisiComputed() {
      const matrixKisi = this.report.communityKeyKPIList;
      const tableOfKisi = matrixKisi.detailTarget;

      return tableOfKisi;
    },
    // isError() {
    //   return this.dashboard.errorBrandByAgeDSO;
    // },
    // errorCause() {
    //   return this.dashboard.errorCauseBrandByAgeDSO;
    // },
    // errorCode() {
    //   return this.dashboard.errorCodeBrandByAgeDSO;
    // },
  },

  methods: {
    async getCommunityKey() {
      this.report.getCommunityKeyKPIList(this.dso, this.chosenYear);
    },
    // async pickArea(area) {
    //   this.isLoading = true;
    //   this.series = [];
    //   this.chartOptions.labels = [];
    //   await this.dashboard.GetBrandByAgeDSO(area);
    //   this.dashboard.brandByAgeDSO.map((item) => {
    //     this.series.push(Number(item.value));
    //     this.chartOptions.labels.push(item.title);
    //   })
    //   this.isLoading = false;

    //   if (this.series.length === 0) {
    //     Swal.fire({
    //       icon: "warning",
    //       text: "Data Tidak Ditemukan",
    //     });
    //   }
    // }
    detailActivity() {
      this.$router.push({
        name: "Community Activity Detail",
        params: {
          dso: this.dso,
          chosenYear: this.chosenYear,
        },
      });
    },
  },
};
</script>
