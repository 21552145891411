<template>
  <div>
    <v-dialog v-model="isShow" width="500" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2"> Rincian Post </v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>ID</v-list-item-title>
              <v-list-item-subtitle>{{ data.id }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="data.user">
            <v-list-item-content>
              <v-list-item-title>Pengguna</v-list-item-title>
              <v-list-item-subtitle>{{ data.user.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Konten</v-list-item-title>
              <v-list-item-subtitle>
                <v-card>
                  <v-card-text>
                    <span v-html="data.content"></span>
                  </v-card-text>
                </v-card>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Link</v-list-item-title>
              <v-list-item-subtitle
                v-for="(media, index) in data.links"
                :key="index"
                >{{ media.tag }} -
                <a target="_blank" :href="media.link">{{ media.link }}</a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line v-if="data.images">
              <v-list-item-content>
                <v-list-item-title>Gambar</v-list-item-title>
                <v-list-item-subtitle>
                  <v-row>
                    <v-col
                      v-for="(img, index) in data.images"
                      :key="index"
                      class="d-flex child-flex"
                      cols="4"
                    >
                      <v-img
                        :src="img"
                        :lazy-src="img"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        style="cursor: pointer;"
                        @click="zoomImage(img)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line v-else>
              <v-list-item-content>
                <v-list-item-title>Gambar</v-list-item-title>
                <v-list-item-subtitle>
                    Gambar Tidak Tersedia
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Dibuat Pada</v-list-item-title>
              <v-list-item-subtitle>{{
                data.createdAt | getNormalDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Diperbaharui Pada</v-list-item-title>
              <v-list-item-subtitle>{{
                data.updatedAt | getNormalDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="closeModal"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isZoom" width="500" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2"> Gambar </v-card-title>
          <v-img :src="imageZoomed"></v-img>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="isZoom = false"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { formatDate } from "../../external/functions/Utilization.js";
export default {
  name: "post-detail",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data: () => ({
    isZoom: false,
    imageZoomed: ""
  }),

  methods: {
    closeModal() {
      this.$emit("hide", false);
    },

    zoomImage(img) {
      this.isZoom = true;
      this.imageZoomed = img;
    }
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },
};
</script>