import axios from "axios";
import { CommunityCategoryList, CreateCommunityCategory, DeleteCommunityCategory, UpdateCommunityCategory, DetailCommunityCategory, } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const CommunityCategoryListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CommunityCategoryList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateCommunityCategoryRequest = (
    key,
    value,
    brand
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateCommunityCategory()}`,
        data: {
            key: key,
            value: value,
            brand: brand
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;    
}

export const DeleteCommunityCategoryRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteCommunityCategory(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateCommunityCategoryRequest = (
    id,
    key,
    value,
    brand
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateCommunityCategory(id)}`,
        data: {
            key: key,
            value: value,
            brand: brand
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CommunityCategoryDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${DetailCommunityCategory(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}



