import {
    SalesTargetListRequest,
    CreateSalesTargetRequest,
    DeleteSalesTargetRequest,
    UpdateSalesTargetRequest,
    SetSalesTargetRequest,
    ConfirmSalesRequest,
    SalesDetailRequest
} from "../request/SalesRequest.js";

export class SalesControllers {
    salesTargetList = new Array();
    salesTargetData = new Array();
    salesDetail = new Object();
    setSalesDetail = new Object();

    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    isErrorConfirm = false;
    errorCauseConfirm = "";

    constructor() {}

    getSalesTargetList(status, page, limit, keyword) {
        this.setLoading(true);
        const resp = SalesTargetListRequest(status, page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setSalesTargetList(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getSalesTargetDetail(id) {
        this.setLoading(true);
        const resp = SalesDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setSalesTargetDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setSalesTargetDetail(data) {
        this.salesDetail = data;
    }

    createSalesTargetList(
        communityId,
        brandId,
        periode,
        goal
    ) {
        this.setLoading(true);
        const resp = CreateSalesTargetRequest(
                communityId,
                brandId,
                periode,
                goal
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getSalesDetail(id) {
        this.salesDetail = this.salesTargetList.find(item => {
            return item.id === id
        });
    }

    getSetSalesTargetList(communityId, page, limit, keyword) {
        this.setLoading(true);
        const resp = SetSalesTargetRequest(communityId, page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setSalesTargetData(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setSalesTargetData(data) {
        this.salesTargetData = data;
    }

    setSalesTargetList(data) {
        this.salesTargetList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteSalesTarget(id) {
        this.setLoading(true);
        const resp = DeleteSalesTargetRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateSalesTargetList(
        id,
        periode,
        goal
    ) {
        this.setLoading(true);
        const resp = UpdateSalesTargetRequest(
                id,
                periode,
                goal
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    confirmSales(
        id
    ) {
        const resp = ConfirmSalesRequest(
                id
            )
            .then(() => {
                this.setErrorConfirm(false);
            }).catch(err => {
                this.setErrorConfirm(true);
                this.setErrorCauseConfirm(err.response.data.message);
            });

        return resp;
    }

    setErrorConfirm(status) {
        this.isErrorConfirm = status;
    }

    setErrorCauseConfirm(err) {
        this.errorCauseConfirm = err;
    }

    getSetSalesDetail(id) {
        this.setSalesDetail = this.salesTargetData.find(item => {
            return item.id === id
        });
    }
}