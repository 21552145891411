import axios from "axios";
import { RolesList } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const RolesListRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${RolesList()}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

