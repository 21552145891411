<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-text-field
            v-model="form.name"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Nama Project"
            required
            outlined
            dense
          ></v-text-field>

          <v-textarea
            v-model="form.description"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Deskripsi"
            outlined
            required
            dense
          ></v-textarea>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

  </v-container>
</template>
<script>
import { ProjectControllers } from "../../controllers/ProjectControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "create-brand",
  data: () => ({
    project: new ProjectControllers(),
    title: "Buat Project",
    valid: true,
    
    form: {
      name: "",
      description: "",
    },
    
    isLoading: false,
    fullPage: true,
  }),

  components: {
    Loading,
  },

  computed: {
    isErrorCreateProject() {
      return this.project.isError;
    },

    errorCauseCreateProject() {
      return this.project.errorCause;
    },

    isLoadingCreateProject() {
      return this.project.isLoading;
    },
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    async submitProcess(name, description) {
      return this.project.createProjectList(
        name,
        description
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.name,
          this.form.description
        );

        if (this.isErrorCreateProject) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateProject,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>