import axios from "axios";
import { PostReportList, UserReportList } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const PostReportListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostReportList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UserReportListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${UserReportList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}
