<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col>
        <v-row>
          <v-col md="3" sm="12">
            <v-autocomplete v-model="dsoChosen" :items="dsos" item-text="name" item-value="id" label="DSO" outlined dense></v-autocomplete>
          </v-col>
          <v-col md="3" sm="12">
            <v-autocomplete v-model="brandChosen" :items="brands" item-text="name" item-value="id" label="Brands" outlined dense></v-autocomplete>
          </v-col>
          <v-col md="3" sm="12">
            <v-autocomplete v-model="categoryChosen" :items="categories" item-text="key" item-value="value" label="Category" outlined dense></v-autocomplete>
          </v-col>
          <v-col md="3" sm="12">
            <v-autocomplete v-model="yearChosen" :items="years" item-text="key" item-value="value" label="Periode" outlined dense></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="12" sm="12" v-if="getRole.roleScore.read">
        <Score :category="categoryChosen" :dso="dsoChosen" :brand="brandChosen" :year="yearChosen" />
      </v-col>
      <v-col md="12" sm="12" v-if="getRole.roleKisi.read">
        <Kisi :category="categoryChosen" :dso="dsoChosen" :brand="brandChosen" :year="yearChosen" />
      </v-col>
    </v-row>

    <!-- Error Reports -->
    <Alert :status="isErrorGetReports && errorCodeGetReports !== 404" icon="error" title="ERROR" :msg="errorCauseGetReports" />

    <!-- Error Categories -->
    <Alert :status="isErrorGetCategories && errorCodeGetCategories !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetCategories" />

    <!-- Error DSO -->
    <Alert :status="isErrorGetUsers && errorCodeGetUsers !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetUsers" />

    <!-- Error Brand -->
    <Alert :status="isErrorBrands && isErrorCodeBrands !== 404" icon="error" title="KESALAHAN" :msg="errorCauseBrandBrands" />
  </v-container>
</template>
<script>
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { AreaManagerControllers } from "../../controllers/AreaManagerControllers.js";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import { UserControllers } from "../../controllers/UserControllers.js";
// import { ReportControllers } from "../../controllers/ReportControllers.js";
import Alert from "../../components/alert/index";
import Score from "./components/report-score";
import Kisi from "./components/report-kisi";

export default {
  name: "report",
  data: () => ({
    title: "Matrik KiSi",
    category: new CategoryControllers(),
    area: new AreaManagerControllers(),
    brand: new BrandControllers(),
    user: new UserControllers(),
    // report: new ReportControllers(),

    dsoChosen: "",
    brandChosen: "",
    categoryChosen: "",
    yearChosen: new Date().getFullYear().toString,

    scopeTable: {
      page: 1,
      limit: 1000,
      keyword: "",
    },
  }),

  computed: {
    years() {
      let yearArr = [];

      const start = 2019;
      const end = new Date().getFullYear();

      for (let i = start; i <= end; i++) {
        yearArr.push({ key: i, value: i.toString() });
      }

      return yearArr;
    },

    getRole() {
      return this.$route.meta;
    },

    // categories
    categories() {
      if (this.brandChosen) {
        return this.category.categoryCommunityList.filter((category) => category.brand.includes(this.brandChosen));
      }
      return this.category.categoryCommunityList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    // dsos
    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },
    // brands
    brands() {
      return this.brand.brandList.filter((item) => item.product === "Rokok");
    },

    isErrorCodeBrands() {
      return this.brand.errorCode;
    },

    isErrorBrands() {
      return this.brand.isError;
    },

    errorCauseBrandBrands() {
      return this.brand.errorCause;
    },

    isLoadingBrandBrands() {
      return this.brand.isLoading;
    },

    // // Report data
    // reportKisiScoreList(){
    //   return this.report.reportKisiScoreList
    // },

    // dataKisi(){
    //   const data = this.report.reportKisiScoreList
    //   const format = {
    //     maxDTK : data.maxDTK,
    //     maxKHK : data.maxKHK
    //   }
    //   return format
    // },

    // errorCodeGetReports() {
    //   return this.report.errorCode;
    // },

    // isErrorGetReports() {
    //   return this.report.isError;
    // },

    // errorCauseGetReports() {
    //   return this.report.errorCause;
    // },

    // isLoadingReports() {
    //   return this.report.isLoading;
    // }
  },

  created() {
    this.getCategories();
    this.getDsos();
    this.getBrandList();
  },

  // watch:{
  //   brandChosen(newBrandId){
  //     this.categories = this.categories.filter(category=>category.brand.includes(newBrandId))
  //   }
  // },

  // watch:{
  //   dsoChosen(){
  //     this.getReports()
  //   },
  //   brandChosen(){
  //     this.getReports()
  //   },
  //   categoryChosen(){
  //     this.getReports()
  //   },
  // },

  methods: {
    // async getReports() {
    //   return this.report.getReportKisiScoreList(
    //     this.dsoChosen,
    //     this.brandChosen,
    //     this.categoryChosen
    //   );
    // },

    getCategories() {
      return this.category.getcategoryCommunityList();
    },

    getDsos() {
      return this.user.getUserAMList("admin.dso", 1, 1000, "");
    },

    getBrandList() {
      return this.brand.getBrandList(this.scopeTable.page, this.scopeTable.limit);
    },
  },

  components: {
    Score,
    Alert,
    Kisi,
  },
};
</script>
