import { MemberListRequest } from "../request/MemberRequest.js";

export class MemberControllers {
    memberList = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    memberDownload = new Array();
    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() { }

    getMemberList(communityId, page, limit, keyword, isReport) {
        this.setLoading(true);
        const resp = MemberListRequest(communityId, page, limit, keyword, isReport)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setMemberList(response.data.data);
                if(!response.data.data){
                    this.setError(true);
                    this.setErrorcode(404);
                }
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadMemberList(communityId, page, limit, keyword, isReport) {
        this.setLoadingDownload(true);
        const resp = MemberListRequest(communityId, page, limit, keyword, isReport)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setMemberListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setMemberListDownload(data) {
        this.memberDownload = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    setMemberList(data) {
        this.memberList = data;
    }
}

