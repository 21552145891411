<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <!-- :items="categories" -->
              <v-autocomplete
                v-model="form.dsoId"
                :items="dsos"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="DSO"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.category"
                :items="categories"
                item-text="value"
                item-value="key"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Jenis Kegiatan"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="brandChosen"
                :items="allBrand"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand"
                required
                outlined
                dense
              >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }}
              </template>
              </v-autocomplete>

              <v-checkbox
                v-model="collabeWithCommunity"
                label="Berkolaborasi dengan komunitas"
                color="primary"
                outlined
                dense
              ></v-checkbox>
              <v-autocomplete
                v-if="collabeWithCommunity"
                :disabled="!isChosenBrand"
                v-model="form.communityId"
                :items="communities"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Komunitas"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.title"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Judul"
                required
                outlined
                dense
              ></v-text-field>

              <v-textarea
                v-model="form.description"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Deskripsi"
                required
                outlined
                dense
              ></v-textarea>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="form.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Tanggal Event"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(form.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="form.time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.time"
                    label="Waktu Event"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  format="24hr"
                  v-model="form.time"
                  full-width
                  @click:minute="$refs.menu2.save(form.time)"
                ></v-time-picker>
              </v-menu>

              <v-text-field
                v-model="form.locationLink"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Link Lokasi"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                  v-model="form.invitedCommunity"
                  :items="communitiesInvite"
                  filled
                  chips
                  label="Tambah Kommunitas"
                  item-text="name"
                  item-value="id"
                  multiple
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
              </v-autocomplete>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert outlined type="info" v-if="!isAddressEditable">
                Untuk ubah alamat klik
                <strong style="cursor: pointer" @click="editAddress"
                  >Ubah Alamat</strong
                >
              </v-alert>
              <v-alert outlined type="error" v-else>
                Untuk batal ubah alamat klik
                <strong style="cursor: pointer" @click="cancelEditAddress"
                  >Batal Ubah</strong
                >
              </v-alert>
              <template v-if="addressForm">
                <v-autocomplete
                  v-model="form.location.province"
                  :items="provinces"
                  item-text="provinceName"
                  item-value="provinceId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Provinsi"
                  required
                  outlined
                  dense
                  @change="getDistrict($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="form.location.district"
                  :items="districts"
                  item-text="districtName"
                  item-value="districtId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kabupaten/Kota"
                  required
                  outlined
                  dense
                  @change="getSubDistrict($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="form.location.subDistrict"
                  :items="subDistricts"
                  item-text="subDistrictName"
                  item-value="subDistrictId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kecamatan"
                  required
                  outlined
                  dense
                  @change="getVillages($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="form.location.village"
                  :items="villages"
                  item-text="villageName"
                  item-value="villageId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kelurahan/Desa"
                  required
                  outlined
                  dense
                  @change="getVillagesValue($event)"
                ></v-autocomplete>

                <v-text-field
                  v-model="form.location.street"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Jalan"
                  required
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.location.zipCode"
                  :rules="[
                    (v) =>
                      Number.isInteger(Number(v)) ||
                      'Isian harus angka',
                    (v) => !!v || 'Kolom wajib diisi',
                  ]"
                  label="Kode Pos"
                  :maxlength="5"
                  required
                  outlined
                  dense
                ></v-text-field>
              </template>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Provinces -->
    <Alert
      :status="isErrorGetProvinces && errorCodeGetProvinces !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetProvinces"
    />

    <!-- Error Districts -->
    <Alert
      :status="isErrorGetDistricts && errorCodeGetDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDistricts"
    />

    <!-- Error Sub Districts -->
    <Alert
      :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetSubDistricts"
    />

    <!-- Error Villages -->
    <Alert
      :status="isErrorGetVillages && errorCodeGetVillages !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetVillages"
    />

    <!-- Error All Districts -->
    <Alert
      :status="isErrorGetAllDistricts && errorCodeGetAllDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAllDistricts"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventControllers } from "../../controllers/EventControllers";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import Swal from "sweetalert2";
import { BrandControllers } from "../../controllers/BrandControllers";
import { UserControllers } from "../../controllers/UserControllers.js";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";

export default {
  name: "update-event",
  data: () => ({
    event: new EventControllers(),
    area: new AreaControllers(),
    category: new CategoryControllers(),
    brand: new BrandControllers(),
    community: new CommunityControllers(),
    user: new UserControllers(),
    valid: true,
    title: "Ubah Event",

    isLoading: false,
    fullPage: true,

    menu: false,
    menu2: false,

    brandChosen : null,
    isBrandChosen: false,
    collabeWithCommunity:false,
    inviteCommunity: false,

    form: {
      title: "",
      dsoId: "",
      brandId: "",
      communityId:"",
      date: "",
      time: "",
      description: "",
      category: "",
      locationLink: "",
      invitedCommunity:[],
    },

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
      allDistricts: null,
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    isAddressEditable: false,
    addressForm: false,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // event length
    eventLength() {
      return this.event.eventList.length;
    },

    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },

    // all districts
    allDistricts() {
      return this.area.allDistrictList;
    },

    errorCodeGetAllDistricts() {
      return this.area.errorCodeAllDistrict;
    },

    isErrorGetAllDistricts() {
      return this.area.isErrorAllDistrict;
    },

    errorCauseGetAllDistricts() {
      return this.area.errorCauseAllDistrict;
    },

    // event update
    isErrorUpdateEvent() {
      return this.event.isError;
    },

    errorCauseUpdateEvent() {
      return this.event.errorCause;
    },

    isLoadingUpdateCreate() {
      return this.event.isLoading;
    },

    // categories
    categories() {
      return this.category.categoryEventList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    // community
    communities() {
      return this.community.communityList.filter(community=>community.brand.id == this.form.brandId && !community.isFreeze && community.isVerified);
    },

    communitiesInvite() {
      return this.community.communityList.filter(community => community != this.form.communityId && !community.isFreeze && community.isVerified);
    },

    errorCodeGetCommunities() {
      return this.community.errorCode;
    },

    isErrorGetCommunities() {
      return this.community.isError;
    },

    errorCauseGetCommunities() {
      return this.community.errorCause;
    },

    isLoadingCommunities() {
      return this.community.isLoading;
    },

    // allbrand
    allBrand() {
      return this.brand.brandList.filter(brand=>brand.product=='Rokok');
    },

    // Dso
    dsos() {
      return this.user.userListAM;
    },

    // detail event
    detailEvent() {
      const event = this.event.eventDetail;
      if (event) {
        return {
          ...event,
          brand: event.brand? event.brand : null,
          dsoId: event.dso? event.dso.id:'',
          communityId: event.community? event.community.id:'',
          title: event.title? event.title:'',
          category: event.category? event.category : '',
          date: event.date ? event.date.substr(0, 10) : '',
          time: event.date ? event.date.substr(11, 5) : '',
          locationLink: event.locationLink? event.locationLink:'',
          invitedCommunity: event.invitedCommunity? event.invitedCommunity.map(community=>community.id): [],
          location: {
            province: event.location ? event.location.province : "",
            district: event.location ? event.location.district : "",
            subDistrict: event.location ? event.location.subDistrict : "",
            village: event.location ? event.location.village : "",
            street: event.location ? event.location.street : "",
            zipCode: event.location ? event.location.zipCode : "",
          },
        };
      }

      return null;
    },
  },

  created() {
    this.getEvents()
    this.getUsers();
    this.getCategories();
    this.getAllBrand();
    this.getCommunities()
    this.getProvinces();
    this.getAllDistricts();
  },

  watch:{
    brandChosen(newBrand){
      if(newBrand.id){
        this.isChosenBrand=true
        this.form.brandId = newBrand.id
      }else{
        this.isChosenBrand=false
        this.detailEvent.brandId = ""
      }
    },
    detailEvent(newDetail){
      this.form.title = newDetail.title
      this.form.dsoId = newDetail.dsoId
      this.form.category = newDetail.category
      this.form.invitedCommunity = newDetail.invitedCommunity
      this.form.date = newDetail.date
      this.form.time = newDetail.time
      this.form.description = newDetail.description
      this.form.locationLink = newDetail.locationLink
      this.addressFiltered.province = newDetail.location.province
      this.addressFiltered.district = newDetail.location.district
      this.addressFiltered.subDistrict = newDetail.location.subDistrict
      this.addressFiltered.village = newDetail.location.village
      if(newDetail.brand){
        this.brandChosen = newDetail.brand
      }
      if(newDetail.communityId){
        this.collabeWithCommunity = newDetail.brand
        this.form.communityId = newDetail.communityId
      }
    }

  },

  methods: {
    async getEvents() {
      return this.event.getEventDetailEdit(
        this.$route.params.id
      );
    },

    getAllBrand() {
      return this.brand.getBrandList(this.selectScope.page, this.selectScope.limit)
    },

    getUsers() {
      return this.user.getUserAMList('admin.dso', 1, 1000, '')
    },

    async getCommunities() {
      return this.community.getCommunityList(
        1,
        999,
        "",
        "",
        "", 
        false, 
        "", 
        ""
      );
    },

    back() {
      this.$router.go(-1);
    },

    getCategories() {
      return this.category.getcategoryEventList();
    },

    getProvinces() {
      return this.area.getProvinceList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find(
        (item) => item.provinceId === provinceId
      );
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(
        provinceId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find(
        (item) => item.districtId === districtId
      );
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(
        districtId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find(
        (item) => item.subDistrictId === subDistrictId
      );
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(
        subDistrictId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getAllDistricts() {
      return this.area.getAllDistrictList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillagesValue(villageId) {
      const { villageName } = this.villages.find(
        (item) => item.villageId === villageId
      );
      this.addressFiltered.village = villageName;
    },

    async submitProcess(
      title,
      dsoId,
      communityId,
      invitedCommunity,
      brandId,
      description,
      category,
      date,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode,
      locationLink
    ) {
      return this.event.updateEventList(
        this.$route.params.id,
        title,
        dsoId,
        communityId,
        invitedCommunity,
        brandId,
        description,
        category,
        date,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        locationLink
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;

        if (this.isAddressEditable) {
          await this.submitProcess(
            this.form.title,
            this.form.dsoId,
            this.form.communityId,
            this.form.invitedCommunity.filter(community => community != this.form.communityId),
            this.form.brandId,
            this.form.description,
            this.form.category,
            `${this.form.date}T${this.form.time}:00`,
            this.addressFiltered.province,
            this.addressFiltered.district,
            this.addressFiltered.subDistrict,
            this.addressFiltered.village,
            this.detailEvent.location.street,
            this.detailEvent.location.zipCode,
            this.form.locationLink
          );
        } else {
          await this.submitProcess(
            this.form.title,
            this.form.dsoId,
            this.form.communityId,
            this.form.invitedCommunity,
            this.form.brandId,
            this.form.description,
            this.form.category,
            `${this.form.date}T${this.form.time}:00`,
            this.detailEvent.location.province,
            this.detailEvent.location.district,
            this.detailEvent.location.subDistrict,
            this.detailEvent.location.village,
            this.detailEvent.location.street,
            this.detailEvent.location.zipCode,
            this.form.locationLink
          );
        }

        if (this.isErrorUpdateEvent) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateEvent,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });

          this.back();
        }
      }
    },

    editAddress() {
      this.isAddressEditable = true;
      this.addressForm = true;
      this.getProvinces();
    },

    cancelEditAddress() {
      this.isAddressEditable = false;
      this.addressForm = false;
    },
  },
};
</script>