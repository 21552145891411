import { 
    ProvinceListRequest, 
    DistrictListRequest, 
    SubDistrictListRequest, 
    VillageListRequest, 
    AllDistrictListRequest,
    AllSubDistrictListRequest,
    AllVillagesListRequest
} from "../request/AreaRequest.js";

export class AreaControllers {
    provinceList = new Array();
    isErrorProvince = false;
    isLoadingProvince = false;
    errorCauseProvince = "";
    errorCodeProvince = 0;

    districtList = new Array();
    isErrorDistrict = false;
    isLoadingDistrict = false;
    errorCauseDistrict = "";
    errorCodeDistrict = 0;

    subDistrictList = new Array();
    isErrorSubDistrict = false;
    isLoadingSubDistrict = false;
    errorCauseSubDistrict = "";
    errorCodeSubDistrict = 0;

    villageList = new Array();
    isErrorVillage = false;
    isLoadingVillage = false;
    errorCauseVillage = "";
    errorCodeVillage = 0;

    allDistrictList = new Array();
    isErrorAllDistrict = false;
    isLoadingAllDistrict = false;
    errorCauseAllDistrict = "";
    errorCodeAllDistrict = 0;

    allSubDistrictList = new Array();
    isErrorAllSubDistrict = false;
    isLoadingAllSubDistrict = false;
    errorCauseAllSubDistrict = "";
    errorCodeAllSubDistrict = 0;

    allVillagesList = new Array();
    isErrorAllVillages = false;
    isLoadingAllVillages = false;
    errorCauseAllVillages = "";
    errorCodeAllVillages = 0;

    constructor() { }

    // provinces
    getProvinceList(page, limit) {
        const resp = ProvinceListRequest(page, limit)
            .then((response) => {
                this.setProvinceList(response.data.data);
            }).catch(err => {
                this.setErrorProvince(true);
                this.setErrorCauseProvince(err.response.data.message);
                this.setErrorcodeProvince(err.response.data.code);
            });

        return resp;
    }

    setProvinceList(data) {
        this.provinceList = data;
    }

    setLoadingProvince(status) {
        this.isLoadingProvince = status;
    }

    setErrorProvince(status) {
        this.isErrorProvince = status;
    }

    setErrorCauseProvince(err) {
        this.errorCauseProvince = err;
    }

    setErrorcodeProvince(code) {
        this.errorCodeProvince = code;
    }

    // districts
    getDistrictList(provinceId, page, limit) {
        const resp = DistrictListRequest(provinceId, page, limit)
            .then((response) => {
                this.setDistrictList(response.data.data);
            }).catch(err => {
                this.setErrorDistrict(true);
                this.setErrorCauseDistrict(err.response.data.message);
                this.setErrorcodeDistrict(err.response.data.code);
            });

        return resp;
    }

    setDistrictList(data) {
        this.districtList = data;
    }

    setLoadingDistrict(status) {
        this.isLoadingDistrict = status;
    }

    setErrorDistrict(status) {
        this.isErrorDistrict = status;
    }

    setErrorCauseDistrict(err) {
        this.errorCauseDistrict = err;
    }

    setErrorcodeDistrict(code) {
        this.errorCodeDistrict = code;
    }

    // sub districts
    getSubDistrictList(districtId, page, limit) {
        const resp = SubDistrictListRequest(districtId, page, limit)
            .then((response) => {
                this.setSubDistrictList(response.data.data);
            }).catch(err => {
                this.setErrorSubDistrict(true);
                this.setErrorCauseSubDistrict(err.response.data.message);
                this.setErrorcodeSubDistrict(err.response.data.code);
            });

        return resp;
    }

    setSubDistrictList(data) {
        this.subDistrictList = data;
    }

    setLoadingSubDistrict(status) {
        this.isLoadingSubDistrict = status;
    }

    setErrorSubDistrict(status) {
        this.isErrorSubDistrict = status;
    }

    setErrorCauseSubDistrict(err) {
        this.errorCauseSubDistrict = err;
    }

    setErrorcodeSubDistrict(code) {
        this.errorCodeSubDistrict = code;
    }

    // village
    getVillageList(subDistrictId, page, limit) {
        const resp = VillageListRequest(subDistrictId, page, limit)
            .then((response) => {
                this.setVillageList(response.data.data);
            }).catch(err => {
                this.setErrorVillage(true);
                this.setErrorCauseVillage(err.response.data.message);
                this.setErrorcodeVillage(err.response.data.code);
            });

        return resp;
    }

    setVillageList(data) {
        this.villageList = data;
    }

    setLoadingVillage(status) {
        this.isLoadingVillage = status;
    }

    setErrorVillage(status) {
        this.isErrorVillage = status;
    }

    setErrorCauseVillage(err) {
        this.errorCauseVillage = err;
    }

    setErrorcodeVillage(code) {
        this.errorCodeVillage = code;
    }

    // all districts
    getAllDistrictList(page, limit) {
        const resp = AllDistrictListRequest(page, limit)
            .then((response) => {
                this.setAllDistrictList(response.data.data);
            }).catch(err => {
                this.setErrorAllDistrict(true);
                this.setErrorCauseAllDistrict(err.response.data.message);
                this.setErrorcodeAllDistrict(err.response.data.code);
            });

        return resp;
    }

    setAllDistrictList(data) {
        this.allDistrictList = data;
    }

    setLoadingAllDistrict(status) {
        this.isLoadingAllDistrict = status;
    }

    setErrorAllDistrict(status) {
        this.isErrorAllDistrict = status;
    }

    setErrorCauseAllDistrict(err) {
        this.errorCauseAllDistrict = err;
    }

    setErrorcodeAllDistrict(code) {
        this.errorCodeAllDistrict = code;
    }

    // all subdistricts
    getAllSubDistrictList(page, limit) {
        const resp = AllSubDistrictListRequest(page, limit)
            .then((response) => {
                this.setAllSubDistrictList(response.data.data);
            }).catch(err => {
                this.setErrorAllSubDistrict(true);
                this.setErrorCauseAllSubDistrict(err.response.data.message);
                this.setErrorcodeAllSubDistrict(err.response.data.code);
            });

        return resp;
    }

    setAllSubDistrictList(data) {
        this.allSubDistrictList = data;
    }

    setLoadingAllSubDistrict(status) {
        this.isLoadingAllSubDistrict = status;
    }

    setErrorAllSubDistrict(status) {
        this.isErrorAllSubDistrict = status;
    }

    setErrorCauseAllSubDistrict(err) {
        this.errorCauseAllSubDistrict = err;
    }

    setErrorcodeAllSubDistrict(code) {
        this.errorCodeAllSubDistrict = code;
    }

    // all villages
    getAllVillagesList(page, limit) {
        const resp = AllVillagesListRequest(page, limit)
            .then((response) => {
                this.setAllVillagesList(response.data.data);
            }).catch(err => {
                this.setErrorAllVillages(true);
                this.setErrorCauseVillages(err.response.data.message);
                this.setErrorcodeAllVillages(err.response.data.code);
            });

        return resp;
    }

    setAllVillagesList(data) {
        this.allVillagesList = data;
    }

    setLoadingAllVillages(status) {
        this.isLoadingAllVillages = status;
    }

    setErrorAllVillages(status) {
        this.isErrorAllVillages = status;
    }

    setErrorCauseVillages(err) {
        this.errorCauseAllVillages = err;
    }

    setErrorcodeAllVillages(code) {
        this.errorCodeAllVillages = code;
    }
}

