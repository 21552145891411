<template>
  <v-container class="pt-8">
    <v-row justify="space-between">
      <v-col md="9" sm="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Set Media Sosial Follower
        </h2>
      </v-col>
      <v-col md="3" sm="12">
          <v-btn
            v-if="medsosfollowers.length<3"
            class="float-right"
            depressed
            color="primary"
            @click="setCreateMedsosFollower"
            block
          >
            Tambah Media Sosial Follower
          </v-btn>
      </v-col>
    </v-row>
    <!-- Set media sosial per kategori -->
    <v-card v-if="create" class="my-10">
      <v-card-title>
        Tambah Media Sosial Follower
      </v-card-title>
      <v-form ref="observer" v-model="valid" lazy-validation>
        <v-row justify="space-around">
            <v-col md="3" sm="12">
                <v-autocomplete
                    v-model="form.dso"
                    :items="dsos"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    label="DSO"
                    dense
                    outlined
                    left
                ></v-autocomplete>
            </v-col>
            <v-col md="3" sm="12">
                <v-autocomplete
                    v-model="form.medsos"
                    :items="medsosdsos"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    label="Media Sosial DSO"
                    dense
                    outlined
                    left
                ></v-autocomplete>
            </v-col>
            <v-col md="3" sm="12">
                <v-autocomplete
                    v-model="form.category"
                    :items="categories"
                    item-text="name"
                    item-value="name"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    label="Categories"
                    dense
                    outlined
                    left
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-n5">
          <v-col md="2" sm="12">
              <v-btn
                  :disabled="!valid"
                  depressed
                  color="success"
                  @click="createMedsosFollower"
                  block
              >
                  Tambahkan
              </v-btn>
        </v-col>
        <v-col md="2" sm="12">
              <v-btn
                  v-if="getRole.role.create"
                  depressed
                  color="primary"
                  @click="cancle"
                  block
              >
                  Batalkan
              </v-btn>
        </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-card v-if="edit" class="my-10">
      <v-card-title>
        Edit Media Sosial Follower
      </v-card-title>
      <v-form ref="observer" v-model="valid" lazy-validation>
        <v-row  justify="space-around">
            <v-col md="3" sm="12">
                <v-autocomplete
                    v-model="form.dso"
                    :items="dsos"
                    item-text="name"
                    item-value="id"
                    label="DSO"
                    dense
                    outlined
                    left
                ></v-autocomplete>
            </v-col>
            <v-col md="3" sm="12">
                <v-autocomplete
                    v-model="form.medsos"
                    :items="medsosdsos"
                    item-text="name"
                    item-value="id"
                    label="Media Sosial DSO"
                    dense
                    outlined
                    left
                ></v-autocomplete>
            </v-col>
            <v-col md="3" sm="12">
                <v-autocomplete
                    v-model="form.category"
                    :items="categories"
                    item-text="name"
                    item-value="name"
                    label="Categories"
                    dense
                    outlined
                    left
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-n5">
          <v-col md="2" sm="12">
              <v-btn
                  :disabled="!valid"
                  v-if="getRole.role.create"
                  depressed
                  color="success"
                  @click="editMedsosFollower"
                  block
              >
                  Edit
              </v-btn>
        </v-col>
        <v-col md="2" sm="12">
              <v-btn
                  v-if="getRole.role.create"
                  depressed
                  color="primary"
                  @click="cancle"
                  block
              >
                  Batalkan
              </v-btn>
        </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-row v-if="!isLoadingMedsosFollower">
      <v-col md="12" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-if="!medsosfollowers.length">
          <v-card-text>Medsos Follower Belum Ditambahkan</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">DSO</th>
                  <th class="text-left">Nama Medsos</th>
                  <th class="text-left">Kategori</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in medsosfollowers" :key="item.id">
                  <td>{{item.dso.name}}</td>
                  <td>{{item.medsos.name}}</td>
                  <td>{{item.category}}</td>
                  <td v-if="getRole.role.update">
                      <v-btn small icon color="warning" dark @click="seteditMedsosFollower(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                  </td>
                  <td >
                    <v-btn small icon color="error" dark @click="deleteMedsosFollower(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseMedsosFollower"
    />

    <!-- Error Categories -->
    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    />

    <!-- Error Categories -->
    <Alert
      :status="isErrorGetMedsosDSO && errorCodeGetMedsosDSO !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetMedsosDSO"
    />

    <Alert
      :status="isErrorUpdateMedsosFollower && errorCodeUpdateMedsosFollower !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseUpdateMedsosFollower"
    />

    <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />

  </v-container>
</template>

<script>
import { MedsosFollowerControllers } from "../../../controllers/MedsosFollowerControllers";
import { MedsosDSOControllers } from "../../../controllers/MedsosDSOControllers";
import { UserControllers } from "../../../controllers/UserControllers.js";
import Alert from "../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  name: "create-user",
  data: () => ({
    user: new UserControllers(),
    medsosdso: new MedsosDSOControllers(),
    medsosfollower: new MedsosFollowerControllers(),
    create:false,
    edit: false,
    categories:[{name:"DSO I"},{name:"DSO II"},{name:"Kolaborasi Kita"}],

    editId: '',

    medsosfollowerTable: {
      page: 1,
      limit: 3,
      keyword: "",
    },

    pages: {
      start: 1,
      end: 2,
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    isLoading: false,
    fullPage: true,

    search: {
      debounce: null,
    },

    searchField: "",

    form: {
      dso: null,
      medsos: null,
      category: "",
    },
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    this.getMedsosFollower();
    this.getUsers()
    this.getMedsosDSO()
    this.getMedsosKolaborasi()
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    medsosfollowers() {
      return this.medsosfollower.medsosfollowerList;
    },

    isErrorCode() {
      return this.medsosfollower.errorCode;
    },

    isError() {
      return this.medsosfollower.isError;
    },

    errorCauseMedsosFollower() {
      return this.medsosfollower.errorCause;
    },

    isLoadingMedsosFollower() {
      return this.medsosfollower.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.medsosfollower.isErrorDelete;
    },

    errorCauseDelete() {
      return this.medsosfollower.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.medsosfollower.isLoading;
    },

    // MedsosDSO
    medsosdsos() {
      const medsoslist = this.medsosdso.medsosdsoList
      medsoslist.push(this.medsoskolaborasi)
      return (medsoslist);
    },

    medsoskolaborasi(){
      return this.medsosdso.medsoskolaborasi;
    },

    isErrorCodeGetMedsosDSO() {
      return this.medsosdso.errorCode;
    },

    isErrorGetMedsosDSO() {
      return this.medsosdso.isError;
    },

    errorCauseGetMedsosDSO() {
      return this.medsosdso.errorCause;
    },

    isLoadingGetMedsosDSO() {
      return this.medsosdso.isLoading;
    },

    // users
    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    // Update Medsos Follower
    isErrorUpdateMedsosFollower() {
      return this.medsosfollower.isError;
    },

    errorCodeUpdateMedsosFollower(){
      return this.medsosfollower.errorCode;
    },

    errorCauseUpdateMedsosFollower() {
      return this.medsosfollower.errorCause;
    },

    isLoadingUpdateMedsosFollower() {
      return this.medsosfollower.isLoading;
    },
  },

  methods: {
    async getMedsosFollower() {
      return this.medsosfollower.getMedsosFollowerList(1,3)
    },

    async getUsers() {
      return this.user.getUserAMList('admin.dso', 1, 1000, '')
    },

    async getMedsosDSO() {
      return this.medsosdso.getMedsosDSOList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    async getMedsosKolaborasi() {
      return this.medsosdso.getMedsosKolaborasi()
    },

    setCreateMedsosFollower() {
      this.edit =false
      this.create=true
    },

    seteditMedsosFollower(item){
      this.create=false
      this.edit=true
      this.editId = item.id
      this.form.dso=item.dso.id
      this.form.medsos= item.medsos.id
      this.form.category= item.category

    },

    deleteMedsosFollower(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.medsosfollower.deleteMedsosFollower(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.medsosfollower.getMedsosFollowerList(
            this.medsosfollowerTable.page,
            this.medsosfollowerTable.limit
          );
        });
    },

    cancle(){
      this.create=false
      this.edit=false
    },

    back() {
      this.$router.go(-1);
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.medsosfollowerTable.page = 1;
      this.medsosfollowerTable.limit = 10;
      this.medsosfollowerTable.keyword = this.searchField;

      await this.getMedsosFollower(
        this.medsosfollowerTable.page,
        this.medsosfollowerTable.limit,
        this.medsosfollowerTable.keyword
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.medsosfollowerTable.page = 1;
          this.medsosfollowerTable.limit = 10;
          this.medsosfollowerTable.keyword = event;

          await this.getMedsosFollower(
            this.medsosfollowerTable.page,
            this.medsosfollowerTable.limit,
            this.medsosfollowerTable.keyword
          );
        }
      }, 500);
    },

  // Create Process
    async createProcess(
      dso,
      medsos,
      category,
    ) {
      return this.medsosfollower.createMedsosFollowerList(
        dso,
        medsos,
        category,
      );
    },

    async createMedsosFollower() {
      const validate = this.$refs.observer.validate();

      if (validate) {
        this.isLoading = true;
        await this.createProcess(
          this.form.dso,
          this.form.medsos,
          this.form.category,
        )
        .finally(() => {
          return this.medsosfollower.getMedsosFollowerList(
            this.medsosfollowerTable.page,
            this.medsosfollowerTable.limit
          );
        });

        if (this.isErrorCreateMedsosFollower) {
          this.isLoading = false;
          this.isErrorCreateMedsosFollower = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateMedsosFollower,
          });
        } else {
          this.isLoading = false;
          this.create=false
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorCreateMedsosFollower = false;
          this.$refs.observer.reset();
        }
      }
    },

  // Edit Process
    async editProcess(
      dso,
      medsos,
      category,
    ) {
      return this.medsosfollower.updateMedsosFollowerList(
        this.editId,
        dso,
        medsos,
        category,
      );
    },

    async editMedsosFollower() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.editProcess(
          this.form.dso,
          this.form.medsos,
          this.form.category,
        )
        .finally(() => {
          return this.medsosfollower.getMedsosFollowerList(
            this.medsosfollowerTable.page,
            this.medsosfollowerTable.limit
          );
        });

        if (this.isErrorUpdateMedsosFollower) {
          this.isLoading = false;
          this.isErrorUpdateMedsosFollower = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateMedsosFollower,
          });
        } else {
          this.isLoading = false;
          this.edit=false
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diupdate",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorUpdateMedsosFollower = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
}
</script>
