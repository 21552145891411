<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="9" sm="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon> </v-btn
          >{{ title }}
        </h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row v-if="!isLoadingTable">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>

        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Brand</th>
                  <th class="text-left">SKU</th>
                  <th class="text-left">Harga</th>
                  <th class="text-left">Total</th>
                  <th class="text-center">Rincian</th>
                  <th class="text-center">Konfirmasi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in targetlist" :key="item.id">
                  <td v-if="item.brand">{{ item.brand.name }}</td>
                  <td v-else>-</td>
                  <td>{{ item.sku }}</td>
                  <td>{{ item.price | formatPrice }}</td>
                  <td>{{ item.total }}</td>
                  <td class="text-center">
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td class="text-center" v-if="item.isConfirmed">
                    <v-chip color="success"> Terkonfirmasi </v-chip>
                  </td>
                  <td class="text-center" v-else>
                    <v-btn
                      small
                      outlined
                      color="success"
                      dark
                      @click="confirmation(item.id)"
                    >
                      Konfirmasi
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Detail :isShow="isDetail" :data="dataDetail" @hide="updateModal" />

    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />

    <Alert
      :status="isErrorConfirm"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseConfirm"
    />
  </v-container>
</template>
<script>
import Alert from "../../../../components/alert/index.vue";
import { SalesControllers } from "../../../../controllers/SalesControllers.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import Detail from "./set-target-detail"
import { formatPrice } from "../../../../external/functions/Utilization.js";

export default {
  name: "set-sales-target",
  data: () => ({
    title: "Verifikasi",
    sales: new SalesControllers(),
    salesTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,
    searchField: ""
  }),

  filters: {
    formatPrice(price) {
      return formatPrice(price.toString(), 'Rp ');
    }
  },

  computed: {
    targetlist() {
      return this.sales.salesTargetData;
    },

    isErrorCode() {
      return this.sales.errorCode;
    },

    isError() {
      return this.sales.isError;
    },

    errorCause() {
      return this.sales.errorCause;
    },

    isLoadingTable() {
      return this.sales.isLoading;
    },

    // confirm
    isErrorConfirm() {
      return this.sales.isErrorConfirm;
    },

    errorCauseConfirm() {
      return this.sales.errorCauseConfirm;
    },

    // detail
    dataDetail() {
      return this.sales.setSalesDetail;
    },
  },

  components: {
    Loading,
    Alert,
    Detail
  },

  created() {
    this.getSalesTargetList();
  },

  methods: {
    back() {
      this.$router.push({
        name: "Sales",
        params: {
          tab: this.$route.params.tab
        }
      });
    },

    async getSalesTargetList() {
      return this.sales.getSetSalesTargetList(
        this.$route.params.id,
        this.salesTable.page,
        this.salesTable.limit,
        this.salesTable.keyword
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingTable;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.salesTable.page -= 1;

        await this.getSalesTargetList(
          this.$route.params.id,
          this.salesTable.page,
          this.salesTable.limit,
          this.salesTable.keyword
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingTable;
      this.pages.start += 10;
      this.pages.end += 10;

      this.salesTable.page += 1;

      await this.getSalesTargetList(
        this.$route.params.id,
        this.salesTable.page,
        this.salesTable.limit,
        this.salesTable.keyword
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    getdetail(id) {
      this.isDetail = true;
      return this.sales.getSetSalesDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.salesTable.page = 1;
      this.salesTable.limit = 10;
      this.salesTable.keyword = this.searchField;

      await this.getSalesTargetList(
        this.$route.params.id,
        this.salesTable.page,
        this.salesTable.limit,
        this.salesTable.keyword
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.salesTable.page = 1;
          this.salesTable.limit = 10;
          this.salesTable.keyword = event;

          await this.getSalesTargetList(
            this.$route.params.id,
            this.salesTable.page,
            this.salesTable.limit,
            this.salesTable.keyword
          );
        }
      }, 500);
    },


    confirmation(id) {
      Swal.fire({
        title: "Yakin akan konfirmasi data ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, konfirmasi!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.sales.confirmSales(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.sales.getSetSalesTargetList(
            this.$route.params.id,
            this.salesTable.page,
            this.salesTable.limit,
            this.salesTable.keyword
          );
        });
    }
  },
};
</script>