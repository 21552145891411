<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12">
        <RightReport />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import RightReport from "./components/right-report.vue";
export default {
  name: "community-report-index",
  data: () => ({
    title: "Laporan Komunitas",
  }),

  components: {
    RightReport
  }
};
</script>
<style scoped>
.vertical-line {
  border-left: 1px solid #95a5a6;
}
</style>