<template>
  <v-dialog v-model="isShow" width="500" scrollable>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Rincian Sales
      </v-card-title>
      <v-card-text>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>ID</v-list-item-title>
            <v-list-item-subtitle>{{ data.id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Tanggal Mulai</v-list-item-title>
            <v-list-item-subtitle>{{ data.startDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Tanggal Akhir</v-list-item-title>
            <v-list-item-subtitle>{{ data.endDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Target</v-list-item-title>
            <v-list-item-subtitle>{{ data.goal }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Jangkauan</v-list-item-title>
            <v-list-item-subtitle>{{ data.reach }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.community">
          <v-list-item-content>
            <v-list-item-title>Nama Komunitas</v-list-item-title>
            <v-list-item-subtitle>{{
              data.community.name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.community">
          <v-list-item-content>
            <v-list-item-title>Area Komunitas</v-list-item-title>
            <v-list-item-subtitle>{{
              data.community.area
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.user">
          <v-list-item-content>
            <v-list-item-title>Pengguna</v-list-item-title>
            <v-list-item-subtitle>{{ data.user.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.brand">
          <v-list-item-content>
            <v-list-item-title>Nama Project</v-list-item-title>
            <v-list-item-subtitle>{{
              data.brand.project.name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.brand">
          <v-list-item-content>
            <v-list-item-title>Nama Brand</v-list-item-title>
            <v-list-item-subtitle>{{ data.brand.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Dibuat Pada</v-list-item-title>
            <v-list-item-subtitle>{{
              data.createdAt | getNormalDate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Diperbaharui Pada</v-list-item-title>
            <v-list-item-subtitle>{{
              data.updatedAt | getNormalDate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="closeModal"> Tutup </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatDate } from "../../../external/functions/Utilization.js";
export default {
  name: "sales-detail",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit("hide", false);
    },
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },
};
</script>