import axios from "axios";
import { 
    SalesTargetList, 
    CreateSalesTarget, 
    DeleteSalesTarget, 
    UpdateSalesTarget, 
    SetSalesTarget, 
    ConfirmSales,
    SalesDetail,
    getSalesPercentage
} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const SalesTargetListRequest = (page, limit, keyword, brand, category, periode, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${SalesTargetList(page, limit, keyword, brand, category, periode, dsoId)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateSalesTargetRequest = (
    communityId,
    brandId,
    periode,
    goal
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateSalesTarget()}`,
        data: {
            communityId: communityId,
            brandId: brandId,
            // startDate: startDate,
            // endDate: endDate,
            periode: periode,
            goal: goal
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteSalesTargetRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteSalesTarget(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateSalesTargetRequest = (
    id,
    // startDate,
    // endDate,
    periode,
    goal
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateSalesTarget(id)}`,
        data: {
            // startDate: startDate,
            // endDate: endDate,
            periode: periode,
            goal: goal
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const SetSalesTargetRequest = (communityId, page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${SetSalesTarget(communityId, page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const ConfirmSalesRequest = (
    id
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${ConfirmSales(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const SalesDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${SalesDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const SalesTargetPercentageRequest = (targetId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getSalesPercentage(targetId)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}