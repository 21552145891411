import { AreaManagerAMListRequest, 
    AreaManagerDSOListRequest, 
    CreateAreaManagerAMRequest, 
    UpdateAreaManagerAMRequest, 
    DeleteAreaManagerAMRequest,
    DeleteAreaManagerDSORequest,
    CreateAreaManagerDSORequest,
    UpdateAreaManagerDSORequest,
    AreaManagerRSOListRequest,
    CreateAreaManagerRSORequest,
    UpdateAreaManagerRSORequest,
    DeleteAreaManagerRSORequest,
    RSODetailRequest,
    AMDetailRequest,
    DSODetailRequest
} from "../request/AreaManagerRequest.js";

export class AreaManagerControllers  {
    areaManagerAMList = new Array();
    areaManagerDSOList = new Array();
    areaManagerRSOList = new Array();
    dsoDetail = new Object();
    rsoDetail = new Object();
    amDetail= new Object();
    dsoDetail= new Object();

    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    isErrorDeleteDSO = false;
    errorCauseDeleteDSO = "";
    messageDeleteDSO = "";

    isErrorLoadAM = false;
    errorCauseLoadAM = "";
    
    constructor() {}

    getAreaManagerAMList(page, limit, keyword) {
        this.setLoading(true);
        const resp = AreaManagerAMListRequest(page, limit, keyword)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setAreaManagerAMList(response.data.data);
            return true;
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
            return false;
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getAreaManagerDSOList(page, limit, keyword) {
        this.setLoading(true);
        const resp = AreaManagerDSOListRequest(page, limit, keyword)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setAreaManagerDSOList(response.data.data);
            return true;
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
            return false;
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getAreaRSODetail(id) {
        this.setLoading(true);
        const resp = RSODetailRequest(id)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setRSODetail(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setRSODetail(data) {
        this.rsoDetail = data;
    }

    getAreaAMDetail(id) {
        this.setLoading(true);
        const resp = AMDetailRequest(id)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setAMDetail(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setAMDetail(data) {
        this.amDetail = data;
    }

    getAreaDSODetail(id) {
        this.setLoading(true);
        const resp = DSODetailRequest(id)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setDSOetail(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setDSOetail(data) {
        this.dsoDetail = data;
    }

    setAreaManagerAMList(data) {
        this.areaManagerAMList = data;
    }

    setAreaManagerDSOList(data) {
        this.areaManagerDSOList = data;
    }

    createAreaManagerAMList(
        name, remark, region, manager
    ) {
        this.setLoading(true);
        const resp = CreateAreaManagerAMRequest(
            name, remark, region, manager
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    updateAreaManagerAMList(
        id, name, remark, region, manager
    ) {
        this.setLoading(true);
        const resp = UpdateAreaManagerAMRequest(
            id, name, remark, region, manager
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteAreaManagerAM(id) {
        this.setLoading(true);
        const resp = DeleteAreaManagerAMRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    deleteAreaManagerDSO(id) {
        this.setLoading(true);
        const resp = DeleteAreaManagerDSORequest(id)
            .then((response) => {
                this.setErrorDeleteDSO(false);
                this.setMsgDeleteDSO(response.data.message);
            }).catch(err => {
                this.setErrorDeleteDSO(true);
                this.setErrorCauseDeleteDSO(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDeleteDSO(value) {
        this.messageDeleteDSO = value;
    }

    setErrorDeleteDSO(status) {
        this.isErrorDeleteDSO = status;
    }

    setErrorCauseDeleteDSO(err) {
        this.errorCauseDeleteDSO = err;
    }

    createAreaManagerDSOList(
        areaId, name, dso, head, category
    ) {
        this.setLoading(true);
        const resp = CreateAreaManagerDSORequest(
            areaId, name, dso, head, category
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    updateAreaManagerDSOList(
        id, areaId, name, dso, head, category
    ) {
        this.setLoading(true);
        const resp = UpdateAreaManagerDSORequest(
            id, areaId, name, dso, head, category
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getDSODetail(id) {
        this.dsoDetail = this.areaManagerDSOList.find(item => {
            return item.id === id
        });
    }

    getAreaManagerAMOptions(page, limit, keyword) {
        this.setLoading(true);
        const resp = AreaManagerAMListRequest(page, limit, keyword)
        .then((response) => {
            this.setErrorLoadAM(false);
            this.setAreaManagerAMOptions(response.data.data);
        }).catch(err => {
            this.setErrorLoadAM(true);
            this.setErrorCauseLoadAM(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setAreaManagerAMOptions(data) {
        this.areaManagerAMList = data;
    }

    setErrorLoadAM(status) {
        this.isErrorLoadAM(status);
    }

    setErrorCauseLoadAM(err) {
        this.errorCauseLoadAM(err);
    }

    getAreaManagerRSOList(page, limit, keyword) {
        this.setLoading(true);
        const resp = AreaManagerRSOListRequest(page, limit, keyword)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setAreaManagerRSOList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setAreaManagerRSOList(data) {
        this.areaManagerRSOList = data;
    }

    createAreaManagerRSOList(
        name, remark, manager, head
    ) {
        this.setLoading(true);
        const resp = CreateAreaManagerRSORequest(
            name, remark, manager, head
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    updateAreaManagerRSOList(
        id, name, remark, manager, head
    ) {
        this.setLoading(true);
        const resp = UpdateAreaManagerRSORequest(
            id, name, remark, manager, head
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    deleteAreaManagerRSO(id) {
        this.setLoading(true);
        const resp = DeleteAreaManagerRSORequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

}

