import { EventListRequest, CreateEventListRequest, DeleteEventRequest, UpdateEventRequest, EventDetailRequest, getTotalEventRequest } from "../request/EventRequest.js";

export class EventControllers {
  eventList = new Array();
  eventDetail = new Object();
  eventReview = new Object();
  eventDownload = new Array();
  eventTotal = new Object();
  isError = false;
  isLoading = false;
  errorCause = "";
  errorCode = 0;

  isErrorDelete = false;
  errorCauseDelete = "";
  messageDelete = "";

  errorDownload = false;
  errorCodeDownload = "";
  errorCauseDownload = "";
  loadingDownload = false;

  constructor() { }

  getEventList(page, limit, keyword, brand, category, dsoId, group) {
    this.setLoading(true);
    const resp = EventListRequest(page, limit, keyword, brand, category, dsoId, group)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setEventList(response.data.data);
      })
      .catch((err) => {
        this.setEventList([]);
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getEventDetailEdit(id) {
    this.setLoading(true);
    const resp = EventDetailRequest(id)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setEventDetail(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setEventDetail(data) {
    this.eventDetail = data;
  }

  getDetail(id) {
    this.eventDetail = this.eventList.find((item) => {
      return item.id === id;
    });
  }

  setEventList(data) {
    this.eventList = data;
  }

  setLoading(status) {
    this.isLoading = status;
  }

  setError(status) {
    this.isError = status;
  }

  setErrorCause(err) {
    this.errorCause = err;
  }

  setErrorcode(code) {
    this.errorCode = code;
  }

  downloadEventList(page, limit, keyword, brand, category, dsoId, group) {
    this.setLoadingDownload(true);
    const resp = EventListRequest(page, limit, keyword, brand, category, dsoId, group)
      .then((response) => {
        this.setErrorDownload(false);
        this.setErrorcodeDownload(response.data.code);
        this.setEventListDownload(response.data.data);
      }).catch(err => {
        this.setErrorDownload(true);
        this.setErrorCauseDownload(err.response.data.message);
        this.setErrorcodeDownload(err.response.data.code);
      }).finally(() => {
        this.setLoadingDownload(false);
      });

    return resp;
  }

  setErrorDownload(status) {
    this.errorDownload = status;
  }

  setErrorcodeDownload(code) {
    this.errorCodeDownload = code;
  }

  setErrorCauseDownload(err) {
    this.errorCauseDownload = err;
  }

  setLoadingDownload(status) {
    this.loadingDownload = status;
  }

  setEventListDownload(data) {
    this.eventDownload = data;
  }

  createEventList(title, dsoId, brandId, communityId, scale, method, invitedCommunity, description, category, date, province, district, subDistrict, village, street, zipCode, locationLink, images, group) {
    this.setLoading(true);
    const resp = CreateEventListRequest(title, dsoId, brandId, communityId, scale, method, invitedCommunity, description, category, date, province, district, subDistrict, village, street, zipCode, locationLink, images, group)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  deleteEvent(id) {
    this.setLoading(true);
    const resp = DeleteEventRequest(id)
      .then((response) => {
        this.setErrorDelete(false);
        this.setMsgDelete(response.data.message);
      })
      .catch((err) => {
        this.setErrorDelete(true);
        this.setErrorCauseDelete(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setMsgDelete(value) {
    this.messageDelete = value;
  }

  setErrorDelete(status) {
    this.isErrorDelete = status;
  }

  setErrorCauseDelete(err) {
    this.errorCauseDelete = err;
  }

  updateEventList(id, title, dsoId, communityId, invitedCommunity, brandId, description, category, date, province, district, subDistrict, village, street, zipCode, locationLink, scale, method, group) {
    this.setLoading(true);
    const resp = UpdateEventRequest(id, title, dsoId, communityId, invitedCommunity, brandId, description, category, date, province, district, subDistrict, village, street, zipCode, locationLink, scale, method, group)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getTotalEvent(dsoId, year) {
    this.setLoading(true);
    const resp = getTotalEventRequest(dsoId, year)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setEventTotal(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setEventTotal(value) {
    this.eventTotal = value;
  }
}
