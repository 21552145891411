<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="10" sm="12"> {{ title }} </v-col>
          <v-col md="2" sm="12">
            <v-btn class="success" :loading="isLoadingDownload" small block @click="download" :disabled="brand == '' || errorCodeGetReports === 404">Unduh Excel</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- <v-row>
          <v-col md="4" sm="12">
            <v-autocomplete
              v-model="dso"
              @change="reportByDso"
              :items="dsos"
              item-text="name"
              item-value="id"
              label="DSO"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="4" sm="12">
            <v-autocomplete
              v-model="brand"
              @change="reportByBrand"
              :items="brands"
              item-text="name"
              item-value="id"
              label="Brands"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="4" sm="12">
            <v-autocomplete
              v-model="category"
              @change="reportByCategory"
              :items="categories"
              item-text="value"
              item-value="key"
              label="Category"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-row> -->

        <v-row v-if="!isLoadingReports">
          <v-col md="12" sm="12">
            <v-card v-if="errorCodeGetReports === 404">
              <v-alert outlined type="error">Data tidak ditemukan</v-alert>
            </v-card>

            <v-card v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Komunitas</th>
                      <th class="text-left">Total DTK</th>
                      <th class="text-left">Total KHK</th>
                      <th class="text-left">Result Kisi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in reports" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.totalDTK }}</td>
                      <td>{{ item.totalKHK }}</td>
                      <td>{{ item.kisi }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col md="6" sm="12">
            <v-card class="mx-auto" color="primary" dark>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Nilai Maksimum DTK = {{ maxDTK }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-card-text>
                <v-row align="center">
                  <v-col class="display-3" cols="6"> {{ (minVal * maxDTK).toFixed(2) }} </v-col>
                </v-row>
                <v-list-item-subtitle>{{`Batas DTK (${minVal*100}% Nilai Maksimum DTK)`}}</v-list-item-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="6" sm="12">
            <v-card class="mx-auto" color="primary" dark>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Nilai Maksimum KHK = {{ maxKHK }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-card-text>
                <v-row align="center">
                  <v-col class="display-3" cols="6"> {{ (minVal * maxKHK).toFixed(2) }} </v-col>
                </v-row>
                <v-list-item-subtitle>{{`Batas KHK (${minVal*100}% Nilai Maksimum KHK)`}}</v-list-item-subtitle>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col md="12" sm="12" v-if="reports && reports.length>0">
            <v-card>
              <v-card-text>
                <VueApexCharts type="scatter" height="250" :options="chartOptions" :series="series" ref="matrixKisiChart"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col md="12" sm="12">
            <v-card>
              <v-card-text>Loading Data...</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Reports -->
    <Alert :status="isErrorGetReports && errorCodeGetReports !== 404" icon="error" title="ERROR" :msg="errorCauseGetReports" />
  </div>
</template>
<script>
import Alert from "../../../components/alert/index";
import { ReportControllers } from "../../../controllers/ReportControllers.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);

export default {
  name: "report-score",
  props: {
    category: {
      type: String(),
      default:''},
    dso: {
      type: String(),
      default:''},
    brand: {
      type: String(),
      default:''},
    year: {
      type: String(),
      default: new Date().getFullYear().toString
    }
  },

  data: () => ({
    title: "Tabel Matrix KiSi Sebelum Pembobotan",
    report: new ReportControllers(),
    // filter: {
    //   dso: "",
    //   brand: "",
    //   category: "",
    // },
    minVal : 0.7,

    isLoading: false,
    fullPage: true,

    series: [],
    chartOptions: {
      chart: {
        height: 250,
        type: "scatter",
        zoom: {
          enabled: true,
          type: "xy",
        },
      },
      xaxis: {
        tickAmount: 10,
        title: {
          text: "Total KHK",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
        labels: {
          formatter: function(val) {
            return parseFloat(val).toFixed(1);
          },
        },
        min: 0,
        max: 0,
      },
      yaxis: {
        tickAmount: 4,
        title: {
          text: "Total DTK",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
        min: 0,
        max: 0,
      },
      legend: {
        position: "right",
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderWidth: 2,
            borderColor: "#2980b9",
          },
        ],
        xaxis: [
          {
            x: 0,
            strokeDashArray: 0,
            borderWidth: 2,
            borderColor: "#2980b9",
          },
        ],
      },
    },

    // mean: [],
    // meanKHK: 0,
    // meanDTK: 0,
    maxKHK: 0,
    maxDTK: 0,
    isLoadingDownload: false,
    isDownloadClicked: true,
  }),

  components: {
    Alert,
    Loading,
    VueApexCharts,
  },

  computed: {
    // reports
    reports() {
      return this.report.reportKisiScoreList.tableOfKisi;
    },

    scoreList(){
      return this.report.reportKisiScoreList
    },

    errorCodeGetReports() {
      return this.report.errorCode;
    },

    isErrorGetReports() {
      return this.report.isError;
    },

    errorCauseGetReports() {
      return this.report.errorCause;
    },

    isLoadingReports() {
      return this.report.isLoading;
    },
  },

  watch: {
    category(event) {
      this.reportByCategory(event);
    },
    dso(event) {
      this.reportByDso(event);
    },
    brand(event) {
      this.reportByBrand(event);
    },
    year(event) {
      if(parseInt(event)>2022){
        this.minVal = 0.5
      }else{
        this.minVal = 0.7
      }
      this.reportByYear(event);
    },
    scoreList(newVal){
      const newOptions = this.chartOptions;
      if(newVal.tableOfKisi){
        let seriesData = [];
        const arr = newVal.tableOfKisi;
        arr.map((item) => {
          seriesData.push({
            name: item.name,
            data: [[item.totalKHK, item.totalDTK]],
          });
        });

        const maxDTK = newVal.maxDTK? newVal.maxDTK:0;
        const maxKHK = newVal.maxKHK? newVal.maxKHK:0;

        newOptions.annotations.yaxis[0].y = this.minVal * maxDTK;
        newOptions.annotations.xaxis[0].x = this.minVal * maxKHK;
        this.maxKHK = maxKHK;
        this.maxDTK = maxDTK;
        this.series = seriesData;
        newOptions.xaxis.max = maxKHK;
        newOptions.yaxis.max = maxDTK;
      }else{
        newOptions.annotations.yaxis[0].y = 0;
        newOptions.annotations.xaxis[0].x = 0;

        this.maxKHK = 0;
        this.maxDTK = 0;
        this.series = [];
        newOptions.xaxis.max = 0;
        newOptions.yaxis.max = 0;
      }

      Object.assign(this.chartOptions, newOptions)
      this.$refs.matrixKisiChart.refresh()
    }
  },

  methods: {
    async getReports(dso, brand, category, year) {
      return this.report.getReportKisiScoreList(dso, brand, category, year);
    },

    async getSeries() {
      if (this.report.reportKisiScoreList) {
        let seriesData = [];
        const arr = this.report.reportKisiScoreList.tableOfKisi;
        await arr.map((item) => {
          seriesData.push({
            name: item.name,
            data: [[item.totalKHK, item.totalDTK]],
          });
        });

        const maxDTK = await this.report.reportKisiScoreList.maxDTK;
        const maxKHK = await this.report.reportKisiScoreList.maxKHK;

        // });
        this.chartOptions.annotations.yaxis[0].y = 0.7 * maxDTK;
        this.chartOptions.annotations.xaxis[0].x = 0.7 * maxKHK;

        this.maxKHK = maxKHK;
        this.maxDTK = maxDTK;
        this.series = seriesData;
        this.chartOptions.xaxis.max = maxKHK;
        this.chartOptions.yaxis.max = maxDTK;
      } else {
        this.chartOptions.annotations.yaxis[0].y = 0;
        this.chartOptions.annotations.xaxis[0].x = 0;

        this.maxKHK = 0;
        this.maxDTK = 0;
        this.series = [];
        this.chartOptions.xaxis.max = 0;
        this.chartOptions.yaxis.max = 0;
      }
    },

    async reportByDso(event) {
      if (this.dso && this.brand && this.category && this.year) {
        this.isDownloadClicked = false;
        await this.getReports(event, this.brand, this.category, this.year);
      }
    },

    async reportByBrand(event) {
      if (this.dso && this.brand && this.category && this.year) {
        this.isDownloadClicked = false;
        await this.getReports(this.dso, event, this.category, this.year);
      }
    },

    async reportByCategory(event) {
      if (this.dso && this.brand && this.category && this.year) {
        this.isDownloadClicked = false;
        await this.getReports(this.dso, this.brand, event, this.year);
      }
    },

    async reportByYear(event) {
      if (this.dso && this.brand && this.category && this.year) {
        this.isDownloadClicked = false;
        await this.getReports(this.dso, this.brand, this.category, event);
      }
    },

    download() {
      this.isLoadingDownload = true;
      const kisi = [];
      for (const item of this.reports) {
        kisi.push({
          name: item.name,
          "total KHK": item.totalKHK,
          "total DTK": item.totalDTK,
          kisi: item.kisi,
        });
      }

      const data = XLSX.utils.json_to_sheet(kisi);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Report Kisi.xlsx");
      this.isLoadingDownload = false;
    },
  },
};
</script>
