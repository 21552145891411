import axios from "axios";
import { EventReviewList, CreateEventReview, DeleteEventReview, UpdateEventReview, EventReviewDetail } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const EventReviewListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${EventReviewList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateEventReviewRequest = (
    eventId,
    totalParticipant,
    collaborateWithCommunity,
    collaborateWithVanue,
    collaborateWithDRP,
    brandId_smoke,
    sku_smoke,
    total_smoke,
    brandId_nonSmoke,
    product_nonSmoke,
    total_nonSmoke,
    images,
    group,
    brandSupport,
    brandSupportDescription,
    dsoParticipation
    // images_smoke,
    // images_nonSmoke
) => {
  console.log({eventId,
    totalParticipant,
    collaborateWithCommunity,
    collaborateWithVanue,
    collaborateWithDRP,
    brandId_smoke,
    sku_smoke,
    total_smoke,
    brandId_nonSmoke,
    product_nonSmoke,
    total_nonSmoke,
    images,
    group,
    brandSupport,
    brandSupportDescription,
    dsoParticipation})
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateEventReview()}`,
        data: {
            eventId: eventId,
            totalParticipant: totalParticipant,
            collaborateWithCommunity: collaborateWithCommunity,
            collaborateWithVanue: collaborateWithVanue,
            collaborateWithDRP: collaborateWithDRP,
            brandId_smoke: brandId_smoke,
            sku_smoke: sku_smoke,
            total_smoke: total_smoke,
            brandId_nonSmoke: brandId_nonSmoke,
            product_nonSmoke: product_nonSmoke,
            total_nonSmoke: total_nonSmoke,
            images: images,
            group: group,
            brand_support: brandSupport,
            brand_support_description: brandSupportDescription,
            dso_participate: dsoParticipation
            // images_smoke: images_smoke? [images_smoke]: [],
            // images_nonSmoke: images_nonSmoke? [images_nonSmoke]: []
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteEventReviewRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteEventReview(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateEventReviewRequest = (
    id,
    eventId,
    totalParticipant,
    collaborateWithCommunity,
    collaborateWithVanue,
    collaborateWithDRP,
    brandId_smoke,
    sku_smoke,
    total_smoke,
    brandId_nonSmoke,
    product_nonSmoke,
    total_nonSmoke,
    images,
    group,
    brandSupport,
    brandSupportDescription,
    dsoParticipation
    // images_smoke,
    // images_nonSmoke
) => {
  console.log({id,
    eventId,
    totalParticipant,
    collaborateWithCommunity,
    collaborateWithVanue,
    collaborateWithDRP,
    brandId_smoke,
    sku_smoke,
    total_smoke,
    brandId_nonSmoke,
    product_nonSmoke,
    total_nonSmoke,
    images,
    group,
    brandSupport,
    brandSupportDescription,
    dsoParticipation})
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateEventReview(id)}`,
        data: {
            eventId: eventId,
            totalParticipant: totalParticipant,
            collaborateWithCommunity: collaborateWithCommunity,
            collaborateWithVanue: collaborateWithVanue,
            collaborateWithDRP: collaborateWithDRP,
            brandId_smoke: brandId_smoke,
            sku_smoke: sku_smoke,
            total_smoke: total_smoke,
            brandId_nonSmoke: brandId_nonSmoke,
            product_nonSmoke: product_nonSmoke,
            total_nonSmoke: total_nonSmoke,
            images: images,
            group: group,
            brand_support: brandSupport,
            brand_support_description: brandSupportDescription,
            dso_participate: dsoParticipation
            // images_smoke: images_smoke? [images_smoke]: [],
            // images_nonSmoke: images_nonSmoke? [images_nonSmoke]: []
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const EventReviewDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${EventReviewDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}




