<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="7" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="5" sm="12">
        <v-btn class="float-right" depressed color="primary"  @click="createSales" v-if="getRole.role.create">
          Buat Penjualan 
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-toolbar color="primary" dark flat>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab href="#tab-1" @click="setTab('1')"> Upcoming </v-tab>
            <v-tab href="#tab-2" @click="setTab('2')"> Ongoing </v-tab>
            <v-tab href="#tab-3" @click="setTab('3')"> Finished </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <Upcoming :tab="tab" />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <Ongoing :tab="tab" />
          </v-tab-item>
          <v-tab-item value="tab-3"> 
            <Finished :tab="tab" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Upcoming from "./tables/upcoming";
import Ongoing from "./tables/ongoing";
import Finished from "./tables/finished";
export default {
  name: "sales-list",
  data: vm => ({
    title: "Penjualan",
    tabPrefix: `tab-${vm.$route.params.tab}`
  }),

  components: {
    Upcoming,
    Ongoing,
    Finished
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    tab: {
      get: function() {
        return this.tabPrefix;
      },
      set: function(val) {
        this.tabPrefix = val;
      }
    }
  },

  methods: {
    createSales() {
      this.$router.push({
        name: "Create Sales",
      });
    },

    setTab(tab) {
      this.tab = `tab-${tab}`;
    }
  }
};
</script>