<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
    </v-row>
    <Cards />
    <v-row>
      <v-col md="12" sm="12">
        <RightReport :isLoadingDSOS="isLoadingDSOS" :dsos="areaDSOList" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Cards from "./components/cards.vue";
import RightReport from "./components/right-report.vue";
import { mapGetters, mapActions } from "vuex";
import { AreaManagerControllers } from "../../../controllers/AreaManagerControllers.js";

export default {
  name: "member-index",
  data: () => ({
    area: new AreaManagerControllers(),
    title: "Laporan Member",
  }),

  methods: {
    ...mapActions(["getAreaDSO"]),
  },

  created() {
    this.getAreaDSO();
  },

  computed: {
    ...mapGetters(["areaDSOList"]),
    isLoadingDSOS() {
      return this.area.isLoading;
    },
    isErrorDSOS() {
      return this.area.isError;
    },
    errorCauseDSOS() {
      return this.area.errorCause;
    },
    errorCodeDSOS() {
      return this.area.errorCode;
    },
  },

  components: {
    Cards,
    RightReport,
  },
};
</script>
<style scoped>
.vertical-line {
  border-left: 1px solid #95a5a6;
}
</style>