<template>
  <v-row>
    <v-col md="10" sm="12">
      <h4>{{ title }}</h4>
    </v-col>
    <v-col md="2" sm="12">
      <v-btn
        class="success" 
        :loading="isLoadingDownload"
        small
        block
        @click="download"
        >Unduh Excel</v-btn
      >
    </v-col>
    <v-col md="4" sm="6">
      <v-select
        v-model="year"
        :items="years"
        @change="pickYear"
        label="Ganti Tahun"
        outlined
        dense
      ></v-select>
    </v-col>
    <v-col md="12" sm="12" v-if="!isLoadingDashboard">
      <p v-if="users.length === 0">Data Tidak Tersedia</p>
      <VueApexCharts
        v-if="chartOptions.labels.length > 0"
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </v-col>
    <v-col md="12" sm="12" v-else>
      <p>Loading...</p>
    </v-col>

    <!-- Error Dashboard -->
    <Alert
      :status="isErrorGetDashboard && errorCodeGetDashboard !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDashboard"
    />
  </v-row>
</template>
<script>
import Alert from "../../../components/alert/index";
import { DashboardControllers } from "../../../controllers/DashboardControllers.js";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);

export default {
  name: "user-growth",
  data: () => ({
    dashboard: new DashboardControllers(),
    title: "Pertumbuhan User",
    year: 2021,
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [],

      yaxis: [
        {
          decimalsInFloat: 0,
          title: {
            text: "Jumlah",
          },
        },
        {
          opposite: true,
          title: {
            text: "Persentase",
          },
        },
      ],
      xaxis: {}
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
    Alert,
  },

  computed: {
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },
    // reports
    users() {
      return this.dashboard.userDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    this.getUserDashboard();
    this.getSeries();
  },

  methods: {
    getUserDashboard() {
      return this.dashboard.getUserDashboardList(this.year);
    },

    async getSeries() {
      await this.getUserDashboard(this.year);

      // series
      const countData = [];
      this.dashboard.userDashboardList.map((item) => {
        countData.push(item.count);
      });

      const percentageData = [];
      this.dashboard.userDashboardList.map((item) => {
        percentageData.push(item.countPercentage);
      });

      this.series = [
        {
          name: "Jumlah",
          type: "column",
          data: countData,
        },
        {
          name: "Persentase",
          type: "line",
          data: percentageData,
        },
      ];

      // labels
      this.dashboard.userDashboardList.map((item) => {
        this.chartOptions.labels.push(item.role);
      });
    },

    async pickYear(event) {
      this.series = [];
      this.chartOptions.labels = [];
      await this.getUserDashboard(event);
      // series
      const countData = [];
      this.dashboard.userDashboardList.map((item) => {
        countData.push(item.count);
      });

      const percentageData = [];
      this.dashboard.userDashboardList.map((item) => {
        percentageData.push(item.countPercentage);
      });

      this.series = [
        {
          name: "Count",
          type: "column",
          data: countData,
        },
        {
          name: "Percentage",
          type: "line",
          data: percentageData,
        },
      ];

      // lables
      this.dashboard.userDashboardList.map((item) => {
        this.chartOptions.labels.push(item.role);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const users = []
      for(const item of this.users) {
          users.push({
              "user": item.role,
              "jumlah": item.count,
              "jumlah dalam persen": item.countPercentage
          });
      }
      const data = XLSX.utils.json_to_sheet(users);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Pertumbunhan User Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>