import axios from "axios";
import { SubProjects, CreateSubProject, UpdateSubProject } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};

export const SubProjectListRequest = (page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${SubProjects(page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateSubProjectRequest = (
    project, name, description
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateSubProject()}`,
        data: {
            projectId: project,
            name: name,
            description: description
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateSubProjectRequest = (
    id,
    project, name, description
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateSubProject(id)}`,
        data: {
            projectId: project,
            name: name,
            description: description
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}




