<template>
  <v-row>
    <v-col md="2" sm="12" class="mt-3 mb-2">
      <h5 class="filter-title">Filter DSO</h5>
      <div class="filter">
        <input type="checkbox" @click="checkAll" v-model="isCheckAll" />
        Pilih Semua
        <ul class="filter-dso">
          <li v-for="area in dsos" :key="area.id">
            <input
              type="checkbox"
              v-bind:value="area.id"
              v-model="areaSelected"
              @change="updateCheckall"
            />
            {{ titleCase(area.name) }}
          </li>
        </ul>
      </div>
      <v-btn
        class="primary mt-2"
        :disabled="areaSelected.length <= 0"
        @click="filter"
        block
        small
        >Filter</v-btn
      >
    </v-col>
    <v-col md="10" sm="12" class="mt-4">
      <VueApexCharts
        v-if="series.length > 0"
        type="donut"
        :options="chartOptions"
        :series="series"
        height="300"
      />
      <p v-else>Data Tidak Ditemukan</p>
    </v-col>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-row>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { DashboardV2Controller } from "../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { titleCase } from "../../../../external/functions/Utilization.js"

export default {
  name: "top-achievement-index",
  props: {
    dsos: Array,
  },
  data: () => ({
    dashboard: new DashboardV2Controller(),
    isCheckAll: false,
    areaSelected: [],

    // charts
    series: [0],
    chartOptions: {
      chart: {
        width: 380,
        type: "pie",
      },
      title: {
        text: "Total Pencapaian Atas Sales",
        align: "center",
      },
      colors: ["#000000", "#e2a63d", "#e23d3d", "#d99494", "#34bf41"],
      labels: ['LA Bold','Djarum 76','Djarum Super','LA Lights','Others'],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                showAlways: true,
                label: "Total",
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    },

    // loading
    isLoading: false,
    fullPage: true,
    colorBrand:[
      {
        brand : 'LA Bold',
        color : "#000000"
      },
      {
        brand : 'Djarum 76',
        color : "#e2a63d"
      },
      {
        brand : 'Djarum Super',
        color : "#e23d3d"
      },
      {
        brand : 'LA Lights',
        color : "#d99494"
      },
      {
        brand : 'Others',
        color : "#34bf41"
    } 
    ],
  }),
  computed: {
    isError() {
      return this.dashboard.errorMemberAchievmentByDSO;
    },
    errorCause() {
      return this.dashboard.errorCauseMemberAchievmentByDSO;
    },
  },

  created(){
    this.checkAll();
    this.filter();
  },

  methods: {
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.areaSelected = [];
      if (this.isCheckAll) {
        // Check all
        for (var key of this.dsos) {
          this.areaSelected.push(key.id);
        }
      }
    },
    updateCheckall() {
      if (this.areaSelected.length == this.dsos.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    async filter() {
      this.isLoading = true;
      this.series = [];
      const dsos = this.areaSelected
        .map((item, index) => (index === 0 ? `${item}` : `&dso=${item}`))
        .join("");
      await this.dashboard.GetSalesAchievmentByDSO(dsos);  
      let dataCommunity = [];
      this.dashboard.salesAchievmentByDSO.reduce(function(res, value) {
        if (!res[value.brand]) {
          res[value.brand] = { id: value.brand, count: 0 };
          dataCommunity.push(res[value.brand])
        }
        res[value.brand].count += value.count;
        return res;
      }, {});
      this.series = this.chartOptions.labels.map((item) => {
        const dataItem = dataCommunity.find(obj=>obj.id == item);
        if(dataItem != null){
          return dataItem.count;
        }
        return 0;
      });
      this.isLoading = false;
    },
    

    titleCase(str) {
      return titleCase(str)
    }
  },

  components: {
    VueApexCharts,
    Alert,
    Loading,
  },
};
</script>
<style scoped>
/* Box styles */
.filter {
  border: none;
  padding: 5px;
  width: 100%;
  height: 285px;
  overflow-y: scroll;
  font-size: 11px;
}

.filter-dso {
  display: inline;
}

.filter-dso li {
  padding-top: 5px;
}

.bar-chart {
  border: none;
  padding: 5px;
  width: 100%;
  overflow-x: scroll;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border: 1px solid #bdc3c7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bdc3c7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bdc3c7;
}
</style>

