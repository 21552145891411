import { LoginRequest, LogoutRequest, ChangePasswordRequest } from "../request/AuthRequest.js";
import { setAuthentication } from "../external/functions/Cookies.js";

export class AuthControllers {
    loginDetail = {};
    isError = false;
    errorCause = "";
    isLoading = false;

    constructor() { }

    goLogin(username, password) {
        this.setLoading(true);
        const resp = LoginRequest(username, password)
            .then((response) => {
                this.setError(false);
                this.setLoginDetail(response);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    goLogout(userId) {
        this.setLoading(true);
        const resp = LogoutRequest(userId)
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoginDetail(user) {
        this.loginDetail = user;
        setAuthentication(
            user.data.data.token.value, 
            user.data.data.token.expiresIn, 
            user.data.data.role, 
            user.data.data.id,
            user.data.data.name,
            user.data.data.email
        );
    }

    changePassword(passwordOld, passwordNew, passwordNew2) {
        this.setLoading(true);
        const resp = ChangePasswordRequest(passwordOld, passwordNew, passwordNew2)
            .then((response) => {
                console.log(response)
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }
}

