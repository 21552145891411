import axios from "axios";
import { InfoCommunityList, VerifyInfoCommunity } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const InfoCommunityRequest = (type, page, limit, keyword, dsoId, startDate, endDate, isReport) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${InfoCommunityList(type, page, limit, keyword, dsoId, startDate, endDate, isReport)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const VerifyInfoCommunityRequest = (
    id
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${VerifyInfoCommunity(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

