import { 
    GetDashboarTotalCommunityRequest, 
    GetCommunityByAreaRequest,
    GetBrandByDSORequest,
    GetBrandByStatusDSORequest,
    GetBrandByAgeDSORequest,
    GetBrandByCollabDSORequest,
    GetDashboardCardMemberRequest,
    GetMemberActiveByAreaRequest,
    GetMemberActiveByDSORequest,
    GetMemberActiveByAgeRequest,
    GetMemberActiveStatsByDSORequest,
    GetMemberInsightByDSORequest,
    GetMemberAchievmentByDSORequest,
    GetSalesAchievmentByDSORequest,
    GeteventStatByDSORequest,
    GetEventSharingByDSORequest,
    GetAtributeByDSORequest,
    GetPeriodSalesRequest,
    GetPostByDSORequest
} from "../request/DashboardV2Request.js";

export class DashboardV2Controller  {
    // total communuty
    totalComunity = new Array();
    isErrorTotalCommunity = false;
    isLoadingTotalCommunity = false;
    errorCauseTotalCommunity = "";
    errorCodeTotalCommunity = 0;

    constructor() {}

    // total community
    GetDashboarTotalCommunity() {
        this.setLoadingTotalCommunity(true);
        const resp = GetDashboarTotalCommunityRequest()
        .then((response) => {
            this.setErrorTotalCommunity(false);
            this.setErrorCodeTotalCommunity(response.data.code);
            this.setDashboarTotalCommunity(response.data.data);
        }).catch(err => {
            this.setErrorTotalCommunity(true);
            this.setErrorCauseTotalCommunity(err.response.data.message);
            this.setErrorCodeTotalCommunity(err.response.data.code);
        }).finally(() => {
            this.setLoadingTotalCommunity(false);
        });
       
        return resp;
    }

    setDashboarTotalCommunity(data) {
        this.totalComunity = data;
    }

    setLoadingTotalCommunity(status) {
        this.isLoadingTotalCommunity = status;
    }

    setErrorTotalCommunity(status) {
        this.isErrorTotalCommunity = status;
    }

    setErrorCauseTotalCommunity(err) {
        this.errorCauseTotalCommunity = err;
    }

    setErrorCodeTotalCommunity(code) {
        this.errorCodeTotalCommunity = code;
    }

    // community by area
    communityByArea = new Array();
    loadingCommunityByArea = false;
    errorCommunityByArea = false;
    errorCodeCommunityByArea = "";
    errorCauseCommunityByArea = "";

    GetCommunityByArea(area) {
        this.setLoadingCommunityByArea(true);
        const resp = GetCommunityByAreaRequest(area)
        .then((response) => {
            this.setErrorCommunityByArea(false);
            this.setErrorCodeCommunityByArea(response.data.code);
            this.setDashboarCommunityByArea(response.data.data);
        }).catch(err => {
            this.setErrorCommunityByArea(true);
            this.setErrorCauseCommunityByArea(err.response.data.message);
            this.setErrorCodeCommunityByArea(err.response.data.code);
        }).finally(() => {
            this.setLoadingCommunityByArea(false);
        });
       
        return resp;
    }

    setLoadingCommunityByArea(status) {
        this.loadingCommunityByArea = status;
    }

    setDashboarCommunityByArea(data) {
        this.communityByArea = data;
    }

    setErrorCommunityByArea(status) {
        this.errorCommunityByArea = status;
    }

    setErrorCodeCommunityByArea(code) {
        this.errorCodeCommunityByArea = code;
    }

    setErrorCauseCommunityByArea(cause) {
        this.errorCauseCommunityByArea = cause;
    }

    // brand by dso
    brandByDSO = new Array();
    loadingBrandByDSO = false;
    errorBrandByDSO = false;
    errorCodeBrandByDSO = "";
    errorCauseBrandByDSO = "";

    GetBrandByDSO(dso) {
        this.setLoadingBrandByDSO(true);
        const resp = GetBrandByDSORequest(dso)
        .then((response) => {
            this.setErrorBrandByDSO(false);
            this.setErrorCodeBrandByDSO(response.data.code);
            this.setDashboardBrandByDSO(response.data.data);
        }).catch(err => {
            this.setErrorBrandByDSO(true);
            this.setErrorCauseBrandByDSO(err.response.data.message);
            this.setErrorCodeBrandByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingBrandByDSO(false);
        });
       
        return resp;
    }

    setLoadingBrandByDSO(status) {
        this.loadingBrandByDSO = status;
    }

    setDashboardBrandByDSO(data) {
        this.brandByDSO = data;
    }

    setErrorBrandByDSO(status) {
        this.errorBrandByDSO = status;
    }

    setErrorCodeBrandByDSO(code) {
        this.errorCodeBrandByDSO = code;
    }

    setErrorCauseBrandByDSO(cause) {
        this.errorCauseBrandByDSO = cause;
    }

    // brand by status dso
    brandByStatusDSO = new Array();
    loadingBrandByStatusDSO = false;
    errorBrandByStatusDSO = false;
    errorCodeBrandByStatusDSO = "";
    errorCauseBrandByStatusDSO = "";

    GetBrandByStatusDSO(dso) {
        this.setLoadingBrandByStatusDSO(true);
        const resp = GetBrandByStatusDSORequest(dso)
        .then((response) => {
            this.setErrorBrandByStatusDSO(false);
            this.setErrorCodeBrandByStatusDSO(response.data.code);
            this.setDashboardBrandByStatusDSO(response.data.data);
        }).catch(err => {
            this.setErrorBrandByStatusDSO(true);
            this.setErrorCauseBrandByStatusDSO(err.response.data.message);
            this.setErrorCodeBrandByStatusDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingBrandByStatusDSO(false);
        });
       
        return resp;
    }

    setLoadingBrandByStatusDSO(status) {
        this.loadingBrandByStatusDSO = status;
    }

    setDashboardBrandByStatusDSO(data) {
        this.brandByStatusDSO = data;
    }

    setErrorBrandByStatusDSO(status) {
        this.errorBrandByStatusDSO = status;
    }

    setErrorCodeBrandByStatusDSO(code) {
        this.errorCodeBrandByStatusDSO = code;
    }

    setErrorCauseBrandByStatusDSO(cause) {
        this.errorCauseBrandByStatusDSO = cause;
    }

    // brand by age dso
    brandByAgeDSO = new Array();
    loadingBrandByAgeDSO = false;
    errorBrandByAgeDSO = false;
    errorCodeBrandByAgeDSO = "";
    errorCauseBrandByAgeDSO = "";

    GetBrandByAgeDSO(dso) {
        this.setLoadingBrandByAgeDSO(true);
        const resp = GetBrandByAgeDSORequest(dso)
        .then((response) => {
            this.setErrorBrandByAgeDSO(false);
            this.setErrorCodeBrandByAgeDSO(response.data.code);
            this.setDashboardBrandByAgeDSO(response.data.data);
        }).catch(err => {
            this.setErrorBrandByAgeDSO(true);
            this.setErrorCauseBrandByAgeDSO(err.response.data.message);
            this.setErrorCodeBrandByAgeDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingBrandByAgeDSO(false);
        });
    
        return resp;
    }

    setLoadingBrandByAgeDSO(status) {
        this.loadingBrandByAgeDSO = status;
    }

    setDashboardBrandByAgeDSO(data) {
        this.brandByAgeDSO = data;
    }

    setErrorBrandByAgeDSO(status) {
        this.errorBrandByAgeDSO = status;
    }

    setErrorCodeBrandByAgeDSO(code) {
        this.errorCodeBrandByAgeDSO = code;
    }

    setErrorCauseBrandByAgeDSO(cause) {
        this.errorCauseBrandByAgeDSO = cause;
    }

    // brand by collab dso
    brandByCollabDSO = new Array();
    loadingBrandByCollabDSO = false;
    errorBrandByCollabDSO = false;
    errorCodeBrandByCollabDSO = "";
    errorCauseBrandByCollabDSO = "";

    GetBrandByCollabDSO(dso) {
        this.setLoadingBrandByCollabDSO(true);
        const resp = GetBrandByCollabDSORequest(dso)
        .then((response) => {
            this.setErrorBrandByCollabDSO(false);
            this.setErrorCodeBrandByCollabDSO(response.data.code);
            this.setDashboardBrandByCollabDSO(response.data.data);
        }).catch(err => {
            this.setErrorBrandByCollabDSO(true);
            this.setErrorCauseBrandByCollabDSO(err.response.data.message);
            this.setErrorCodeBrandByCollabDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingBrandByCollabDSO(false);
        });

        return resp;
    }

    setLoadingBrandByCollabDSO(status) {
        this.loadingBrandByCollabDSO = status;
    }

    setDashboardBrandByCollabDSO(data) {
        this.brandByCollabDSO = data;
    }

    setErrorBrandByCollabDSO(data) {
        this.errorBrandByCollabDSO = data;
    }

    setErrorCodeBrandByCollabDSO(code) {
        this.errorCodeBrandByCollabDSO = code;
    }

    setErrorCauseBrandByCollabDSO(cause) {
        this.errorCauseBrandByCollabDSO = cause;
    }

    // cards member
    cardsMemberData = new Array();
    loadingMemberData = false;
    errorMemberData = false;
    errorCodeMemberData = "";
    errorCauseMemberData = "";

    GetDashboardCardMember() {
        this.setLoadingCardMember(true);
        const resp = GetDashboardCardMemberRequest()
        .then((response) => {
            this.setErrorCardMember(false);
            this.setErrorCodeCardMember(response.data.code);
            this.setDashboardCardMember(response.data.data);
        }).catch(err => {
            this.setErrorCardMember(true);
            this.setErrorCauseCardMember(err.response.data.message);
            this.setErrorCodeCardMember(err.response.data.code);
        }).finally(() => {
            this.setLoadingCardMember(false);
        });

        return resp;
    }

    setLoadingCardMember(status) {
        this.loadingMemberData = status;
    }

    setDashboardCardMember(data) {
        this.cardsMemberData = data;
    }

    setErrorCardMember(data) {
        this.errorMemberData = data;
    }

    setErrorCodeCardMember(code) {
        this.errorCodeMemberData = code;
    }

    setErrorCauseCardMember(cause) {
        this.errorCauseMemberData = cause;
    }

    // member active by area
    memberActiveByArea = new Array();
    loadingMemberActiveByArea = false;
    errorMemberActiveByArea = false;
    errorCodeMemberActiveByArea = "";
    errorCauseMemberActiveByArea = "";

    GetMemberActiveByArea(area) {
        this.setLoadingMemberActiveByArea(true);
        const resp = GetMemberActiveByAreaRequest(area)
        .then((response) => {
            this.setErrorMemberActiveByArea(false);
            this.setErrorCodeMemberActiveByArea(response.data.code);
            this.setDashboardMemberActiveByArea(response.data.data);
        }).catch(err => {
            this.setErrorMemberActiveByArea(true);
            this.setErrorCauseMemberActiveByArea(err.response.data.message);
            this.setErrorCodeMemberActiveByArea(err.response.data.code);
        }).finally(() => {
            this.setLoadingMemberActiveByArea(false);
        });

        return resp;
    }

    setLoadingMemberActiveByArea(status) {
        this.loadingMemberActiveByArea = status;
    }

    setDashboardMemberActiveByArea(data) {
        this.memberActiveByArea = data;
    }

    setErrorMemberActiveByArea(data) {
        this.errorMemberActiveByArea = data;
    }

    setErrorCodeMemberActiveByArea(code) {
        this.errorCodeMemberActiveByArea = code;
    }

    setErrorCauseMemberActiveByArea(cause) {
        this.errorCauseMemberActiveByArea = cause;
    }

    // member active by dso
    memberActiveByDSO = new Array();
    loadingMemberActiveByDSO = false;
    errorMemberActiveByDSO = false;
    errorCodeMemberActiveByDSO = "";
    errorCauseMemberActiveByDSO = "";

    GetMemberActiveByDSO(dso) {
        this.setLoadingMemberActiveByDSO(true);
        const resp = GetMemberActiveByDSORequest(dso)
        .then((response) => {
            this.setErrorMemberActiveByDSO(false);
            this.setErrorCodeMemberActiveByDSO(response.data.code);
            this.setDashboardMemberActiveByDSO(response.data.data);
        }).catch(err => {
            this.setErrorMemberActiveByDSO(true);
            this.setErrorCauseMemberActiveByDSO(err.response.data.message);
            this.setErrorCodeMemberActiveByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingMemberActiveByDSO(false);
        });

        return resp;
    }

    setLoadingMemberActiveByDSO(status) {
        this.loadingMemberActiveByDSO = status;
    }

    setDashboardMemberActiveByDSO(data) {
        this.memberActiveByDSO = data;
    }

    setErrorMemberActiveByDSO(data) {
        this.errorMemberActiveByDSO = data;
    }

    setErrorCodeMemberActiveByDSO(code) {
        this.errorCodeMemberActiveByDSO = code;
    }

    setErrorCauseMemberActiveByDSO(cause) {
        this.errorCauseMemberActiveByDSO = cause;
    }

    // member active by age
    memberActiveByAge = new Array();
    loadingMemberActiveByAge = false;
    errorMemberActiveByAge = false;
    errorCodeMemberActiveByAge = "";
    errorCauseMemberActiveByAge = "";

    GetMemberActiveByAge(dso) {
        this.setLoadingMemberActiveByAge(true);
        const resp = GetMemberActiveByAgeRequest(dso)
        .then((response) => {
            this.setErrorMemberActiveByAge(false);
            this.setErrorCodeMemberActiveByAge(response.data.code);
            this.setDashboardMemberActiveByAge(response.data.data);
        }).catch(err => {
            this.setErrorMemberActiveByAge(true);
            this.setErrorCauseMemberActiveByAge(err.response.data.message);
            this.setErrorCodeMemberActiveByAge(err.response.data.code);
        }).finally(() => {
            this.setLoadingMemberActiveByAge(false);
        });

        return resp;
    }

    setLoadingMemberActiveByAge(status) {
        this.loadingMemberActiveByAge = status;
    }

    setDashboardMemberActiveByAge(data) {
        this.memberActiveByAge = data;
    }

    setErrorMemberActiveByAge(data) {
        this.errorMemberActiveByAge = data;
    }

    setErrorCodeMemberActiveByAge(code) {
        this.errorCodeMemberActiveByAge = code;
    }

    setErrorCauseMemberActiveByAge(cause) {
        this.errorCauseMemberActiveByAge = cause;
    }

    // member active stat by dso
    memberActiveStatByDSO = new Array();
    loadingMemberStatByDSO = false;
    errorMemberStatByDSO = false;
    errorCodeMemberStatByDSO = "";
    errorCauseMemberStatByDSO = "";

    GetMemberActiveStatsByDSO(dso) {
        this.setLoadingMemberStatByDSO(true);
        const resp = GetMemberActiveStatsByDSORequest(dso)
        .then((response) => {
            this.setErrorMemberStatByDSO(false);
            this.setErrorCodeMemberStatByDSO(response.data.code);
            this.setDashboardMemberStatByDSO(response.data.data);
        }).catch(err => {
            this.setErrorMemberStatByDSO(true);
            this.setErrorCauseMemberStatByDSO(err.response.data.message);
            this.setErrorCodeMemberStatByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingMemberStatByDSO(false);
        });

        return resp;
    }

    setLoadingMemberStatByDSO(status) {
        this.loadingMemberStatByDSO = status;
    }

    setDashboardMemberStatByDSO(data) {
        this.memberActiveStatByDSO = data;
    }

    setErrorMemberStatByDSO(data) {
        this.errorMemberStatByDSO = data;
    }

    setErrorCodeMemberStatByDSO(code) {
        this.errorCodeMemberStatByDSO = code;
    }

    setErrorCauseMemberStatByDSO(cause) {
        this.errorCauseMemberStatByDSO = cause;
    }

    // member insight stat by dso
    memberInsightByDSO = new Array();
    loadingMemberInsightByDSO = false;
    errorMemberInsightByDSO = false;
    errorCodeMemberInsightByDSO = "";
    errorCauseMemberInsightByDSO = "";

    GetMemberInsightByDSO(dso) {
        this.setLoadingMemberInsightByDSO(true);
        const resp = GetMemberInsightByDSORequest(dso)
        .then((response) => {
            this.setErrorMemberInsightByDSO(false);
            this.setErrorCodeMemberInsightByDSO(response.data.code);
            this.setDashboardMemberInsightByDSO(response.data.data);
        }).catch(err => {
            this.setErrorMemberInsightByDSO(true);
            this.setErrorCauseMemberInsightByDSO(err.response.data.message);
            this.setErrorCodeMemberInsightByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingMemberInsightByDSO(false);
        });

        return resp;
    }

    setLoadingMemberInsightByDSO(status) {
        this.loadingMemberInsightByDSO = status;
    }

    setDashboardMemberInsightByDSO(data) {
        this.memberInsightByDSO = data;
    }

    setErrorMemberInsightByDSO(err) {
        this.errorMemberInsightByDSO = err;
    }

    setErrorCodeMemberInsightByDSO(code) {
        this.errorCodeMemberInsightByDSO = code;
    }

    setErrorCauseMemberInsightByDSO(cause) {
        this.errorCauseMemberInsightByDSO = cause;
    }

    // member achievement stat by dso
    memberAchievmentByDSO = new Array();
    loadingMemberAchievmentByDSO = false;
    errorMemberAchievmentByDSO = false;
    errorCodeMemberAchievmentByDSO = "";
    errorCauseMemberAchievmentByDSO = "";

    GetMemberAchievmentByDSO(dso) {
        this.setLoadingMemberAchievmentByDSO(true);
        const resp = GetMemberAchievmentByDSORequest(dso)
        .then((response) => {
            this.setErrorMemberAchievmentByDSO(false);
            this.setErrorCodeMemberAchievmentByDSO(response.data.code);
            this.setDashboardMemberAchievmentByDSO(response.data.data);
        }).catch(err => {
            this.setErrorMemberAchievmentByDSO(true);
            this.setErrorCauseMemberAchievmentByDSO(err.response.data.message);
            this.setErrorCodeMemberAchievmentByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingMemberAchievmentByDSO(false);
        });

        return resp;
    }

    setLoadingMemberAchievmentByDSO(status) {
        this.loadingMemberAchievmentByDSO = status;
    }

    setDashboardMemberAchievmentByDSO(data) {
        this.memberAchievmentByDSO = data;
    }

    setErrorMemberAchievmentByDSO(err) {
        this.errorMemberAchievmentByDSO = err;
    }

    setErrorCodeMemberAchievmentByDSO(code) {
        this.errorCodeMemberAchievmentByDSO = code;
    }

    setErrorCauseMemberAchievmentByDSO(cause) {
        this.errorCauseMemberAchievmentByDSO = cause;
    }

    // sales achievement stat by dso
    salesAchievmentByDSO = new Array();
    loadingSalesAchievmentByDSO = false;
    errorSalesAchievmentByDSO = false;
    errorCodeSalesAchievmentByDSO = "";
    errorCauseSalesAchievmentByDSO = "";

    GetSalesAchievmentByDSO(dso) {
        this.setLoadingSalesAchievmentByDSO(true);
        const resp = GetSalesAchievmentByDSORequest(dso)
        .then((response) => {
            this.setErrorSalesAchievmentByDSO(false);
            this.setErrorCodeSalesAchievmentByDSO(response.data.code);
            this.setDashboardSalesAchievmentByDSO(response.data.data);
        }).catch(err => {
            this.setErrorSalesAchievmentByDSO(true);
            this.setErrorCauseSalesAchievmentByDSO(err.response.data.message);
            this.setErrorCodeSalesAchievmentByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingSalesAchievmentByDSO(false);
        });

        return resp;
    }

    setLoadingSalesAchievmentByDSO(status) {
        this.loadingSalesAchievmentByDSO = status;
    }

    setDashboardSalesAchievmentByDSO(data) {
        this.salesAchievmentByDSO = data;
    }

    setErrorSalesAchievmentByDSO(err) {
        this.errorSalesAchievmentByDSO = err;
    }

    setErrorCodeSalesAchievmentByDSO(code) {
        this.errorCodeSalesAchievmentByDSO = code;
    }

    setErrorCauseSalesAchievmentByDSO(cause) {
        this.errorCauseSalesAchievmentByDSO = cause;
    }

    // event stat by dso
    eventStatByDSO = new Array();
    loadingEventStatByDSO = false;
    errorEventStatByDSO = false;
    errorCodeEventStatByDSO = "";
    errorCauseEventStatByDSO = "";

    GeteventStatByDSO(dso) {
        this.setLoadingEventStatByDSO(true);
        const resp = GeteventStatByDSORequest(dso)
        .then((response) => {
            this.setErrorEventStatByDSO(false);
            this.setErrorCodeEventStatByDSO(response.data.code);
            this.setDashboardEventStatByDSO(response.data.data);
        }).catch(err => {
            this.setErrorEventStatByDSO(true);
            this.setErrorCauseEventStatByDSO(err.response.data.message);
            this.setErrorCodeEventStatByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingEventStatByDSO(false);
        });

        return resp;
    }

    setLoadingEventStatByDSO(status) {
        this.loadingEventStatByDSO = status;
    }

    setDashboardEventStatByDSO(data) {
        this.eventStatByDSO = data;
    }

    setErrorEventStatByDSO(err) {
        this.errorEventStatByDSO = err;
    }

    setErrorCodeEventStatByDSO(code) {
        this.errorCodeEventStatByDSO = code;
    }

    setErrorCauseEventStatByDSO(cause) {
        this.errorCauseEventStatByDSO = cause;
    }

    // event sharing by dso
    eventSharingByDSO = new Array();
    loadingEventSharingByDSO = false;
    errorEventSharingByDSO = false;
    errorCodeEventSharingByDSO = "";
    errorCauseEventSharingByDSO = "";

    GetEventSharingByDSO(dso) {
        this.setLoadingEventSharingByDSO(true);
        const resp = GetEventSharingByDSORequest(dso)
        .then((response) => {
            this.setErrorEventSharingByDSO(false);
            this.setErrorCodeEventSharingByDSO(response.data.code);
            this.setDashboardEventSharingByDSO(response.data.data);
        }).catch(err => {
            this.setErrorEventSharingByDSO(true);
            this.setErrorCauseEventSharingByDSO(err.response.data.message);
            this.setErrorCodeEventSharingByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingEventSharingByDSO(false);
        });

        return resp;
    }

    setLoadingEventSharingByDSO(status) {
        this.loadingEventSharingByDSO = status;
    }

    setDashboardEventSharingByDSO(data) {
        this.eventSharingByDSO = data;
    }

    setErrorEventSharingByDSO(err) {
        this.errorEventSharingByDSO = err;
    }

    setErrorCodeEventSharingByDSO(code) {
        this.errorCodeEventSharingByDSO = code;
    }

    setErrorCauseEventSharingByDSO(cause) {
        this.errorCauseEventSharingByDSO = cause;
    }

    // attribute by dso
    attributeByDSO = new Array();
    loadingAttributeByDSO = false;
    errorAttributeByDSO = false;
    errorCodeAttributeByDSO = "";
    errorCauseAttributeByDSO = "";

    GetAtributeByDSO(dso) {
        this.setLoadingAttributeByDSO(true);
        const resp = GetAtributeByDSORequest(dso)
        .then((response) => {
            this.setErrorAttributeByDSO(false);
            this.setErrorCodeAttributeByDSO(response.data.code);
            this.setDashboardAttributeByDSO(response.data.data);
        }).catch(err => {
            this.setErrorAttributeByDSO(true);
            this.setErrorCauseAttributeByDSO(err.response.data.message);
            this.setErrorCodeAttributeByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingAttributeByDSO(false);
        });

        return resp;
    }

    setLoadingAttributeByDSO(status) {
        this.loadingAttributeByDSO = status;
    }

    setDashboardAttributeByDSO(data) {
        this.attributeByDSO = data;
    }

    setErrorAttributeByDSO(err) {
        this.errorAttributeByDSO = err;
    }

    setErrorCodeAttributeByDSO(code) {
        this.errorCodeAttributeByDSO = code;
    }

    setErrorCauseAttributeByDSO(cause) {
        this.errorCauseAttributeByDSO = cause;
    }

    // report by period
    salesPeriod = new Array();
    loadingSalesPeriod = false;
    errorSalesPeriod = false;
    errorCodeSalesPeriod = "";
    errorCauseSalesPeriod = "";

    GetPeriodSales(dso, year, brand) {
        this.setLoadingSalesPeriod(true);
        const resp = GetPeriodSalesRequest(dso, year, brand)
        .then((response) => {
            this.setErrorSalesPeriod(false);
            this.setErrorCodeSalesPeriod(response.data.code);
            this.setReportSalesPeriod(response.data.data);
        }).catch(err => {
            this.setErrorSalesPeriod(true);
            this.setErrorCauseSalesPeriod(err.response.data.message);
            this.setErrorCodeSalesPeriod(err.response.data.code);
        }).finally(() => {
            this.setLoadingSalesPeriod(false);
        });

        return resp;
    }

    setLoadingSalesPeriod(status) {
        this.loadingSalesPeriod = status;
    }

    setReportSalesPeriod(data) {
        this.salesPeriod = data;
    }

    setErrorSalesPeriod(err) {
        this.errorSalesPeriod = err;
    }

    setErrorCodeSalesPeriod(code) {
        this.errorCodeSalesPeriod = code;
    }

    setErrorCauseSalesPeriod(cause) {
        this.errorCauseSalesPeriod = cause;
    }

    // post by dso
    postByDSO = new Array();
    loadingPostByDSO = false;
    errorPostByDSO = false;
    errorCodePostByDSO = "";
    errorCausePostByDSO = "";

    GetPostByDSORequest(dso) {
        this.setLoadingPostByDSO(true);
        const resp = GetPostByDSORequest(dso)
        .then((response) => {
            this.setErrorPostByDSO(false);
            this.setErrorCodePostByDSO(response.data.code);
            this.setDashboardPostByDSO(response.data.data);
        }).catch(err => {
            this.setErrorPostByDSO(true);
            this.setErrorCausePostByDSO(err.response.data.message);
            this.setErrorCodePostByDSO(err.response.data.code);
        }).finally(() => {
            this.setLoadingPostByDSO(false);
        });

        return resp;
    }

    setLoadingPostByDSO(status) {
        this.loadingPostByDSO = status;
    }

    setDashboardPostByDSO(data) {
        this.postByDSO = data;
    }

    setErrorPostByDSO(err) {
        this.errorPostByDSO = err;
    }

    setErrorCodePostByDSO(code) {
        this.errorCodePostByDSO = code;
    }

    setErrorCausePostByDSO(cause) {
        this.errorCausePostByDSO = cause;
    }
}

