<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="4" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn v-if="getRole.role.create" class="float-right" depressed color="primary" @click="createMedsosDSO" block>
          Tambah Media Sosial
        </v-btn>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn class="float-right" @click="download" :loading="isLoadingDownload" depressed color="warning" block>
          Unduh Excel
        </v-btn>
      </v-col>
      <v-col md="3" sm="12"> <v-text-field @keyup.enter="debounceSearch" @input="debounceSearchEmpty" v-model="searchField" label="Pencarian" placeholder="Ketik di sini lalu enter/return" outlined dense block></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
        <v-autocomplete :items="allUserAM" v-model="dso" item-text="name" item-value="id" @change="pickArea" label="Pilih DSO" dense block right outlined></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="!isLoadingMedsosDSO">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">DSO</th>
                  <th class="text-left">Nama Medsos</th>
                  <th class="text-left">Link Medsos</th>
                  <th class="text-left">Kategori Medsos</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in medsosdsos" :key="item.id">
                  <td>{{ item.dso ? item.dso.name : " " }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <a target="_blank" :href="item.medsosLink">{{ item.medsosLink }}</a>
                  </td>
                  <td>{{ item.category }}</td>
                  <td v-if="getRole.role.update">
                    <v-btn small icon color="warning" dark @click="edit(item.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn small icon color="error" dark @click="deleteMedsosDSO(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text"> Tampil {{ pages.start }} - {{ pages.end }} </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert :status="isError && isErrorCode !== 404" icon="error" title="KESALAHAN" :msg="errorCauseMedsosDSO" />

    <Alert :status="isErrorDelete" icon="error" title="KESALAHAN" :msg="errorCauseDelete" />

    <Alert :status="isErrorDownload && isErrorCodeDownload !== 404" icon="error" title="KESALAHAN" :msg="isErrorCauseDownload" />
  </v-container>
</template>
<script>
import { MedsosDSOControllers } from "../../controllers/MedsosDSOControllers.js";
import { UserControllers } from "../../controllers/UserControllers";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import moment from "moment";
import XLSX from "xlsx";
import { getRole } from "../../external/functions/Cookies";

export default {
  name: "medsosdso-list",
  data: () => ({
    medsosdso: new MedsosDSOControllers(),
    userControllers: new UserControllers(),
    title: "Media Sosial DSO",
    dso: "",
    medsosdsoTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    search: {
      debounce: null,
    },

    dialogDownload: false,

    searchField: "",
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    if (getRole() !== "admin.dso") {
      this.userControllers.getUserAMList("admin.dso", 1, 1000, "");
    }
    this.getMedsosDSOs();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    allUserAM() {
      let arrDso = this.userControllers.userListAM;
      arrDso.unshift({ name: "Semua DSO", id: "" });
      return arrDso;
    },

    medsosdsos() {
      return this.medsosdso.medsosdsoList;
    },

    isErrorCode() {
      return this.medsosdso.errorCode;
    },

    isError() {
      return this.medsosdso.isError;
    },

    errorCauseMedsosDSO() {
      return this.medsosdso.errorCause;
    },

    isLoadingMedsosDSO() {
      return this.medsosdso.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.medsosdso.isErrorDelete;
    },

    errorCauseDelete() {
      return this.medsosdso.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.medsosdso.isLoading;
    },

    // download
    dataDownload() {
      return this.medsosdso.medsosdsoDownload;
    },

    isErrorDownload() {
      return this.medsosdso.errorDownload;
    },

    isErrorCauseDownload() {
      return this.medsosdso.errorCauseDownload;
    },

    isLoadingDownload() {
      return this.medsosdso.loadingDownload;
    },

    isErrorCodeDownload() {
      return this.medsosdso.errorCodeDownload;
    },
  },

  watch: {
    dso() {
      this.getMedsosDSOs();
    },
  },

  methods: {
    async getMedsosDSOs() {
      return this.medsosdso.getMedsosDSOList(this.medsosdsoTable.page, this.medsosdsoTable.limit, this.medsosdsoTable.keyword, this.dso);
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingMedsosDSO;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.medsosdsoTable.page -= 1;

        await this.getMedsosDSOs(this.medsosdsoTable.page, this.medsosdsoTable.limit, this.medsosdsoTable.keyword, this.dso);
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingMedsosDSO;
      this.pages.start += 10;
      this.pages.end += 10;

      this.medsosdsoTable.page += 1;

      await this.getMedsosDSOs(this.medsosdsoTable.page, this.medsosdsoTable.limit, this.medsosdsoTable.keyword, this.dso);
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createMedsosDSO() {
      this.$router.push({
        name: "Create Social Media",
      });
    },

    deleteMedsosDSO(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.medsosdso.deleteMedsosDSO(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.medsosdso.getMedsosDSOList(this.medsosdsoTable.page, this.medsosdsoTable.limit, this.medsosdsoTable.keyword, this.dso);
        });
    },

    edit(id) {
      this.$router.push({
        name: "Edit Social Media",
        params: {
          id: id,
        },
      });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.medsosdsoTable.page = 1;
      this.medsosdsoTable.limit = 10;
      this.medsosdsoTable.keyword = this.searchField;

      await this.getMedsosDSOs(this.medsosdsoTable.page, this.medsosdsoTable.limit, this.medsosdsoTable.keyword, this.dso);
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.medsosdsoTable.page = 1;
          this.medsosdsoTable.limit = 10;
          this.medsosdsoTable.keyword = event;

          await this.getMedsosDSOs(this.medsosdsoTable.page, this.medsosdsoTable.limit, this.medsosdsoTable.keyword, this.dso);
        }
      }, 500);
    },

    normalDate(val) {
      return moment(val).format("DD-MM-YYYY h:mm:ss");
    },

    async download() {
      await this.medsosdso.downloadMedsosDSOList(this.medsosdsoTable.page, this.medsosdsoTable.limit, this.medsosdsoTable.keyword, this.dso);

      if (this.dataDownload.length > 0) {
        const dataDownload = [];
        for (const item of this.dataDownload) {
          dataDownload.push({
            dso: item.dso ? item.dso.name : "",
            name: item.name,
            link: item.medsosLink,
            kategori: item.category,
            "dibuat pada": this.normalDate(item.createdAt),
            "diperbaharui pada": this.normalDate(item.updatedAt),
          });
        }

        const data = XLSX.utils.json_to_sheet(dataDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Report MedsosDSO List.xlsx`);
      } else {
        Swal.fire({
          text: "Data Tidak ditemukan",
          icon: "warning",
        });
      }
    },
  },
};
</script>
