<template>
  <div>
    <v-row class="pb-0 mt-2" justify="space-between">
      <v-col md="3" sm="12" v-if="getRole.role.filter">
          <v-autocomplete
            :items="allUserAM"
            v-model="dso"
            item-text="name"
            item-value="id"
            @change="pickArea"
            label="Pilih DSO"
            dense
            block
            right
            outlined
          ></v-autocomplete>
        </v-col>
      <v-col md="3" sm="12" class="mt-2">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row v-if="!isLoadingTable">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404 || promotions.length === 0">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>

        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Pengguna</th>
                  <th class="text-left">Judul</th>
                  <th class="text-left">Konten</th>
                  <th class="text-left">Tanggal</th>
                  <th class="text-left">Verifikasi</th>
                  <th class="text-left">Dibuat Pada</th>
                  <th class="text-left">Lihat Gambar</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in promotions" :key="item.id">
                  <td>{{ item.user.name }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.content }}</td>
                  <td>{{ item.date }}</td>
                  <td v-if="item.isVerified">
                    <v-chip color="success"> Terverifikasi </v-chip>
                  </td>
                  <td v-else-if="!item.isVerified && !getRole.role.verify">
                    <v-chip color="error"> Belum Terverifikasi </v-chip>
                  </td>
                  <td v-else>
                    <v-btn
                      :disabled="item.isVerified"
                      small
                      outlined
                      color="success"
                      dark
                      @click="verify(item.id)"
                    >
                      Verifikasi
                    </v-btn>
                  </td>
                  <td>{{ item.createdAt | getNormalDate }}</td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-image</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Detail :isShow="isDetail" :data="dataDetail" @hide="updateModal" />
    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </div>
</template>
<script>
import Alert from "../../../components/alert";
import { InfoCommunityControllers } from "../../../controllers/InfoCommunityControllers.js";
import { UserControllers } from "../../../controllers/UserControllers";
import { formatDate } from "../../../external/functions/Utilization.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import Detail from "./info-community-detail";
import { getRole} from "../../../external/functions/Cookies";

export default {
  name: "promotion",
  data: () => ({
    community: new InfoCommunityControllers(),
    userControllers: new UserControllers(),
    communityTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },
    searchField: ""
  }),

  created() {
    if(getRole() !== 'admin.dso') {
      this.userControllers.getUserAMList('admin.dso', 1, 1000, '')  
    }
    this.getPromotions();
  },

  computed: {
    promotions() {
      return this.community.infoCommunityList;
    },

    getRole() {
      return this.$route.meta;
    },

    allUserAM() {
      let arrDso = this.userControllers.userListAM
      arrDso.unshift({name:'Semua DSO',id:''})
      return arrDso
    },

    isErrorCode() {
      return this.community.errorCode;
    },

    isError() {
      return this.community.isError;
    },

    errorCause() {
      return this.community.errorCause;
    },

    isLoadingTable() {
      return this.community.isLoading;
    },

    dataDetail() {
      return this.community.infoCommunityDetail;
    },
  },

  components: {
    Loading,
    Alert,
    Detail,
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  watch:{
    dso(){
      this.getPromotions()
    }
  },

  methods: {
    async getPromotions() {
      return this.community.getInfoCommunityList(
        'promotion',
        this.communityTable.page,
        this.communityTable.limit,
        this.communityTable.keyword,
        this.dso
      );
    },


    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingTable;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.communityTable.page -= 1;

        await this.getPromotions(
          'promotion',
          this.communityTable.page,
          this.communityTable.limit,
          this.communityTable.keyword,
          this.dso

        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingTable;
      this.pages.start += 10;
      this.pages.end += 10;

      this.communityTable.page += 1;

      await this.getPromotions(
        'promotion',
        this.communityTable.page,
        this.communityTable.limit,
        this.communityTable.keyword,
        this.dso
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    getdetail(id) {
      this.isDetail = true;
      return this.community.getDetail(id);
    },

    verify(id) {
      Swal.fire({
        title: "Yakin akan melakukan verifikasi?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, verifikasi!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.community.verifyInfoCommunity(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.community.getInfoCommunityList(
          'promotion',
          this.communityTable.page,
          this.communityTable.limit,
          this.communityTable.keyword,
          this.dso
      );
        });
    },

    updateModal(status) {
      this.isDetail = status;
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.communityTable.page = 1;
      this.communityTable.limit = 10;
      this.communityTable.keyword = this.searchField;

      await this.getPromotions(
        'promotion',
        this.communityTable.page,
        this.communityTable.limit,
        this.communityTable.keyword,
        this.dso
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.communityTable.page = 1;
          this.communityTable.limit = 10;
          this.communityTable.keyword = event;

          await this.getPromotions(
            'promotion',
            this.communityTable.page,
            this.communityTable.limit,
            this.communityTable.keyword,
            this.dso
          );
        }
      }, 500);
    },
  },
};
</script>