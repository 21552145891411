<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col>
        <v-row justify="space-between">
          <v-col md="2" sm="8">
            <v-autocomplete v-model="dsoChosen" :items="dsos" item-text="name" item-value="id" label="DSO" outlined dense></v-autocomplete>
          </v-col>
          <v-col md="2" sm="8">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dateChosen"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateChosen"
                    label="Tanggal Event"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <!-- <v-date-picker v-model="dateChosen" no-title scrollable :min="nowDate"> -->
                <v-date-picker v-model="dateChosen" no-title scrollable :max="nowDate">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dateChosen)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-col md="2" sm="8">
            <v-autocomplete v-model="brandChosen" :items="brands" item-text="name" item-value="id" label="Brands" outlined dense></v-autocomplete>
          </v-col>
          <v-col md="2" sm="8">
            <v-autocomplete v-model="categoryChosen" :items="categories" item-text="key" item-value="value" label="Category" outlined dense></v-autocomplete>
          </v-col>
          <v-col md="2" sm="8">
            <v-autocomplete v-model="status" :items="statusMatrixKisi" item-text="key" item-value="value" label="Status" outlined dense></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="12" sm="12" v-if="getRole.roleKisi.read">
        <Kisi :category="categoryChosen" :dso="dsoChosen" :brand="brandChosen" :date="dateChosen" :status="status" />
      </v-col>
    </v-row>
    <!--Error Reports-->
    <Alert :status="isErrorGetReports && errorCodeGetReports !== 404" icon="error" title="ERROR" :msg="errorCauseGetReports" />

    <!--Error Categories-->
    <Alert :status="isErrorGetCategories && errorCodeGetCategories !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetCategories" />

    <!--Error DSO-->
    <Alert :status="isErrorGetUsers && errorCodeGetUsers !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetUsers" />

    <!--Error Brand-->
    <Alert :status="isErrorBrands && isErrorCodeBrands !== 404" icon="error" title="KESALAHAN" :msg="errorCauseBrandBrands" />
  </v-container>
</template>
<script>
import { CategoryControllers } from "../../../../controllers/CategoryControllers.js";
import { AreaManagerControllers } from "../../../../controllers/AreaManagerControllers.js";
import { BrandControllers } from "../../../../controllers/BrandControllers.js";
import { UserControllers } from "../../../../controllers/UserControllers.js";
//import { ReportControllers } from "../../../../controllers/ReportControllers.js";
import Alert from "../../../../components/alert/index";
//import Score from "./components/report-score";
import Kisi from "./components/report-community-key.vue";
export default {
  name: "activity-detail",
  data: () => ({
    title: "Detail Komunitas Key",
    category: new CategoryControllers(),
    area: new AreaManagerControllers(),
    brand: new BrandControllers(),
    user: new UserControllers(),
    // report: new ReportControllers(),

    dsoChosen: "",
    brandChosen: "",
    categoryChosen: "",
    status: "",
    dateChosen: new Date().toISOString().split('T')[0],
    nowDate: new Date().toISOString().split('T')[0],

    scopeTable: {
      page: 1,
      limit: 1000,
      keyword: "",
    },

    menu:false
  }),

  computed: {
    // dsos
    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },
    // brands
    brands() {
      let brand = this.brand.brandList.filter((item) => item.product === "Rokok");
      brand.unshift({name:"Semua Brand", id:""});

      return brand
    },

    isErrorCodeBrands() {
      return this.brand.errorCode;
    },

    isErrorBrands() {
      return this.brand.isError;
    },

    errorCauseBrandBrands() {
      return this.brand.errorCause;
    },

    isLoadingBrandBrands() {
      return this.brand.isLoading;
    },

    getRole() {
      return this.$route.meta;
    },

    //categories
    categories() {
      if (this.brandChosen) {
        let category = this.category.categoryCommunityList.filter((category) => category.brand.includes(this.brandChosen));
        category.unshift({key:"Semua Category",value:""})
        return category;
      }
      let category = this.category.categoryCommunityList;
      category.unshift({key:"Semua Category",value:""})
      return category;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },
    statusMatrixKisi() {
      return [
        { key: "Semua Hasil Matrix", value: "" },
        { key: "OPPORTUNISTIC", value: "OPPORTUNISTIC" },
        { key: "MAINTENANCE", value: "MAINTENANCE" },
        { key: "DEVELOPMENT", value: "DEVELOPMENT" },
        { key: "KEY", value: "KEY" },
      ];
    },
  },
  created() {
    this.getCategories();
    this.getDsos();
    this.getBrandList();
    this.dsoChosen = this.$route.params.dso;
  },
  methods: {
    getCategories() {
      return this.category.getcategoryCommunityList();
    },
    getDsos() {
      return this.user.getUserAMList("admin.dso", 1, 1000, "");
    },

    getBrandList() {
      return this.brand.getBrandList(this.scopeTable.page, this.scopeTable.limit);
    },
  },
  components: {
    //Score,
    Alert,
    Kisi,
  },
};
</script>
