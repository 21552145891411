<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-autocomplete
                v-model="form.community"
                :items="communities"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Komunitas"
                required
                outlined
                dense
              ></v-autocomplete>

              <!-- :items="community" -->
              <v-text-field
                v-model="form.follower"
                :rules="[
                  (v) =>
                    Number.isInteger(Number(v)) ||
                    'Isian harus angka',
                  (v) => !!v || 'Kolom wajib diisi',
                ]"
                label="Masukan Jumlah Follower"
                required
                outlined
                dense
                type="number"
              ></v-text-field>

            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
            >
              Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Brands -->
    <Alert
      :status="isErrorCreateFollowerMedsos && errorCodeCreateFollowerMedsos !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseCreateFollowerMedsos"
    />

    <!-- Error Community -->
    <Alert
      :status="isErrorGetCommunity && errorCodeGetCommunity !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetCommunity"
    />

    <!-- Error Users -->
    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    />

  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { FollowerMedsosControllers} from "../../controllers/FollowerMedsosControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-user",
  data: () => ({
    community: new CommunityControllers(),
    followermedsos: new FollowerMedsosControllers(),
    title: "Tambah Follower Media Sosial",
    valid:false,

    isLoading: false,
    fullPage: true,

    form: {
      medfol: null,
      community: null,
      follower: null,
    },

    selectScope: {
      page: 1,
      limit: 600,
      keyword:''
    },

    tag: {
      value: [],
      options: [],
    },
    logoDisplay: null,
    thereIsImage: false,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },
    // followermedsos
    isErrorCreateFollowerMedsos() {
      return this.followermedsos.isError;
    },

    errorCauseCreateFollowerMedsos() {
      return this.followermedsos.errorCause;
    },

    isLoadingCreateFollowerMedsos() {
      return this.followermedsos.isLoading;
    },

    errorCodeCreateFollowerMedsos(){
      return this.followermedsos.errorCode
    },

    // community
    communities() {
      return this.community.communityList.filter(community=>community.dso.id=== this.$route.params.dsoId && !community.isFreeze && community.isVerified);
    },

    errorCodeGetCommunities() {
      return this.community.errorCode;
    },

    isErrorGetCommunities() {
      return this.community.isError;
    },

    errorCauseGetCommunities() {
      return this.community.errorCause;
    },
  },

  created() {
    this.getCommunities();
    this.getMedfolId();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getMedfolId(){
      this.form.medfol = this.$route.params.medfol
    },

    addTag(newTag) {
      this.tag.options.push(newTag);
      this.tag.value.push(newTag);
    },


    async getCommunities() {
      return this.community.getCommunityList(
        this.selectScope.page,
        this.selectScope.limit,
        this.selectScope.keyword
      );
    },


    async submitProcess(
      medfol,
      community,
      follower,
    ) {
      return this.followermedsos.createFollowerMedsosList(
        medfol,
        community,
        follower,
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.medfol,
          this.form.community,
          parseInt(this.form.follower)
        );

        if (this.isErrorCreateFollowerMedsos) {
          this.isLoading = false;
          this.isErrorCreateFollowerMedsos = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateFollowerMedsos,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorCreateFollowerMedsos = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>