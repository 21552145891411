<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Dasar</v-alert>
              <v-autocomplete
                v-model="detailVenue.dso.id"
                :items="users"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="DSO"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="detailVenue.area"
                :items="allDistricts"
                item-text="districtName"
                item-value="districtName"
                dense
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="ID Area"
                required
                outlined
              ></v-autocomplete>

              <v-autocomplete
                v-model="detailVenue.brand.id"
                :items="brands"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Brand"
                required
                outlined
                dense
              ></v-autocomplete>
              <v-text-field
                v-model="detailVenue.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Name"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="detailVenue.excellence"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Keunggulan"
                required
                outlined
                dense
              ></v-text-field>
              
              <v-autocomplete
                v-model="detailVenue.category"
                :items="categories"
                item-text="value"
                item-value="value"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kategori"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert outlined type="info" v-if="!isAddressEditable">
                Untuk ubah alamat klik
                <strong style="cursor: pointer" @click="editAddress"
                  >Ubah Alamat</strong
                >
              </v-alert>
              <v-alert outlined type="error" v-else>
                Untuk batalk ubah alamat klik
                <strong style="cursor: pointer" @click="cancelEditAddress"
                  >Batal Ubah</strong
                >
              </v-alert>

              <template v-if="addressForm">
                <v-autocomplete
                  v-model="detailVenue.address.province"
                  :items="provinces"
                  item-text="provinceName"
                  item-value="provinceId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Provinsi"
                  required
                  outlined
                  dense
                  @change="getDistrict($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailVenue.address.district"
                  :items="districts"
                  item-text="districtName"
                  item-value="districtId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kabupaten/Kota"
                  required
                  outlined
                  dense
                  @change="getSubDistrict($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailVenue.address.subDistrict"
                  :items="subDistricts"
                  item-text="subDistrictName"
                  item-value="subDistrictId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kecamatan"
                  required
                  outlined
                  dense
                  @change="getVillages($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailVenue.address.village"
                  :items="villages"
                  item-text="villageName"
                  item-value="villageId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kelurahan/Desa"
                  required
                  outlined
                  dense
                  @change="getVillagesValue($event)"
                ></v-autocomplete>

                <v-text-field
                  v-model="detailVenue.address.street"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Jalan"
                  required
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="detailVenue.address.zipCode"
                  :rules="[
                    (v) =>
                      Number.isInteger(Number(v)) ||
                      'The value must be an integer number',
                    (v) => !!v || 'Kolom wajib diisi',
                  ]"
                  label="Kode Pos"
                  :maxlength="5"
                  required
                  outlined
                  dense
                ></v-text-field>
              </template>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Users -->
    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    />

    <!-- Error Brands -->
    <Alert
      :status="isErrorGetBrands && errorCodeGetBrands !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetBrands"
    />

    <!-- Error Categories -->
    <Alert
      :status="isErrorGetCategories && errorCodeGetCategories !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetCategories"
    />

    <!-- Error Provinces -->
    <Alert
      :status="isErrorGetProvinces && errorCodeGetProvinces !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetProvinces"
    />

    <!-- Error Districts -->
    <Alert
      :status="isErrorGetDistricts && errorCodeGetDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDistricts"
    />

    <!-- Error Sub Districts -->
    <Alert
      :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetSubDistricts"
    />

    <!-- Error Villages -->
    <Alert
      :status="isErrorGetVillages && errorCodeGetVillages !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetVillages"
    />

    <!-- Error All Districts -->
    <Alert
      :status="isErrorGetAllDistricts && errorCodeGetAllDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAllDistricts"
    />
  </v-container>
</template>
<script>
import { VenueControllers } from "../../controllers/VenueControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../controllers/UserControllers.js";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-venue",
  data: () => ({
    venue: new VenueControllers(),
    user: new UserControllers(),
    brand: new BrandControllers(),
    category: new CategoryControllers(),
    area: new AreaControllers(),
    valid: true,
    title: "Ubah Venue",

    isLoading: false,
    fullPage: true,

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
    },

    selectScope: {
      page: 1,
      limit: 1000,
    },

    isAddressEditable: false,
    addressForm: false,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // venue length
    venueLength() {
      return this.venue.venueList.length;
    },

    // venues
    isErrorUpdateVenue() {
      return this.venue.isError;
    },

    errorCauseUpdateVenue() {
      return this.venue.errorCause;
    },

    isLoadingUpdateVenue() {
      return this.venue.isLoading;
    },

    // users
    users() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    // brands
    brands() {
      return this.brand.brandList.filter(brand=>brand.product=='Rokok');
    },

    errorCodeGetBrands() {
      return this.brand.errorCode;
    },

    isErrorGetBrands() {
      return this.brand.isError;
    },

    errorCauseGetBrands() {
      return this.brand.errorCause;
    },

    // categories
    categories() {
      return this.category.categoryVenusList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },

    // all districts
    allDistricts() {
      return this.area.allDistrictList;
    },

    errorCodeGetAllDistricts() {
      return this.area.errorCodeAllDistrict;
    },

    isErrorGetAllDistricts() {
      return this.area.isErrorAllDistrict;
    },

    errorCauseGetAllDistricts() {
      return this.area.errorCauseAllDistrict;
    },

    // detail venue
    detailVenue() {
      const venue = this.venue.venueDetail;

      if (venue) {
        return {
          ...venue,
          dso: {
            id: venue.dso ? venue.dso.id : "",
          },
          brand: {
            id: venue.brand ? venue.brand.id : "",
          },
          address: {
            province: venue.address ? venue.address.province : "",
            district: venue.address ? venue.address.district : "",
            subDistrict: venue.address ? venue.address.subDistrict : "",
            village: venue.address ? venue.address.village : "",
            street: venue.address ? venue.address.street : "",
            zipCode: venue.address ? venue.address.zipCode : "",
          },
        };
      }

      return null;
    },
  },

  created() {
    this.getUsers();
    this.getBrands();
    this.getCategories();
    this.getProvinces();
    this.getAllDistricts();
    this.getVenueList();
  },

  methods: {
    async getVenueList() {
      return this.venue.getVenueDetailEdit(
        this.$route.params.id
      );
    },
    back() {
      this.$router.go(-1);
    },

    getUsers() {
      return this.user.getUserAMList('admin.dso', 1, 1000, '')
    },

    getBrands() {
      return this.brand.getBrandList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getCategories() {
      return this.category.getcategoryVenusList();
    },

    getProvinces() {
      return this.area.getProvinceList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find(
        (item) => item.provinceId === provinceId
      );
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(
        provinceId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find(
        (item) => item.districtId === districtId
      );
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(
        districtId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find(
        (item) => item.subDistrictId === subDistrictId
      );
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(
        subDistrictId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillagesValue(villageId) {
      const { villageName, postalCode } = this.villages.find(
        (item) => item.villageId === villageId
      );
      this.addressFiltered.village = villageName;
      this.detailVenue.address.zipCode = postalCode;
    },

    getAllDistricts() {
      return this.area.getAllDistrictList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    async submitProcess(
      dso,
      areaId,
      brands,
      names,
      excellence,
      category,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode
    ) {
      return this.venue.updateVenueList(
        this.$route.params.id,
        dso,
        areaId,
        brands,
        names,
        excellence,
        category,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        if (this.isAddressEditable) {
          await this.submitProcess(
            this.detailVenue.dso.id,
            this.detailVenue.area,
            this.detailVenue.brand.id,
            this.detailVenue.name,
            this.detailVenue.excellence,
            this.detailVenue.category,
            this.addressFiltered.province,
            this.addressFiltered.district,
            this.addressFiltered.subDistrict,
            this.addressFiltered.village,
            this.detailVenue.address.street,
            this.detailVenue.address.zipCode
          );
        } else {
          await this.submitProcess(
            this.detailVenue.dso.id,
            this.detailVenue.area,
            this.detailVenue.brand.id,
            this.detailVenue.name,
            this.detailVenue.excellence,
            this.detailVenue.category,
            this.detailVenue.address.province,
            this.detailVenue.address.district,
            this.detailVenue.address.subDistrict,
            this.detailVenue.address.village,
            this.detailVenue.address.street,
            this.detailVenue.address.zipCode
          );
        }

        if (this.isErrorUpdateVenue) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateVenue,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });

          this.back();
        }
      }
    },

    editAddress() {
      this.isAddressEditable = true;
      this.addressForm = true;
      this.getProvinces();
    },

    cancelEditAddress() {
      this.isAddressEditable = false;
      this.addressForm = false;
    },
  },
};
</script>