<template>
  <v-row>
    <v-col md="10" sm="12">
      <h4>{{ title }}</h4>
    </v-col>
    <v-col md="2" sm="12">
      <v-btn
        class="success"
        :loading="isLoadingDownload"
        small
        block
        @click="download"
        >Unduh Excel</v-btn
      >
    </v-col>
    <v-col md="12" sm="12">
      <v-row v-if="!isLoadingDashboard">
          <v-col md="12" sm="12">
            <v-card v-if="errorCodeGetDashboard === 404">
              <v-card-text>Data Tidak Tersedia</v-card-text>
            </v-card>

            <v-card v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Komunitas</th>
                      <th class="text-left">Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in communities" :key="index">
                      <td>{{ item.category }}</td>
                      <td>{{ item.count }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
      </v-row>

      <v-row v-else>
        <v-col md="12" sm="12">
          <v-card>
            <v-card-text>Memuat Data...</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <!-- Error Dashboard -->
    <Alert
      :status="isErrorGetDashboard && errorCodeGetDashboard !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDashboard"
    />
  </v-row>
</template>
<script>
import Alert from "../../../components/alert/index";
import { DashboardControllers } from "../../../controllers/DashboardControllers";
import XLSX from "xlsx";

export default {
  name: "category-list",
  data: () => ({
    title: "Kategori Komunitas",
    dashboard: new DashboardControllers(),
    isLoadingDownload: false
  }),

  components: {
    Alert
  },

  computed: {
    // community categories
    communities() {
      return this.dashboard.communityCategoryDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    this.getDashboard();
  },

  methods: {
    getDashboard() {
      return this.dashboard.getCommunityCategoryDashboardList();
    },

    download() {
      this.isLoadingDownload = true;
      const communities = []
      for(const item of this.communities) {
          communities.push({
              "comunity name": item.category,
              "jumlah": item.count
          });
      }
      const data = XLSX.utils.json_to_sheet(communities);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Dashboard Kategori Komunitas.xlsx");
      this.isLoadingDownload = false;
    }
  }
};
</script>