<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="4" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn class="float-right" depressed block color="primary" @click="createDRP" v-if="getRole.role.create">
          Buat DRP
        </v-btn>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn class="float-right" @click="download" :loading="isLoadingDownload" depressed color="warning" block>
          Unduh Excel
        </v-btn>
      </v-col>
      <v-col md="3" sm="12"> <v-text-field @keyup.enter="debounceSearch" @input="debounceSearchEmpty" v-model="searchField" label="Pencarian" placeholder="Ketik di sini lalu enter/return" outlined dense></v-text-field></v-col>
    </v-row>

    <v-row>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
        <v-autocomplete :items="allUserAM" v-model="dso" item-text="name" item-value="id" @change="pickArea" label="Pilih DSO" dense block right outlined></v-autocomplete>
      </v-col>
    </v-row>

    <v-row v-if="!isLoadingDRP">
      <v-col md="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama DRP</th>
                  <th class="text-left">DSO</th>
                  <th class="text-left">Area</th>
                  <th class="text-left">Keunggulan</th>
                  <th class="text-left">Kategori</th>
                  <th class="text-left">Rincian</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in DRPs" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td v-if="item.dso">{{ item.dso.name }}</td>
                  <td v-else>-</td>
                  <td>{{ item.area ? item.area : item.address.district }}</td>
                  <td>{{ item.excellence }}</td>
                  <td>{{ item.category }}</td>
                  <td>
                    <v-btn small icon color="success" dark @click="getdetail(item.id)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn small icon color="warning" dark @click="edit(item.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn small icon color="error" dark @click="deleteDRP(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col>
        <span class="mr-4 grey--text"> Tampil {{ pages.start }} - {{ pages.end }} </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Detail :isShow="isDetail" :data="DRPDetail" @hide="updateModal" />
    <Alert :status="isError && isErrorCode !== 404" icon="error" title="KESALAHAN" :msg="errorCause" />
  </v-container>
</template>
<script>
import { DRPControllers } from "../../controllers/DRPControllers.js";
import { UserControllers } from "../../controllers/UserControllers";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Detail from "./drp-detail";
import Swal from "sweetalert2";
import moment from "moment";
import XLSX from "xlsx";
import { getRole } from "../../external/functions/Cookies";

export default {
  name: "DRP-list",
  data: () => ({
    DRP: new DRPControllers(),
    userControllers: new UserControllers(),
    title: "DRP",
    dso: "",
    DRPTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },

    dialogDownload: false,

    searchField: "",
  }),

  components: {
    Alert,
    Loading,
    Detail,
  },

  created() {
    if (getRole() !== "admin.dso") {
      this.userControllers.getUserAMList("admin.dso", 1, 1000, "");
    }
    this.getDRPs();
  },

  watch: {
    dso() {
      this.getDRPs();
    },
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    DRPs() {
      return this.DRP.DRPList;
    },

    DRPDetail() {
      return this.DRP.DRPDetail;
    },

    allUserAM() {
      let arrDso = this.userControllers.userListAM;
      arrDso.unshift({ name: "Semua DSO", id: "" });
      return arrDso;
    },

    isErrorCode() {
      return this.DRP.errorCode;
    },

    isError() {
      return this.DRP.isError;
    },

    errorCauseDRP() {
      return this.DRP.errorCause;
    },

    isLoadingDRP() {
      return this.DRP.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.DRP.isErrorDelete;
    },

    errorCauseDelete() {
      return this.DRP.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.DRP.isLoading;
    },

    // download
    dataDownload() {
      return this.DRP.DRPDownload;
    },

    isErrorDownload() {
      return this.DRP.errorDownload;
    },

    isErrorCauseDownload() {
      return this.DRP.errorCauseDownload;
    },

    isLoadingDownload() {
      return this.DRP.loadingDownload;
    },

    isErrorCodeDownload() {
      return this.DRP.errorCodeDownload;
    },
  },

  methods: {
    async getDRPs() {
      return this.DRP.getDRPList(this.DRPTable.page, this.DRPTable.limit, this.DRPTable.keyword, this.dso);
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingDRP;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.DRPTable.page -= 1;

        await this.getDRPs(this.DRPTable.page, this.DRPTable.limit, this.DRPTable.keyword, this.dso);
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingDRP;
      this.pages.start += 10;
      this.pages.end += 10;

      this.DRPTable.page += 1;

      await this.getDRPs(this.DRPTable.page, this.DRPTable.limit, this.DRPTable.keyword, this.dso);
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createDRP() {
      this.$router.push({
        name: "Create DRP",
      });
    },

    getdetail(id) {
      this.isDetail = true;
      return this.DRP.getDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    deleteDRP(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.DRP.deleteDRP(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.DRP.getDRPList(this.DRPTable.page, this.DRPTable.limit, this.DRPTable.keyword, this.dso);
        });
    },

    edit(id) {
      this.$router.push({
        name: "Edit DRP",
        params: {
          id: id,
        },
      });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.DRPTable.page = 1;
      this.DRPTable.limit = 10;
      this.DRPTable.keyword = this.searchField;

      await this.getDRPs(this.DRPTable.page, this.DRPTable.limit, this.DRPTable.keyword, this.dso);
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.DRPTable.page = 1;
          this.DRPTable.limit = 10;
          this.DRPTable.keyword = event;

          await this.getDRPs(this.DRPTable.page, this.DRPTable.limit, this.DRPTable.keyword, this.dso);
        }
      }, 500);
    },

    normalDate(val) {
      return moment(val).format("DD-MM-YYYY h:mm:ss");
    },

    async download() {
      await this.DRP.downloadDRPList(this.DRPTable.page, this.DRPTable.limit, this.DRPTable.keyword, this.dso);

      if (this.dataDownload.length > 0) {
        const dataDownload = [];
        for (const item of this.dataDownload) {
          const data = {
            "nama DRP": item.name,
            DSO: "",
            area: item.area,
            keunggulan: item.excellence,
            kota: item.address.district,
            alamat: item.address ? `${item.address.street}, ${item.address.village}, ${item.address.subDistrict}, ${item.address.district}, ${item.address.province}, ${item.address.zipCode}` : "",
            kategori: item.category,
            "dibuat pada": this.normalDate(item.createdAt),
            "diperbaharui pada": this.normalDate(item.updatedAt),
          };
          if (item.dso) {
            data.DSO = item.dso.name;
          }
          dataDownload.push(data);
        }

        const data = XLSX.utils.json_to_sheet(dataDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Report DRP List.xlsx`);
      } else {
        Swal.fire({
          text: "Data Tidak ditemukan",
          icon: "warning",
        });
      }
    },
  },
};
</script>
