<template>
  <div>
    <v-col md="12" sm="12" align="center" class="mt-n10 mx-0">
      <VueApexCharts ref="monthlyChartCommunity" type="bar" height="200" max-width="310" :options="chartOptions"
        :series="series" />
    </v-col>
  </div>
</template>


<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts
  },
  props: {
    communityAchievement: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  watch: {
    communityAchievement(newVal) {
      this.series = [{ name: "Jumlah Komunitas", data: newVal.monthlyCommunity }]
      this.$refs.monthlyChartCommunity.refresh()
    }
  },
  data: () => ({
    // charts
    series: [
      {
        name: "Jumlah Komunitas",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chartOptions: {
      chart: {
        height: 200,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          if(val == 0){
            val = ''
          }
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      grid: {
        show: false
      },

      xaxis: {
        categories: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MEI",
          "JUN",
          "JUL",
          "AGU",
          "SEP",
          "OKT",
          "NOV",
          "DES",
        ],
        position: "bottom",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        lines: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
          position: "top",
        },
      },
    },
  }),
}
</script>