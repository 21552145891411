<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on"
        style="margin-right: 12px; z-index: 1"
      >
        <v-badge
          color="green"
          :content="isLength"
          v-if="isLength > 0"
        >
          <v-icon @click="showNotifs">mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else @click="showNotifs">mdi-bell</v-icon>
      </v-btn>
    </template>
    <v-list v-if="isErrorCode === 404">
      <v-list-item-group>
        <template>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-subtitle>Tidak ada pemberitahuan terbaru</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
        <template>
          <div class="more">
            <router-link :to="{ name: 'Notification'}"
              >Lihat semua pemberitahuan</router-link
            >
          </div>
        </template>
      </v-list-item-group>
    </v-list>
    <v-list two-line v-else>
      <v-list-item-group>
        <template v-for="item in notifs">
          <v-list-item :key="item.id">
            <template>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ item.message }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>
                  {{ item.createdAt | createdDate }}
                </v-list-item-action-text>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
        <template>
          <div class="more">
            <router-link :to="{ name: 'Notification'}"
              >Lihat semua pemberitahuan</router-link
            >
          </div>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
import { NotificationControllers } from "../../controllers/NotificationControllers.js";
import moment from "moment";
moment.suppressDeprecationWarnings = true;

export default {
  name: "Notif",
  data: () => ({
    notif: new NotificationControllers(),
  }),

  filters: {
    createdDate(val) {
      return moment(val).startOf("day").fromNow();
    },
  },

  computed: {
    notifs() {
      return this.notif.notificationPreviewList;
    },

    isErrorCode() {
      return this.notif.errorCode;
    },

    isLoading() {
      return this.notif.isLoading;
    },

    isLength: {
      get: function() {
        return this.notif.lengthNotifications;
      },

      set: function(val) {
        this.notif.lengthNotifications = val;
      }
    }
  },

  created() {
    this.getNotifications();
  },

  methods: {
    async getNotifications() {
      return this.notif.getNotificationPreviewList();
    },

    async showNotifs() {
      this.isLength = 0;
    }
  },
};
</script>
<style scoped>
.more {
  padding: 16px;
  font-size: 12px;
  z-index: auto;
}

.more a {
  text-decoration: none;
}

.more a:hover {
  text-decoration: underline;
}
</style>