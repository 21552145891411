<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 pt-4">
          <v-col md="4" sm="12" xs="12">
            <v-autocomplete
              :items="years"
              v-model="chosenYear"
              label="Tahun"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12" xs="12">
            <!-- <v-btn
              class="success"
              :loading="isLoadingDownload"
              small
              block
              @click="download"
              >Unduh Excel
            </v-btn> -->
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col md="4" sm="12">
            <v-row class="d-flex flex-column"> 
              <v-row>
                <!-- Title -->
                <v-col align="center" md="12" xs="12">
                  <h4>{{ title }}</h4>
                </v-col>
              </v-row>  
              <v-row>
                <!-- Text total community -->
                <v-col md="12" sm="12" align="center" class="mt-n1">
                  <h2 class="text-h2 font-weight-black">
                    <b>{{communityAchievementComputed.reach}}</b><span class="text-h6 font-weight-black" v-if="isOldKPI">/{{communityAchievementComputed.target}}</span>
                  </h2>
                  <div v-if="isOldKPI">
                    <p v-if="!communityAchievementComputed.target || communityAchievementComputed.target == 0" style="font-size: 12px" class="font-weight-black red--text">
                      TARGET NOT SET
                    </p>
                    <p v-else-if="communityAchievementComputed.reach >= communityAchievementComputed.target" style="font-size: 12px" class="font-weight-black green--text">
                      ACHIEVED
                    </p>
                    <p v-else style="font-size: 12px" class="font-weight-black red--text">
                      NOT ACHIEVED
                    </p>
                  </div>
                  <!-- <h3 class="font-weight-black green--text">
                    <v-icon class="font-weight-black green--text mt-n1">
                      mdi-arrow-up-bold</v-icon> INCREASE 30%
                  </h3> -->
                  <!-- component bar target -->
                </v-col>
              </v-row>
              <v-row >
                <v-col md="12" sm="12" class="mt-n5 mx-0 ms-n3" justify="left">
                  <div class="mt-n5">
                    <BarTarget :communityAchievement="communityAchievementComputed" v-if="isOldKPI"/>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <!-- Barchart total community per category -->
                <!-- <v-col md="12" sm="12" class="mt-n13 ms-n1">
                  <VueApexCharts
                    v-if="chartOptions.xaxis.categories.length > 0"
                    type="bar"
                    height="200"
                    :options="chartOptions"
                    :series="series"
                  />
                </v-col> -->
                <ChartPerCategory :communityAchievement="communityAchievementComputed" class="ms-5 mt-n5"/>
              </v-row>
            </v-row>
          </v-col>

          <v-col md="4" sm="12">
            <v-row>
              <v-col align="center" md="12" ms="12" class="mt-n3">
                <h4 class="font-weight-black">KOMUNITAS PER BRAND</h4>
              </v-col>

              <v-col md="12" sm="12" class="mt-2">
                <RadialbarAchievement />
              </v-col> 
              <v-col md="12" sm="12" class="mt-2">
                <StackedBar :communityAchievement="communityAchievementComputed"/>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="4" sm="12">
            <v-row>
              <v-col align="center" md="12" ms="12" class="mb-5 mt-n3">
                <h4 class="font-weight-black">PERTAMBAHAN KOMUNITAS PER BULAN</h4>
              </v-col>

              <ChartPerMonth :communityAchievement="communityAchievementComputed"/>
            </v-row>
          </v-col>
        </v-row>
 
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>

<script>
import Alert from "../../../../components/alert/index";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);
import StackedBar from "./stacked-bar-achievement";
import BarTarget from "./bar-target";
import ChartPerMonth from "./chart-per-month";
import ChartPerCategory from "./chart-per-category.vue";
import { CommunityControllers } from "../../../../controllers/CommunityControllers";

export default {
  name: "community-achievement",
  props: {
    dso: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    title: "CAPAIAN KOMUNITAS",
    communityControllers: new CommunityControllers(),
    year: '',
    chosenYear: new Date().getFullYear(),
    brand: 'Super',
    series: [
      {
        data: [12, 12, 5, 3, 11],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 380,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "50%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#000000", "#e2a63d", "#e23d3d", "#d99494", "#34bf41"],
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"],
          fontSize: "10px",
          fontWeight: 900,
        },
        formatter: function(val, opt) {
          const series = opt.w.globals.series[0]
          const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0);
          const percent = (val / sum) * 100;
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+` (${percent.toFixed(0)}%)`;
        },
        offsetX: "10px",
        offsetY: -11,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "Bold Riders",
          "Heppiii Community",
          "Super Family",
          "Kill the LAst",
          "Other",
        ],
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function() {
              return "";
            },
          },
        },
      },
    },

    isLoadingDownload: false,
    // Data Capaian Komunitas
    dataCapaianKomunitas: {
      // totalCapaian: 100,
      // hasilCapaian: 10,
      categories: ["Bold Riders",
          "Heppiii Community",
          "Super Family",
          "Kill the LAst",
          "Other",],
      series: [1, 2,3, 4, 5]
    },
    dataKomunitasPerBrand: {
      
    }
  }),

  components: {
    // VueApexCharts,
    Alert,
    StackedBar,
    BarTarget,
    ChartPerMonth,
    ChartPerCategory,
  },

  computed: {
    communityAchievementComputed() {
      // console.log(this.communityControllers.communityAchievement)
      const achievement = this.communityControllers.communityAchievement.communityAchievement;
      const brand = this.communityControllers.communityAchievement.brandCommunity
      // let brandlist = brand.map(datum => datum.brand)
      const categoryPerBrand = this.communityControllers.communityAchievement.categoryPerBrand;
      const monthlyCommunity = this.communityControllers.communityAchievement.monthlyCommunity;
      // console.log({
      //   reach:Number(achievement?.split('/')?.[0] || 0),
      //   target: Number(achievement?.split('/')?.[1] || 0),
      //   communityBarSeries:brand && brand.map(datum => datum.value),
      //   communityBarCategories: brand && brand.map(datum => datum.brand),
      //   categoryPerBrandTotal: categoryPerBrand && categoryPerBrand.reduce((a, b) => a + b.value, 0),
      //   categoryPerBrandSeries: categoryPerBrand && categoryPerBrand.filter((item) => item.value).map((item) => ({name: item.category, data: [item.value]})),
      //   categoryPerBrand: categoryPerBrand,
      //   monthlyCommunity: monthlyCommunity && monthlyCommunity.map((datum) => datum.value)
      // })
      return {
        reach: brand ? brand.reduce((a, b) => a + b.value, 0) : 0,
        target: Number(achievement?.split('/')?.[1] || 0),
        communityBarSeries:brand && brand.map(datum => datum.value),
        communityBarCategories: brand && brand.map(datum => datum.brand),
        categoryPerBrandTotal: categoryPerBrand && categoryPerBrand.reduce((a, b) => a + b.value, 0),
        categoryPerBrandSeries: categoryPerBrand && categoryPerBrand.filter((item) => item.value).map((item) => ({name: item.category, data: [item.value]})),
        categoryPerBrand: categoryPerBrand,
        monthlyCommunity: monthlyCommunity && monthlyCommunity.map((datum) => datum.value)
      }
    },
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },

    isOldKPI(){
      return this.chosenYear < 2023;
    },

    getRole() {
      return this.$route.meta
    },
    // sales
    // sales() {
    //   return this.dashboard.salesDashboardList;
    // },

    // errorCodeGetDashboard() {
    //   return this.dashboard.errorCode;
    // },

    // isErrorGetDashboard() {
    //   return this.dashboard.isError;
    // },

    // errorCauseGetDashboard() {
    //   return this.dashboard.errorCause;
    // },

    // isLoadingDashboard() {
    //   return this.dashboard.isLoading;
    // },
  },

  created() {
    // this.getDashboard();
    // this.getSeries();
    // this.getCommunityAchievement(this.dso, this.chosenYear)

  },

  watch: {
    chosenYear() {
      this.getCommunityAchievement()
    },
    dso() {
      this.getCommunityAchievement()
    }
  },

  methods: {
    getDashboard() {
      return this.dashboard.getSalesDashboardList(this.year);
    },

    getCommunityAchievement() {
      return this.communityControllers.getCommunityAchievement(this.dso, this.chosenYear)
    },

    async getSeries() {
      await this.getDashboard(this.year);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    async pickYear(event) {
      this.series = [
        {
          data: [],
        },
      ];
      this.chartOptions.xaxis.categories = [];

      await this.getDashboard(event);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const sales = [];
      for (const item of this.sales) {
        sales.push({
          "comunity name": item._id,
          jumlah: item.count,
        });
      }
      const data = XLSX.utils.json_to_sheet(sales);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Sales Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>
