const Login = () => {
  return `/dashboard/v1/login`;
};

const Logout = () => {
  return `/dashboard/v1/logout`;
};

const ChangePassword = () => {
  return `/dashboard/v1/user/changePassword`;
};

const Projects = (page, limit) => {
  return `/dashboard/v1/project?page=${page}&size=${limit}`;
};

const SubProjects = (page, limit) => {
  return `/dashboard/v1/sub-project?page=${page}&size=${limit}`;
};

const BrandList = (page, limit, keyword = "") => {
  return `/dashboard/v1/brand?page=${page}&size=${limit}&keyword=${keyword}`;
};

const CreateBrand = () => {
  return `/dashboard/v1/brand`;
};

const VenueList = (page, limit, keyword = "", dsoId = "") => {
  return `/dashboard/v1/venue?page=${page}&size=${limit}&keyword=${keyword}&dsoId=${dsoId}`;
};

const CreateVenue = () => {
  return `/dashboard/v1/venue`;
};

const SalesTargetList = (page, limit, keyword = "", brand = "", category = "", periode = "", dsoId = "") => {
  return `/dashboard/v1/sales-target?page=${page}&size=${limit}&keyword=${keyword}&brand=${brand}&category=${category}&periode=${periode}&dsoId=${dsoId}`;
};

const CreateSalesTarget = () => {
  return `/dashboard/v1/sales-target`;
};

const UserList = (page, limit, keyword = "", startDate = "", endDate = "", isReport = false, amId = "", rsoId = "", exPic = false, role = "") => {
  return `/dashboard/v1/user?page=${page}&size=${limit}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&isReport=${isReport}&amId=${amId}&rsoId=${rsoId}&exPic=${exPic}&role=${role}`;
};

const CategoryVenueList = () => {
  return `/dashboard/v1/category/venue`;
};

const CategoryMedsosList = () => {
  return `/dashboard/v1/category/medsos`;
};

const BrandCommunityList = () => {
  return `/dashboard/v1/category/brand`;
};

const ProvinceList = (page, limit) => {
  return `/dashboard/v1/province?page=${page}&size=${limit}`;
};

const DistrictList = (provinceId, page, limit) => {
  return `/dashboard/v1/${provinceId}/district?page=${page}&size=${limit}`;
};

const AllDistrictList = (page, limit) => {
  return `/dashboard/v1/district?page=${page}&size=${limit}`;
};

const SubDistrictList = (districtId, page, limit) => {
  return `/dashboard/v1/${districtId}/subdistrict?page=${page}&size=${limit}`;
};

const VillageList = (subDistrictId, page, limit) => {
  return `/dashboard/v1/${subDistrictId}/village?page=${page}&size=${limit}`;
};

const CreateUser = () => {
  return `/dashboard/v1/user`;
};

const InputUser = () => {
  return `/dashboard/v1/user/input`;
};

const RolesList = () => {
  return `/dashboard/v1/role`;
};

const DeleteUser = (id) => {
  return `/dashboard/v1/user/${id}`;
};

const CommunityList = (page, limit, keyword = "", startDate = "", endDate = "", isReport = false, brand = "", category = "", status = "", dsoId = "") => {
  return `/dashboard/v1/community?page=${page}&size=${limit}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&isReport=${isReport}&brand=${brand}&category=${category}&status=${status}&dsoId=${dsoId}`;
};

const uploadMedia = () => {
  return `/dashboard/v1/image/upload`;
};

const CreateCommunity = () => {
  return `/dashboard/v1/community`;
};

const InputCommunity = () => {
  return `/dashboard/v1/community/input`;
};

const DeleteBrand = (id) => {
  return `/dashboard/v1/brand/${id}`;
};

const DeleteVenue = (id) => {
  return `/dashboard/v1/venue/${id}`;
};

const DeleteCommunity = (id) => {
  return `/dashboard/v1/community/${id}`;
};

const InfoCommunityList = (type, page, limit, keyword = "", dsoId = "", startDate = "", endDate = "", isReport = false) => {
  return `/dashboard/v1/community-insight/${type}?page=${page}&size=${limit}&keyword=${keyword}&dsoId=${dsoId}&startDate=${startDate}&endDate=${endDate}&isReport=${isReport}`;
};

const VerifyInfoCommunity = (id) => {
  return `/dashboard/v1/community-insight/${id}/verify`;
};

const PostList = (page, limit, keyword = "", role = "", dsoId = "") => {
  return `/dashboard/v1/post-activity?page=${page}&size=${limit}&keyword=${keyword}&role=${role}&dsoId=${dsoId}`;
};

const DeletePost = (id) => {
  return `/dashboard/v1/post-activity/${id}`;
};

const VerifyPost = (id) => {
  return `/dashboard/v1/post-activity/${id}/verify`;
};

const DRPList = (page, limit, keyword = "", dsoId = "") => {
  return `/dashboard/v1/drp?page=${page}&size=${limit}&keyword=${keyword}&dsoId=${dsoId}`;
};

const CreateDRP = () => {
  return `/dashboard/v1/drp`;
};

const CategoryDRPList = () => {
  return `/dashboard/v1/category/drp`;
};

const DeleteDRP = (id) => {
  return `/dashboard/v1/drp/${id}`;
};

const UpdateSalesTarget = (id) => {
  return `/dashboard/v1/sales-target/${id}`;
};

const DeleteSalesTarget = (id) => {
  return `/dashboard/v1/sales-target/${id}`;
};

const CategoryCommunityList = () => {
  return `/dashboard/v1/category/community`;
};

const CategoryEventList = () => {
  return `/dashboard/v1/category/event`;
};

const AllSubDistrictList = (page, limit) => {
  return `/dashboard/v1/subdistrict?page=${page}&size=${limit}`;
};

const AllVillagesList = (page, limit) => {
  return `/dashboard/v1/village?page=${page}&size=${limit}`;
};

const UpdateUser = (id) => {
  return `/dashboard/v1/user/${id}`;
};

const CreatePostList = () => {
  return `/dashboard/v1/post-activity`;
};

const EventList = (page, limit, keyword = "", brand = "", category = "", dsoId = "", group="") => {
  return `/dashboard/v1/event-activity?page=${page}&size=${limit}&keyword=${keyword}&brand=${brand}&category=${category}&dsoId=${dsoId}&group=${group}`;
};

const CreateEventList = () => {
  return `/dashboard/v1/event-activity`;
};

const DeleteEvent = (id) => {
  return `/dashboard/v1/event-activity/${id}`;
};

const UpdateEvent = (id) => {
  return `/dashboard/v1/event-activity/${id}`;
};

const CreateProject = () => {
  return `/dashboard/v1/project`;
};

const CreateSubProject = () => {
  return `/dashboard/v1/sub-project`;
};

const UpdateCommunityList = (id) => {
  return `/dashboard/v1/community/${id}`;
};

const UpdateBrand = (id) => {
  return `/dashboard/v1/brand/${id}`;
};

const UpdateVenue = (id) => {
  return `/dashboard/v1/venue/${id}`;
};

const UpdateDRPList = (id) => {
  return `/dashboard/v1/drp/${id}`;
};

const EventEvidenceList = (page, limit, keyword = "") => {
  return `/dashboard/v1/event-evidence?page=${page}&size=${limit}&keyword=${keyword}`;
};

const UpdateProject = (id) => {
  return `/dashboard/v1/project/${id}`;
};

const UpdateSubProject = (id) => {
  return `/dashboard/v1/sub-project/${id}`;
};

const ReportScores = (dso, brand, category) => {
  return `/dashboard/v1/formula/scoring?dso=${dso}&brandId=${brand}&category=${category}`;
};

const CommunityKeyKPI = (dsoId, year) => {
  return `/dashboard/v1/communityKeyKPI?dsoId=${dsoId}&year=${year}`;
};

const ReportActivityKey = (dso, brand, category, score, periodeScoring) => {
  const brandQuery = brand ? `&brandId=${brand}` : "";
  const categoryQuery = category ? `&category=${category}` : "";
  const periodeScoringQuery = periodeScoring ? `&periodeScoring=${periodeScoring}` : "";

  return `/dashboard/v1/matrix/list-dtk-khk?dso=${dso}${categoryQuery}${brandQuery}&score=${score}${periodeScoringQuery}`;
};

const ReportActivityKeyByDate = (dso, brand, category, score, date) => {
  const brandQuery = brand ? `&brandId=${brand}` : "";
  const categoryQuery = category ? `&category=${category}` : "";
  const dateQuery = date ? `&dateInput=${date}` : "";
  const scoreQuery = score ? `&score=${score}` : "";

  console.log(`/dashboard/v1/matrix/list-dtk-khk-bydate?dso=${dso}${categoryQuery}${brandQuery}${scoreQuery}${dateQuery}`)

  return `/dashboard/v1/matrix/list-dtk-khk-bydate?dso=${dso}${categoryQuery}${brandQuery}${scoreQuery}${dateQuery}`;
};

const ReportKisi = (dso, brand, category, periodeScoring) => {
  const brandQuery = brand ? `&brandId=${brand}` : "";
  const categoryQuery = category ? `&category=${category}` : "";
  const periodeScoringQuery = periodeScoring ? `&periodeScoring=${periodeScoring}` : "";

  return `/dashboard/v1/matrix/kisi?dso=${dso}${categoryQuery}${brandQuery}${periodeScoringQuery}`;
};

const ReportKisiScore = (dso, brand, category, periodeScoring) => {
  const brandQuery = brand ? `&brandId=${brand}` : "";
  const categoryQuery = category ? `&category=${category}` : "";
  const periodeScoringQuery = periodeScoring ? `&periodeScoring=${periodeScoring}` : "";

  return `/dashboard/v1/matrix/kisi-score?dso=${dso}${categoryQuery}${brandQuery}${periodeScoringQuery}`;
};

const InputDtkKhk = () => {
  return `/dashboard/v1/formula/input`;
};

const AreaManagerAMList = (page, limit, keyword = "") => {
  return `/dashboard/v1/area?page=${page}&size=${limit}&keyword=${keyword}`;
};

const UserDashboard = (year) => {
  return `/dashboard/v1/reporting/user/${year}`;
};

const CommunityDashboard = (area) => {
  return `/dashboard/v1/reporting/community/${area}`;
};

const CommunityCategoryDashboard = () => {
  return `/dashboard/v1/reporting/community/category`;
};

const SalesDashboard = (year) => {
  return `/dashboard/v1/reporting/sales/${year}`;
};

const AreaManagerDSOList = (page, limit, keyword = "") => {
  return `/dashboard/v1/area/management?page=${page}&size=${limit}&keyword=${keyword}`;
};

const CreateAreaManagerAM = () => {
  return `/dashboard/v1/area`;
};

const UpdateAreaManagerAM = (id) => {
  return `/dashboard/v1/area/${id}`;
};

const DeleteAreaManagerAM = (id) => {
  return `/dashboard/v1/area/${id}`;
};

const DeleteAreaManagerDSO = (id) => {
  return `/dashboard/v1/area/management/${id}`;
};

const CreateAreaManagerDSO = () => {
  return `/dashboard/v1/area/management`;
};

const UpdateAreaManagerDSO = (id) => {
  return `/dashboard/v1/area/management/${id}`;
};

const FilterUserAM = (role, page, limit, keyword = "", dsoId = "") => {
  return `/dashboard/v1/user/role/${role}?page=${page}&size=${limit}&keyword=${keyword}&dsoId=${dsoId}`;
};

const VerifyCommunity = (id) => {
  return `/dashboard/v1/community/${id}/verify`;
};

const AttributeList = (page, limit, keyword = "", dsoId = "") => {
  return `/dashboard/v1/attribute?page=${page}&size=${limit}&keyword=${keyword}&dsoId=${dsoId}`;
};

const AttributeDetail = (id) => {
  return `/dashboard/v1/attribute/${id}`;
};

const UpdateAttribute = (id) => {
  return `/dashboard/v1/attribute/${id}`;
};

const CreateAttribute = () => {
  return `/dashboard/v1/attribute`;
};

const DeleteAttribute = (id) => {
  return `/dashboard/v1/attribute/${id}`;
};

const VerifyAttribute = (id) => {
  return `/dashboard/v1/attribute/${id}/verify`;
};

const AchievementList = (page, limit, keyword = "", dsoId = "") => {
  return `/dashboard/v1/achievement?page=${page}&size=${limit}&keyword=${keyword}&dsoId=${dsoId}`;
};

const VerifyAchievement = (id) => {
  return `/dashboard/v1/achievement/${id}/verify`;
};

const UpdateAchievementById = (id) => {
  return `/dashboard/v1/achievement/${id}`;
};

const CreateAchievement = () => {
  return `/dashboard/v1/achievement`;
};

const DeleteAchievementById = (id) => {
  return `/dashboard/v1/achievement/${id}`;
};

const AchievementDetail = (id) => {
  return `/dashboard/v1/achievement/${id}`;
};

const SetSalesTarget = (communityId, page, limit, keyword = "") => {
  return `/dashboard/v1/sales-target/${communityId}/evidence?page=${page}&size=${limit}&keyword=${keyword}`;
};

const ConfirmSales = (id) => {
  return `/dashboard/v1/sales-target/evidence/${id}/confirm`;
};

const AreaManagerRSOList = (page, limit, keyword = "") => {
  return `/dashboard/v1/region?page=${page}&size=${limit}&keyword=${keyword}`;
};

const CreateAreaManagerRSO = () => {
  return `/dashboard/v1/region`;
};

const UpdateAreaManagerRSO = (id) => {
  return `/dashboard/v1/region/${id}`;
};

const DeleteAreaManagerRSO = (id) => {
  return `/dashboard/v1/region/${id}`;
};

const AllNotificationsPreviewList = () => {
  return `/dashboard/v1/notification/preview`;
};

const AllNotificationsList = (page, limit, keyword = "") => {
  return `/dashboard/v1/notification?page=${page}&size=${limit}&keyword=${keyword}`;
};

const MemberList = (communityId, page, limit, keyword, isReport) => {
  return `/dashboard/v1/community/${communityId}/member?page=${page}&size=${limit}&keyword=${keyword}&isReport=${isReport}`;
};

const FreezeCommunity = (id) => {
  return `/dashboard/v1/community/${id}/freeze`;
};

const BrandDetail = (id) => {
  return `/dashboard/v1/brand/${id}`;
};

const VenueDetail = (id) => {
  return `/dashboard/v1/venue/${id}`;
};

const SalesDetail = (id) => {
  return `/dashboard/v1/sales-target/${id}`;
};

const DRPDetail = (id) => {
  return `/dashboard/v1/drp/${id}`;
};

const RSODetail = (id) => {
  return `/dashboard/v1/region/${id}`;
};

const AMDetail = (id) => {
  return `/dashboard/v1/area/${id}`;
};

const DSODetail = (id) => {
  return `/dashboard/v1/area/management/${id}`;
};

const UserDetail = (id) => {
  return `/dashboard/v1/user/${id}`;
};

const CommunityDetail = (id) => {
  return `/dashboard/v1/community/${id}`;
};

const EventDetail = (id) => {
  return `/dashboard/v1/event-activity/${id}`;
};

const PostCommentDetail = (id, page, limit, keyword = "") => {
  return `/dashboard/v1/post-activity/${id}/comment?page=${page}&size=${limit}&keyword=${keyword}`;
};

const PostDetail = (id) => {
  return `/dashboard/v1/post-activity/${id}`;
};

const ProjectSettings = (id, page, limit, keyword = "") => {
  return `/dashboard/v1/project/${id}/setting?page=${page}&size=${limit}&keyword=${keyword}`;
};

const CreateProjectSettings = () => {
  return `/dashboard/v1/project/setting`;
};

const ProductList = () => {
  return `/dashboard/v1/product`;
};

const EngagementList = (year, type, size) => {
  return `/dashboard/v1/reporting/engagement/${year}/${type}/top/${size}`;
};

const UpdateProjectSettings = (projectId, settingId) => {
  return `/dashboard/v1/project/${projectId}/setting/${settingId}`;
};

const DeleteProjectSettings = (projectId, settingId) => {
  return `/dashboard/v1/project/${projectId}/setting/${settingId}`;
};

const GetDashboarTotalCommunity = () => {
  return `/dashboard/v1/reporting/community/total`;
};

const GetCommunityByArea = (area) => {
  return `/dashboard/v1/reporting/community/brand/area?area=${area}`;
};

const GetBrandByDSO = (dso) => {
  return `/dashboard/v1/reporting/community/brand/dso?dso=${dso}`;
};

const GetBrandByStatusDSO = (dso) => {
  return `/dashboard/v1/reporting/community/brand/dso/stats?dso=${dso}`;
};

const GetBrandByAgeDSO = (dso) => {
  return `/dashboard/v1/reporting/community/age/dso?dso=${dso}`;
};

const GetBrandByCollabDSO = (dso) => {
  return `/dashboard/v1/reporting/community/collaboration/dso?dso=${dso}`;
};

const GetDashboardCardMember = () => {
  return `/dashboard/v1/reporting/member/quick-stats`;
};

const GetMemberActiveByArea = (area) => {
  return `/dashboard/v1/reporting/member/active/brand/area?area=${area}`;
};

const GetMemberActiveByDSO = (dso) => {
  return `/dashboard/v1/reporting/member/active/brand/dso?dso=${dso}`;
};

const GetMemberActiveByAge = (dso) => {
  return `/dashboard/v1/reporting/member/age/dso?dso=${dso}`;
};

const GetMemberActiveStatsByDSO = (dso) => {
  return `/dashboard/v1/reporting/member/active/brand/dso/stats?dso=${dso}`;
};

const GetMemberInsightByDSO = (dso) => {
  return `/dashboard/v1/reporting/member/insight/dso?dso=${dso}`;
};

const GetMemberAchievmentByDSO = (dso) => {
  return `/dashboard/v1/reporting/reach/member?dso=${dso}`;
};

const GetSalesAchievmentByDSO = (dso) => {
  return `/dashboard/v1/reporting/reach/sales?dso=${dso}`;
};

const GeteventStatByDSO = (dso) => {
  return `/dashboard/v1/reporting/activity/event/dso/stats?dso=${dso}`;
};

const GetEventSharingByDSO = (dso) => {
  return `/dashboard/v1/reporting/activity/information-sharing/dso/stats?dso=${dso}`;
};

const GetAtributeByDSO = (dso) => {
  return `/dashboard/v1/reporting/communication/attribute/cobranding/dso/stats?dso=${dso}`;
};

const GetPeriodSales = (dso, year, brand) => {
  return `/dashboard/v1/reporting/reach/sales/period?dso=${dso}&year=${year}&brandCommunity=${brand}`;
};

const GetPostByDSO = (dso) => {
  return `/dashboard/v1/reporting/communication/post/dso/stats?dso=${dso}`;
};

// Medsos DSO
const MedsosDSOList = (page, limit, keyword = "", dsoId = "") => {
  return `/dashboard/v1/medsos-dso?page=${page}&size=${limit}&keyword=${keyword}&dsoId=${dsoId}`;
};

const CreateMedsosDSO = () => {
  return `/dashboard/v1/medsos-dso`;
};

const MedsosDSODetail = (id) => {
  return `/dashboard/v1/medsos-dso/${id}`;
};

const MedsosKolaborasi = () => {
  return `/dashboard/v1/medsos-dso/kolaborasi`;
};

const UpdateMedsosDSO = (id) => {
  return `/dashboard/v1/medsos-dso/${id}`;
};

const DeleteMedsosDSO = (id) => {
  return `/dashboard/v1/medsos-dso/${id}`;
};

// Medsos Follower
const MedsosFollowerList = (page, limit, dsoId = "") => {
  return `/dashboard/v1/medsos-follower?page=${page}&size=${limit}&dsoId=${dsoId}`;
};

const CreateMedsosFollower = () => {
  return `/dashboard/v1/medsos-follower`;
};

const MedsosFollowerDetail = (id) => {
  return `/dashboard/v1/medsos-follower/${id}`;
};

const UpdateMedsosFollower = (id) => {
  return `/dashboard/v1/medsos-follower/${id}`;
};

const DeleteMedsosFollower = (id) => {
  return `/dashboard/v1/medsos-follower/${id}`;
};

// Follower Medsos (Community Following Medsos DSO)
const FollowerMedsosList = (page, limit, keyword = "") => {
  return `/dashboard/v1/medsos-follower-list?page=${page}&size=${limit}&keyword=${keyword}`;
};

const CreateFollowerMedsos = () => {
  return `/dashboard/v1/medsos-follower-list`;
};

const FollowerMedsosDetail = (id) => {
  return `/dashboard/v1/medsos-follower-list/${id}`;
};

const UpdateFollowerMedsos = (id) => {
  return `/dashboard/v1/medsos-follower-list/${id}`;
};

const DeleteFollowerMedsos = (id) => {
  return `/dashboard/v1/medsos-follower-list/${id}`;
};

const FollowerMedsosListByMedfol = (medfol, page, limit, keyword = "") => {
  return `/dashboard/v1/medsos-follower-list/medsos-follower/${medfol}?page=${page}&size=${limit}&keyword=${keyword}`;
};

// Input Target KPI
const KpiTargetList = (page, limit, keyword = "", year= "") => {
  return `/dashboard/v1/kpi-target?page=${page}&size=${limit}&keyword=${keyword}&year=${year}`;
};

const KpiTargetDetail = (id) => {
  return `/dashboard/v1/kpi-target/${id}`;
};

const CreateKpiTarget = () => {
  return `/dashboard/v1/kpi-target`;
};

const UpdateKpiTarget = (id) => {
  return `/dashboard/v1/kpi-target/${id}`;
};

const DeleteKpiTarget = (id) => {
  return `/dashboard/v1/kpi-target/${id}`;
};

const InputKPIData = () => {
  return `/dashboard/v1/insert-kpi`;
};
const PerformanceList = (communityId, year = "") => {
  return `/dashboard/v1/medsos-performance?communityId=${communityId}&year=${year}`;
};
const CreatePerformance = () => {
  return `/dashboard/v1/medsos-performance`;
};
const DeletePerformance = (id) => {
  return `/dashboard/v1/medsos-performance/${id}`;
};
const UpdatePerformance = (id) => {
  return `/dashboard/v1/medsos-performance/${id}`;
};
const PerformanceDetail = (id) => {
  return `/dashboard/v1/medsos-performance/${id}`;
};

// Big Event Community
const EventCommunityList = (page, limit, keyword = "", brand = "", category = "", dsoId = "") => {
  return `/dashboard/v1/big-event-community?page=${page}&size=${limit}&keyword=${keyword}&brand=${brand}&category=${category}&dsoId=${dsoId}`;
};

const EventCommunityDetail = (id) => {
  return `/dashboard/v1/big-event-community/${id}`;
};

const CreateEventCommunity = () => {
  return `/dashboard/v1/big-event-community`;
};

const UpdateEventCommunity = (id) => {
  return `/dashboard/v1/big-event-community/${id}`;
};

const DeleteEventCommunity = (id) => {
  return `/dashboard/v1/big-event-community/${id}`;
};

// Review Activity
const EventReviewDetail = (id) => {
  return `/dashboard/v1/review-activity/${id}`;
};

const CreateEventReview = () => {
  return `/dashboard/v1/review-activity`;
};

const UpdateEventReview = (id) => {
  return `/dashboard/v1/review-activity/${id}`;
};

const DeleteEventReview = (id) => {
  return `/dashboard/v1/review-activity/${id}`;
};

const PostCommunityList = (page, limit, keyword = "") => {
  return `/dashboard/v1/post-activity-community?page=${page}&size=${limit}&keyword=${keyword}`;
};

const PostCommunityDetail = (id) => {
  return `/dashboard/v1/post-activity-community/${id}`;
};

const PostCommunityCommentDetail = (id, page, limit, keyword = "") => {
  return `/dashboard/v1/post-activity-community/${id}/comment?page=${page}&size=${limit}&keyword=${keyword}`;
};

const CreatePostCommunityList = () => {
  return `/dashboard/v1/post-activity-community`;
};

const DeletePostCommunity = (id) => {
  return `/dashboard/v1/post-activity-community/${id}`;
};

// input consumption
const CreateInputConsumption = () => {
  return `/dashboard/v1/profile/consumption`;
};

const UpdateInputConsumption = (id) => {
  return `/dashboard/v1/profile/consumption/${id}`;
};

const DeleteInputConsumption = (id) => {
  return `/dashboard/v1/profile/consumption/${id}`;
};

const getListInputConsumption = ({ page, size, keyword, targetId }) => `/dashboard/v1/sales-target-meta?page=${page}&size=${size}&keyword=${keyword}&targetId=${targetId}`;

const getSalesPercentage = (targetId) => `/dashboard/v1/sales-target-percentage/${targetId}`;

// Community Achievement
const getCommunityAchievement = (dsoId, year) => {
  return `/dashboard/v1/communityAchievement?dsoId=${dsoId}&year=${year}`;
};

// total Event
const getTotalEvent = (dsoId, year) => {
  return `/dashboard/v1/totalEvent?dsoId=${dsoId}&year=${year}`;
};

// Get Consumption KPI Chart
// Community Achievement
const getConsumptionKPIChart = (dsoId, year) => {
  return `/dashboard/v1/consumptionKPI?dsoId=${dsoId}&year=${year}`;
};

const getConsumptionCategoryKPIChart = (dsoId, year) => {
  return `/dashboard/v1/consumptionPerCategoryKPI?dsoId=${dsoId}&year=${year}`;
};

// Competency
const CompetencyList = (dsoId, year, workshop, keyword = "") => {
  return `/dashboard/v1/competency?dsoId=${dsoId}&year=${year}&workshop=${workshop}&keyword=${keyword}`;
};

const CompetencyDetail = (id) => {
  return `/dashboard/v1/competency/${id}`;
};

const CreateCompetency = () => {
  return `/dashboard/v1/competency`;
};

const UpdateCompetency = (id) => {
  return `/dashboard/v1/competency/${id}`;
};

const DeleteCompetency = (id) => {
  return `/dashboard/v1/competency/${id}`;
};

const getCompetencyKpi = (dsoId, year) => {
  return `/dashboard/v1/competencyKPI?dsoId=${dsoId}&year=${year}`;
};

const getCompetencyOverview = (page = 1, limit = 4, year = 2022) => {
  return `/dashboard/v1/competencyKPIOverview?page=${page}&size=${limit}&year=${year}`;
};

const getMedsosDsoKPI = (dsoId, year) => {
  return `/dashboard/v1/medsosKPI?dsoId=${dsoId}&year=${year}`;
};

const getPostCommunityKPI = (dsoId, year) => {
  return `/dashboard/v1/postCommunityKPI?dsoId=${dsoId}&year=${year}`;
};

const getTargetMedsosKPI = (dsoId, year) => {
  return `/dashboard/v1/targetMedsosKPI?dsoId=${dsoId}&year=${year}`;
};

const getSummaryKpi = (page, size, year) => {
  return `/dashboard/v1/summaryKPI?page=${page}&size=${size}&year=${year}`;
};

const getCommunityFollowed = (dsoId, year) => {
  return `/dashboard/v1/communityFollowed?dsoId=${dsoId}&year=${year}`;
};

const CommunityCategoryList = (page, limit, keyword = "") => {
  return `/dashboard/v1/community-category?page=${page}&size=${limit}&keyword=${keyword}`;
};

const CreateCommunityCategory = () => {
  return `/dashboard/v1/community-category`;
};

const UpdateCommunityCategory = (id) => {
  return `/dashboard/v1/community-category/${id}`;
};

const DeleteCommunityCategory = (id) => {
  return `/dashboard/v1/community-category/${id}`;
};

const DetailCommunityCategory = (id) => {
  return `/dashboard/v1/community-category/${id}`;
};

const PostReportList = (page = 1, limit = 10, keyword = "") => {
  return `/dashboard/v1/post-activity/report?page=${page}&size=${limit}&keyword=${keyword}`;
};

const UserReportList = (page = 1, limit = 10, keyword = "") => {
  return `/dashboard/v1/post-activity/user-report?page=${page}&size=${limit}&keyword=${keyword}`;
};

module.exports = {
  Login,
  Logout,
  ChangePassword,
  Projects,
  BrandList,
  CreateBrand,
  VenueList,
  CreateVenue,
  SalesTargetList,
  UserList,
  CategoryVenueList,
  CategoryMedsosList,
  ProvinceList,
  DistrictList,
  SubDistrictList,
  VillageList,
  CreateUser,
  AllDistrictList,
  RolesList,
  DeleteUser,
  CommunityList,
  uploadMedia,
  CreateCommunity,
  DeleteBrand,
  DeleteVenue,
  DeleteCommunity,
  InfoCommunityList,
  VerifyInfoCommunity,
  PerformanceList,
  CreatePerformance,
  DeletePerformance,
  UpdatePerformance,
  PerformanceDetail,
  CreateSalesTarget,
  PostList,
  DeletePost,
  DRPList,
  CreateDRP,
  CategoryDRPList,
  DeleteDRP,
  UpdateSalesTarget,
  DeleteSalesTarget,
  CategoryCommunityList,
  CategoryEventList,
  AllSubDistrictList,
  AllVillagesList,
  UpdateUser,
  CreatePostList,
  EventList,
  CreateEventList,
  DeleteEvent,
  UpdateEvent,
  CreateProject,
  SubProjects,
  CreateSubProject,
  UpdateCommunityList,
  UpdateBrand,
  UpdateVenue,
  UpdateDRPList,
  EventEvidenceList,
  UpdateProject,
  UpdateSubProject,
  ReportScores,
  ReportActivityKey,
  ReportKisi,
  ReportKisiScore,
  AreaManagerAMList,
  UserDashboard,
  CommunityDashboard,
  CommunityCategoryDashboard,
  SalesDashboard,
  AreaManagerDSOList,
  CreateAreaManagerAM,
  UpdateAreaManagerAM,
  DeleteAreaManagerAM,
  DeleteAreaManagerDSO,
  CreateAreaManagerDSO,
  UpdateAreaManagerDSO,
  FilterUserAM,
  VerifyCommunity,
  AttributeList,
  VerifyAttribute,
  CreateAttribute,
  UpdateAttribute,
  AttributeDetail,
  DeleteAttribute,
  AchievementList,
  VerifyAchievement,
  UpdateAchievementById,
  CreateAchievement,
  DeleteAchievementById,
  AchievementDetail,
  SetSalesTarget,
  ConfirmSales,
  AreaManagerRSOList,
  CreateAreaManagerRSO,
  UpdateAreaManagerRSO,
  DeleteAreaManagerRSO,
  AllNotificationsPreviewList,
  AllNotificationsList,
  MemberList,
  FreezeCommunity,
  BrandDetail,
  VenueDetail,
  SalesDetail,
  DRPDetail,
  RSODetail,
  AMDetail,
  DSODetail,
  UserDetail,
  CommunityDetail,
  EventDetail,
  PostCommentDetail,
  PostDetail,
  VerifyPost,
  ProjectSettings,
  CreateProjectSettings,
  ProductList,
  EngagementList,
  UpdateProjectSettings,
  DeleteProjectSettings,
  GetDashboarTotalCommunity,
  GetCommunityByArea,
  GetBrandByDSO,
  GetBrandByStatusDSO,
  GetBrandByAgeDSO,
  GetBrandByCollabDSO,
  GetDashboardCardMember,
  GetMemberActiveByArea,
  GetMemberActiveByDSO,
  GetMemberActiveByAge,
  GetMemberActiveStatsByDSO,
  GetMemberInsightByDSO,
  GetMemberAchievmentByDSO,
  GetSalesAchievmentByDSO,
  GeteventStatByDSO,
  GetEventSharingByDSO,
  GetAtributeByDSO,
  GetPeriodSales,
  GetPostByDSO,
  MedsosDSOList,
  MedsosDSODetail,
  MedsosKolaborasi,
  CreateMedsosDSO,
  UpdateMedsosDSO,
  DeleteMedsosDSO,
  MedsosFollowerList,
  MedsosFollowerDetail,
  UpdateMedsosFollower,
  CreateMedsosFollower,
  DeleteMedsosFollower,
  FollowerMedsosList,
  FollowerMedsosDetail,
  CreateFollowerMedsos,
  UpdateFollowerMedsos,
  DeleteFollowerMedsos,
  FollowerMedsosListByMedfol,
  KpiTargetList,
  KpiTargetDetail,
  CreateKpiTarget,
  UpdateKpiTarget,
  DeleteKpiTarget,
  EventCommunityList,
  EventCommunityDetail,
  CreateEventCommunity,
  UpdateEventCommunity,
  DeleteEventCommunity,
  EventReviewDetail,
  CreateEventReview,
  UpdateEventReview,
  DeleteEventReview,
  PostCommunityList,
  CreateInputConsumption,
  UpdateInputConsumption,
  DeleteInputConsumption,
  getListInputConsumption,
  getSalesPercentage,
  PostCommunityDetail,
  PostCommunityCommentDetail,
  CreatePostCommunityList,
  DeletePostCommunity,
  getCommunityAchievement,
  getTotalEvent,
  getConsumptionKPIChart,
  getConsumptionCategoryKPIChart,
  getCompetencyKpi,
  getCompetencyOverview,
  CompetencyList,
  CompetencyDetail,
  CreateCompetency,
  UpdateCompetency,
  DeleteCompetency,
  getMedsosDsoKPI,
  getTargetMedsosKPI,
  getSummaryKpi,
  getCommunityFollowed,
  BrandCommunityList,
  CommunityKeyKPI,
  CommunityCategoryList,
  CreateCommunityCategory,
  UpdateCommunityCategory,
  DeleteCommunityCategory,
  DetailCommunityCategory,
  PostReportList,
  UserReportList,
  InputDtkKhk,
  InputKPIData,
  InputCommunity,
  InputUser,
  getPostCommunityKPI,
  ReportActivityKeyByDate
};
