<template>
  <v-dialog v-model="isShow" width="500" scrollable>
    <v-card>
      <v-card-title class="headline grey lighten-2"> Detil Prestasi </v-card-title>
      <v-card-text>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>ID</v-list-item-title>
            <v-list-item-subtitle>{{ data.id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Nama Prestasi</v-list-item-title>
            <v-list-item-subtitle>{{ data.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Tahun</v-list-item-title>
            <v-list-item-subtitle>{{ data.year }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Skala</v-list-item-title>
            <v-list-item-subtitle>{{ data.scale }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

         <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Nominasi</v-list-item-title>
            <v-list-item-subtitle>{{ data.nomination }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.user">
          <v-list-item-content>
            <v-list-item-title>Pengguna</v-list-item-title>
            <v-list-item-subtitle>{{ data.user.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.community">
          <v-list-item-content>
            <v-list-item-title>Nama Komunitas</v-list-item-title>
            <v-list-item-subtitle>{{ data.community.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.community">
          <v-list-item-content>
            <v-list-item-title>DSO</v-list-item-title>
            <v-list-item-subtitle>{{ data.community.dso.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.community">
          <v-list-item-content>
            <v-list-item-title>Area Komunitas</v-list-item-title>
            <v-list-item-subtitle>{{ data.community.area }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.community">
          <v-list-item-content>
            <v-list-item-title>Kategori Komunitas</v-list-item-title>
            <v-list-item-subtitle>{{ data.community.category }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>


         <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Kontrak</v-list-item-title>
            <v-list-item-subtitle>{{ data.isContract ? 'Yes' : 'No' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Terverifikasi</v-list-item-title>
            <v-list-item-subtitle>{{ data.isVerified ? 'Yes' : 'No' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item three-line v-if="data.image">
          <v-list-item-content>
            <v-list-item-title>Gambar</v-list-item-title>
            <v-list-item-subtitle>
              <v-img
                :src="data.image"
                :lazy-src="data.image"
                class="grey lighten-2"
                />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Dibuat Pada</v-list-item-title>
            <v-list-item-subtitle>{{
              data.createdAt | getNormalDate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Diperbaharui Pada</v-list-item-title>
            <v-list-item-subtitle>{{
              data.updatedAt | getNormalDate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="closeModal"> Tutup </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatDate } from "../../external/functions/Utilization.js";
export default {
  name: "user-detail",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit("hide", false);
    },
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },
};
</script>