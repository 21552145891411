<template>
    <div>
        <VueApexCharts type="bar" height="200" :options="chartOptions" :series="series" />
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    props:{
        dataArr:{
            type: Array(),
            default: []
        },
        member:{
            type: Array(),
            default: []
        },
    },
    data: () => ({

    // Bar Chart follower per brand
    series: [{
        data:[],
        member:[]
    }],
    chartOptions: {
      chart: {
        type: "bar",
        width: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "50%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors:["#000000","#e2a63d","#e23d3d","#d99494","#34bf41"],
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"],
          fontSize: "15px",
        },
        formatter: function (val, opt) {
          const series = opt.w.globals.series[1]
          const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0);
          const percent = (val / sum) * 100;
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + `${percent.toFixed(0)}%`;
        },
        offsetX: '0px',
        offsetY: -18,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          var member = w.globals.initialSeries[seriesIndex].member[dataPointIndex];
          var persen = 0
          if(member>0){
            persen = Math.round(data / member * 100)
          }

          return '<ul class="pa-1 border">' +
            '<li><b>Follower</b>: ' + data + '</li>' +
            '<li><b>Member Aktif</b>: ' + member + '</li>' +
            '<li><b>Persentase</b>: ' + persen + '% </li>' +
            '</ul>';
        }
      },
      xaxis: {
        categories:["Bold Riders","Super Friends","Heppiii Community","Kill The LAst","Others"],
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
    }
  }),
  components:{
    VueApexCharts
  },
  watch:{
    dataArr(){
        this.series[0].data = []
        this.series[0].member = []
        if(this.dataArr.length>0){

            this.series.push({
                data:this.dataArr,
                member: this.member
            })
        }
    }
  },
  created(){
    this.series[0].data = []
    this.series[0].member = []
    if(this.dataArr.length>0){

        this.series.push({
            data:this.dataArr,
            member: this.member
        })
    }

  }

}
</script>