<template>
  <v-container class="pt-8">
    <v-row justify="space-between">
      <v-col md="6" sm="12">
        <h2>Follower Media Sosial</h2>
      </v-col>
      <v-col md="3" sm="12">
          <v-btn
            v-if="getRole.role.create"
            class="float-right"
            depressed
            color="primary"
            @click="setMedfol"
            block
          >
            Set Medsos Follower
          </v-btn>
      </v-col>
      <v-col md="3" sm="12">
          <v-btn
            v-if="!getRole.role.filter"
            :disabled="!valid"
            class="float-right"
            depressed
            color="primary"
            @click="createFollower"
            block
          >
            Tambah Folower Medsos
          </v-btn>
      </v-col>
      <!-- <v-col md="3" sm="12">
        <v-text-field
          :disabled="!valid"
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col> -->
      
    </v-row>
    <v-row>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
        <v-autocomplete
          :items="allUserAM"
          v-model="dso"
          item-text="name"
          item-value="id"
          @change="pickArea"
          label="Pilih DSO"
          dense
          block
          right
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col md="3" sm="4">
        <v-autocomplete
            v-model="medsosfollowerId"
            :items="medsosfollowers"
            item-text="category"
            item-value="id"
            label="Medsos DSO"
            @change="changeMedsosFollower"
            dense
            outlined
            left
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else-if="!valid">
          <v-card-text>Medsos Follower Belum Dipilih</v-card-text>
        </v-card>
        <v-card v-else-if="!Array.isArray(followermedsoses)">
          <v-card-text>Follower Medsos Belum Ditambahkan</v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title v-if="Array.isArray(followermedsoses)">
            {{followermedsoses.length>0 ? followermedsoses[0].medfol.medsos.name:''}}
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">DSO</th>
                  <th class="text-left">Nama Komunitas</th>
                  <th class="text-left">Jumlah Follower</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in followermedsoses" :key="item.id">
                  <td>{{item.medfol && item.medfol.dso? item.medfol.dso.name:''}}</td>
                  <td>{{item.community? item.community.name:''}}</td>
                  <td>{{item.follower}}</td>
                  <td v-if="getRole.role.update">
                      <v-btn small icon color="warning" dark @click="edit(item.id)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn small icon color="error" dark @click="deleteMedsosFollower(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12" v-if="valid">
        <span class="mr-4 grey--text"> Tampil 1 dari 1 </span>
        <v-btn dark color="blue darken" class="mr-1">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseMedsosFollower"
    />

    <!-- Error Categories -->
    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    />

    <!-- Error Categories -->
    <Alert
      :status="isErrorGetMedsosDSO && errorCodeGetMedsosDSO !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetMedsosDSO"
    />

    <Alert
      :status="isErrorUpdateMedsosFollower && errorCodeUpdateMedsosFollower !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseUpdateMedsosFollower"
    />

    <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />
  </v-container>
</template>

<script>
import { MedsosFollowerControllers } from "../../controllers/MedsosFollowerControllers";
import { FollowerMedsosControllers } from "../../controllers/FollowerMedsosControllers";
import { UserControllers } from "../../controllers/UserControllers";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import { getRole, getUserId } from "../../external/functions/Cookies";

export default {
  name: "create-user",
  data: () => ({
    followermedsos: new FollowerMedsosControllers(),
    medsosfollower: new MedsosFollowerControllers(),
    userControllers: new UserControllers(),
    medsosfollowerId: '',
    valid:false,
    dso: '',

    followermedsosTable: {
      page: 1,
      limit: 3,
      keyword: "",
    },

    pages: {
      start: 1,
      end: 2,
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    isLoading: false,
    fullPage: true,

    search: {
      debounce: null,
    },

    searchField: "",
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    if(getRole() !== 'admin.dso') {
      this.userControllers.getUserAMList('admin.dso', 1, 1000, '')  
    }
  },

  mounted() {
    if(getRole() === 'admin.dso') {
    this.dso = getUserId()
    }

    this.getMedsosFollower();
  },

  watch:{
    allUserAM(newVal) {
      if(newVal.length) {
        this.dso = newVal[0].id
      }
    },
    dso(){
      this.getMedsosFollower()
    },
    medsosfollowers(value){
      if(value.length==0){
        this.medsosfollowerId = ''
        this.valid = false
      }else{
        this.medsosfollowerId = value[0].id
        this.valid = true
      }

      
    },
    medsosfollowerId(){
      return this.followermedsos.getFollowerMedsosListByMedfol(
        this.medsosfollowerId,
        this.followermedsosTable.page,
        this.followermedsosTable.limit,
        this.followermedsosTable.keyword
      )
    },
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    allUserAM() {
      return this.userControllers.userListAM
    },

    ...mapGetters({
      medfolId: "setMedfol/medfol",
    }),


    medsosfollowers() {
      const medfol = this.medsosfollower.medsosfollowerList;

      if(medfol){
        return medfol
      }
      return []
    },

    isErrorCodeGetMedsosFollower() {
      return this.medsosfollower.errorCode;
    },

    isErrorGetMedsosFollower() {
      return this.medsosfollower.isError;
    },

    errorCauseGetMedsosFollower() {
      return this.medsosfollower.errorCause;
    },

    isLoadingGetMedsosFollower() {
      return this.medsosfollower.isLoading;
    },

    // Follower Medsos

    followermedsoses() {
      return this.followermedsos.followermedsosListByMedfol;
    },

    isErrorCodeGetFollowerMedsos() {
      return this.followermedsos.errorCode;
    },

    isErrorGetFollowerMedsos() {
      return this.followermedsos.isError;
    },

    errorCauseGetFollowerMedsos() {
      return this.followermedsos.errorCause;
    },

    isLoadingGetFollowerMedsos() {
      return this.followermedsos.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.followermedsos.isErrorDelete;
    },

    errorCauseDelete() {
      return this.followermedsos.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.followermedsos.isLoading;
    },
  },

  methods: {
    ...mapActions({
      setmedfol: "setMedfol/setmedfol"
    }),

    async getMedsosFollower() {
      return this.medsosfollower.getMedsosFollowerList(1,999,this.dso)
    },

    setMedfolId(){
      this.medsosfollowerId = this.medfolId
    },

    async changeMedsosFollower(){
      this.valid=true
      this.setmedfol({id:this.medsosfollowerId})
      return this.followermedsos.getFollowerMedsosListByMedfol(
        this.medsosfollowerId,
        this.followermedsosTable.page,
        this.followermedsosTable.limit,
        this.followermedsosTable.keyword
      )
    },

    createFollower() {
      this.$router.push({
        name: "Create Follower Social Media",
        params: {
          dsoId: this.dso,
          medfol: this.medsosfollowerId,
        },
      });
    },

    edit(id) {
      this.$router.push({
        name: "Edit Follower Social Media",
        params: {
          dsoId: this.dso,
          medfol:this.medsosfollowerId,
          id: id,
        },
      });
    },

    setMedfol() {
      this.$router.push({
        name: "Set Sosmed Follower",
      });
    },

    deleteMedsosFollower(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.followermedsos.deleteFollowerMedsos(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.followermedsos.getFollowerMedsosListByMedfol(
            this.medsosfollowerId,
            this.followermedsosTable.page,
            this.followermedsosTable.limit,
            this.followermedsosTable.keyword
          );
        });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.medsosfollowerTable.page = 1;
      this.medsosfollowerTable.limit = 10;
      this.medsosfollowerTable.keyword = this.searchField;

      await this.followermedsos.getFollowerMedsosListByMedfol(
            this.medsosfollowerId,
            this.followermedsosTable.page,
            this.followermedsosTable.limit,
            this.followermedsosTable.keyword
          );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.medsosfollowerTable.page = 1;
          this.medsosfollowerTable.limit = 10;
          this.medsosfollowerTable.keyword = event;

          await this.followermedsos.getFollowerMedsosListByMedfol(
            this.medsosfollowerId,
            this.followermedsosTable.page,
            this.followermedsosTable.limit,
            this.followermedsosTable.keyword
          );
        }
      }, 500);
    },
  },
}
</script>
