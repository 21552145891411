<template>
  <v-row>
    <v-col md="12" sm="12">
      <h4>{{ title }}</h4>
    </v-col>
    <v-col md="4" sm="12">
      <v-select
        v-model="filter.year"
        :items="years"
        @change="pickYear"
        label="Ganti Tahun"
        outlined
        dense
      ></v-select>
    </v-col>
    <v-col md="4" sm="12">
      <v-select
        v-model="filter.type"
        :items="types"
        @change="pickTypes"
        label="Ganti Tipe"
        outlined
        dense
      ></v-select>
    </v-col>
    <v-col md="4" sm="12">
      <v-text-field
        v-model="filter.size"
        label="Masukan jumlah data lalu tekan enter/return"
        type="number"
        outlined
        dense
        @keyup.enter="getDashboard"
      ></v-text-field>
    </v-col>
    <v-col md="12" sm="12">
      <v-row v-if="!isLoadingDashboard">
        <v-col md="12" sm="12">
          <v-card v-if="errorCodeGetDashboard === 404">
            <v-card-text>Data Tidak Tersedia</v-card-text>
          </v-card>

          <v-card v-else>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">User</th>
                    <th class="text-left">Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in engagements" :key="index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.count }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col md="12" sm="12">
          <v-card>
            <v-card-text>Memuat Data...</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <!-- Error Dashboard -->
    <Alert
      :status="isErrorGetDashboard && errorCodeGetDashboard !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDashboard"
    />
  </v-row>
</template>
<script>
import Alert from "../../../components/alert/index";
import { EngagementControllers } from "../../../controllers/EngagementControllers";

export default {
  name: "engagement-list",
  data: () => ({
    title: "Engagement",
    dashboard: new EngagementControllers(),
    filter: {
      year: 2021,
      type: "comment",
      size: 5,
    },
    types: ["comment", "like"],
  }),

  components: {
    Alert,
  },

  computed: {
    engagements() {
      return this.dashboard.engagementList;
    },

    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    this.getDashboard();
  },

  methods: {
    getDashboard() {
      return this.dashboard.getEngagementList(
        this.filter.year,
        this.filter.type,
        this.filter.size
      );
    },

    pickYear(event) {
      this.filter.year = event;
      return this.dashboard.getEngagementList(
        event,
        this.filter.type,
        this.filter.size
      );
    },

    pickTypes(event) {
      this.filter.type = event;
      return this.dashboard.getEngagementList(
        this.filter.year,
        event,
        this.filter.size
      );
    }
  },
};
</script>