<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-text-field
                v-model="form.key"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Kategori"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="form.value"
                :rules="[
                  (v) => !!v || 'Kolom wajib diisi',
                  (v) => /^[a-z-]+$/.test(v) || 'Hanya terdiri dari huruf lowercase dan tanda (-)',
                  ]"
                label="Kode"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                  v-model="form.brand"
                  :items="brands"
                  filled
                  chips
                  label="Tambah Brand"
                  item-text="name"
                  item-value="id"
                  multiple
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
              </v-autocomplete>

            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isProjectListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseProjects"
    />

    <Alert
      :status="isErrorProduct && isErrorCodeProduct !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseProduct"
    />
  </v-container>
</template>
<script>
import { CommunityCategoryControllers } from "../../controllers/CommunityCategoryControllers.js";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import Alert from "../../components/alert";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "create-brand",
  data: () => ({
    communityCategory: new CommunityCategoryControllers(),
    brand: new BrandControllers(),
    title: "Buat Kategori Komunitas",
    valid: true,
    form: {
      key: "",
      value: "",
      brand: [],
    },
    brandScope: {
      page: 1,
      limit: 50,
    },
    isLoading: false,
    fullPage: true,
    tag: {
      value: [],
      options: [],
    },
    isSku: true
  }),

  components: {
    Alert,
    Loading
  },

  created() {
    this.getBrandList();
  },

  computed: {
    // brands
    brands() {
      return this.brand.brandList.filter(brand => brand.product == 'Rokok');
    },

    isErrorCodeBrand() {
      return this.brand.errorCode;
    },

    isErrorBrand() {
      return this.brand.isError;
    },

    errorCauseBrand() {
      return this.brand.errorCause;
    },

    isLoadingBrand() {
      return this.brand.isLoading;
    },

    // Community Category
    isErrorCreateCommunityCategory() {
      return this.communityCategory.isError;
    },

    errorCauseCreateCommunityCategory() {
      return this.communityCategory.errorCause;
    },

    isLoadingCreateCommunityCategory() {
      return this.communityCategory.isLoading;
    },
  },

  methods: {
    async getBrandList() {
      return this.brand.getBrandList(
        1,
        50
      );
    },

    back() {
      this.$router.go(-1);
    },

    async submitProcess(
      key,
      value,
      brand
        ) {
      return this.communityCategory.createCommunityCategoryList(
        key,
        value,
        brand
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.key,
          this.form.value,
          this.form.brand
        );

        if (this.isErrorCreateCommunityCategory) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateCommunityCategory,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>