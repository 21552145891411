<template>
  <div>
    <v-container class="pt-9 d-flex flex-column">
      <v-row class="d-flex flex-row" justify="space-between">
        <v-col md="7" sm="12">
          <h2>{{ title }}</h2>
        </v-col>
        <v-col md="3" sm="12">
          <v-autocomplete v-if="getRole.role.filter" :items="allUserAM" v-model="dso" item-text="name" item-value="id" label="Pilih DSO" dense block right></v-autocomplete>
        </v-col>
        <v-col md="2" sm="12">
          <v-btn class="float-right" :loading="isLoadingPDF" :disabled="isLoadingPDF" depressed color="error" @click="pdf">
            Export PDF
            <template v-slot:loader>
              <span>Exporting...</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div id="activity-content-chart">
      <v-container class="pt-5 d-flex flex-column">
        <v-row class="d-flex flex-row">
          <!-- <v-col md="3" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh PDF
        </v-btn>
      </v-col> -->
          <v-col md="12" sm="12">
            <v-card>
              <v-card-text class="py-0">
                <CommunityAchievement :dso="dso" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" sm="12">
            <v-card>
              <v-card-text class="py-0">
                <CommunityTotalKey :dso="dso" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="8" sm="12">
            <v-card>
              <v-card-text class="py-0">
                <Event :dso="dso" />
              </v-card-text>
            </v-card>
          </v-col>

          <!-- <v-col md="4" sm="12" v-if="!getRole.role.summarydso">
        <v-card>
          <v-card-text class="py-0">
            <Competency :dso="dso" />
          </v-card-text>
        </v-card>
      </v-col> -->
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import CommunityAchievement from "./community-achievement/index";
import CommunityTotalKey from "./community-total-key/index";
import { UserControllers } from "../../../controllers/UserControllers";
import Event from "./total-event/index";
// import Competency from "./competency/competency";
import { CommunityControllers } from "../../../controllers/CommunityControllers";
import { getRole, getUserId, getUser } from "../../../external/functions/Cookies";
import { toJpeg } from "html-to-image";
import { jsPDF } from "jspdf";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "activity-report",
  data: () => ({
    title: "Aktivitas",
    userControllers: new UserControllers(),
    communityControllers: new CommunityControllers(),
    dso: null,
    isLoadingPDF: false,
  }),
  components: {
    CommunityTotalKey,
    CommunityAchievement,
    Event,
    // Competency,
  },
  created() {
    if (getRole() !== "admin.dso") {
      this.userControllers.getUserAMList("admin.dso", 1, 1000, "");
    }
  },
  mounted() {
    if (getRole() === "admin.dso") {
      this.dso = getUserId();
    }
  },
  computed: {
    allUserAM() {
      return this.userControllers.userListAM;
    },
    getRole() {
      return this.$route.meta;
    },
    userName() {
      return getUser();
    },

    ...mapGetters({
      dsoId: "setDso/dsoId",
    }),
  },
  watch: {
    allUserAM(newVal) {
      if (newVal.length) {
        this.dso = newVal[0].id;
      }
      if (this.dsoId) {
        this.dso = this.dsoId;
      }
    },
    dso(newVal) {
      this.setDsoId(newVal);
    },
  },
  methods: {
    ...mapActions({
      setDsoId: "setDso/setdsoId",
    }),
    pdf() {
      let dsoChosen = this.allUserAM.find((datum) => datum.id === this.dso)?.name;
      if (dsoChosen == null) {
        dsoChosen = this.userName;
      }
      this.isLoadingPDF = true;
      const self = this;

      setTimeout(() => {
        toJpeg(document.getElementById("activity-content-chart"), { backgroundColor: "#FFFFFF" }).then(function(dataUrl) {
          var doc = new jsPDF();

          doc.setFontSize(24);
          doc.text("Chart KPI Aktivitas", 18, 20);
          doc.setFontSize(8);
          doc.setTextColor("#616161");
          doc.text(`DSO: ${dsoChosen}`, 19, 28);
          doc.addImage(dataUrl, "JPEG", 15, 40, 180, 240);
          doc.save("chart-kpi-aktivitas.pdf");
          self.isLoadingPDF = false;
        });
      }, 500);
    },
  },
};
</script>
