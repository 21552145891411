import axios from "axios";
import { CompetencyList, CreateCompetency, DeleteCompetency, UpdateCompetency, CompetencyDetail, getCompetencyKpi, getCompetencyOverview } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};

export const getCompetencyKpiRequest = (dsoId, year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getCompetencyKpi(dsoId, year)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const getCompetencyOverviewRequest = (page, limit, year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${getCompetencyOverview(page, limit, year)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CompetencyListRequest = (dsoId, year, workshop, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CompetencyList(dsoId, year, workshop, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateCompetencyRequest = (
    dso,
    name,
    workshop,
    score,
    year
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateCompetency()}`,
        data: {
            dso: dso,
            name: name,
            workshop: workshop,
            score: score,
            year: year
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteCompetencyRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteCompetency(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateCompetencyRequest = (
    id,
    name,
    workshop,
    score,
    year
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateCompetency(id)}`,
        data: {
            name,
            workshop,
            score,
            year
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CompetencyDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CompetencyDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}





