import { EventEvidenceListRequest } from "../request/EventEvidence.js";

export class EventEvidenceControllers {
    eventList = new Array();
    eventDetail = new Object();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    constructor() { }

    getEventEvidenceList(page, limit, keyword) {
        this.setLoading(true);
        const resp = EventEvidenceListRequest(page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setEventList(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getDetail(id) {
        this.eventDetail = this.eventList.find(item => {
            return item.id === id
        });
    }

    setEventList(data) {
        this.eventList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

}

