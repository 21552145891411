<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="8">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Daftar Target Konsumsi
        </h2>
      </v-col>
      <!-- <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col> -->
      <v-col>
        <h2>
          {{allConsumptionTarget[0].community.name}}
        </h2>
      </v-col>
    </v-row>

    <v-row >
      <v-col md="12" sm="12">
        <!-- <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card> -->
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Status</th>
                  <th class="text-left">Jumlah Target</th>
                  <th class="text-left">Pencapaian</th>
                  <th class="text-left">Persentase</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in allConsumptionTarget" :key="item.id">
                  <td>
                    {{item.status}}
                  </td>
                  <td >
                    {{item.goal}}
                  </td>
                  <td>
                    {{item.reach}}
                  </td>
                  <td>
                    {{item.percentage}}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <!-- <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col> -->
    </v-row>

    <!-- <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row> -->

    <!-- <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isError && errorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause" -->
    <!-- /> -->
  </v-container>
</template>
<script>
// import Alert from "../../components/alert";
// import Loading from "vue-loading-overlay";
// import { formatDate } from "../../external/functions/Utilization.js";
import "vue-loading-overlay/dist/vue-loading.css";
// // import { targetControllers } from "../../controllers/targetControllers";
// import Swal from "sweetalert2";
import { ConsumptionControllers } from "../../controllers/ConsumptionControllers.js";


export default {
  name: "consumption-target-list",
  data: () => ({
    title: "Daftar Target Konsumsi",
    // target: new targetControllers(),
    consumptionTarget: new ConsumptionControllers(),
    targetTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    searchField: "",
  }),

//   components: {
//     Alert,
//     Loading,
//   },

  computed: {
    allConsumptionTarget() {
      return this.consumptionTarget.consumptionTargetList;
    }
    // getRole() {
    //   return this.$route.meta;
    // },

    // detailtarget() {
    //     return this.target.targetDetail;
    // },

    // targets() {
    //   return this.target.targetCommentDetail;
    // },

    // errorCode() {
    //   return this.target.errorCode;
    // },

    // isError() {
    //   return this.target.isError;
    // },

    // errorCause() {
    //   return this.target.errorCause;
    // },

    // isLoadingtarget() {
    //   return this.target.isLoading;
    // },
  },

//   filters: {
//     getNormalDate(value) {
//       return formatDate(value);
//     },
//   },

  created() {
    this.getAllConsumptionTarget()
  },

  methods: {
    getAllConsumptionTarget() {
      return this.consumptionTarget.getSalesPercentage(this.$route.params.id)
    },
    back() {
      this.$router.go(-1);
    },}
//     async getComments() {
//       return this.target.gettargetCommentDetail(
//         this.$route.params.id,
//         this.targetTable.page,
//         this.targetTable.limit,
//         this.targetTable.keyword
//       );
//     },

//     async gettargetDetail() {
//         return this.target.gettargetDetail(
//             this.$route.params.id,
//         );
//     },

//     async formerPage() {
//       if (this.pages.start > 10) {
//         this.isLoading = this.isLoadingtarget;
//         this.pages.start -= 10;
//         this.pages.end -= 10;

//         this.targetTable.page -= 1;

//         await this.getComments(
//           this.targetTable.page,
//           this.targetTable.limit,
//           this.targetTable.keyword
//         );
//         if (this.isError) {
//           Swal.fire({
//             icon: "warning",
//             text: "Data sebelumnya tidak tersedia",
//           });
//         }
//       }
//     },

//     async nextPage() {
//       this.isLoading = this.isLoadingtarget;
//       this.pages.start += 10;
//       this.pages.end += 10;

//       this.targetTable.page += 1;

//       await this.getComments(
//         this.targetTable.page,
//         this.targetTable.limit,
//         this.targetTable.keyword
//       );
//       if (this.isError) {
//         Swal.fire({
//           icon: "warning",
//           text: "Data selanjutnya tidak tersedia",
//         });
//       }
//     },

//     async debounceSearch() {
//       this.pages.start = 1;
//       this.pages.end = 10;

//       this.targetTable.page = 1;
//       this.targetTable.limit = 10;
//       this.targetTable.keyword = this.searchField;

//       await this.getComments(
//         this.targetTable.page,
//         this.targetTable.limit,
//         this.targetTable.keyword
//       );
//     },

//     debounceSearchEmpty(event) {
//       clearTimeout(this.debounce);
//       this.search.debounce = setTimeout(async () => {
//         if (event.length === 0) {
//           this.pages.start = 1;
//           this.pages.end = 10;

//           this.targetTable.page = 1;
//           this.targetTable.limit = 10;
//           this.targetTable.keyword = event;

//           await this.getComments(
//             this.targetTable.page,
//             this.targetTable.limit,
//             this.targetTable.keyword
//           );
//         }
//       }, 500);
//     },
//   },
};
</script>