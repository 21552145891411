<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }} 
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="detailSales.startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="detailSales.startDate"
                    label="Tanggal Mulai"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="detailSales.startDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(detailSales.startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="detailSales.endDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="detailSales.endDate"
                    label="Tanggal Akhir"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="detailSales.endDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(detailSales.endDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field
                v-model.number="detailSales.goal"
                :rules="[
                  (v) =>
                    Number.isInteger(Number(v)) ||
                    'Isian harus angka',
                  (v) => !!v || 'Kolom wajib diisi',
                ]"
                label="Target"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <!-- Error Get Data Sales -->
    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { SalesControllers } from "../../controllers/SalesControllers.js";
import Swal from "sweetalert2";

export default {
  name: "update-sales",
  data: () => ({
    sales: new SalesControllers(),
    valid: true,
    title: "Ubah Penjualan",

    isLoading: false,
    fullPage: true,

    menu: false,
    menu2: false
  }),

  components: {
    Loading,
    Alert
  },

  computed: {
    // sales length
    salesLength() {
      return this.sales.salesTargetList.length;
    },

    // update sales
    isErrorUpdateSales() {
      return this.sales.isError;
    },

    errorCauseUpdateSales() {
      return this.sales.errorCause;
    },

    isLoadingUpdateSales() {
      return this.sales.isLoading;
    },

    // edit sales
    detailSales() {
      return this.sales.salesDetail;
    },

    isErrorCode() {
      return this.sales.errorCode;
    },

    isError() {
      return this.sales.isError;
    },

    errorCause() {
      return this.sales.errorCause;
    },
  },

  created() {
    this.getSalesDetail();
  },

  methods: {
    getSalesDetail(){
      return this.sales.getSalesTargetDetail(
        this.$route.params.id
      );
    },

    back() {
      this.$router.push({
        name: "Sales",
        params: {
          tab: this.$route.params.tab
        }
      });
    },

    async submitProcess(id, startDate, endDate, goal) {
      return this.sales.updateSalesTargetList(
        id,
        startDate,
        endDate,
        goal
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.$route.params.id,
          this.detailSales.startDate,
          this.detailSales.endDate,
          parseInt(this.detailSales.goal)
        );

        if (this.isErrorUpdateSales) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateSales,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.back();
        }
      }
    },
  },
};
</script>