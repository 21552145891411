import {
    CreateMedsosDSORequest,
    DeleteMedsosDSORequest,
    UpdateMedsosDSORequest,
    MedsosDSOListRequest,
    MedsosDSODetailRequest,
    MedsosKolaborasiRequest,
    getMedsosDsoRequest,
    getTargetMedsosRequest,
    getCommunityFollowedRequest,
    getPostCommunityKPIRequest
} from "../request/MedsosDSORequest.js";

export class MedsosDSOControllers {
    medsosdsoList = new Array();
    medsosdsoDetail = new Object();
    medsosdsoDownload = new Array();
    medsoskolaborasi = new Object();
    medsosDsoChart = new Object();
    postCommunityChart = new Object();
    targetMedsosChart = new Object();
    communityFollowedChart = new Object();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getMedsosDSOList(page, limit, keyword, dsoId) {
        this.setLoading(true);
        const resp = MedsosDSOListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setMedsosDSOList(response.data.data);
                return true;
            }).catch(err => {
                this.setMedsosDSOList([]);
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getMedsosDSODetail(id) {
        this.setLoading(true);
        const resp = MedsosDSODetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setMedsosDSODetail(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getMedsosKolaborasi() {
        this.setLoading(true);
        const resp = MedsosKolaborasiRequest()
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setMedsosKolaborasi(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadMedsosDSOList(page, limit, keyword, dsoId) {
        this.setLoadingDownload(true);
        const resp = MedsosDSOListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setMedsosDSOListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setMedsosDSOListDownload(data) {
        this.medsosdsoDownload = data;
    }

    setMedsosDSOList(data) {
        this.medsosdsoList = data;
    }

    setMedsosDSODetail(data) {
        this.medsosdsoDetail = data;
    }

    setMedsosKolaborasi(data) {
        this.medsoskolaborasi = data;
    }

    createMedsosDSOList(
        dso,
        name,
        category,
        medsosLink
    ) {
        this.setLoading(true);
        const resp = CreateMedsosDSORequest(
                dso,
                name,
                category,
                medsosLink
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteMedsosDSO(id) {
        this.setLoading(true);
        const resp = DeleteMedsosDSORequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateMedsosDSOList(
        id,
        dso,
        name,
        category,
        medsosLink
    ) {
        this.setLoading(true);
        const resp = UpdateMedsosDSORequest(
                id,
                dso,
                name,
                category,
                medsosLink
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getMedsosDSOChart(dsoId, year) {
        this.setLoading(true);
        const resp = getMedsosDsoRequest(dsoId, year)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setMedsosDsoChart(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMedsosDsoChart(payload) {
        this.medsosDsoChart = payload
    }

    getTargetMedsosChart(dsoId, year) {
        this.setLoading(true);
        const resp = getTargetMedsosRequest(dsoId, year)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setTargetMedsosChart(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getPostCommunityKPI(dsoId, year) {
        this.setLoading(true);
        const resp = getPostCommunityKPIRequest(dsoId, year)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setPostCommunityChart(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setTargetMedsosChart(payload) {
        this.targetMedsosChart = payload
    }

    setPostCommunityChart(payload) {
        this.postCommunityChart = payload
    }

    getCommunityFollowed(dsoId, year) {
        this.setLoading(true);
        const resp = getCommunityFollowedRequest(dsoId, year)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setCommunityFollowed(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setCommunityFollowed(payload) {
        this.communityFollowedChart = payload
    }

}