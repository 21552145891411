<template>
<div>
  <v-container class="pt-9 d-flex flex-column">
    <v-row class="d-flex flex-row" justify="space-between">
      <v-col md="7" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      
      <v-col md="3" sm="12">
        <v-autocomplete
         v-if="getRole.role.filter"
          :items="allUserAM"
          v-model="dso"
          item-text="name"
          item-value="id"
          @change="pickArea"
          label="Pilih DSO"
          dense
          block
          right
        ></v-autocomplete>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn
          class="float-right"
          :loading="isLoadingPDF"
          :disabled="isLoadingPDF"
          depressed
          color="error"    
          @click="pdf">
            Export PDF
            <template v-slot:loader>
              <span>Exporting...</span>
            </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  <div id="communication-content-chart">
  <v-container class="pt-9 d-flex flex-column">
    <v-row class="d-flex flex-row" justify="space-between">
      <!-- <v-col md="3" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh PDF
        </v-btn>
      </v-col> -->
      <v-col md="6" sm="12">
        <v-card>
          <v-card-text>
            <SosmedCom :dso="dso"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="12">
        <v-card>
          <v-card-text>
            <SosmedDSO :dso="dso" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </div>
</div>
</template>
<script>
import { UserControllers } from "../../../controllers/UserControllers";
import { getRole, getUserId, getUser } from "../../../external/functions/Cookies";
import SosmedCom from "./social-media-com/sosmed-com";
import SosmedDSO from "./social-media-dso/sosmed-dso";
import {toJpeg} from "html-to-image";
import {jsPDF} from "jspdf";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "communication-report",
  data: () => ({
    title: "Komunikasi",
    userControllers: new UserControllers(),
    dso: null,
    isLoadingPDF: false,
  }),
  components: {
    SosmedCom,
    SosmedDSO,
  },
  created() {
    if(getRole() !== 'admin.dso') {
      this.userControllers.getUserAMList('admin.dso', 1, 1000, '')  
    }
  },
   mounted() {
    if(getRole() === 'admin.dso') {
    this.dso = getUserId()
    }
  },
  computed: {
     allUserAM() {
      return this.userControllers.userListAM
    },
    getRole() {
      return this.$route.meta;
    },
    userName(){
      return getUser()
    },
    ...mapGetters({
      medsos: "setMedsos/medsos",
      dsoId: 'setDso/dsoId'
    }),
  },
  methods: {
    ...mapActions({
      setDsoId: 'setDso/setdsoId'
    }),
    pdf() {
      let dsoChosen = this.allUserAM.find((datum) => datum.id === this.dso)?.name
      if(dsoChosen == null){
        dsoChosen = this.userName
      }
      this.isLoadingPDF = true
      const self = this
      let hight = 0
      if(this.medsos){
        hight = 120;
      }else{
        hight = 76
      }
      setTimeout(() => {
        toJpeg(document.getElementById('communication-content-chart'),  { backgroundColor: "#FFFFFF" })
        .then(function (dataUrl) {
          var doc = new jsPDF();

          doc.setFontSize(24);
          doc.text("Chart KPI Komunikasi", 18, 20);
          doc.setFontSize(8);
          doc.setTextColor("#616161")
          doc.text(`DSO: ${dsoChosen}`, 19, 28);
          doc.addImage(dataUrl, "JPEG", 15, 40, 180, hight);
          doc.save("chart-kpi-komunikasi.pdf");
          self.isLoadingPDF = false
        });
      }, 500)
    },
  },
  watch: {
    allUserAM(newVal) {
      if(newVal.length) {
        this.dso = newVal[0].id
      }
      if(this.dsoId){
      this.dso = this.dsoId
      }
    },
    dso(newVal){
      this.setDsoId(newVal)
    }
  },
};
</script>