<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="9">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row>
      <v-col md="12" sm="12">
        <v-card class="mx-auto">
        <v-img
            :src="detailPost.images[0]"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="300px"
            contain
        >
        </v-img>
          <v-card-title>
            <v-icon large left> mdi-book</v-icon>
            <span class="text-h6 font-weight-light">Post</span>
          </v-card-title>

          <v-card-text class="font-weight-bold">
            {{ detailPost.content }}
          </v-card-text>

          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  alt=""
                  :src="detailPost.user ? detailPost.user.image : '' "
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ detailPost.user ? detailPost.user.name : "" }}</v-list-item-title>
              </v-list-item-content>

              <v-row align="center" justify="end">
                <v-icon class="mr-1"> mdi-heart </v-icon>
                <span class="subheading mr-2">{{ detailPost.totalLike }}</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1"> mdi-chat </v-icon>
                <span class="subheading">{{ detailPost.totalComment }}</span>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!isLoadingPost">
      <v-col md="12" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-list three-line>
            <template v-for="(item, index) in posts">
              <v-list-item :key="index">
                <v-list-item-avatar>
                  <v-img :src="item.user.image"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.user.name }}
                    <span class="grey--text text--lighten-1">{{
                      item.createdAt | getNormalDate
                    }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.content }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isError && errorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import { formatDate } from "../../external/functions/Utilization.js";
import "vue-loading-overlay/dist/vue-loading.css";
import { PostControllers } from "../../controllers/PostControllers";
import Swal from "sweetalert2";

export default {
  name: "post-comments",
  data: () => ({
    title: "Komentar",
    post: new PostControllers(),
    postTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    searchField: "",
  }),

  components: {
    Alert,
    Loading,
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    detailPost() {
        return this.post.postDetail;
    },

    posts() {
      return this.post.postCommentDetail;
    },

    errorCode() {
      return this.post.errorCode;
    },

    isError() {
      return this.post.isError;
    },

    errorCause() {
      return this.post.errorCause;
    },

    isLoadingPost() {
      return this.post.isLoading;
    },
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  created() {
    this.getComments();
    this.getPostDetail();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    async getComments() {
      return this.post.getPostCommentDetail(
        this.$route.params.id,
        this.postTable.page,
        this.postTable.limit,
        this.postTable.keyword
      );
    },

    async getPostDetail() {
        return this.post.getPostDetail(
            this.$route.params.id,
        );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingPost;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.postTable.page -= 1;

        await this.getComments(
          this.postTable.page,
          this.postTable.limit,
          this.postTable.keyword
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingPost;
      this.pages.start += 10;
      this.pages.end += 10;

      this.postTable.page += 1;

      await this.getComments(
        this.postTable.page,
        this.postTable.limit,
        this.postTable.keyword
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.postTable.page = 1;
      this.postTable.limit = 10;
      this.postTable.keyword = this.searchField;

      await this.getComments(
        this.postTable.page,
        this.postTable.limit,
        this.postTable.keyword
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.postTable.page = 1;
          this.postTable.limit = 10;
          this.postTable.keyword = event;

          await this.getComments(
            this.postTable.page,
            this.postTable.limit,
            this.postTable.keyword
          );
        }
      }, 500);
    },
  },
};
</script>