<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="9">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-alert text>
          <v-icon>mdi-plus</v-icon> Buat Project Settings
        </v-alert>
        <v-card>
          <v-card-title>
            <p>Unggah Gambar Sebagai Background</p>
          </v-card-title>
          <v-card-text v-if="form.logoTemp.length > 0">
            <v-btn depressed color="error" small @click="deleteImageUpload"
              >Batal</v-btn
            >
          </v-card-text>
          <v-card-text v-else>
            <input
              @change="changeMedia"
              ref="myFileInput"
              type="file"
              accept="image/*"
            />
          </v-card-text>
          <v-img :src="logoDisplay" v-if="thereIsImage" width="40%" class="ml-4 mb-4 mt-4"></v-img>
          <v-btn color="success" class="ml-4 mb-4 mt-4" @click="submit"> Proses </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!isLoadingProject">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>

        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Code</th>
                  <th class="text-left">View Image</th>
                  <th class="text-left">Ubah</th>
                  <th class="text-left">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in projects" :key="item.id">
                  <td>{{ item.code }}</td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getImage(item.id)"
                    >
                      <v-icon>mdi-image</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn small icon color="warning" dark @click="edit(item.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn small icon color="error" dark @click="deleteProjectSettings(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Detail :isShow="isDetail" :data="projectDetail" @hide="updateModal" />
    <Edit :isShowEdit="isEdit" :data="projectEdit" @hide="updateModalEdit" />

    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />

    <Alert
      :status="errorCreateProjectSettings"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseProjectSettings"
    />

    <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />
  </v-container>
</template>
<script>
import { ProjectControllers } from "../../controllers/ProjectControllers.js";
import { formatDate } from "../../external/functions/Utilization.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import Detail from "./project-settings-detail.vue";
import Edit from "./settings-project-edit.vue";
import { MediaControllers } from "../../controllers/MediaControllers.js";

export default {
  name: "brand-list",
  data: () => ({
    project: new ProjectControllers(),
    media: new MediaControllers(),
    title: "Project Settings",
    projectTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    search: {
      debounce: null,
    },
    searchField: "",
    isDetail: false,
    isEdit: false,

    form: {
      logoTemp: [],
    },
    logoDisplay: null,
    thereIsImage: false,
  }),

  components: {
    Alert,
    Loading,
    Detail,
    Edit
  },

  created() {
    this.getProjectsList();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    // medias
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },

    // projects
    projects() {
      return this.project.projectList;
    },

    isErrorCode() {
      return this.project.errorCode;
    },

    isError() {
      return this.project.isError;
    },

    errorCause() {
      return this.project.errorCause;
    },

    isLoadingProject() {
      return this.project.isLoading;
    },

    projectDetail() {
      return this.project.projectDetail;
    },

    projectEdit() {
      return this.project.projectDetail;
    },

    // create
    loadingProjectSettings() {
      return this.project.loadingProjectSettings;
    },

    errorCreateProjectSettings() {
      return this.project.errorCreateProjectSettings;
    },

    errorCauseProjectSettings() {
      return this.project.errorCauseProjectSettings;
    },

    // delete
    isErrorDelete() {
      return this.project.isErrorDelete;
    },

    errorCauseDelete() {
      return this.project.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.project.isLoading;
    },
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  methods: {
    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.thereIsImage = true;
          this.logoDisplay = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.logoDisplay = null;
      this.thereIsImage = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submit() {
      if(this.thereIsImage) {
        this.isLoading = true;
        await this.uploadMedia("community", this.form.logoTemp);
    
        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isLoading = false;
          this.isErrorUploadMedia = false;
        } else {
          this.isLoading = false;
          this.form.logo = this.medias[0];
        }
      }

      if (!this.isErrorUploadMedia) {
        this.isLoading = true;
        await this.submitProcess(
          this.$route.params.id,
          "header-background-second",
          this.form.logo,
        );

        if (this.errorCreateProjectSettings) {
          this.isLoading = false;
          this.project.setErrorCreateProjectSettings(false);
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseProjectSettings,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Gambar berhasil diunggah",
          });
          this.project.setErrorCreateProjectSettings(false);
          this.deleteImageUpload();
          return this.project.getProjectSettingsList(
            this.$route.params.id,
            this.projectTable.page,
            this.projectTable.limit,
            this.projectTable.keyword
          );
        }
      }
    },

    async submitProcess(
      projectId, key, value
    ) {
      return this.project.createProjectSettings(
        projectId, key, value
      );
    },
    
    back() {
      this.$router.go(-1);
    },

    async getProjectsList() {
      return this.project.getProjectSettingsList(
        this.$route.params.id,
        this.projectTable.page,
        this.projectTable.limit,
        this.projectTable.keyword
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingProject;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.projectTable.page -= 1;

        await this.getProjectsList(
          this.$route.params.id,
          this.projectTable.page,
          this.projectTable.limit,
          this.projectTable.keyword
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingProject;
      this.pages.start += 10;
      this.pages.end += 10;

      this.projectTable.page += 1;

      await this.getProjectsList(
        this.$route.params.id,
        this.projectTable.page,
        this.projectTable.limit,
        this.projectTable.keyword
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.projectTable.page = 1;
      this.projectTable.limit = 10;
      this.projectTable.keyword = this.searchField;

      await this.getProjectsList(
        this.$route.params.id,
        this.projectTable.page,
        this.projectTable.limit,
        this.projectTable.keyword
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.projectTable.page = 1;
          this.projectTable.limit = 10;
          this.projectTable.keyword = event;

          await this.getProjectsList(
            this.$route.params.id,
            this.projectTable.page,
            this.projectTable.limit,
            this.projectTable.keyword
          );
        }
      }, 500);
    },

    getImage(id) {
      this.isDetail = true;
      return this.project.getProjectSettingsDetail(id);
    },

    edit(id) {
      this.isEdit = true;
      return this.project.getProjectSettingsDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    updateModalEdit(status) {
      this.isEdit = status;
    },

    deleteProjectSettings(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.project.DeleteProjectSettings(this.$route.params.id, id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.project.getProjectSettingsList(
            this.$route.params.id,
            this.projectTable.page,
            this.projectTable.limit,
            this.projectTable.keyword
          );
        });
    },

  },
};
</script>