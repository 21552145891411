<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>

              <v-text-field
                v-model="detailAM.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama AM"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="detailAM.remark"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Catatan"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="detailAM.region.id"
                :items="regions"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Wilayah"
                item-text="name"
                item-value="id"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="detailAM.manager.id"
                :items="managers"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Manajer"
                item-text="name"
                item-value="id"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isUserListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseUser"
    />

    <Alert
      :status="isRegionListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseRegion"
    />
  </v-container>
</template>
<script>
import { AreaManagerControllers } from "../../controllers/AreaManagerControllers.js";
import { UserControllers } from "../../controllers/UserControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Alert from "../../components/alert";

export default {
  name: "create-am",
  data: () => ({
    am: new AreaManagerControllers(),
    user: new UserControllers(),
    title: "Ubah AM",
    valid: true,
   
    tableScope: {
      page: 1,
      limit: 1000,
      keyword: ""
    },

    form: {
      name: "",
      remark: "",
      manager: "",
    },
    
    isLoading: false,
    fullPage: true,
    tag: {
      value: [],
      options: [],
    },
  }),

  components: {
    Loading,
    Alert
  },

  computed: {
    // am length
    amLength() {
      return this.am.areaManagerAMList.length;
    },

    isErrorUpdateAM() {
      return this.am.isError;
    },

    errorCauseUpdateAM() {
      return this.am.errorCause;
    },

    isLoadingUpdateAM() {
      return this.am.isLoading;
    },

    // detail user am
    detailAM() {
      const am = this.am.amDetail;

      if (am) {
        return {
          ...am,
          region: {
            id: am.region ? am.region.id : ""
          },
          manager: {
            id: am.manager ? am.manager.id : ""
          }
        };
      }

      return null;
    },

    // get users
    managers() {
      return this.user.userListAM;
    },

    isUserListError() {
      return this.user.isError;
    },

    errorCauseUser() {
      return this.user.errorCause;
    },

    // get regions
    regions() {
      return this.am.areaManagerRSOList;
    },

    isRegionListError() {
      return this.am.isError;
    },

    errorCauseRegion() {
      return this.am.errorCause;
    },
  },
  
  created(){
      this.getAMList();
      this.getUserAM();
      this.getRegions();
  },

  methods: {
    async getAMList() {
      return this.am.getAreaAMDetail(
        this.$route.params.id
      );
    },

    getUserAM() {
      return this.user.getUserAMList(
        "admin.am",
        this.tableScope.page,
        this.tableScope.limit,
        this.tableScope.keyword
      );
    },

    getRegions() {
      return this.am.getAreaManagerRSOList(
        this.tableScope.page,
        this.tableScope.limit,
        this.tableScope.keyword
      );
    },

    back() {
      this.$router.go(-1);
    },

    async submitProcess(name, remark, region, manager) {
      return this.am.updateAreaManagerAMList(
        this.$route.params.id,
        name, remark, region, manager
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailAM.name,
          this.detailAM.remark,
          this.detailAM.region.id,
          this.detailAM.manager.id
        );

        if (this.isErrorUpdateAM) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateAM,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });
          
          this.$refs.observer.reset();
          this.back();
        }
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>