<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="10" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh Excel
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12">
        <v-toolbar color="primary" dark flat>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab> Info Tetangga </v-tab>
            <v-tab> Info Pasar </v-tab>
            <v-tab> Info Promo </v-tab>
            <v-tab> Lainnya </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Competitor />
          </v-tab-item>
          <v-tab-item>
            <Distribution />
          </v-tab-item>
          <v-tab-item>
            <Promotion />
          </v-tab-item>
          <v-tab-item>
            <Other />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    
    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="ERROR"
      :msg="errorCause"
    />
  </v-container>
</template>
<script>
import Competitor from "./table/competitor";
import Distribution from "./table/distribution";
import Promotion from "./table/promotion";
import Other from "./table/other";
import { InfoCommunityControllers } from "../../controllers/InfoCommunityControllers.js";
import Alert from "../../components/alert";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import moment from "moment";

export default {
  name: "community-list",
  data: () => ({
    title: "Verifikasi Berbagi Informasi",
    community: new InfoCommunityControllers(),
    tab: null,
    dialogDownload: false,

    startDate: false,
    endDate: false,
    filter: {
      type: "",
      startDate: "",
      endDate: "",
      size: null,
    },

    types: [
      {
        name: 'Info Tetangga',
        value: 'competitor'
      },
      {
        name: 'Info Pasar',
        value: 'distribution'
      },
      {
        name: 'Info Promo',
        value: 'promotion'
      },
      {
        name: 'Lainnya',
        value: 'other'
      }
    ]
  }),

  computed: {
    dataDownload() {
      return this.community.infoCommunityList;
    },

    isErrorCode() {
      return this.community.errorCode;
    },

    isError() {
      return this.community.isError;
    },

    errorCause() {
      return this.community.errorCause;
    },

    isLoadingDownload() {
      return this.community.isLoading;
    },
  },

  components: {
    Competitor,
    Distribution,
    Promotion,
    Other,
    Alert
  },

  methods: {
    normalDate(val) {
      return moment(val).format('DD-MM-YYYY h:mm:ss');
    },

    async download() {
      await this.community.getInfoCommunityList(
        this.filter.type,
        1, 10,
        "",
        this.filter.startDate,
        this.filter.endDate,
        true
      );

      if(this.dataDownload.length > 0) {
        const dataDownload = []
        for(const item of this.dataDownload) {
            dataDownload.push({
              'pengguna': item.user ? item.user.name : '',
              'judul': item.title,
              'konten': item.content,
              'tanggal': item.date,
              'dibuat pada': this.normalDate(item.createdAt)
          });
        }

        const data = XLSX.utils.json_to_sheet(dataDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Report Info Community Tipe.xlsx`);
      } else {
        Swal.fire({
          text: "Data Tidak Ditemukan",
          icon: "warning",
        });
      }
    }
  }
};
</script>