import axios from "axios";
import { Projects, CreateProject, UpdateProject, ProjectSettings, CreateProjectSettings, UpdateProjectSettings, DeleteProjectSettings } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};

export const ProjectListRequest = (page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${Projects(page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateProjectRequest = (
    name, description
) => {
const resp = axios({
    method: 'post',
    url: `${BaseUrl()}${CreateProject()}`,
    data: {
        name: name,
        description: description
    },
    headers: headers
}).then((response) => {
    return response;
});

return resp;
}


export const UpdateProjectRequest = (
    id,
    name, description
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateProject(id)}`,
        data: {
            name: name,
            description: description
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const ProjectSettingsRequest = (id, page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${ProjectSettings(id, page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateProjectSettingsRequest = (
    projectId, key, value
) => {
const resp = axios({
    method: 'post',
    url: `${BaseUrl()}${CreateProjectSettings()}`,
    data: {
        projectId: projectId,
        key: key,
        value: value
    },
    headers: headers
}).then((response) => {
    return response;
});

return resp;
}

export const UpdateProjectSettingsRequest = (
    projectId, settingId, key, value
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateProjectSettings(projectId, settingId)}`,
        data: {
            key: key,
            value: value
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteProjectSettingsRequest = (projectId, id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteProjectSettings(projectId, id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}




