import axios from "axios";
import { 
    AreaManagerAMList,
    AreaManagerDSOList, 
    CreateAreaManagerAM, 
    UpdateAreaManagerAM, 
    DeleteAreaManagerAM, 
    DeleteAreaManagerDSO,
    CreateAreaManagerDSO,
    UpdateAreaManagerDSO,
    AreaManagerRSOList,
    CreateAreaManagerRSO,
    UpdateAreaManagerRSO,
    DeleteAreaManagerRSO,
    RSODetail,
    AMDetail,
    DSODetail
} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const AreaManagerAMListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AreaManagerAMList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateAreaManagerAMRequest = (
    name, remark, region, manager
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateAreaManagerAM()}`,
        data: {
            name: name,
            remark: remark,
            region: region,
            manager: manager
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AreaManagerDSOListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AreaManagerDSOList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateAreaManagerAMRequest = (
    id, name, remark, region, manager
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateAreaManagerAM(id)}`,
        data: {
            name: name,
            remark: remark,
            region: region,
            manager: manager
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteAreaManagerAMRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteAreaManagerAM(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteAreaManagerDSORequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteAreaManagerDSO(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateAreaManagerDSORequest = (
    areaId, name, dso, head, category
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateAreaManagerDSO()}`,
        data: {
            areaId: areaId,
            name: name,
            dso: dso,
            heads: head,
            category: category
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateAreaManagerDSORequest = (
    id, areaId, name, dso, head, category
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateAreaManagerDSO(id)}`,
        data: {
            areaId: areaId,
            name: name,
            dso: dso,
            heads: head,
            category: category
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AreaManagerRSOListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AreaManagerRSOList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateAreaManagerRSORequest = (
    name, remark, manager, head
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateAreaManagerRSO()}`,
        data: {
            name: name,
            remark: remark,
            manager: manager,
            heads: head
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateAreaManagerRSORequest = (
    id, name, remark, manager, head
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateAreaManagerRSO(id)}`,
        data: {
            name: name,
            remark: remark,
            manager: manager,
            heads: head
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteAreaManagerRSORequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteAreaManagerRSO(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const RSODetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${RSODetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AMDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AMDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DSODetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${DSODetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}
