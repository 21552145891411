<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0 me-0">
          <v-col md="8" sm="12">
            <span class="text--secondary text-caption">Tahun</span>
            <v-autocomplete :items="years" 
            v-model="chosenYear" item-text="name" item-value="id"
              dense></v-autocomplete>
          </v-col>
          <!-- <v-col md="2" sm="12">
            <v-btn class="success" small block>Unduh Excel </v-btn>
          </v-col> -->
          <v-col align="center" justify='center' md="12" ms="12" class="mt-n5">
            <h2>{{ title }}</h2>
          </v-col>
          <v-col class="d-flex flex-row">
            <v-col align="center" md="4" ms="12" class="mt-0 pe-0">
              <h2 class="display-1 font-weight-black">
                <b>{{ consumptionKPIChartComputed.reach }}</b><span style="font-size: 10px">/{{
                    consumptionKPIChartComputed.target
                }}</span>
              </h2>
                <p v-if="!consumptionKPIChartComputed.target || consumptionKPIChartComputed.target == 0" style="font-size: 10px" class="font-weight-black red--text">
                      TARGET NOT SET
                    </p>
                <p v-else-if="parseInt(consumptionKPIChartComputed.reach) >= parseInt(consumptionKPIChartComputed.target)" style="font-size: 10px" class="font-weight-black green--text">
                  ACHIEVED
                </p>
                <p v-else style="font-size: 10px" class="font-weight-black red--text">
                  NOT ACHIEVED
                </p>
              <!-- <p style="font-size: 10px" class="font-weight-black mt-n3 green--text">
                <v-icon class="font-weight-black green--text mt-n1">
                  mdi-arrow-up-bold</v-icon> INCREASE 30%
              </p> -->
            </v-col>
            <v-col md="8" ms="12">
              <BarTarget :consumptionKPIChart="consumptionKPIChartComputed" />
            </v-col>
          </v-col>
          <v-col md="12" sm="12" class="px-0 py-0 mt-n5 me-0">
            <VueApexCharts type="bar" height="250" :options="chartOptions" :series="series" ref="detailConsumptionKPIChart" />
          </v-col>

          <!-- <v-col align="center" md="12" ms="12" class="mt-n8 me-0">
            <BarChart />
          </v-col> -->
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { InputConsumptionControllers } from "../../../../controllers/InputConsumptionControllers";
Vue.use(VueApexCharts);

// import BarChart from "./bar-chart";
import BarTarget from "./bar-target";

export default {
  name: "community-achievement",
  props: {
    dso: {
      type: String,
      default: ''
    }
  },
  data: (instance) => ({
    title: "CAPAIAN KONSUMSI",
    inputConsumption: new InputConsumptionControllers(),
    year: new Date().getFullYear()-1,
    chosenYear: new Date().getFullYear(),
    series: [
      {
        data: [0, 0, 0, 0, 0],
        sku: [{
          sku10: 0,
          sku12: 0,
          sku16: 0,
        }, {
          sku10: 0,
          sku12: 0,
          sku16: 0,
        }, {
          sku10: 0,
          sku12: 0,
          sku16: 0,
        }, {
          sku10: 0,
          sku12: 0,
          sku16: 0,
        }, {
          sku10: 0,
          sku12: 0,
          sku16: 0,
        }]
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 175,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "50%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#000000", "#e2a63d", "#e23d3d", "#d99494", "#34bf41"],
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"],
          fontSize: "15px",
        },
        formatter: function (val, opt) {
          const series = opt.w.globals.series[0]
          const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0) || 1;
          const percent = (val / sum) * 100;
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + ` (${percent.toFixed(0)}%)`;
        },
        offsetX: '0px',
        offsetY: -18,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        custom: function ({seriesIndex, dataPointIndex}) {
          var sku = instance.seriesComputed[seriesIndex].sku[dataPointIndex];
          return '<ul>' +
            Object.keys(sku).map(key => {
              return `<li><b>${key}</b>: ` + sku[key] + '</li>' 
            }).join('') +
            '</ul>';
        }
      },
      xaxis: {
        categories: [
          "LA BOLD",
          "DJARUM 76",
          "DJARUM SUPER",
          "LA LIGHTS",
          "OTHERS",
        ],
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
    },
  }),

  components: {
    VueApexCharts,
    // BarChart,
    BarTarget,
  },

  computed: {
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },

    isOldKPI(){
      console.log(this.chosenYear < 2023)
      return this.chosenYear < 2023;
    },
    consumptionKPIChartComputed() {
      const kpiChart = this.inputConsumption.consumptionKPIChart;

      return {
        reach: (kpiChart.totalTarget && kpiChart.totalTarget.split('/')[0]) || 0,
        target: (kpiChart.totalTarget && kpiChart.totalTarget.split('/')[1]) || 0,
        kpiChart: kpiChart
      }
    },
    seriesComputed() {
      return this.series;
    }
  },

  watch: {
    dso() {
      this.getConsumptionKPIChart()
    },
    chosenYear() {
      this.getConsumptionKPIChart()
    },
    consumptionKPIChartComputed({ kpiChart }) {
      const consumptionList = kpiChart.consumptionlist;
      this.series = []

      if (consumptionList) {
        const seriesValue = consumptionList.map((consumption) => consumption.value);
        const seriesLabel = consumptionList.map((consumption) => consumption.name);
        const skuDetail = []
        consumptionList.forEach(element => {
          const skuReduced = element.sku.reduce((a, skuDatum) => ({ ...a, ['sku'+skuDatum.name]: skuDatum.value }), {})
          skuDetail.push(skuReduced)
        });

        const newChartOptions = this.chartOptions;
        newChartOptions.xaxis.categories = seriesLabel;

        Object.assign(this.chartOptions, newChartOptions)
        Object.assign(this.series, [{ data: seriesValue, sku: skuDetail}])

        this.$refs.detailConsumptionKPIChart.refresh()
      }
    }
  },

  methods: {
    getConsumptionKPIChart() {
      return this.inputConsumption.getInputConsumptionKPIChart(this.dso, this.chosenYear);
    }
  }
};
</script>