<template>
  <div>
    <v-dialog v-model="isShow" width="500" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2"> Rincian Komunitas </v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>ID</v-list-item-title>
              <v-list-item-subtitle>{{ data.id }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Nama Komunitas</v-list-item-title>
              <v-list-item-subtitle>{{ data.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="data.brand">
            <v-list-item-content>
              <v-list-item-title>Nama Brand</v-list-item-title>
              <v-list-item-subtitle>{{ data.brand.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="data.pic">
            <v-list-item-content>
              <v-list-item-title>PIC</v-list-item-title>
              <v-list-item-subtitle>{{ data.pic.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="data.dso">
            <v-list-item-content>
              <v-list-item-title>DSO</v-list-item-title>
              <v-list-item-subtitle>{{ data.dso.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="data.createdBy">
            <v-list-item-content>
              <v-list-item-title>Dibuat Oleh</v-list-item-title>
              <v-list-item-subtitle>{{ data.createdBy.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Area</v-list-item-title>
              <v-list-item-subtitle>{{ data.area }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>Alamat</v-list-item-title>
              <v-list-item-subtitle>{{
                  data.address
                    ? `${data.address.street}, ${data.address.village}, ${data.address.subDistrict},
                              ${data.address.district},
                              ${data.address.province}, ${data.address.zipCode}`
                    : "-"
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Total Anggota</v-list-item-title>
              <v-list-item-subtitle>{{ data.totalMember }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Total Anggota Aktif</v-list-item-title>
              <v-list-item-subtitle>{{ data.totalActiveMember }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Total Perokok</v-list-item-title>
              <v-list-item-subtitle>{{ data.totalSmoker }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="data.category == 'heppiii'">
            <v-list-item-content>
              <v-list-item-title>Warga Desa</v-list-item-title>
              <v-list-item-subtitle>{{ data.totalVillagers}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>Deskripsi</v-list-item-title>
              <v-list-item-subtitle>{{ data.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Tanggal Lahir Komunitas</v-list-item-title>
              <v-list-item-subtitle>{{ data.dob }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Terverifikasi</v-list-item-title>
              <v-list-item-subtitle>{{ data.isVerified ? 'Yes' : 'No' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Social Media</v-list-item-title>
              <v-list-item-subtitle v-for="(item, index) in data.socialMedias" :key="index">
                <a :href="item" target="_blank">{{ item }}</a>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Dibuat Pada</v-list-item-title>
              <v-list-item-subtitle>{{
                  data.createdAt | getNormalDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Diperbaharui Pada</v-list-item-title>
              <v-list-item-subtitle>{{
                  data.updatedAt | getNormalDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line v-if="data.logo">
            <v-list-item-content>
              <v-list-item-title>Logo</v-list-item-title>
              <v-list-item-subtitle>
                <v-row>
                  <v-col class="d-flex child-flex" cols="4">
                    <v-img :src="data.logo" :lazy-src="data.logo" aspect-ratio="1" class="grey lighten-2"
                      style="cursor: pointer;" @click="zoomImage(data.logo)">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="closeModal"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isZoom" width="500" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2"> Logo </v-card-title>
        <v-img :src="imageZoomed"></v-img>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="isZoom = false"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { formatDate } from "../../external/functions/Utilization.js";
export default {
  name: "user-detail",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },

  data: () => ({
    isZoom: false,
    imageZoomed: ""
  }),

  methods: {
    closeModal() {
      this.$emit("hide", false);
    },
    zoomImage(img) {
      this.isZoom = true;
      this.imageZoomed = img;
    }
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },
};
</script>