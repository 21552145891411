<template>
  <v-dialog v-model="isShow" width="500" scrollable>
    <v-card>
      <v-card-title class="headline grey lighten-2"> Rincian DRP </v-card-title>

      <v-card-text>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>ID</v-list-item-title>
            <v-list-item-subtitle>{{ data.id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Nama DRP</v-list-item-title>
            <v-list-item-subtitle>{{ data.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.dso">
          <v-list-item-content>
            <v-list-item-title>DSO</v-list-item-title>
            <v-list-item-subtitle>{{ data.dso.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Area</v-list-item-title>
            <v-list-item-subtitle>{{
              data.area ? data.area : data.address.district
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Keunggulan</v-list-item-title>
            <v-list-item-subtitle>{{ data.excellence }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Kategori</v-list-item-title>
            <v-list-item-subtitle>{{ data.category }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="data.createdBy">
          <v-list-item-content>
            <v-list-item-title>Dibuat Oleh</v-list-item-title>
            <v-list-item-subtitle>{{
              data.createdBy.name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Alamat</v-list-item-title>
            <v-list-item-subtitle>{{
              data.address
                ? `${data.address.street}, ${data.address.village}, ${data.address.subDistrict}, ${data.address.district}, ${data.address.province}, ${data.address.zipCode}`
                : "-"
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Diuat Pada</v-list-item-title>
            <v-list-item-subtitle>{{
              data.createdAt | getNormalDate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Diperbaharui Pada</v-list-item-title>
            <v-list-item-subtitle>{{
              data.updatedAt | getNormalDate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="closeModal"> Tutup </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatDate } from "../../external/functions/Utilization.js";
export default {
  name: "drp-detail",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit("hide", false);
    },
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },
};
</script>