import axios from "axios";
import { UserDashboard, CommunityDashboard, CommunityCategoryDashboard, SalesDashboard } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const UserDashboardRequest = (year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${UserDashboard(year)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CommunityDashboardRequest = (area) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CommunityDashboard(area)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CommunityCategoryDashboardRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${CommunityCategoryDashboard()}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}


export const SalesDashboardRequest = (year) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${SalesDashboard(year)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}
