import axios from "axios";
import { ProvinceList, DistrictList, SubDistrictList, VillageList, AllDistrictList, AllSubDistrictList, AllVillagesList } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const ProvinceListRequest = (page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${ProvinceList(page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DistrictListRequest = (provinceId, page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${DistrictList(provinceId, page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}


export const SubDistrictListRequest = (districtId, page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${SubDistrictList(districtId, page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const VillageListRequest = (subDistrictId, page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${VillageList(subDistrictId, page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AllDistrictListRequest = (page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AllDistrictList(page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AllSubDistrictListRequest = (page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AllSubDistrictList(page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AllVillagesListRequest = (page, limit) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AllVillagesList(page, limit)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

