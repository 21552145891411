<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-file-input
                v-model="file"
                accept=".xlsx"
                label="Data DTK KHK (Excel)"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                outlined
                dense
                required
                prepend-icon="mdi-file-excel"
                @change="onChange"
              ></v-file-input>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="form.year"
                :items="years"
                item-text="value"
                item-value="key"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Periode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="dataInput.length > 0">
      <v-col md="12">
        <h2>Review Data Input</h2>
      </v-col>
      <v-col md="12" sm="12">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">DSO</th>
                  <th class="text-left">Brand</th>
                  <th class="text-left">Kategori</th>
                  <th class="text-left">Nama Komunitas</th>
                  <th class="text-left">Member Aktif</th>
                  <th class="text-left">Perokok Aktif</th>
                  <th class="text-left">Kegiatan</th>
                  <th class="text-left">Massa Terbanyak</th>
                  <th class="text-left">Medsos</th>
                  <th class="text-left">Atribut</th>
                  <th class="text-left">Persen Konsumsi</th>
                  <th class="text-left">Persen Kolaborasi</th>
                  <th class="text-left">Persen Partisipasi</th>
                  <th class="text-left">Sharing Info</th>
                  <th class="text-left">Persen Cobranding</th>
                  <th class="text-left">Viralisasi</th>
                  <th class="text-left">Warga Desa</th>
                  <th class="text-left">Achievement</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in dataInput" :key="item.no">
                  <td>{{ item.no }}</td>
                  <td>{{ item.dso }}</td>
                  <td>{{ item.brand }}</td>
                  <td>{{ item.category }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.activeMember }}</td>
                  <td>{{ item.activeSmoker }}</td>
                  <td>{{ item.activity }}</td>
                  <td>{{ item.totalParticipant }}</td>
                  <td>{{ item.sosmed }}</td>
                  <td>{{ item.attribute }}</td>
                  <td>{{ item.consumption }}</td>
                  <td>{{ item.collaboration }}</td>
                  <td>{{ item.activeParticipation }}</td>
                  <td>{{ item.sharingInfo}}</td>
                  <td>{{ item.coBranding }}</td>
                  <td>{{ item.sosmedViralization }}</td>
                  <td>{{ item.wargaDesa }}</td>
                  <td>{{ item.achievement }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  </v-container>
</template>
<script>
// import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ReportControllers } from "../../controllers/ReportControllers.js";
import Swal from "sweetalert2";
import XLSX from "xlsx";

export default {
  name: "insert-dtk-khk",
  data: () => ({
    valid: true,
    title: "Insert DTK KHK",
    report: new ReportControllers,

    isLoading: false,
    fullPage: true,
    file: null,
    dataInput: [],

    form: {
      year: '',
    },
  }),

  components: {
    Loading,
    // Alert,
  },

  computed: {
    years() {
      let yearArr = [];

      const start = 2019;
      const end = 2022;
      // const end = new Date().getFullYear() - 1;

      for (let i = start; i <= end; i++) {
        yearArr.push({ key: i, value: i });
      }

      return yearArr;
    },

    isErrorInsertDtkKhk() {
      return this.report.isError;
    },

    errorCauseInsertDtkKhk() {
      return this.report.errorCause;
    },

    isLoadingInsertDtkKhk() {
      return this.report.isLoading;
    },

    errorCodeInsertDtkKhk(){
      return this.report.errorCode
    },
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    async submitProcess(
      year,
      dataInput
    ) {
      return this.report.inputDtkKhk(
        year,
        dataInput
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.year,
          this.dataInput
        );

        if (this.isErrorInsertDtkKhk) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseInsertDtkKhk,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },

    groupBy(xs, f) {
      return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    },

    onChange() {
      if (this.file != null) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          const dataInput = data.slice(1);

          this.dataInput = dataInput.map((datum) =>{
            return {
              'no': datum[0],
              'dso': datum[1],
              'brand': datum[2],
              'category': datum[3],
              'brandCategory': `${datum[2]} - ${datum[3]}`,
              'dsoCategory': `${datum[1]} - ${datum[3]}`,
              'name': datum[4],
              'activeMember': datum[5],
              'activeSmoker': datum[6],
              'activity': datum[7],
              'totalParticipant': datum[8],
              'sosmed': datum[9],
              'attribute': datum[10],
              'consumption': parseFloat(datum[13].toFixed(2)),
              'collaboration': parseFloat(datum[16].toFixed(2)),
              'activeParticipation': parseFloat(datum[19].toFixed(2)),
              'sharingInfo': datum[20],
              'coBranding': parseFloat(datum[23].toFixed(2)),
              'sosmedViralization': datum[24],
              'wargaDesa': datum[25],
              'achievement': datum[26],
            }
          })

          // let grouped = this.groupBy(this.dataInput, (datum)=> datum.brandCategory)
          console.log(this.dataInput)
        };

        reader.readAsBinaryString(this.file);
      }
    },
  },
};
</script>