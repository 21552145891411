import { BigEventDetailRequest, CreateBigEventListRequest,BigEventListRequest, DeleteBigEventRequest, UpdateBigEventRequest } from "../request/BigEventCommunityRequest.js";

export class BigEventControllers {
    eventList = new Array();
    eventDetail = new Object();
    eventReview = new Object();
    eventDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getEventList(page, limit, keyword, brand, category, dsoId) {
        this.setLoading(true);
        const resp = BigEventListRequest(page, limit, keyword, brand, category, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setEventList(response.data.data);
            }).catch(err => {
                this.setEventList([]);
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getEventDetailEdit(id) {
        this.setLoading(true);
        const resp = BigEventDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setEventDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setEventDetail(data) {
        this.eventDetail = data;
    }

    getDetail(id) {
        this.eventDetail = this.eventList.find(item => {
            return item.id === id
        });
    }

    setEventList(data) {
        this.eventList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    downloadEventList(page, limit, keyword) {
        this.setLoadingDownload(true);
        const resp = BigEventListRequest(page, limit, keyword)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setEventListDownload(response.data.data);
            }).catch(err => {
                this.setEventListDownload([]);
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setEventListDownload(data) {
        this.eventDownload = data;
    }

    createEventList(
        title,
        description,
        category,
        date,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        locationLink,
        communityId,
        totalParticipant,
        images
    ) {
        this.setLoading(true);
        const resp = CreateBigEventListRequest(
                title,
                description,
                category,
                date,
                province,
                district,
                subDistrict,
                village,
                street,
                zipCode,
                locationLink,
                communityId,
                totalParticipant,
                images
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    deleteEvent(id) {
        this.setLoading(true);
        const resp = DeleteBigEventRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateEventList(
        id,
        totalParticipant,
        title,
        description,
        date,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        locationLink
    ) {
        this.setLoading(true);
        const resp = UpdateBigEventRequest(
                id,
                title,
                totalParticipant,
                description,
                date,
                province,
                district,
                subDistrict,
                village,
                street,
                zipCode,
                locationLink
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

}