import { AllNotificationsPreviewListRequest, AllNotificationsListRequest } from "../request/NotificationsRequest.js";

export class NotificationControllers {
    notificationPreviewList = new Array();
    notificationList = new Array();
    lengthNotifications = 0;
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    constructor() { }

    getNotificationPreviewList() {
        this.setLoading(true);
        const resp = AllNotificationsPreviewListRequest()
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setNotificationPreviewList(response.data.data);
                this.setLengthNotification(response.data.data.length);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getNotificationList(page, limit, keyword) {
        this.setLoading(true);
        const resp = AllNotificationsListRequest(page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setNotificationList(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLengthNotification(total) {
        this.lengthNotifications = total;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    setErrorCause(causes) {
        this.errorCause = causes;
    }

    setNotificationPreviewList(data) {
        this.notificationPreviewList = data;
    }

    setNotificationList(data) {
        this.notificationList = data;
    } 

}

