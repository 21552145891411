import axios from "axios";
import { UserList, CreateUser, DeleteUser, UpdateUser, FilterUserAM, UserDetail, InputUser} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const UserListRequest = (page, limit, keyword, startDate, endDate, isReport, amId, rsoId, exPic, role) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${UserList(page, limit, keyword, startDate, endDate, isReport, amId, rsoId, exPic, role)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateUserRequest = (
    email, 
    name, 
    role,
    gender, 
    mobileNumber, 
    province, 
    district, 
    subDistrict, 
    village, 
    street, 
    zipCode, 
    pob, 
    dob,
    dso
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateUser()}`,
        data: {
            email: email,
            name: name,
            role: role,
            gender: gender,
            mobileNumber: mobileNumber,
            address: {
                province: province,
                district: district,
                subDistrict: subDistrict,
                village: village,
                street: street,
                zipCode: zipCode
            },
            pob: pob,
            dob: dob,
            dso: dso
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const InputUserRequest = (
    dataInput
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${InputUser()}`,
        data: {
            dataInput: JSON.stringify(dataInput)
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteUserRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteUser(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateUserRequest = (
    id,
    email, 
    name, 
    role,
    gender,
    mobileNumber, 
    province, 
    district, 
    subDistrict, 
    village, 
    street, 
    zipCode, 
    pob, 
    dob,
    dso
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateUser(id)}`,
        data: {
            email: email,
            name: name,
            role: role,
            gender: gender,
            mobileNumber: mobileNumber,
            address: {
                province: province,
                district: district,
                subDistrict: subDistrict,
                village: village,
                street: street,
                zipCode: zipCode
            },
            pob: pob,
            dob: dob,
            dso: dso
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const FilterUserAMRequest = (role, page, limit, keyword, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${FilterUserAM(role, page, limit, keyword, dsoId)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UserDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${UserDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}


