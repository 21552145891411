import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {}

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#EF233C',
                blue:  '#EF233C',
                darken: '#EF233C',
                info: '#EF233C',
            },
            dark: {
                primary: '#EF233C',
                blue:  '#EF233C',
                darken: '#EF233C',
            },
        },
    },
})

export default new Vuetify(opts, vuetify)