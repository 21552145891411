import { performanceListRequest, CreatePerformanceRequest, DeletePerformanceRequest, UpdatePerformanceRequest, PerformanceDetailRequest } from "../request/PerformanceRequest.js";

export class PerformanceControllers {
  PerformanceList = new Array();
  performanceDetail = new Object();
  isError = false;
  isLoading = false;
  errorCause = "";
  errorCode = 0;

  constructor() {}

  createPerformance(communityId, year, month, postQty) {
    this.setLoading(true);
    const resp = CreatePerformanceRequest(communityId, year, month, postQty)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getPerformanceList(year, communityId) {
    this.setLoading(true);
    const resp = performanceListRequest(year, communityId)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setPerformanceList(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }
  setPerformanceList(value) {
    this.PerformanceList = value;
  }

  setEmptyPerformance() {
    this.PerformanceList = [];
  }

  setLoading(status) {
    this.isLoading = status;
  }

  setError(status) {
    this.isError = status;
  }

  setErrorCause(err) {
    this.errorCause = err;
  }

  setErrorcode(code) {
    this.errorCode = code;
  }

  deletePerformance(id) {
    this.setLoading(true);
    const resp = DeletePerformanceRequest(id)
      .then((response) => {
        this.setErrorDelete(false);
        this.setMsgDelete(response.data.message);
      })
      .catch((err) => {
        this.setErrorDelete(true);
        this.setErrorCauseDelete(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setMsgDelete(value) {
    this.messageDelete = value;
  }

  setErrorDelete(status) {
    this.isErrorDelete = status;
  }

  setErrorCauseDelete(err) {
    this.errorCauseDelete = err;
  }

  updatePerformance(id, communityId, year, month, postQty) {
    this.setLoading(true);
    const resp = UpdatePerformanceRequest(id, communityId, year, month, postQty)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getPerformanceDetail(id) {
    this.setLoading(true);
    const resp = PerformanceDetailRequest(id)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setPerformanceDetail(response.data.data);
        console.log(response.data);
        return true;
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
        console.log(err.response.data);
        return false;
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setPerformanceDetail(data) {
    this.performanceDetail = data;
  }
}
