<template>
  <div>
    <v-col md="12" sm="12" class="mt-n4 mx-0">
    <p style="font-size: 12px" class="font-weight-black text-uppercase wrap">TARGET EVENT DENGAN DRP dan VENUE
      (<sapan v-if="A=true" class="font-weight-black green--text">ACHIEVED</sapan>
      <sapan v-else class="font-weight-black red--text">NOT ACHIEVED</sapan>)
      </p>
    </v-col>
    <v-col md="12" sm="12" class="mt-n13 mx-0">
    <VueApexCharts
    type="bar"
    height="100"
    :options="chartOptions"
    :series="series"
    />
    </v-col>
    
  </div>
</template>
<style>
p {
  font-size: 0.8em;
}
</style>
<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "total-key",
  data: () => ({
    // charts
    brand : 'DSO Bekasi',
    total : '',
    allEvent:[
      {
        name : 'DSO Bekasi',
        total:20,
        data:[
          {
            category : 'Bold Riders',
            count: 4,
          },
          {
            category : 'Super Friends',
            count : 5
          },
          {
            category : 'Heppiii Community',
            count : 3
          },
          {
            category : 'Kill The LAst',
            count : 3
          },
          {
            category : 'Other',
            count : 3
          },
        ]
      },
    ],
        series: [
      {
        data: [57],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors:["#e2a63d"],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "center", // top, center, bottom
          },
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontWeight: 900,
          colors: ["#fff"],
        },
      },

      xaxis: {
        categories: ["Capaian"],
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tickAmount: 2,
        crosshairs: {
          fill: {
            type: "solid",
          },
        },
        tooltip: {
          enabled: false,
        },
        max: 120,
      },
      grid: {
        show: false,
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 2,
        labels: {
          show: false,
        },
      },
      title: {
        show: false,
      },
      annotations: {
        position: 'back',
        xaxis: [
          {
            x: 0,
            x2: 116,
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              show: false,
              text: "116",
              offsetY: 1,
              position:'right',
              orientation: 'horizontal',
              borderColor: 'transparent',
              style:{
                background: 'transparent',
                color: 'transparent'
              }
            },
          },
        ],
        yaxis: [
          {
            y: 0,
            y2: "capaian",
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              text: "119",
              offsetY: 1,
              position:'right',
              orientation: 'horizontal'
            },
          },
        ],
      },
    },
  }),

  components: {
    VueApexCharts,
  },

  // methods:{
  //   updateBrand(brandI){
  //     this.allEvent.forEach((brand)=>{
  //       if(brand.name==brandI){
  //         this.chartOptions.xaxis.categories.push(brandI)
  //         this.total = brand.total
  //         const data = brand.data
  //         if(data.length>0){
  //           data.forEach(datai=>{
  //             const form = {
  //               name: datai.category,
  //               data: [datai.count]
  //             }
  //             this.series.push(form)
  //           })
  //         }
  //       }
  //     })
  //   },
  //   pickBrand(event){
  //     this.total = ''
  //     this.series = []
  //     this.chartOptions.xaxis.categories = []
  //     this.updateBrand(event)
  //   },
  // },
  // created(){
  //   this.updateBrand(this.brand)
  // }
};
</script>
