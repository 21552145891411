export const defineRole = (role, menu) => {
    const roleStored = {
        "admin.inspire": {
            "brand": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "category": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "venue": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: true
            },
            "infoKomunitas": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: true,
                verify: true
            },
            "sales": {
                create: true,
                read: true,
                update: true,
                del: true,
                set: true,
                filter: true
            },
            "drp": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: true
            },
            "userRSO": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "userAm": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "userDSO": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "user": {
                create: true,
                read: true,
                update: true,
                del: true,
                dso: true,
                filter: true,
                insert: true
            },
            "communityList": {
                insert: true,
                create: true,
                read: true,
                update: true,
                del: true,
                verify: true,
                freeze: true,
                filter: true
            },
            "medsosPerformance": {
                read: true,
                create: true,
                edit: true,
                delete: true
            },
            "post": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: true,
                verify: false,
            },
            "comments": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "attribute": {
                create: true,
                read: true,
                update: true,
                del: true,
                verify: true,
                filter: true
            },
            "achievement": {
                create: true,
                read: true,
                update: true,
                del: true,
                verify: true,
                filter: true
            },
            "eventList": {
                create: true,
                read: true,
                update: true,
                del: true,
                review: true,
                filter: true
            },
            "eventEvidence": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "sosmed": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: true
            },
            "follower": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: true
            },
            "inputCompetency": {
                read: true,
                create: true,
                update: true,
                del: true,
                filter: true,
            },
            "targetKPI": {
                read: true,
                create: true,
                update: true,
                del: true,
            },
            "dashboardKPIDSO": {
                read: true,
                filter: true,
                summarydso: false,
                insert: true
            },
            "dashboardKPI76": {
                read: false,
            },
            "dashboardSummary": {
                read: true
            },
            "dashboardCommunity": {
                read: true
            },
            "dashboardSales": {
                read: true
            },
            "dashboardCategory": {
                read: true
            },
            "dashboardClasificationUser": {
                read: true
            },
            "reportSummary": {
                read: true,
                insert: true
            },
            "reportScore": {
                read: true
            },
            "reportKisi": {
                read: true
            },
            "project": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "projectSettings": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "subProject": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "notification": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "member": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "communityReport": {
                read: true
            },
            "memberReport": {
                read: true
            },
            "achievementReport": {
                read: true
            },
            "activityReport": {
                read: true
            },
            "communicationReport": {
                read: true
            }
        },
        "admin.rso": {
            "brand": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "category": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "venue": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "infoKomunitas": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true,
                verify: false
            },
            "sales": {
                create: false,
                read: true,
                update: false,
                del: false,
                set: false,
                filter: true
            },
            "drp": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "userRSO": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userAm": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "userDSO": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "user": {
                create: false,
                read: true,
                update: false,
                del: false,
                dso: false,
                filter: true,
                insert: false
            },
            "communityList": {
                insert: false,
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                freeze: false,
                filter: true
            },
            "medsosPerformance": {
                read: true,
                create: false,
                edit: false,
                delete: false
            },
            "post": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true,
                verify: false,
            },
            "comments": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "attribute": {
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                filter: true
            },
            "achievement": {
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                filter: true
            },
            "eventList": {
                create: false,
                read: true,
                update: false,
                del: false,
                review: false,
                filter: true
            },
            "eventEvidence": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "sosmed": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "follower": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "inputCompetency": {
                read: true,
                create: false,
                update: false,
                del: false,
                filter: false,
            },
            "targetKPI": {
                read: true,
                create: true,
                update: true,
                del: true,
            },
            "dashboardKPIDSO": {
                read: true,
                filter: true,
                summarydso: false,
                insert: false
            },
            "dashboardKPI76": {
                read: false,
            },
            "dashboardSummary": {
                read: true
            },
            "dashboardCommunity": {
                read: true
            },
            "dashboardSales": {
                read: true
            },
            "dashboardCategory": {
                read: true
            },
            "dashboardClasificationUser": {
                read: true
            },
            "reportSummary": {
                read: true,
                insert: false
            },
            "reportScore": {
                read: true
            },
            "reportKisi": {
                read: true
            },
            "project": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "projectSettings": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "subProject": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "notification": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "member": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "communityReport": {
                read: true
            },
            "memberReport": {
                read: true
            },
            "achievementReport": {
                read: true
            },
            "activityReport": {
                read: true
            },
            "communicationReport": {
                read: true
            }
        },
        "admin.am": {
            "brand": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "category": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "venue": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "infoKomunitas": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true,
                verify: false
            },
            "sales": {
                create: false,
                read: true,
                update: false,
                del: false,
                set: false,
                filter: true
            },
            "drp": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "userRSO": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userAm": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userDSO": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "user": {
                create: false,
                read: true,
                update: false,
                del: false,
                dso: false,
                filter: true,
                insert: false
            },
            "communityList": {
                insert: false,
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                freeze: false,
                filter: true
            },
            "medsosPerformance": {
                read: true,
                create: false,
                edit: false,
                delete: false
            },
            "post": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true,
                verify: false,
            },
            "comments": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "attribute": {
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                filter: true
            },
            "achievement": {
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                filter: true
            },
            "eventList": {
                create: false,
                read: true,
                update: false,
                del: false,
                review: false,
                filter: true
            },
            "eventEvidence": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "sosmed": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "follower": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "inputCompetency": {
                read: true,
                create: false,
                update: false,
                del: false,
                filter: false,
            },
            "targetKPI": {
                read: false,
                create: false,
                update: false,
                del: false,
            },
            "dashboardKPIDSO": {
                read: true,
                filter: true,
                summarydso: false,
                insert: false
            },
            "dashboardKPI76": {
                read: false,
            },
            "dashboardSummary": {
                read: true
            },
            "dashboardCommunity": {
                read: true
            },
            "dashboardSales": {
                read: true
            },
            "dashboardCategory": {
                read: true
            },
            "dashboardClasificationUser": {
                read: true
            },
            "reportSummary": {
                read: true,
                insert: false
            },
            "reportScore": {
                read: true
            },
            "reportKisi": {
                read: true
            },
            "project": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "projectSettings": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "subProject": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "notification": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "member": {
                create: false,
                read: true,
                update: false,
                del: false
            }
        },
        "admin.dso": {
            "brand": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "category": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "venue": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: false
            },
            "infoKomunitas": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: false,
                verify: true
            },
            "sales": {
                create: true,
                read: true,
                update: true,
                del: true,
                set: true,
                filter: false
            },
            "drp": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: false
            },
            "userRSO": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userAm": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userDSO": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "user": {
                create: true,
                read: true,
                update: true,
                del: true,
                dso: false,
                filter: false,
                insert: false
            },
            "communityList": {
                insert: false,
                create: true,
                read: true,
                update: true,
                del: true,
                verify: true,
                freeze: true,
                filter: false
            },
            "medsosPerformance": {
                read: true,
                create: false,
                edit: false,
                delete: false
            },
            "post": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: false,
                verify: true,
            },
            "comments": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "attribute": {
                create: true,
                read: true,
                update: true,
                del: true,
                verify: true,
                filter: false
            },
            "achievement": {
                create: true,
                read: true,
                update: true,
                del: true,
                verify: true,
                filter: false
            },
            "eventList": {
                create: true,
                read: true,
                update: true,
                del: true,
                review: true,
                filter: false
            },
            "eventEvidence": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "sosmed": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: false
            },
            "follower": {
                create: true,
                read: true,
                update: true,
                del: true,
                filter: false
            },
            "inputCompetency": {
                read: true,
                create: false,
                update: false,
                del: false,
                filter: false,
            },
            "targetKPI": {
                read: false,
                create: false,
                update: false,
                del: false,
            },
            "dashboardKPIDSO": {
                read: true,
                filter: false,
                summarydso: true,
                insert: false
            },
            "dashboardKPI76": {
                read: false,
            },
            "dashboardSummary": {
                read: true
            },
            "dashboardCommunity": {
                read: true
            },
            "dashboardSales": {
                read: true
            },
            "dashboardCategory": {
                read: true
            },
            "dashboardClasificationUser": {
                read: true
            },
            "reportSummary": {
                read: true,
                insert: false
            },
            "reportScore": {
                read: true
            },
            "reportKisi": {
                read: true
            },
            "project": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "projectSettings": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "subProject": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "notification": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "member": {
                create: false,
                read: true,
                update: true,
                del: true
            }
        },
        "admin.dso.low": {
            "brand": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "category": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "venue": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: false
            },
            "infoKomunitas": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: false,
                verify: false
            },
            "sales": {
                create: false,
                read: true,
                update: false,
                del: false,
                set: false,
                filter: false
            },
            "drp": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: false
            },
            "userRSO": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userAm": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "userDSO": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "user": {
                create: false,
                read: true,
                update: false,
                del: false,
                dso: false,
                filter: false,
                insert: false
            },
            "communityList": {
                insert: false,
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                freeze: false,
                filter: false
            },
            "medsosPerformance": {
                read: true,
                create: false,
                edit: false,
                delete: false
            },
            "post": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: false,
                verify: false,
            },
            "comments": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "attribute": {
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                filter: false
            },
            "achievement": {
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                filter: false
            },
            "eventList": {
                create: false,
                read: true,
                update: false,
                del: false,
                review: false,
                filter: false
            },
            "eventEvidence": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "sosmed": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: false
            },
            "follower": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: false
            },
            "inputCompetency": {
                read: true,
                create: false,
                update: false,
                del: false,
                filter: false,
            },
            "targetKPI": {
                read: false,
                create: false,
                update: false,
                del: false,
            },
            "dashboardKPIDSO": {
                read: true,
                filter: false,
                summarydso: true,
                insert: false
            },
            "dashboardKPI76": {
                read: false,
            },
            "dashboardSummary": {
                read: true
            },
            "dashboardCommunity": {
                read: true
            },
            "dashboardSales": {
                read: true
            },
            "dashboardCategory": {
                read: true
            },
            "dashboardClasificationUser": {
                read: true
            },
            "reportSummary": {
                read: true,
                insert: false
            },
            "reportScore": {
                read: true
            },
            "reportKisi": {
                read: true
            },
            "project": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "projectSettings": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "subProject": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "notification": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "member": {
                create: false,
                read: true,
                update: false,
                del: false
            }
        },
        "admin.rso.low": {
            "brand": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "category": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "venue": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "infoKomunitas": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true,
                verify: false
            },
            "sales": {
                create: false,
                read: true,
                update: false,
                del: false,
                set: false,
                filter: true
            },
            "drp": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "userRSO": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userAm": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "userDSO": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "user": {
                create: false,
                read: true,
                update: false,
                del: false,
                dso: false,
                filter: true,
                insert: false
            },
            "communityList": {
                insert: false,
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                freeze: false,
                filter: true
            },
            "medsosPerformance": {
                read: true,
                create: false,
                edit: false,
                delete: false
            },
            "post": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true,
                verify: false,
            },
            "comments": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "attribute": {
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                filter: true
            },
            "achievement": {
                create: false,
                read: true,
                update: false,
                del: false,
                verify: false,
                filter: true
            },
            "eventList": {
                create: false,
                read: true,
                update: false,
                del: false,
                review: false,
                filter: true
            },
            "eventEvidence": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "sosmed": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "follower": {
                create: false,
                read: true,
                update: false,
                del: false,
                filter: true
            },
            "inputCompetency": {
                read: true,
                create: false,
                update: false,
                del: false,
                filter: false,
            },
            "targetKPI": {
                read: true,
                create: false,
                update: false,
                del: false,
            },
            "dashboardKPIDSO": {
                read: true,
                filter: true,
                summarydso: false,
                insert: false
            },
            "dashboardKPI76": {
                read: false,
            },
            "dashboardSummary": {
                read: true
            },
            "dashboardCommunity": {
                read: true
            },
            "dashboardSales": {
                read: true
            },
            "dashboardCategory": {
                read: true
            },
            "dashboardClasificationUser": {
                read: true
            },
            "reportSummary": {
                read: true,
                insert: false
            },
            "reportScore": {
                read: true
            },
            "reportKisi": {
                read: true
            },
            "project": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "projectSettings": {
                create: true,
                read: true,
                update: true,
                del: true
            },
            "subProject": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "notification": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "member": {
                create: false,
                read: true,
                update: false,
                del: false
            },
            "communityReport": {
                read: true
            },
            "memberReport": {
                read: true
            },
            "achievementReport": {
                read: true
            },
            "activityReport": {
                read: true
            },
            "communicationReport": {
                read: true
            }
        },
        "admin.brand76": {
            "brand": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "category": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "venue": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "infoKomunitas": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "sales": {
                create: false,
                read: false,
                update: false,
                del: false,
                set: false
            },
            "drp": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userRSO": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userAm": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "userDSO": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "user": {
                create: false,
                read: false,
                update: false,
                del: false,
                dso: false,
                insert: false
            },
            "communityList": {
                insert: false,
                create: false,
                read: false,
                update: false,
                del: false,
                verify: false,
                freeze: false
            },
            "medsosPerformance": {
                read: true,
                create: false,
                edit: false,
                delete: false
            },
            "post": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "comments": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "attribute": {
                create: false,
                read: false,
                update: false,
                del: false,
                verify: false,
            },
            "achievement": {
                create: false,
                read: false,
                update: false,
                del: false,
                verify: false,
            },
            "eventList": {
                create: false,
                read: false,
                update: false,
                del: false,
                review: false
            },
            "eventEvidence": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "targetKPI": {
                read: false,
                create: false,
                update: false,
                del: false,
            },
            "dashboardKPIDSO": {
                read: false,
                filter: false,
                summarydso: false,
                insert: false
            },
            "dashboardKPI76": {
                read: true,
            },
            "dashboardSummary": {
                read: false
            },
            "dashboardCommunity": {
                read: false
            },
            "dashboardSales": {
                read: false
            },
            "dashboardCategory": {
                read: false
            },
            "dashboardClasificationUser": {
                read: false
            },
            "reportSummary": {
                read: true,
                insert: false
            },
            "reportScore": {
                read: true
            },
            "reportKisi": {
                read: true
            },
            "project": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "projectSettings": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "subProject": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "notification": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "member": {
                create: false,
                read: false,
                update: false,
                del: false
            },
            "communityReport": {
                read: false
            },
            "memberReport": {
                read: false
            },
            "achievementReport": {
                read: false
            },
            "activityReport": {
                read: false
            },
            "communicationReport": {
                read: false
            }
        },
        "default": {},
    }

    return roleStored[role][menu];
}