<template>
  <div>
    <v-container class="pt-8">
      <v-row justify="space-between">
        <v-col md="8" sm="12" xs="12" block>
          <h2>Rincian KPI DSO</h2>
        </v-col>
        <v-col md="2" sm="12" xs="12">
          <v-autocomplete :items="years" v-model="chosenYear" label="Tahun" dense></v-autocomplete>

          <!-- <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh Excel
        </v-btn> -->
        </v-col>
        <v-col md="2" sm="12">
          <v-btn class="float-right" :loading="isLoadingPDF" :disabled="isLoadingPDF" depressed color="error" @click="pdf">
            Export PDF
            <template v-slot:loader>
              <span>Exporting...</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div id="summary-content-chart">
      <v-container class="pt-3">
        <v-row>
          <v-col md="12" sm="12">
            <v-card v-if="errorCode === 404">
              <v-card-text>Data Tidak Tersedia</v-card-text>
            </v-card>
            <v-card v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">DSO</th>
                      <th class="text-left">Progress KPI</th>
                      <th class="text-left"></th>
                      <th v-if="!isLoadingPDF" class="text-left">Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in kpiSummaryComputed" :key="item.name">
                      <td>{{ item.name }}</td>
                      <td>
                        <ProgressBar :capaian="item.total" :target="nTarget" />
                      </td>
                      <td>
                        <v-card class="border rounded-md grey white--text my-0 pa-2 " height="40px" align="center" justify="center"> {{ item.total }}/{{nTarget}} </v-card>
                      </td>
                      <td v-if="!isLoadingPDF">
                        <v-btn small icon color="success" dark @click="getdetail(item)">
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!isLoadingPDF">
          <v-col md="12" sm="12">
            <span class="mr-4 grey--text"> Tampil 1 dari 1 </span>
            <v-btn dark color="blue darken" class="mr-1">
              <v-icon>mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn dark color="blue darken" class="ml-1">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../../../components/dashboard/proress-bar.vue";
import { KpiTargetControllers } from "../../../controllers/KpiTargetControllers";
import { toJpeg } from "html-to-image";
import { jsPDF } from "jspdf";

export default {
  data: () => ({
    title: "Summary Achievement DSO",
    chosenYear: new Date().getFullYear(),
    kpiTarget: new KpiTargetControllers(),
    pageScope: {
      page: 1,
      size: 9999,
    },
    nTarget : 11,
    dso: [
      {
        name: "DSO Bekasi Test",
        capaian: 11,
        target: 14,
      },
      {
        name: "DSO Bekasi Test II",
        capaian: 5,
        target: 14,
      },
      {
        name: "DSO Bekasi Test III",
        capaian: 13,
        target: 14,
      },
      {
        name: "DSO Bekasi Test IV",
        capaian: 14,
        target: 14,
      },
    ],
    isLoadingPDF: false,
  }),
  components: {
    ProgressBar,
  },
  computed: {
    years() {
      const start = 2019;
      const end = new Date().getFullYear();

      let year = [];

      for (let i = start; i <= end; i++) {
        year.push(i);
      }

      return year;
    },
    
    isOldKPI(){
      return this.chosenYear < 2023;
    },
    
    kpiSummaryComputed() {
      const kpiTarget = this.kpiTarget.kpiSummary.filter((datum) => datum != null);
      console.log(kpiTarget);

      return kpiTarget;
    },
  },
  created() {
    this.kpiTarget.getKpiSummary(this.pageScope.page, this.pageScope.size, this.chosenYear);
  },
  watch: {
    chosenYear(newVal) {
      this.kpiTarget.setEmptyKpi();
      this.kpiTarget.getKpiSummary(this.pageScope.page, this.pageScope.size, newVal);
    },
    isOldKPI(newVal){
      if(newVal==true){
        this.nTarget = 15
      }else{
        this.nTarget = 11
      }
    }
  },
  methods: {
    pdf() {
      this.isLoadingPDF = true;
      const self = this;
      let height = this.kpiSummaryComputed.length * 8 + 10;
      setTimeout(() => {
        toJpeg(document.getElementById("summary-content-chart"), { backgroundColor: "#FFFFFF" }).then(function(dataUrl) {
          var doc = new jsPDF();

          doc.setFontSize(24);
          doc.text("Chart KPI Summary", 18, 20);
          doc.setFontSize(8);
          doc.setTextColor("#616161");
          doc.text(`Tahun: ${self.chosenYear}`, 19, 28);
          doc.addImage(dataUrl, "JPEG", 15, 40, 180, height);
          doc.save("chart-kpi-summary.pdf");
          self.isLoadingPDF = false;
        });
      }, 500);
    },
    getdetail(data) {
      this.$router.push({
        name: "Detail KPI DSO",
        params: {
          data: JSON.stringify(data),
          year: this.chosenYear,
        },
      });
    },
  },
};
</script>
