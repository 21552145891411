import { EngagementListRequest } from "../request/EngagementRequest.js";

export class EngagementControllers {
    engagementList = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    constructor() { }

    getEngagementList(year, type, size) {
        this.setLoading(true);
        const resp = EngagementListRequest(year, type, size)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setEngagementList(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setEngagementList(data) {
        this.engagementList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }
}

