<template>
  <v-container class="pt-8">
    <v-row justify="center">
      <v-col md="9" sm="12">
        <h2 class="justify-start">{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12" xs="12">
        <v-autocomplete
          :items="years"
          v-model="chosenYear"
          label="Tahun"
          dense
        ></v-autocomplete>
      </v-col>
      <v-col md="12" sm="12">
        <v-row class="flex" justify="center">
          <v-col md="3" sm="12" v-if="isOldKPI">
            <v-btn
              @click="show0"
              depressed
              v-bind:color="show == 0 ? 'primary' : 'success'"
              block
            >
              Medsos DSO
            </v-btn>
          </v-col>
          <v-col md="3" sm="12" v-if="isOldKPI">
            <v-btn
              @click="show1"
              depressed
              v-bind:color="show == 1 ? 'primary' : 'success'"
              block
            >
              Medsos Komunitas
            </v-btn>
          </v-col>
          <v-col md="3" sm="12">
            <v-btn
              @click="show2"
              depressed
              v-bind:color="show == 2 ? 'primary' : 'success'"
              block
            >
              Postingan DSO
            </v-btn>
          </v-col>
          <v-col md="3" sm="12" v-if="isOldKPI">
            <v-btn
              @click="show3"
              depressed
              v-bind:color="show == 3 ? 'primary' : 'success'"
              block
            >
              Follower Medsos
            </v-btn>
          </v-col>
          <v-col md="4" sm="12" v-if="!isOldKPI">
            <v-btn
              @click="show4"
              depressed
              v-bind:color="show == 4 ? 'primary' : 'success'"
              block
            >
              Postingan Komunitas
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="show == 0" md="12" sm="12">
        <v-card>
          <TargetSosmedDSO :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
      <v-col v-if="show == 1" md="12" sm="12">
        <v-card>
          <TargetSosmedCommunity :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
      <v-col v-if="show == 2" md="12" sm="12">
        <v-card>
          <TargetPostDSO :isOldKPI="isOldKPI" :year="chosenYear" />
        </v-card>
      </v-col>
      <v-col v-if="show == 3" md="12" sm="12">
        <v-card>
          <TargetFollower :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
      <v-col v-if="show == 4" md="12" sm="12">
        <v-card>
          <TargetPostCommunity :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TargetSosmedDSO from "./target-sosmed-dso/index";
import TargetSosmedCommunity from "./target-sosmed-community/index";
import TargetPostDSO from "./target-post-dso/index";
import TargetFollower from "./target-follower/index";
import TargetPostCommunity from "./target-post-community/index";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "activity-report",
  data: () => ({
    title: "Target Komunikasi",
    chosenYear: new Date().getFullYear()
  }),

  components: {
    TargetSosmedDSO,
    TargetSosmedCommunity,
    TargetPostDSO,
    TargetFollower,
    TargetPostCommunity
  },
  methods: {
    ...mapActions({
      show0: "targetKPI/set10",
      show1: "targetKPI/set11",
      show2: "targetKPI/set12",
      show3: "targetKPI/set13",
      show4: "targetKPI/set14",
    }),
  },
  computed: {
    ...mapGetters({
      show: "targetKPI/show1",
    }),

    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },

    isOldKPI(){
      console.log(this.chosenYear < 2023)
      return this.chosenYear < 2023;
    },
  },
};
</script>
