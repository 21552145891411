<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 pt-4">
          <v-col md="2" sm="12">
            <v-autocomplete :items="years" v-model="chosenYear" label="Tahun" dense></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="px-1 mt-2" style="width: 800px;">
          <v-col md="6" sm="12">
            <v-row>
              <v-col md="12" ms="12" class="mt-n1">
                <h4 align="center">{{ title }}</h4>
              </v-col>
              <v-col md="12" ms="12" class="mt-n1" align="center">
                <h2 class="text-h2 font-weight-black">
                  <b>{{ totalEventComputed.reach }}</b
                  ><span class="text-h6 font-weight-black" v-if="isOldKPI">/{{ totalEventComputed.target }}</span>
                </h2>
                <div v-if="isOldKPI">
                  <p v-if="!totalEventComputed.target || totalEventComputed.target == 0" style="font-size: 12px" class="font-weight-black red--text">
                    TARGET NOT SET
                  </p>
                  <p v-else-if="totalEventComputed.reach >= totalEventComputed.target" style="font-size: 12px" class="font-weight-black green--text">
                    ACHIEVED
                  </p>
                  <p v-else style="font-size: 12px" class="font-weight-black red--text">
                    NOT ACHIEVED
                  </p>
                </div>
              </v-col>

              <v-col md="12" ms="12">
                <h4 align="center">KATEGORI EVENT</h4>
              </v-col>
              <v-col md="12" ms="12" class="mt-n1">
                <v-select :items="allBrand" v-model="brand" label="Brand Komunitas" dense></v-select>
              </v-col>
              <v-col md="6" ms="12" class="mt-n5"></v-col>
              <v-col md="12" ms="12">
                <v-row justify="center">
                  <v-col md="12" sm="12" class="mt-n2 pa-0 ms-n1 mb-4">
                    <VueApexCharts ref="pieChartCategoryEvent" type="pie" width="100%" :options="chartOptions" :series="series" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6" sm="8">
            <v-row justify="left">
              <v-col md="12" ms="12">
                <StackedBar :eventComputed="totalEventComputed" :isOldKpi="isOldKPI" />
              </v-col>
            </v-row>
            <v-row justify="left">
              <v-col md="12" ms="12" class="mt-n10">
                <StackedBarLkk :eventLKKComputed="totalEventComputed" />
              </v-col>
            </v-row>
            <v-row justify="left">
              <v-col md="12" ms="12" class="mt-n10">
                <StackedBarDRPVenue :eventDrpComputed="totalEventComputed" />
              </v-col>
            </v-row>
            <v-row justify="left" v-if="isOldKPI">
              <v-col md="12" ms="12" class="mt-n10">
                <StackedBarEsport :eventEsportComputed="totalEventComputed" />
              </v-col>
            </v-row>
            <v-row justify="left" v-if="isOldKPI">
              <v-col md="12" ms="12" class="mt-n10">
                <StackedBarSuper :eventSuperNonEsportComputed="totalEventComputed" />
              </v-col>
            </v-row>
            <v-row justify="left">
              <v-col md="12" ms="12" class="mt-n10">
                <StackedBarTargetKegiatanLokal :eventLokalComputed="totalEventComputed"/>
              </v-col>
            </v-row>
            <v-row justify="left">
              <v-col md="12" ms="12" class="mt-n10">
                <StackedBarEventSkalaRegional :eventRegionalComputed="totalEventComputed" />
              </v-col>
            </v-row>
            <v-row justify="left">
              <v-col md="12" ms="12" class="mt-n10">
                <StackedBarSharingAntarKomunitas :eventSharingComputed="totalEventComputed"/>
              </v-col>
            </v-row>
            <v-row justify="left">
              <v-col md="12" ms="12" class="mt-n10">
                <StackedBarBuddySystemDSO :eventBuddySystemComputed="totalEventComputed"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCauseGetDashboard" />
  </div>
</template>

<script>
import Alert from "../../../../components/alert/index";
import { DashboardControllers } from "../../../../controllers/DashboardControllers";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);
import StackedBar from "./stacked-bar-all.vue";
import StackedBarLkk from "./stacked-bar-lkk.vue";
import StackedBarEsport from "./stacked-bar-esport.vue";
import StackedBarDRPVenue from "./stacked-bar-drp-venue.vue";
import StackedBarSuper from "./stacked-bar-super.vue";
import StackedBarTargetKegiatanLokal from "./stacked-bar-target-kegiatan-lokal.vue";
import StackedBarEventSkalaRegional from "./stacked-bar-event-skala-regional.vue";
import StackedBarSharingAntarKomunitas from "./stacked-bar-sharing-antar-komunitas.vue";
import StackedBarBuddySystemDSO from "./stacked-bar-buddy-system-dso.vue";
import { EventControllers } from "../../../../controllers/EventControllers";

export default {
  name: "event-report",
  data: () => ({
    title: "JUMLAH EVENT",
    dashboard: new DashboardControllers(),
    eventControllers: new EventControllers(),
    year: new Date().getFullYear(),
    chosenYear: new Date().getFullYear(),
    brand: "Super Family",
    series: [],
    // series: [27, 67, 27, 13],
    chartOptions: {
      chart: {
        width: "100%",
        type: "pie",
      },
      labels: [],
      // labels: ["GATHERING", "SOSIAL", "NOBAR BOLA", "ESPORT"],
      legend: {
        show: false,
        position: "right",
        formatter: function(label, opts) {
          return label + " : " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "middle",
        style: {
          colors: ["#fff"],
        },
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(0) + "%"];
        },
      },
    },

    isLoading: false,
    isLoadingDownload: false,
    isError: false,
  }),

  props: {
    dso: {
      type: String,
      default: "",
    },
  },

  components: {
    VueApexCharts,
    Alert,
    StackedBar,
    StackedBarLkk,
    StackedBarEsport,
    StackedBarDRPVenue,
    StackedBarSuper,
    StackedBarTargetKegiatanLokal,
    StackedBarSharingAntarKomunitas,
    StackedBarEventSkalaRegional,
    StackedBarBuddySystemDSO,
  },

  computed: {
    years() {
      const start = 2019;
      const end = new Date().getFullYear();

      let year = [];

      for (let i = start; i <= end; i++) {
        year.push(i);
      }

      return year;
    },

    isOldKPI(){
      return this.chosenYear < 2023;
    },
    // sales
    totalEventComputed() {
      const totalEvent = this.eventControllers.eventTotal?.totalEvent;
      const totalLKK = this.eventControllers.eventTotal?.totalLKK;
      const totalVenueDrpTarget = this.eventControllers.eventTotal?.totalVenueDrpTarget;
      const totalVenueEsportTarget = this.eventControllers.eventTotal?.totalEventEsportTarget;
      const totalEventSuperNonEsportTarget = this.eventControllers.eventTotal?.totalEventSuperNonEsportTarget;
      const eventRegionalTarget = this.eventControllers.eventTotal?.eventRegionalTarget;
      const eventLocalTarget = this.eventControllers.eventTotal?.eventLocalTarget;
      const eventSharingTarget = this.eventControllers.eventTotal?.eventSharingTarget;
      const eventBuddySystemTarget = this.eventControllers.eventTotal?.eventBuddySystemTarget;

      const eventDSOSeries = this.eventControllers.eventTotal?.eventDSOSeries || [];
      const reviewActivitySeries = this.eventControllers.eventTotal?.reviewActivitySeries || [];
      const eventDrpSeries = this.eventControllers.eventTotal?.drpVenueSeries || [];
      const eventEsportSeries = this.eventControllers.eventTotal?.eSportSeries || [];
      const superPecintaAlamSeries = this.eventControllers.eventTotal?.superPecintaAlamSeries || [];
      const eventRegionalSeries = this.eventControllers.eventTotal?.eventRegionalSeries || [];
      const eventLocalSeries = this.eventControllers.eventTotal?.eventLocalSeries || [];
      const eventSharingSeries = this.eventControllers.eventTotal?.eventSharingSeries || [];
      const eventBuddySystemSeries = this.eventControllers.eventTotal?.eventBuddySystemSeries || [];
      return {
        reach: totalEvent ? Number(totalEvent?.split("/")?.[0]) : 0,
        target: totalEvent ? Number(totalEvent?.split("/")?.[1]) : 0,

        reachLKK: reviewActivitySeries ? reviewActivitySeries.reduce((a, b) => a + b.value, 0) : 0,
        targetLKK: totalLKK ? Number(totalLKK) : 0,

        reachDrp: eventDrpSeries ? eventDrpSeries.reduce((a, b) => a + b.value, 0) : 0,
        targetDrp: totalVenueDrpTarget ? Number(totalVenueDrpTarget) : 0,

        reachEsport: eventEsportSeries ? eventEsportSeries.reduce((a, b) => a + b.value, 0) : 0,
        targetEsport: totalVenueEsportTarget ? Number(totalVenueEsportTarget) : 0,

        reachSuperNonEsport: superPecintaAlamSeries ? superPecintaAlamSeries.reduce((a, b) => a + b.value, 0) : 0,
        targetSuperNonEsport: totalEventSuperNonEsportTarget ? Number(totalEventSuperNonEsportTarget) : 0,

        reachRegional: eventRegionalSeries ? eventRegionalSeries.reduce((a, b) => a + b.value, 0) : 0,
        targetRegional: eventRegionalTarget ? Number(eventRegionalTarget) : 0,

        reachLocal: eventLocalSeries ? eventLocalSeries.reduce((a, b) => a + b.value, 0) : 0,
        targetLocal: eventLocalTarget ? Number(eventLocalTarget) : 0,

        reachSharing: eventSharingSeries ? eventSharingSeries.reduce((a, b) => a + b.value, 0) : 0,
        targetSharing: eventSharingTarget ? Number(eventSharingTarget) : 0,

        reachBuddySystem: eventBuddySystemSeries ? eventBuddySystemSeries.reduce((a, b) => a + b.value, 0) : 0,
        targetBuddySystem: eventBuddySystemTarget ? Number(eventBuddySystemTarget) : 0,

        eventDSOSeries: eventDSOSeries.map((eventdso) => ({ name: eventdso.key, data: [eventdso.value] })),
        reviewActivitySeries: reviewActivitySeries ? reviewActivitySeries.map((activity) => ({ name: activity.key, data: [activity.value] })) : [],
        eventDrpSeries: eventDrpSeries ? eventDrpSeries.map((activity) => ({ name: activity.key, data: [activity.value] })) : [],
        eventEsportSeries: eventEsportSeries ? eventEsportSeries.map((activity) => ({ name: activity.key, data: [activity.value] })) : [],
        superPecintaAlamSeries: superPecintaAlamSeries ? superPecintaAlamSeries.map((activity) => ({ name: activity.name, data: [activity.value] })) : [],
        eventLocalSeries: eventLocalSeries ? eventLocalSeries.map((activity) => ({ name: activity.name, data: [activity.value] })) : [],
        eventRegionalSeries: eventRegionalSeries ? eventRegionalSeries.map((activity) => ({ name: activity.key, data: [activity.value] })) : [],
        eventSharingSeries: eventSharingSeries ? eventSharingSeries.map((activity) => ({ name: activity.key, data: [activity.value] })) : [],
        eventBuddySystemSeries: eventBuddySystemSeries ? eventBuddySystemSeries.map((activity) => ({ name: activity.key, data: [activity.value] })) : [],
      };
    },

    sales() {
      return this.dashboard.salesDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },

    // allbrand
    allBrand() {
      return ["Bold Riders", "Heppiii Community", "Super Family", "Kill The LAst", "Others"];
    },
  },

  // created() {
  //   // this.getDashboard();
  //   // this.getSeries();
  //   this.getTotalEvent()
  //   this.updatePieChart(this.brand)
  // },

  watch: {
    dso() {
      this.getTotalEvent();
    },
    chosenYear() {
      this.getTotalEvent();
    },
    brand(value) {
      this.updatePieChart(value);
    },
    totalEventComputed(value) {
      if (value) {
        this.brand = "Super Family";
        this.updatePieChart(this.brand);
      }
    },
    //
  },

  methods: {
    getDashboard() {
      return this.dashboard.getSalesDashboardList(this.year);
    },

    getTotalEvent() {
      return this.eventControllers.getTotalEvent(this.dso, this.chosenYear);
    },

    updatePieChart(value) {
      const categoryEvent = this.eventControllers.eventTotal.categoryEvent;

      if (categoryEvent) {
        const filteredEvent = categoryEvent.filter((event) => event.brandName === value);
        this.series = filteredEvent.map((event) => event.value);

        const newOptions = this.chartOptions;
        newOptions.labels = filteredEvent.map((event) => event.category);
        Object.assign(this.chartOptions, newOptions);

        this.$refs.pieChartCategoryEvent.refresh();
      }
    },
    async getSeries() {
      await this.getDashboard(this.year);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    async pickYear(event) {
      this.series = [
        {
          data: [],
        },
      ];
      this.chartOptions.xaxis.categories = [];

      await this.getDashboard(event);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const sales = [];
      for (const item of this.sales) {
        sales.push({
          "comunity name": item._id,
          jumlah: item.count,
        });
      }
      const data = XLSX.utils.json_to_sheet(sales);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Sales Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>
