<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="6" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn
          v-if="getRole.role.create"
          class="float-right"
          depressed
          color="primary"
          @click="createPost"
          block
        >
          Buat Post
        </v-btn>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row justify="space-between" class="mt-n6">
      <v-col md="3" sm="12" v-if="getRole.role.filter">
        <v-autocomplete
          :items="allUserAM"
          v-model="dso"
          item-text="name"
          item-value="id"
          @change="pickArea"
          label="Pilih DSO"
          dense
          block
          right
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col md="3" sm="4">
        <!-- <v-autocomplete
          :items="allMedsos"
          @change="pickArea"
          item-text="name"
          item-value="name"
          label="Media Sosial DSO"
          dense
          outlined
          left
        ></v-autocomplete> -->
      </v-col>
      <v-col md="3" sm="12">
        <v-btn text block class="font-weight-black" color='primary' @click="toComPost">
          POSTINGAN KOMUNITAS <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!isLoadingPost">
      <v-col md="12" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Pengguna</th>
                  <th class="text-left">Konten</th>
                  <th class="text-left">Dibuat Pada</th>
                  <th class="text-left">Total Komentar</th>
                  <th class="text-left">Total Like</th>
                  <th class="text-left">Rincian</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in posts" :key="item.id">
                  <td>{{ item.user.name }}</td>
                  <td
                    v-html="
                      item.content.length > 130
                        ? `${item.content.substr(0, 130)}...`
                        : item.content
                    "
                  ></td>
                  <td>{{ item.createdAt | getNormalDate }}</td>
                  <td>
                    <router-link style="text-decoration: none;" :to="{ name: 'Comments', params: { id: item.id } }"> {{ item.totalComment?  item.totalComment : 0}}</router-link>
                  </td>
                  <td>
                    <router-link style="text-decoration: none;" :to="{ name: 'Comments', params: { id: item.id } }"> {{ item.totalLike?  item.totalLike : 0 }}</router-link>
                  </td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deletePost(item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Detail :isShow="isDetail" :data="dataDetail" @hide="updateModal" />
    <Alert
      :status="isError && errorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />

    <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import { formatDate } from "../../external/functions/Utilization.js";
import "vue-loading-overlay/dist/vue-loading.css";
import { PostControllers } from "../../controllers/PostControllers";
import { UserControllers } from "../../controllers/UserControllers";
import Swal from "sweetalert2";
import Detail from "./post-detail";
import { getRole} from "../../external/functions/Cookies";


export default {
  name: "post-list",
  data: () => ({
    post: new PostControllers(),
    userControllers: new UserControllers(),
    title: "Postingan DSO",
    dso:'',
    postTable: {
      page: 1,
      limit: 10,
      keyword: "",
      role: 'DSO'
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,
    searchField: ""
  }),

  components: {
    Alert,
    Loading,
    Detail,
  },

  created() {
    if(getRole() !== 'admin.dso') {
      this.userControllers.getUserAMList('admin.dso', 1, 1000, '')  
    }
    this.getPosts();
  },

  watch:{
    dso(){
      this.getPosts()
    }
  },

  computed: {
    allMedsos(){
      return [{name:'Media Sosial DSO I'},{name:'Media Sosial DSO II'},{name:'Media Sosial Kolaborasi Kita'}]
    },
    getRole() {
      return this.$route.meta;
    },

    allUserAM() {
      let arrDso = this.userControllers.userListAM
      arrDso.unshift({name:'Semua DSO',id:''})
      return arrDso
    },
    
    // community
    posts() {
      return this.post.postList;
    },

    errorCode() {
      return this.post.errorCode;
    },

    isError() {
      return this.post.isError;
    },

    errorCause() {
      return this.post.errorCause;
    },

    isLoadingPost() {
      return this.post.isLoading;
    },

    dataDetail() {
      return this.post.postDetail;
    },

    // delete
    isErrorDelete() {
      return this.post.isErrorDelete;
    },

    errorCauseDelete() {
      return this.post.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.post.isLoading;
    },
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  methods: {
    async getPosts() {
      return this.post.getPostList(
        this.postTable.page,
        this.postTable.limit,
        this.postTable.keyword,
        this.postTable.role,
        this.dso
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingPost;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.postTable.page -= 1;

        await this.getPosts(
          this.postTable.page,
          this.postTable.limit,
          this.postTable.keyword,
          this.postTable.role,
        this.dso
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingPost;
      this.pages.start += 10;
      this.pages.end += 10;

      this.postTable.page += 1;

      await this.getPosts(
        this.postTable.page,
        this.postTable.limit,
        this.postTable.keyword,
        this.postTable.role,
        this.dso
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    getdetail(id) {
      this.isDetail = true;
      return this.post.getDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    createPost() {
      this.$router.push({
        name: "Create Post",
      });
    },

    toComPost() {
      this.$router.push({
        name: "Post Community",
      });
    },

    deletePost(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.post.deletePost(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.post.getPostList(
            this.postTable.page,
            this.postTable.limit,
            '',
            this.postTable.role,
            this.dso
          );
        });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.postTable.page = 1;
      this.postTable.limit = 10;
      this.postTable.keyword = this.searchField,

      await this.getPosts(
        this.postTable.page,
        this.postTable.limit,
        this.postTable.keyword,
        this.postTable.role,
        this.dso
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.postTable.page = 1;
          this.postTable.limit = 10;
          this.postTable.keyword = event;

          await this.getPosts(
            this.postTable.page,
            this.postTable.limit,
            this.postTable.keyword,
            this.postTable.role,
            this.dso
          );
        }
      }, 500);
    },
  },
};
</script>