export default{
    namespaced: true,
    state:{
        show : 1,
        show1 : 2,
    },
    mutations:{
        set0 :(state) => {
            state.show = 0
            },
        set1 :(state) => {
            state.show = 1
            },
        set2 :(state) => {
            state.show = 2
            },
        set3 :(state) => {
            state.show = 3
            },
        set4 :(state) => {
            state.show = 4
            },
        set10 :(state) => {
            state.show1 = 0
            },
        set11 :(state) => {
            state.show1 = 1
            },
        set12 :(state) => {
            state.show1 = 2
            },
        set13 :(state) => {
            state.show1 = 3
            },
        set14 :(state) => {
            state.show1 = 4
            }
    },
    actions:{
        set0:({commit})=>{
            commit('set0')
        },
        set1:({commit})=>{
            commit('set1')
        },
        set2:({commit})=>{
            commit('set2')
        },
        set3:({commit})=>{
            commit('set3')
        },
        set4:({commit})=>{
            commit('set4')
        },
        set10:({commit})=>{
            commit('set10')
        },
        set11:({commit})=>{
            commit('set11')
        },
        set12:({commit})=>{
            commit('set12')
        },
        set13:({commit})=>{
            commit('set13')
        },
        set14:({commit})=>{
            commit('set14')
        },
    },
    getters:{
        show : state => state.show,
        show1 : state => state.show1,
    },
    
}