<template>
  <v-dialog v-model="isShow" width="500" scrollable>
    <v-card>
      <v-card-title class="headline grey lighten-2"> Gambar </v-card-title>
        <v-card-text v-if="!data.value">Gambar Tidak Tersedia</v-card-text>
        <v-card-text v-else>
          <v-img
            :src="data.value"
            :lazy-src="data.value"
            aspect-ratio="1"
            class="grey lighten-2 mt-10"
          />
        </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="closeModal"> Tutup </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "project-settings-detail",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit("hide", false);
    },
  },
};
</script>