<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>          
          Ulasan Kegiatan DSO
        </h2>
      </v-col>
    </v-row>
    <v-row v-if="isErrorGetEventReviewDetail && getRole.role.create || isEdit">
    <!-- <v-row> -->
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Kegiatan</v-alert>
              <v-alert text type="info">
                Maksimal File adalah 2 MB
              </v-alert>
              <v-card class="mb-5">
                <v-card-title> Unggah Foto </v-card-title>
                <v-card-text>
                  <input @change="changeMedia" ref="myFileInput" type="file" accept="image/*" />
                  <br /><br />
                  <v-row>
                    <v-col
                      v-for="(item, index) in [...picssExist,...pics]"
                      :key="index"
                      class="d-flex child-flex"
                      cols="4"
                    >
                      <v-img
                        :src="item"
                        :lazy-src="item"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                      <v-icon color="error" style="cursor: pointer;" @click="deletePic(index)">mdi-close</v-icon>
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-text-field
                v-model="form.totalParticipant"
                label="Jumlah Peserta"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                required
                outlined
                dense
                type="number"
              ></v-text-field>

              <v-checkbox
                v-model="collabeWithCommunity"
                label="Partisipasi Aktif Komunitas"
                color="primary"
                outlined
                dense
              ></v-checkbox>
              <v-autocomplete
                  v-if="collabeWithCommunity"
                  v-model="form.collaborateWithCommunity"
                  :items="detailEvent.invitedCommunity"
                  filled
                  chips
                  label="Tambah Kommunitas"
                  item-text="name"
                  item-value="id"
                  multiple
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggleInvite"
                    >
                      <v-list-item-action>
                        <v-icon :color="isToggleActive ? '#EF233C' : ''">{{ icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{isToggleActive? "Unselect All" : "Select All" }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
              </v-autocomplete>

              <v-checkbox
                v-model="form.collaborateWithVanue"
                label="Berkolaborasi Dengan Venue"
                color="primary"
                outlined
                dense
              ></v-checkbox>

              <v-checkbox
                v-model="form.collaborateWithDRP"
                label="Berkolaborasi Dengan DRP"
                color="primary"
                outlined
                dense
              ></v-checkbox>
            </v-col>

            <!-- Konsumsi Rokok -->

            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Input Konsumsi Rokok</v-alert>
              
              <v-autocomplete
                v-model="brand_smoke"
                :items="brandrokok"
                item-text="name"
                item-value="id"
                label="Brand"
                @change="setSku"
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                :disabled="!isBrand"
                v-model="sku_smoke"
                :items="skus.skus"
                label="SKU"
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="total_smoke"
                label="Jumlah"
                :disabled="!isBrand"
                :rules="[(v) => (v > 0) || 'Total konsumsi harus lebih dari 0']"
                outlined
                dense
                type="number"
              ></v-text-field>

              <v-btn
                :disabled="total_smoke <= 0 || isNaN(total_smoke) || brand_smoke == ''"
                color="success"
                class="mr-4 mb-5"
                @click="tambahSmoke"
              >
                Tambahkan
              </v-btn>

              <v-card class="mb-5" v-if="Array.isArray(brand_smokes) && brand_smokes.length>0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left font-weight-black">BRAND</th>
                        <th class="text-left font-weight-black">SKU</th>
                        <th class="text-left font-weight-black">JUMLAH</th>
                        <th class="text-left font-weight-black"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in brand_smokes" :key="index">
                        <td class="font-weight-black">{{ item.name }}</td>
                        <td class="font-weight-black">{{ form.sku_smoke[index] }}</td>
                        <td class="font-weight-black">{{ form.total_smoke[index] }}</td>
                        <td class="font-weight-black">
                          <v-btn small icon color="error" dark @click="deleteSmoke(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>

              <!-- <v-alert v-if="isBrand" text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card v-if="isBrand">
                <v-card-title>
                  <p>Unggah Bukti Transaksi (foto/nota)</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length>0">
                  <v-btn depressed color="error" @click="deleteImageUpload"
                    >Batal</v-btn
                  >
                </v-card-text>
                <v-card-text v-else>
                  <input
                    @change="changeMedia"
                    ref="myFileInput"
                    type="file"
                    accept="image/*"
                  />
                </v-card-text>
                <v-img :src="logoDisplay" v-if="thereIsImage"></v-img>
              </v-card> -->
            </v-col>

            <!-- Konsumsi Non Rokok -->
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Input Konsumsi Non Rokok</v-alert>
              <v-autocomplete
                v-model="product_nonSmoke"
                :items="products"
                item-text="name"
                item-value="name"
                label="Produk"
                @change="setBrand"
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="brand_nonSmoke"
                :disabled="!isProduct"
                :items="brandsByProduct"
                item-text="name"
                item-value="id"
                label="Brand"
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="total_nonSmoke"
                label="Jumlah"
                :disabled="!isProduct"
                :rules="[(v) => (v > 0) || 'Total konsumsi harus lebih dari 0']"
                required
                outlined
                dense
                type="number"
              ></v-text-field>

              <v-btn
                :disabled="total_nonSmoke <= 0 || isNaN(total_nonSmoke) || brand_nonSmoke == ''"
                color="success"
                class="mr-4 mb-5"
                @click="tambahNonSmoke"
              >
                Tambahkan
              </v-btn>

              <v-card class="mb-5" v-if="Array.isArray(brand_nonSmokes) && brand_nonSmokes.length>0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left font-weight-black">PRODUK</th>
                        <th class="text-left font-weight-black">BRAND</th>
                        <th class="text-left font-weight-black">JUMLAH</th>
                        <th class="text-left font-weight-black"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in brand_nonSmokes" :key="index">
                        <td class="font-weight-black">{{ form.product_nonSmoke[index] }}</td>
                        <td class="font-weight-black">{{ item.name }}</td>
                        <td class="font-weight-black">{{ form.total_nonSmoke[index] }}</td>
                        <td class="font-weight-black">
                          <v-btn small icon color="error" dark @click="deleteNonSmoke(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <!-- <v-alert v-if="isProduct" text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card v-if="isProduct">
                <v-card-title>
                  <p>Unggah Bukti Transaksi (foto/nota)</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp1.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload1"
                    >Batal</v-btn
                  >
                </v-card-text>
                <v-card-text v-else>
                  <input
                    @change="changeMedia1"
                    ref="myFileInput"
                    type="file"
                    accept="image/*"
                  />
                </v-card-text>
                <v-img :src="logoDisplay1" v-if="thereIsImage1"></v-img>
              </v-card> -->
            </v-col>
          </v-row >
          <v-row v-if="!isEdit" justify="start">
            <v-col md="2" sm="6">
              <v-btn
                :disabled="isNaN(form.totalParticipant) || form.totalParticipant <= 0"
                color="success"
                class="mr-4"
                @click="submit"
              >
                Ulas
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else justify="start">
            <v-col md="2" sm="6">
              <v-btn
                :disabled="isNaN(form.totalParticipant) || form.totalParticipant <= 0"
                color="success"
                class="mr-4"
                @click="submitEdit"
              >
                Submit Edit
              </v-btn>
            </v-col>
            <v-col md="2" sm="6">
              <v-btn
                :disabled="!valid"
                color="error"
                class="mr-4"
                @click="cancleEdit"
              >
                Batal
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-card v-else >
      <v-container v-if="isErrorGetEventReviewDetail" class="p-4">
        Kegiatan DSO belum diulas
      </v-container>
      <v-container v-else class="p-4">
        <v-row>
          <v-col md="12" sm="12" class="p-5">
            <v-form ref="observer" v-model="valid" lazy-validation>
              <v-row>
                <v-col md="4" sm="12" class="p-5">
                  <v-alert type="info">Informasi Kegiatan</v-alert>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Jumlah Peserta</v-list-item-title>
                      <v-list-item-subtitle>{{ eventreviewDetail.totalPaticipant }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Foto Kegiatan</v-list-item-title>
                      <v-list-item-subtitle v-if="!eventreviewDetail.images || eventreviewDetail.images.length==0">Foto Kegiatan Tidak Tersedia</v-list-item-subtitle>
                      <v-row v-else>
                        <v-col
                          v-for="(item, index) in eventreviewDetail.images"
                          :key="index"
                          class="d-flex child-flex"
                          cols="4"
                        >
                          <v-img
                            :src="item"
                            :lazy-src="item"
                            aspect-ratio="1"
                            class="grey lighten-2"
                          >
                          <v-icon color="success" style="cursor: pointer;" @click="zoomImage(item)">mdi-magnify</v-icon>
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Berkolaborasi Dengan Venue</v-list-item-title>
                      <v-list-item-subtitle>{{ eventreviewDetail.collaborateWithVanue ? "Iya":"Tidak" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Berkolaborasi Dengan DRP</v-list-item-title>
                      <v-list-item-subtitle>{{ eventreviewDetail.collaborateWithDRP ? "Iya":"Tidak" }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col md="4" sm="12" class="p-5">
                  <v-alert type="info">Input Konsumsi Rokok</v-alert>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Konsumsi Rokok</v-list-item-title>
                      <v-list-item-subtitle v-if="!eventreviewDetail.smokes || eventreviewDetail.smokes.length==0">Data Konsumsi Rokok Tidak Tersedia</v-list-item-subtitle>
                      <v-card class="mb-5" v-else>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left font-weight-black">BRAND</th>
                                <th class="text-left font-weight-black">SKU</th>
                                <th class="text-left font-weight-black">JUMLAH</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item,index) in eventreviewDetail.smokes" :key="index">
                                <td class="font-weight-black">{{ item.brand_smoke? item.brand_smoke.name: '-' }}</td>
                                <td class="font-weight-black">{{ item.sku_smoke }}</td>
                                <td class="font-weight-black">{{ item.total_smoke }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col md="4" sm="12" class="p-5">
                  <v-alert type="info">Input Konsumsi Non Rokok</v-alert>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Konsumsi Non Rokok</v-list-item-title>
                      <v-list-item-subtitle v-if="!eventreviewDetail.nonSmokes || eventreviewDetail.nonSmokes.length==0">Data Konsumsi Non Rokok Tidak Tersedia</v-list-item-subtitle>
                      <v-card class="mb-5" v-else>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left font-weight-black">PRODUK</th>
                                <th class="text-left font-weight-black">BRAND</th>
                                <th class="text-left font-weight-black">JUMLAH</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item,index) in eventreviewDetail.nonSmokes" :key="index">
                                <td class="font-weight-black">{{ item.product_nonSmoke }}</td>
                                <td class="font-weight-black">{{ item.brand_nonSmoke? item.brand_nonSmoke.name: '-'}}</td>
                                <td class="font-weight-black">{{ item.total_nonSmoke }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Partisipasi Aktif Komunitas</v-list-item-title>
                    <div v-if="Array.isArray(eventreviewDetail.collaborateWithCommunity) && eventreviewDetail.collaborateWithCommunity.length>0">
                      <v-simple-table>
                        <template >
                          <thead>
                            <tr>
                              <th class="text-left">Nomor</th>
                              <th class="text-left">Nama Komunitas</th>
                              <th class="text-left">PIC</th>
                              <th class="text-left">Brand</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr v-for="(item,index) in eventreviewDetail.collaborateWithCommunity" :key="item.id">
                              <td>{{index+1}}</td>
                              <td>{{item.name}}</td>
                              <td>{{item.pic.name}}</td>
                              <td>{{ item.brand? item.brand.brandCommunity:'No Data' }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                    <v-list-item-subtitle v-else>Tidak</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="getRole.role.update">
          <v-col md="3" sm="12">
            <v-btn
              class="float-right"
              depressed
              color="warning"
              @click="setEdit(eventreviewDetail)"
              block
            >
              Ubah Ulasan
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog v-model="isZoom" width="500" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2"> Gambar </v-card-title>
          <v-img :src="imageZoomed"></v-img>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="isZoom = false"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isErrorUploadMedia"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseMediaUpload"
    />
  </v-container>
</template>

<script>
import { EventReviewControllers } from "../../controllers/EventReviewControllers";
import { BrandControllers } from "../../controllers/BrandControllers";
import { MediaControllers } from "../../controllers/MediaControllers";
import { CommunityControllers} from "../../controllers/CommunityControllers";
import { EventControllers } from "../../controllers/EventControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  name: "create-user",
  data: () => ({
    brand: new BrandControllers(),
    event: new EventControllers(),
    eventreview: new EventReviewControllers(),
    media: new MediaControllers(),
    community : new CommunityControllers(),
    create:true,
    valid:false,

    isBrand: false,
    isProduct: false,

    selectScope: {
      page: 1,
      limit: 600,
    },

    isLoading: false,
    fullPage: true,

    collabeWithCommunity:false,
    isToggleActive: false,
    brand_smoke: '',
    sku_smoke: '',
    total_smoke: null,
    brand_nonSmoke: '',
    product_nonSmoke: '',
    total_nonSmoke: null,

    brand_smokes: [],
    brand_nonSmokes: [],
    pics: [],
    picssExist:[],


    form: {
      totalParticipant: null,
      activeParticipation: 0,
      collaborateWithCommunity: [],
      collaborateWithVanue: false,
      collaborateWithDRP: false,
      brandId_smoke: [],
      sku_smoke: [],
      total_smoke: [],
      brandId_nonSmoke: [],
      product_nonSmoke: [],
      total_nonSmoke: [],
      images:[],
      // images_smoke: '',
      // images_nonSmoke: '',
      logoTemp:[],
      logoTemp1:[]
    },

    // edit
    editId: '',
    isEdit: false,
    isImageUploaded: false,
    // isImageUploaded1: false,

    // image zoom
    isZoom : false,
    imageZoomed: null
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    this.getBrand()
    this.getEventReviewDetail()
    this.getCommunityList()
    this.getEvents()
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },

    detailEvent() {
      const event = this.event.eventDetail;
      if (event) {
        return {
          ...event,
          invitedCommunity: event.invitedCommunity? event.invitedCommunity:[]
        };
      }

      return null;
    },

    // eventreviews() {
    //   return this.eventreview.eventreviewList;
    // },

    // isErrorCode() {
    //   return this.eventreview.errorCode;
    // },

    // isError() {
    //   return this.eventreview.isError;
    // },

    // errorCauseEventReview() {
    //   return this.eventreview.errorCause;
    // },

    // isLoadingEventReview() {
    //   return this.eventreview.isLoading;
    // },

    // // delete
    // isErrorDelete() {
    //   return this.eventreview.isErrorDelete;
    // },

    // errorCauseDelete() {
    //   return this.eventreview.errorCauseDelete;
    // },

    // isLoadingDelete() {
    //   return this.eventreview.isLoading;
    // },

    // Brand
    communities(){
      return this.community.communityList.filter(community=>!community.isFreeze && community.isVerified)
    },

    brands() {
      return this.brand.brandList;
    },
    products(){
      const product = this.brand.brandList.filter((item) => item.product != "Rokok").map(brand=>brand.product);
      let unique = [...new Set(product)]
      return unique.map(product => ({name:product}))
    },

    brandrokok() {
      return this.brand.brandList.filter((item) => item.product === "Rokok");
    },

    skus(){
      if(this.brand_smoke){
        return this.brand.brandList.find((item) => item.id === this.brand_smoke)
      }
      return []
    },

    brandsByProduct() {
      if(this.product_nonSmoke){
      return this.brand.brandList.filter((item) => item.product === this.product_nonSmoke)
      }
      return []
    },

    errorCodeGetBrands() {
      return this.brand.errorCode;
    },

    isErrorGetBrands() {
      return this.brand.isError;
    },

    errorCauseGetBrands() {
      return this.brand.errorCause;
    },

    eventreviewDetail(){
      const detail = this.eventreview.eventreviewDetail
      
      if(detail){
        return detail 
      }
      return null
    },

    isErrorGetEventReviewDetail(){
      return this.eventreview.isError
    },

    icon () {
      if (this.isToggleActive) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },

    
  },

  methods: {
    remove (item) {
      const index = this.form.collaborateWithCommunity.indexOf(item.id)
      if (index >= 0) this.form.collaborateWithCommunity.splice(index, 1)
    },

    async getEvents() {
      return this.event.getEventDetailEdit(
        this.$route.params.id
      );
    },

    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.pics.push(e.target.result);
          this.isImageUploaded = true
        };
      }
    },

    deletePic(index) {
      const n = this.form.images.length-1
      if(n >=index){
        this.form.images.splice(index,1)
        this.picssExist.splice(index,1)
      }
      this.pics.splice(index-n, 1);
    },

    deleteImageUpload() {
      this.pics = [];
      this.picssExist = [];
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    getEventReviewDetail(){
      return this.eventreview.getEventReviewDetail(this.$route.params.id)
    },

    zoomImage(img) {
      this.isZoom = true;
      this.imageZoomed = img;
    },

    setEdit(datum){
      this.isEdit = true
      this.editId = datum.id
      this.form.totalParticipant = datum.totalPaticipant
      this.collabeWithCommunity = Array.isArray(datum.collaborateWithCommunity) && datum.collaborateWithCommunity.length>0? true:false
      this.form.collaborateWithCommunity = Array.isArray(datum.collaborateWithCommunity) && datum.collaborateWithCommunity.length>0? datum.collaborateWithCommunity.map(community=>community.id):[]
      this.form.collaborateWithVanue = datum.collaborateWithVanue
      this.form.collaborateWithDRP = datum.collaborateWithDRP
      if(datum.smokes && datum.smokes.length>0){
        this.brand_smokes = datum.smokes.map(smoke=>smoke.brand_smoke)
        this.form.brandId_smoke = datum.smokes.map(smoke=>smoke.brand_smoke.id)
        this.form.sku_smoke = datum.smokes.map(smoke=>smoke.sku_smoke)
        this.form.total_smoke = datum.smokes.map(smoke=>smoke.total_smoke)
      }
      if(datum.nonSmokes && datum.nonSmokes.length>0){
        this.brand_nonSmokes = datum.nonSmokes.map(smoke=>smoke.brand_nonSmoke)
        this.form.brandId_nonSmoke = this.brand_nonSmokes.map(smoke=>smoke.id)
        this.form.product_nonSmoke = datum.nonSmokes.map(smoke=>smoke.product_nonSmoke)
        this.form.total_nonSmoke = datum.nonSmokes.map(smoke=>smoke.total_nonSmoke)
      }
      this.picssExist = datum.images
      this.form.images = datum.images

    },

    async cancleEdit(){
      Object.assign(this.$data, this.$options.data.call(this))
      this.getBrand()
      this.getEventReviewDetail()
      this.getCommunityList()
      this.getEvents()
    },


    setSku(){
      this.isBrand=true
    },

    setBrand(){
      this.isProduct=true
    },

    async getBrand() {
      return this.brand.getBrandList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    async getCommunityList() {
      return this.community.getCommunityList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    back() {
      this.$router.go(-1);
    },

  // Create Process
    async submitProcess(
      totalParticipant,
      collaborateWithCommunity,
      collaborateWithVanue,
      collaborateWithDRP,
      brandId_smoke,
      sku_smoke,
      total_smoke,
      brandId_nonSmoke,
      product_nonSmoke,
      total_nonSmoke,
      images
    ) {
      return this.eventreview.createEventReview(
        this.$route.params.id,
        totalParticipant,
        collaborateWithCommunity,
        collaborateWithVanue,
        collaborateWithDRP,
        brandId_smoke,
        sku_smoke,
        total_smoke,
        brandId_nonSmoke,
        product_nonSmoke,
        total_nonSmoke,
        images
      );
    },

    async submit() {
      
      if (parseInt(this.form.totalParticipant)>0) {
        this.isLoading = true;

        if (this.pics.length > 0) {
        await this.uploadMedia("post", this.pics);

          if (this.isErrorUploadMedia) {
            Swal.fire({
              icon: "error",
              title: "KESALAHAN",
              text: this.errorCauseMediaUpload,
            });
            // this.isLoading = false;
            this.isErrorUploadMedia = false;
          } else {
            // this.isLoading = false;
            this.form.images = this.medias;
          }
        }

        await this.submitProcess(
          parseInt(this.form.totalParticipant),
          this.form.collaborateWithCommunity,
          this.form.collaborateWithVanue,
          this.form.collaborateWithDRP,
          this.form.brandId_smoke,
          this.form.sku_smoke,
          this.form.total_smoke,
          this.form.brandId_nonSmoke,
          this.form.product_nonSmoke,
          this.form.total_nonSmoke,
          this.form.images
        )

        if (this.isErrorCreateEvent) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateEvent,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
          this.cancleEdit()
        }
      }
    },

    // Edit Process
    async editProcess(
      totalParticipant,
      collaborateWithCommunity,
      collaborateWithVanue,
      collaborateWithDRP,
      brandId_smoke,
      sku_smoke,
      total_smoke,
      brandId_nonSmoke,
      product_nonSmoke,
      total_nonSmoke,
      images
    ) {
      return this.eventreview.updateEventReview(
        this.editId,
        this.$route.params.id,
        totalParticipant,
        collaborateWithCommunity,
        collaborateWithVanue,
        collaborateWithDRP,
        brandId_smoke,
        sku_smoke,
        total_smoke,
        brandId_nonSmoke,
        product_nonSmoke,
        total_nonSmoke,
        images
      );
    },

    async submitEdit() {
      if (parseInt(this.form.totalParticipant)>0) {
        this.isLoading = true;

        if (this.isImageUploaded) {
        this.isLoading = true;
        await this.uploadMedia("event", this.pics);

          if (this.isErrorUploadMedia) {
            Swal.fire({
              icon: "error",
              title: "KESALAHAN",
              text: this.errorCauseMediaUpload,
            });
            // this.isLoading = false;
            this.isErrorUploadMedia = false;
          } else {
            // this.isLoading = false;
            const exist = this.form.images
            const newImg = this.medias
            this.form.images = [...exist,...newImg];
          }
        }
        await this.editProcess(
          parseInt(this.form.totalParticipant),
          this.form.collaborateWithCommunity,
          this.form.collaborateWithVanue,
          this.form.collaborateWithDRP,
          this.form.brandId_smoke,
          this.form.sku_smoke,
          this.form.total_smoke,
          this.form.brandId_nonSmoke,
          this.form.product_nonSmoke,
          this.form.total_nonSmoke,
          this.form.images
        )

        if (this.isErrorCreateEvent) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateEvent,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
          this.cancleEdit()
        }
      }
    },

    toggleInvite(){
      this.isToggleActive = !this.isToggleActive
      const communities = this.detailEvent.invitedCommunity
      if(this.isToggleActive == true){
        this.form.collaborateWithCommunity = communities.map(community => community.id)
      }else{
        this.form.collaborateWithCommunity = []
      }
    },

    tambahSmoke(){
      this.brand_smokes.push(this.brand.brandList.find(brand=>brand.id== this.brand_smoke))
      this.form.brandId_smoke.push(this.brand_smoke)
      this.form.sku_smoke.push(this.sku_smoke)
      this.form.total_smoke.push(this.total_smoke)

      this.brand_smoke = ''
      this.sku_smoke = ''
      this.total_smoke = null
      this.isBrand = false
    },

    tambahNonSmoke(){
      this.brand_nonSmokes.push(this.brand.brandList.find(brand=>brand.id== this.brand_nonSmoke))
      this.form.brandId_nonSmoke.push(this.brand_nonSmoke)
      this.form.product_nonSmoke.push(this.product_nonSmoke)
      this.form.total_nonSmoke.push(this.total_nonSmoke)

      this.brand_nonSmoke = ''
      this.product_nonSmoke = ''
      this.total_nonSmoke = null
      this.isProduct = false
    },

    deleteSmoke(index){
      this.brand_smokes.splice(index,1)
      this.form.brandId_smoke.splice(index,1)
      this.form.sku_smoke.splice(index,1)
      this.form.total_smoke.splice(index,1)
    },

    deleteNonSmoke(index){
      this.brand_nonSmokes.splice(index,1)
      this.form.brandId_nonSmoke.splice(index,1)
      this.form.product_nonSmoke.splice(index,1)
      this.form.total_nonSmoke.splice(index,1)
    }
  },
}
</script>
