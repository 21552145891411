import axios from "axios";
import { 
    GetDashboarTotalCommunity, 
    GetCommunityByArea,
    GetBrandByDSO,
    GetBrandByStatusDSO,
    GetBrandByAgeDSO,
    GetBrandByCollabDSO,
    GetDashboardCardMember,
    GetMemberActiveByArea,
    GetMemberActiveByDSO,
    GetMemberActiveByAge,
    GetMemberActiveStatsByDSO,
    GetMemberInsightByDSO,
    GetMemberAchievmentByDSO,
    GetSalesAchievmentByDSO,
    GeteventStatByDSO,
    GetEventSharingByDSO,
    GetAtributeByDSO,
    GetPeriodSales,
    GetPostByDSO
} from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const GetDashboarTotalCommunityRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetDashboarTotalCommunity()}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}


export const GetCommunityByAreaRequest = (area) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetCommunityByArea(area)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetBrandByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetBrandByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetBrandByStatusDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetBrandByStatusDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetBrandByAgeDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetBrandByAgeDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetBrandByCollabDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetBrandByCollabDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetDashboardCardMemberRequest = () => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetDashboardCardMember()}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetMemberActiveByAreaRequest = (area) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetMemberActiveByArea(area)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetMemberActiveByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetMemberActiveByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetMemberActiveByAgeRequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetMemberActiveByAge(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetMemberActiveStatsByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetMemberActiveStatsByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}


export const GetMemberInsightByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetMemberInsightByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetMemberAchievmentByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetMemberAchievmentByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetSalesAchievmentByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetSalesAchievmentByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GeteventStatByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GeteventStatByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetEventSharingByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetEventSharingByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetAtributeByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetAtributeByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetPeriodSalesRequest = (dso, year, brand) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetPeriodSales(dso, year, brand)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const GetPostByDSORequest = (dso) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${GetPostByDSO(dso)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}
