<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Daftarkan Prestasi
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-alert text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card>
                <v-card-title>
                  <p>Unggah Gambar/Foto Prestasi</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload"
                    >Batal</v-btn
                  >
                </v-card-text>
                <v-card-text v-else>
                  <input
                    @change="changeMedia"
                    ref="myFileInput"
                    type="file"
                    accept="image/*"
                  />
                </v-card-text>
                <v-img :src="detailAchievement.image" v-if="thereIsImage"></v-img>
              </v-card>
            </v-col>

            <v-col md="6" sm="12" class="p-5">

              <v-list-item two-line v-if="detailAchievement.community" class="mb-5">
                <v-list-item-content>
                  <v-list-item-title>Komunitas</v-list-item-title>
                  <v-list-item-subtitle>{{ detailAchievement.community.name }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-text-field
                v-model="detailAchievement.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Prestasi"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="detailAchievement.year"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tahun"
                required
                outlined
                dense
                type="number"
              ></v-text-field>

              <v-autocomplete
                v-model="detailAchievement.scale"
                :items="achievements"
                item-text="key"
                item-value="key"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Skala Prestasi"
                required
                outlined
                dense
                @change="choseAchievement"
              ></v-autocomplete>

              <v-text-field
                v-model="detailAchievement.nomination"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nominasi"
                required
                outlined
                dense
              ></v-text-field>

              <v-checkbox
                v-model="detailAchievement.isContract"
                label="Member/Komunitas Pernah Dikontrak oleh Perusahaan"
                required
                outlined
                dense
                @change="setContract"
              ></v-checkbox>

            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4 mt-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isErrorGetAchievement && errorCodeGetAchievement !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAchievement"
    />




  </v-container>
</template>

<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { AchievementControllers } from "../../controllers/AchievementControllers";
import Swal from "sweetalert2";
// import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { MediaControllers } from "../../controllers/MediaControllers.js";

export default {
  name: "create-achievement",
  data: () => ({
    achievement: new AchievementControllers(),
    // community: new CommunityControllers(),
    media: new MediaControllers(),
    valid: true,
    title: "Buat Prestasi",

    isLoading: false,
    fullPage: true,
    brandChosen : null,
    isBrandChosen: false,

    achievements:[
      {
      key:'Lokal',
      value: 1
      },
      {
      key:'Kota/Kabupaten',
      value: 2
      },
      {
      key:'Provinsi',
      value: 3
      },
      {
      key:'Nasional',
      value: 4
      }
    ],

    chosenAchievement:0,
    // isContract: false,

    form: {
      image: "",
      logoTemp: []
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    isImageUploaded: false,
    thereIsImage: true,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // achievement
    isErrorGetAchievement() {
      return this.achievement.isError;
    },

    errorCauseGetAchievement() {
      return this.achievement.errorCause;
    },

    isLoadingGetAchievement() {
      return this.achievement.isLoading;
    },

    // // community
    // communities() {
    //   return this.community.communityList.filter(community=>community.category==='e-sport');
    // },

    // errorCodeGetCommunities() {
    //   return this.community.errorCode;
    // },

    // isErrorGetCommunities() {
    //   return this.community.isError;
    // },

    // errorCauseGetCommunities() {
    //   return this.community.errorCause;
    // },

    // isLoadingCommunities() {
    //   return this.community.isLoading;
    // },


    // media
   
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },

    detailAchievement() {
      let achiev = this.achievement.achievementDetail;
      if(achiev){
        return{
          ...achiev,
          name: achiev.name? achiev.name : '',
          year: achiev.year? achiev.year : '',
          scale: achiev.scale ? achiev.scale : '',
          nomination: achiev.nomination ? achiev.nomination : '',
          isContract: achiev.isContract ? achiev.isContract : false,
          score: achiev.score? achiev.score : 0

        }
      }
      return null
    }
  },

  created() {
    // this.getCommunities();
    this.getAchievement()
  },


  methods: {
    async getAchievement() {
      return this.achievement.getAchievementDetailEdit(
        this.$route.params.id
      );
    },

    setContract(isContract){
      if(isContract){
        this.detailAchievement.score = 5
      }else{
        this.detailAchievement.score = this.chosenAchievement || 0
      }
    },

    choseAchievement(newVal){
      const val = this.achievements.find(achiev=>achiev.key==newVal).value
      this.chosenAchievement = val
      if(!this.detailAchievement.isContract){
        this.detailAchievement.score = val
      }
    },

    back() {
      this.$router.go(-1);
    },

    async getCommunities() {
      return this.community.getCommunityList(1, 9999);
    },

    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.isImageUploaded = true;
          this.thereIsImage = true;
          this.detailAchievement.image = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.detailAchievement.image = null;
      this.thereIsImage = false;
      this.isImageUploaded = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submitProcess(
      name,
      year,
      scale,
      nomination,
      image,
      isContract,
      score
    ) {
      return this.achievement.updateAchievementList(
        this.$route.params.id,
        name,
        year,
        scale,
        nomination,
        image,
        isContract,
        score
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (this.isImageUploaded) {
        this.isLoading = true;
        await this.uploadMedia('community', this.form.logoTemp);

        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isLoading = false;
          this.isErrorUploadMedia = false;
        } else {
          this.isLoading = false;
          this.form.image = this.medias[0];
        }
      }

      if (validate && !this.isErrorUploadMedia) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailAchievement.name,
          this.detailAchievement.year,
          this.detailAchievement.scale,
          this.detailAchievement.nomination,
          this.isImageUploaded ? this.form.image : this.detailAchievement.image,
          this.detailAchievement.isContract,
          this.detailAchievement.score
        );

        if (this.isErrorCreateAchievement) {
          this.isLoading = false;
          this.isErrorCreateAchievement = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateAchievement,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diupdate",
          })
          this.$refs.observer.reset();
          this.back()
        }
      }
    },
  },
};
</script>
