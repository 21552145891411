import {
    CreateUserRequest,
    DeleteUserRequest,
    UpdateUserRequest,
    FilterUserAMRequest,
    UserDetailRequest
} from "../request/UserRequest.js";

export class UserRoleControllers {
    userList = new Array();
    userDetail = new Object();
    userDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getUserList(role, page, limit, keyword, dsoId) {
        this.setLoading(true);
        const resp = FilterUserAMRequest(role, page, limit, keyword, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setUserList(response.data.data);
                if(!response.data.data || response.data.data.length == 0){
                    this.setError(true);
                    this.setErrorcode(404);
                }
                return true;
            }).catch(err => {
                this.setUserList([])
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getUserDetailEdit(id) {
        this.setLoading(true);
        const resp = UserDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setUserDetail(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadUserList(role, page, limit, keyword, dsoId) {
        this.setLoadingDownload(true);
        const resp = FilterUserAMRequest(role, page, limit, keyword, dsoId)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setUserListDownload(response.data.data);
            }).catch(err => {
                this.setUserListDownload([])
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setUserListDownload(data) {
        this.userDownload = data;
    }

    setUserDetail(data) {
        this.userDetail = data;
    }

    setUserList(data) {
        this.userList = data;
    }

    createUserList(
        email,
        name,
        role,
        gender,
        mobileNumber,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        pob,
        dob,
        dso
    ) {
        this.setLoading(true);
        const resp = CreateUserRequest(
                email,
                name,
                role,
                gender,
                mobileNumber,
                province,
                district,
                subDistrict,
                village,
                street,
                zipCode,
                pob,
                dob,
                dso
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getUserDetail(id) {
        this.userDetail = this.userList.find(item => {
            return item.id === id
        });
    }

    deleteUser(id) {
        this.setLoading(true);
        const resp = DeleteUserRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateUserList(
        id,
        email,
        name,
        role,
        gender,
        mobileNumber,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        pob,
        dob,
        dso
    ) {
        this.setLoading(true);
        const resp = UpdateUserRequest(
                id,
                email,
                name,
                role,
                gender,
                mobileNumber,
                province,
                district,
                subDistrict,
                village,
                street,
                zipCode,
                pob,
                dob,
                dso
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}