<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" sm="12" class="p-5" v-if="projectLength > 0">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-text-field
            v-model="detailProject.name"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Nama Project"
            required
            outlined
            dense
          ></v-text-field>

          <v-textarea
            v-model="detailProject.description"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Deskripsi"
            outlined
            required
            dense
          ></v-textarea>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>

      <v-col md="12" sm="12" class="p-5" v-else>
        <v-row>
          <v-col md="4" sm="12" class="p-5">
            <p>Memuat form...</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

  </v-container>
</template>
<script>
import { ProjectControllers } from "../../controllers/ProjectControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "create-brand",
  data: () => ({
    project: new ProjectControllers(),
    title: "Ubah Project",
    valid: true,

    selectScope: {
      page: 1,
      limit: 600,
    },

    isLoading: false,
    fullPage: true,
  }),

  components: {
    Loading,
  },

  computed: {
    // project length
    projectLength() {
      return this.project.projectList.length;
    },

    isErrorUpdateProject() {
      return this.project.isError;
    },

    errorCauseUpdateProject() {
      return this.project.errorCause;
    },

    isLoadingUpdateProject() {
      return this.project.isLoading;
    },

    // detail project
    detailProject() {
      const project = this.project.projectList.find(
        (item) => item.id === this.$route.params.id
      );

      if (project) {
        return {
          ...project
        };
      }

      return null;
    },
  },

  created() {
      this.getProjects();
  },

  methods: {
    async getProjects() {
      return this.project.getProjectsList(
        this.selectScope.page,
        this.selectScope.limit,
        ""
      );
    },

    back() {
      this.$router.go(-1);
    },

    async submitProcess(name, description) {
      return this.project.updateProjectList(
        this.$route.params.id,
        name,
        description
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailProject.name,
          this.detailProject.description
        );

        if (this.isErrorUpdateProject) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateProject,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });
          this.back();
        }
      }
    },
  },
};
</script>