<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-autocomplete
            v-model="form.project"
            :items="projects"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Nama Project"
            required
            outlined
            dense
          ></v-autocomplete>

          <v-text-field
            v-model="form.name"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Nama Sub Project"
            required
            outlined
            dense
          ></v-text-field>

          <v-textarea
            v-model="form.description"
            :rules="[(v) => !!v || 'Kolom wajib diisi']"
            label="Deskripsi"
            outlined
            required
            dense
          ></v-textarea>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isProjectListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseProjects"
    />
  </v-container>
</template>
<script>
import { ProjectControllers } from "../../controllers/ProjectControllers.js";
import { SubprojectControllers } from "../../controllers/SubprojectControllers.js";
import Alert from "../../components/alert";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "create-subproject",
  data: () => ({
    project: new ProjectControllers(),
    subproject: new SubprojectControllers(),
    title: "Buat Sub Project",
    valid: true,
    
    form: {
      project: null,
      name: "",
      description: "",
    },
    projectScope: {
      page: 1,
      limit: 10,
    },
    isLoading: false,
    fullPage: true,
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    this.getProjects();
  },

  computed: {
    // project
    projects() {
      return this.project.projectList;
    },

    isProjectListError() {
      return this.project.isError;
    },

    errorCauseProjects() {
      return this.project.errorCause;
    },

    // sub project
    isErrorCreateSubProject() {
      return this.subproject.isError;
    },

    errorCauseCreateSubProject() {
      return this.subproject.errorCause;
    },

    isLoadingCreateSubProject() {
      return this.subproject.isLoading;
    },
  },

  methods: {
    getProjects() {
      return this.project.getProjectsList(
        this.projectScope.page,
        this.projectScope.limit
      );
    },

    back() {
      this.$router.go(-1);
    },

    async submitProcess(project, name, description) {
      return this.subproject.createSubProjectList(
        project,
        name,
        description
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.project,
          this.form.name,
          this.form.description
        );

        if (this.isErrorCreateSubProject) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateSubProject,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>