var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-8"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('h2',[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.back}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_vm._v(" "+_vm._s(_vm.title)+" ")],1)])],1),_c('v-row',[_c('v-col',{staticClass:"p-5",attrs:{"md":"12","sm":"12"}},[_c('v-form',{ref:"observer",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"p-5",attrs:{"md":"4","sm":"12"}},[_c('v-alert',{attrs:{"type":"info"}},[_vm._v("Informasi Umum")]),_c('v-autocomplete',{attrs:{"items":_vm.brands,"item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'Kolom wajib diisi'; }],"label":"Nama Brand","required":"","outlined":"","dense":""},model:{value:(_vm.form.brands),callback:function ($$v) {_vm.$set(_vm.form, "brands", $$v)},expression:"form.brands"}}),_c('v-autocomplete',{attrs:{"items":_vm.communities,"item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'Kolom wajib diisi'; }],"label":"Nama Komunitas","required":"","outlined":"","dense":""},model:{value:(_vm.form.communities),callback:function ($$v) {_vm.$set(_vm.form, "communities", $$v)},expression:"form.communities"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.form.startDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "startDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "startDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Mulai","rules":[function (v) { return !!v || 'Kolom wajib diisi'; }],"readonly":"","required":"","outlined":"","dense":""},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.form.startDate)}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.form.endDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "endDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "endDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Akhir","rules":[function (v) { return !!v || 'Kolom wajib diisi'; }],"readonly":"","required":"","outlined":"","dense":""},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.form.endDate)}}},[_vm._v(" OK ")])],1)],1),_c('v-text-field',{attrs:{"rules":[
                function (v) { return Number.isInteger(Number(v)) ||
                  'Isian harus angka'; },
                function (v) { return !!v || 'Kolom wajib diisi'; } ],"label":"Target","required":"","outlined":"","dense":""},model:{value:(_vm.form.goal),callback:function ($$v) {_vm.$set(_vm.form, "goal", _vm._n($$v))},expression:"form.goal"}})],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.submit}},[_vm._v(" Buat ")])],1)],1)],1),_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('Alert',{attrs:{"status":_vm.isErrorGetBrands && _vm.errorCodeGetBrands !== 404,"icon":"error","title":"KESALAHAN","msg":_vm.errorCauseGetBrands}}),_c('Alert',{attrs:{"status":_vm.isErrorGetCommunity && _vm.errorCodeGetCommunity !== 404,"icon":"error","title":"KESALAHAN","msg":_vm.errorCauseGetCommunity}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }