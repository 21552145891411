<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
    </v-row>
    <TopReport :dsos="areaDSOList" />
    <BottomReport :dsos="areaDSOList" />
  </v-container>
</template>
<script>
import TopReport from "./components/TopReport.vue"
import BottomReport from "./components/BottomReport.vue"
import { mapGetters, mapActions } from "vuex";
import { AreaManagerControllers } from "../../../controllers/AreaManagerControllers.js";

export default {
  name: "achievement-index",
  data: () => ({
    area: new AreaManagerControllers(),
    title: "Laporan Komunikasi",
  }),

  methods: {
    ...mapActions(["getAreaDSO"]),
  },

  created() {
    this.getAreaDSO();
  },

  computed: {
    ...mapGetters(["areaDSOList"]),
    isLoadingDSOS() {
      return this.area.isLoading;
    },
    isErrorDSOS() {
      return this.area.isError;
    },
    errorCauseDSOS() {
      return this.area.errorCause;
    },
    errorCodeDSOS() {
      return this.area.errorCode;
    },
  },

  components: {
      TopReport,
      BottomReport
  },
};
</script>
