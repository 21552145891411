import { UploadMediaRequest } from "../request/MediaRequest.js";

export class MediaControllers  {
    isSuccess = false;
    successMsg = "";
    isError= false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    constructor() {}

    uploadMedia(
        type,
        image
    ) {
        this.setLoading(true);
        const resp = UploadMediaRequest(
            type,
            image
        )
        .then((response) => {
            this.setError(false);
            this.setSuccess(true);
            this.setSuccessMsg(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setSuccess(status) {
        this.isSuccess = status;
    }

    setSuccessMsg(msg) {
        this.successMsg = msg;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }
}

