<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="7" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh Excel
        </v-btn>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
          block
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
          <v-autocomplete
            :items="allUserAM"
            v-model="dso"
            item-text="name"
            item-value="id"
            label="Pilih DSO"
            dense
            block
            right
            outlined
          ></v-autocomplete>
        </v-col>
    </v-row>

    <v-row v-if="!isLoadingUser">
      <v-col md="12" sm="12">
        <v-card v-if="errorCodeGetUsers === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Pengguna</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Gender</th>
                  <th class="text-left">Komunitas</th>
                  <th class="text-left">Telepon</th>
                  <th class="text-left">Rincian</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in users" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{item.gender?item.gender: "No Data" }}</td>
                  <td>{{item.community?item.community.name: "No Data" }}</td>
                  <td>{{ item.mobileNumber }}</td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn
                      small
                      icon
                      color="warning"
                      dark
                      @click="edit(item.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deleteUser(item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Detail :isShow="isDetail" :data="userDetail" @hide="updateModal" />

    <Alert
      :status="isErrorGetUsers && errorCodeGetUsers !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetUsers"
    />

    <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />

    <Alert
      :status="isErrorDownload && isErrorCodeDownload !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseDownload"
    />
  </v-container>
</template>
<script>
import { UserRoleControllers } from "../../controllers/UserRoleControllers.js";
import { UserControllers } from "../../controllers/UserControllers";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Detail from "./user-detail";
import Swal from "sweetalert2";
import moment from "moment";
import XLSX from "xlsx";
import { getRole} from "../../external/functions/Cookies";

export default {
  name: "user-list",
  data: () => ({
    user: new UserRoleControllers(),
    userControllers: new UserControllers(),
    title: "Pengguna Member",
    dso:'',
    userTable: {
      role: "user.member",
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },

    dialogDownload: false,
    startDate: false,
    endDate: false,
    filter: {
      startDate: "",
      endDate: "",
      size: null,
    },
    searchField: "",
  }),

  components: {
    Alert,
    Loading,
    Detail,
  },

  created() {
    if(getRole() !== 'admin.dso') {
      this.userControllers.getUserAMList('admin.dso', 1, 1000, '')  
    }
    this.getUsers();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    allUserAM() {
      let arrDso = this.userControllers.userListAM
      arrDso.unshift({name:'Semua DSO',id:''})
      return arrDso
    },

    users() {
      return this.user.userList;
    },

    userDetail() {
      return this.user.userDetail;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    isLoadingUser() {
      return this.user.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.user.isErrorDelete;
    },

    errorCauseDelete() {
      return this.user.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.user.isLoading;
    },

    // download
    dataDownload() {
      return this.user.userDownload;
    },

    isErrorDownload() {
      return this.user.errorDownload;
    },

    isErrorCauseDownload() {
      return this.user.errorCauseDownload;
    },

    isLoadingDownload() {
      return this.user.loadingDownload;
    },

    isErrorCodeDownload() {
      return this.user.errorCodeDownload;
    },
  },

  watch:{
    dso(){
      this.getUsers()
    }
  },

  methods: {
    async getUsers() {
      return this.user.getUserList(
        this.userTable.role,
        this.userTable.page,
        this.userTable.limit,
        this.userTable.keyword,
        this.dso
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingUser;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.userTable.page -= 1;

        await this.getUsers(
          this.userTable.role,
          this.userTable.page,
          this.userTable.limit,
          this.userTable.keyword,
          this.dso
        );
        if (this.isErrorGetUsers) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingUser;
      this.pages.start += 10;
      this.pages.end += 10;

      this.userTable.page += 1;

      await this.getUsers(
        this.userTable.role,
        this.userTable.page,
        this.userTable.limit,
        this.userTable.keyword,
        this.dso
      );
      if (this.isErrorGetUsers) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createUser() {
      this.$router.push({
        name: "Create User",
      });
    },

    getdetail(id) {
      this.isDetail = true;
      return this.user.getUserDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    deleteUser(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.user.deleteUser(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.user.getUserList(
            this.userTable.role,
            this.userTable.page,
            this.userTable.limit
          );
        });
    },

    edit(id) {
      this.$router.push({
        name: "Edit User",
        params: {
          id: id,
        },
      });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.userTable.page = 1;
      this.userTable.limit = 10;
      this.userTable.keyword = this.searchField;

      await this.getUsers(
        this.userTable.role,
        this.userTable.page,
        this.userTable.limit,
        this.userTable.keyword,
        this.dso
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.userTable.page = 1;
          this.userTable.limit = 10;
          this.userTable.keyword = event;

          await this.getUsers(
            this.userTable.role,
            this.userTable.page,
            this.userTable.limit,
            this.userTable.keyword,
            this.dso
          );
        }
      }, 500);
    },

    normalDate(val) {
      return moment(val).format("DD-MM-YYYY h:mm:ss");
    },

    async download() {
      await this.user.downloadUserList(
        this.userTable.role,
        this.userTable.page,
        this.userTable.limit,
        this.userTable.keyword,
        this.dso
      );

      if (this.dataDownload.length > 0) {
        const dataDownload = [];
        for (const item of this.dataDownload) {
          dataDownload.push({
            pengguna: item.name,
            email: item.email,
            role: item.role,
            phone: item.mobileNumber,
            alamat: item.address
              ? `${item.address.street}, ${item.address.village}, ${item.address.subDistrict}, ${item.address.district}, ${item.address.province}, ${item.address.zipCode}`
              : "",
            "tanggal lahir": item.dob,
            terverifikasi: item.isVerified ? "Yes" : "No",
            aktif: item.isActive ? "Yes" : "No",
            "login terakhir": this.normalDate(item.lastLogin),
            "dibuat pada": this.normalDate(item.createdAt),
            "diperbaharui pada": this.normalDate(item.updatedAt),
          });
        }

        const data = XLSX.utils.json_to_sheet(dataDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Report User Member List.xlsx`);
      } else {
        Swal.fire({
          text: "Data Tidak ditemukan",
          icon: "warning",
        });
      }
    },
  },
};
</script>