<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="9" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
    </v-row>

    <v-row v-if="!isLoadingNotif">
      <v-col md="12" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-list two-line>
            <v-list-item-group active-class="pink--text" multiple>
              <template v-for="(item, index) in notifs">
                <v-list-item :key="item.id">
                  <template>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.message"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>{{ item.createdAt | createdDate }}</v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < notifs.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isError && errorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { NotificationControllers } from "../../controllers/NotificationControllers.js";
import Swal from "sweetalert2";
import moment from "moment";
moment.suppressDeprecationWarnings = true;

export default {
  name: "notif-list",
  data: () => ({
    notif: new NotificationControllers(),
    title: "Pemberitahuan",
    notifTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,
  }),

  filters: {
    createdDate(val) {
      return moment(val).startOf("day").fromNow();
    },
  },

  components: {
    Alert,
    Loading,
  },

  created() {
    this.getNotifications();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    notifs() {
      return this.notif.notificationList;
    },

    errorCode() {
      return this.notif.errorCode;
    },

    isError() {
      return this.notif.isError;
    },

    errorCause() {
      return this.notif.errorCause;
    },

    isLoadingNotif() {
      return this.notif.isLoading;
    },
  },

  methods: {
    async getNotifications() {
      return this.notif.getNotificationList(
        this.notifTable.page,
        this.notifTable.limit,
        this.notifTable.keyword
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingNotif;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.notifTable.page -= 1;

        await this.getNotifications(
          this.notifTable.page,
          this.notifTable.limit,
          this.notifTable.keyword
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingNotif;
      this.pages.start += 10;
      this.pages.end += 10;

      this.notifTable.page += 1;

      await this.getNotifications(
        this.notifTable.page,
        this.notifTable.limit,
        this.notifTable.keyword
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },
  },
};
</script>