<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
      
              <v-text-field
                v-model="detailCommunityCategory.key"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Kategori"
                required
                outlined
                dense
              ></v-text-field>

              <!-- <v-text-field
                v-model="detailCommunityCategory.value"
                :rules="[
                  (v) => !!v || 'Kolom wajib diisi',
                  (v) => /^[a-z-]+$/.test(v) || 'Hanya terdiri dari huruf lowercase dan tanda (-)',
                  ]"
                label="Kode"
                required
                outlined
                dense
              ></v-text-field> -->

              <v-autocomplete
                  v-model="detailCommunityCategory.brand"
                  :items="brands"
                  filled
                  chips
                  label="Tambah Brand"
                  item-text="name"
                  item-value="id"
                  multiple
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isErrorBrand && isErrorCodeBrand !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseBrand"
    />
  </v-container>
</template>
<script>
import { CommunityCategoryControllers } from "../../controllers/CommunityCategoryControllers.js";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "create-brand",
  data: () => ({
    communityCategory: new CommunityCategoryControllers(),
    brand: new BrandControllers(),
    title: "Ubah Kategori",
    valid: true,
    selectScope: {
      page: 1,
      limit: 1000,
    },
    
    isLoading: false,
    fullPage: true,

    tag: {
      value: [],
      options: [],
    },
  }),

  components: {
    Loading,
  },

  created() {
    this.getAllBrand();
    this.getCommunityCategory()
  },

  computed: {
    // brands
    brands() {
      return this.brand.brandList.filter(brand => brand.product == 'Rokok');
    },

    isErrorCodeBrand() {
      return this.brand.errorCode;
    },

    isErrorBrand() {
      return this.brand.isError;
    },

    errorCauseBrand() {
      return this.brand.errorCause;
    },

    isLoadingBrand() {
      return this.brand.isLoading;
    },

    // update community Category
    isErrorUpdateCommunityCategory() {
      return this.communityCategory.isError;
    },

    errorCauseUpdateCommunityCategory() {
      return this.communityCategory.errorCause;
    },

    isLoadingUpdateCommunityCategory() {
      return this.communityCategory.isLoading;
    },

    // detail brand
    detailCommunityCategory() {
      const category = this.communityCategory.CommunityCategoryDetail;
      if(category){
        return {
          ...category,
          brand: Array.isArray(category.brand)&&category.brand.length>0 ? category.brand : []
        }
      }
      return null
    }
  },

  methods: {
    async getCommunityCategory() {
      return this.communityCategory.getCommunityCategoryDetailEdit(
        this.$route.params.id
      );
    },

    getAllBrand() {
      return this.brand.getBrandList(this.selectScope.page, this.selectScope.limit)
    },

    back() {
      this.$router.go(-1);
    },

    addTag(newTag) {
      this.tag.value.push(newTag);
      this.tag.options.push(newTag);
    },

    deleteSKU(index) {
      this.detailBrand.skus.splice(index, 1);
    },

    async submitProcess(
      key,
      value,
      brand
    ) {
      return this.communityCategory.updateCommunityCategoryList(
        this.$route.params.id,
        key,
        value,
        brand
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailCommunityCategory.key,
          this.detailCommunityCategory.value,
          this.detailCommunityCategory.brand,
        );
        

        if (this.isErrorUpdateCommunityCategory) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "ERROR",
            text: this.errorCauseUpdateCommunityCategory,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });

          this.back();
        }
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>