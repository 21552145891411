<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-autocomplete v-model="detailMedsosDSO.dso" :items="dsos" item-text="name" item-value="id" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="DSO" required outlined dense></v-autocomplete>

              <v-text-field v-model="detailMedsosDSO.name" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Nama Media Sosial" required outlined dense></v-text-field>

              <v-text-field v-model="detailMedsosDSO.medsosLink" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Link Media Sosial" required outlined dense></v-text-field>
              <!-- :items="categories" -->
              <v-autocomplete v-model="detailMedsosDSO.category" :items="categories" item-text="value" item-value="key" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Kategori" required outlined dense></v-autocomplete>
            </v-col>
          </v-row>
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit">
            Submit
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Categories -->
    <Alert :status="isErrorGetCategories && errorCodeGetCategories !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetCategories" />

    <!-- Error Users -->
    <Alert :status="isErrorGetUsers && errorCodeGetUsers !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetUsers" />

    <Alert :status="isErrorUpdateMedsosDSO && errorCodeUpdateMedsosDSO !== 404" icon="error" title="KESALAHAN" :msg="errorCauseUpdateMedsosDSO" />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../controllers/UserControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { MedsosDSOControllers } from "../../controllers/MedsosDSOControllers.js";
import Swal from "sweetalert2";

export default {
  name: "update-medsosdso",
  data: () => ({
    user: new UserControllers(),
    category: new CategoryControllers(),
    medsosdso: new MedsosDSOControllers(),
    valid: true,
    title: "Edit Media Sosial",

    isLoading: false,
    fullPage: true,

    selectScope: {
      page: 1,
      limit: 600,
    },

    tag: {
      value: [],
      options: [],
    },
    logoDisplay: null,
    thereIsImage: false,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // medsosdso length
    medsosdsoLength() {
      return this.medsosdso.medsosdsoList.length;
    },

    // medsosdsos
    isErrorUpdateMedsosDSO() {
      return this.medsosdso.isError;
    },

    errorCodeUpdateMedsosDSO() {
      return this.medsosdso.errorCode;
    },

    errorCauseUpdateMedsosDSO() {
      return this.medsosdso.errorCause;
    },

    isLoadingUpdateMedsosDSO() {
      return this.medsosdso.isLoading;
    },

    // users
    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    // categories
    categories() {
      return this.category.categoryMedsosList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    detailMedsosDSO() {
      const medsosdso = this.medsosdso.medsosdsoDetail;

      if (medsosdso) {
        return {
          ...medsosdso,
          dso: medsosdso.dso ? medsosdso.dso.id : "",
        };
      }

      return null;
    },
  },

  created() {
    this.getUsers();
    this.getCategories();
    this.getMedsosDSO();
  },

  methods: {
    async getMedsosDSO() {
      return this.medsosdso.getMedsosDSODetail(this.$route.params.id);
    },
    back() {
      this.$router.go(-1);
    },

    getUsers() {
      return this.user.getUserAMList("admin.dso", 1, 1000, "");
    },

    getCategories() {
      return this.category.getcategoryMedsosList();
    },

    async submitProcess(dso, name, category, medsosLink) {
      return this.medsosdso.updateMedsosDSOList(this.$route.params.id, dso, name, category, medsosLink);
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(this.detailMedsosDSO.dso, this.detailMedsosDSO.name, this.detailMedsosDSO.category, this.detailMedsosDSO.medsosLink);

        if (this.isErrorUpdateMedsosDSO) {
          this.isLoading = false;
          this.isErrorUpdateMedsosDSO = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateMedsosDSO,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diupdate",
          });
          this.back();
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorUpdateMedsosDSO = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>
