import { UserDashboardRequest, CommunityDashboardRequest, CommunityCategoryDashboardRequest, SalesDashboardRequest } from "../request/DashboardRequest.js";

export class DashboardControllers  {
    userDashboardList = new Array();
    communityDashboardList = new Array();
    communityCategoryDashboardList = new Array();
    salesDashboardList = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    constructor() {}

    getUserDashboardList(year) {
        this.setLoading(true);
        const resp = UserDashboardRequest(year)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setUserDashboardList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getCommunityDashboardList(area) {
        this.setLoading(true);
        const resp = CommunityDashboardRequest(area)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setCommunityDashboardList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getCommunityCategoryDashboardList() {
        this.setLoading(true);
        const resp = CommunityCategoryDashboardRequest()
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setCommunityCategoryDashboardList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getSalesDashboardList(year) {
        this.setLoading(true);
        const resp = SalesDashboardRequest(year)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setSalesDashboardList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setUserDashboardList(data) {
        this.userDashboardList = data;
    }

    setCommunityDashboardList(data) {
        this.communityDashboardList = data;
    }

    setCommunityCategoryDashboardList(data) {
        this.communityCategoryDashboardList = data;
    }

    setSalesDashboardList(data) {
        this.salesDashboardList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }
}

