<template>
  <v-container class="pt-8">
    <v-row justify="space-between">
      <v-col md="9" sm="12">
        <h2 class="justify-start">{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12" xs="12">
        <v-autocomplete
          :items="years"
          v-model="chosenYear"
          label="Tahun"
          dense
        ></v-autocomplete>
      </v-col>
      <v-col md="12" sm="12" class="flex" justify="center">
        <v-row justify="space-between">
          <v-col md="2" sm="12" v-if="isOldKPI">
            <v-btn
              @click="show0"
              depressed
              v-bind:color="show == 0 ? 'primary' : 'success'"
              block
            >
              Komunitas
            </v-btn>
          </v-col>
          <v-col md="2" sm="12">
            <v-btn
              @click="show1"
              depressed
              v-bind:color="show == 1 ? 'primary' : 'success'"
              block
            >
              Super
            </v-btn>
          </v-col>
          <v-col md="2" sm="12">
            <v-btn
              @click="show2"
              depressed
              v-bind:color="show == 2 ? 'primary' : 'success'"
              block
            >
              Komunitas Key
            </v-btn>
          </v-col>
          <v-col md="2" sm="12">
            <v-btn
              @click="show3"
              depressed
              v-bind:color="show == 3 ? 'primary' : 'success'"
              block
            >
              Event
            </v-btn>
          </v-col>
          <v-col md="2" sm="12">
            <v-btn
              @click="show4"
              depressed
              v-bind:color="show == 4 ? 'primary' : 'success'"
              block
              v-if="isOldKPI"
            >
              Kompetensi
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="show == 0" md="12" sm="12">
        <v-card>
          <TargetCommunity :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
      <v-col v-if="show == 1" md="12" sm="12">
        <v-card>
          <TargetCommunitySuper :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
      <v-col v-if="show == 2" md="12" sm="12">
        <v-card>
          <TargetCommunityKey :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
      <v-col v-if="show == 3" md="12" sm="12">
        <v-card>
          <TargetEvent :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
      <v-col v-if="show == 4" md="12" sm="12">
        <v-card>
          <TargetCompetency :isOldKPI="isOldKPI" :year="chosenYear"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TargetCommunity from "./target-community/index";
import TargetCommunitySuper from "./target-community-super/index";
import TargetEvent from "./target-event/index";
import TargetCompetency from "./target-competency/index";
import TargetCommunityKey from "./target-community-key/index";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "activity-report",
  data: () => ({
    title: "Target Aktivitas",
    chosenYear: new Date().getFullYear()
  }),


  components: {
    TargetCommunity,
    TargetCommunitySuper,
    TargetCommunityKey,
    TargetEvent,
    TargetCompetency,
  },
  methods: {
    ...mapActions({
      show0: "targetKPI/set0",
      show1: "targetKPI/set1",
      show2: "targetKPI/set2",
      show3: "targetKPI/set3",
      show4: "targetKPI/set4",
    }),
  },
  // created(){
  //   if(this.chosenYear>2022){
  //     this.show1()
  //   }
  // },
  computed: {
    ...mapGetters({
      show: "targetKPI/show",
    }),

    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },

    isOldKPI(){
      console.log(this.chosenYear < 2023)
      return this.chosenYear < 2023;
    },
  },
};
</script>
