<template>
  <v-app v-if="token">
    <Drawer />
    <v-main style="margin-top: 50px">
      <v-container fluid>
        <router-view></router-view>
        <v-dialog v-model="updateExists" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5"> Info </v-card-title>
            <v-card-text
              >Terdapat pembaharuan fitur. Mohon untuk melakukan update dengan klik tombol Update di bawah</v-card-text
            >
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" outlined text @click="updateSw">
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else>
    <v-container>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>
<script>
import "@/assets/style.css";
import { getToken } from "./external/functions/Cookies.js";
import Drawer from "./components/layout/drawer";

export default {
  name: "App",
  data: () => ({
    refreshing: false,
    registration: null,
    updateExists: false,
  }),

  components: {
    Drawer,
  },

  computed: {
    token() {
      return getToken();
    },
  },

  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      
      window.location.reload();
    });
  },

  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },

    updateSw() {
      this.updateExists = false;
      
      if (!this.registration || !this.registration.waiting) return;
      this.registration?.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
};
</script>
