<template>
  <div>
    <!-- Filter Brand Komunitas-->
          <v-col md="12" ms="12" class="mt-0">
            <v-select
              :items="brandList"
              item-text="name"
              item-value="name"
              v-model="brand"
              label="Brand Komunitas"
              dense
            ></v-select>
          </v-col>
          <!-- <v-col md="6" ms="12" class="mt-n5"></v-col> -->
          <!-- text total community per brand -->
          <v-col md="12" sm="12" align="center" class="mt-n5 mx-0">
            <h2 class="text-h2 font-weight-black"><b>{{total}}</b></h2>
            <p class="font-weight-black text-uppercase">KOMUNITAS {{brand}}</p>
          </v-col>
          <v-col md="12" sm="12" align="center" class="mt-n10 mx-0">
            <VueApexCharts
            ref="stackedCommunityAchievement"
            type="bar"
            height="100"
            :options="chartOptions"
            :series="series"
            />
          </v-col>
    
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "total-key",
  props: {
    communityAchievement: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },

  data: () => ({
    // charts
    brand : 'Super Family',
    total : '',
    brandList: [],
    brandCom:[
      {
        name : 'heppiii community',
        total: 12,
        data:[
          {
            category : 'karta',
            count: 7,
          },
          {
            category : 'musik',
            count : 5
          }

        ]
      },
      {
        name : 'superfriends',
        total:12,
        data:[
          {
            category : 'fansclub',
            count: 4,
          },
          {
            category : 'mapala',
            count : 5
          },
          {
            category : 'basketball',
            count : 3
          },
        ]
      },
    ],
    series: [
     
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 400,
        stacked: true,
        stackType: "100%",
        // barHeight: "40%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          
        ],
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        // floating: true,
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"],
          fontSize: "10px",
          fontWeight: 900,
        },
        formatter: function(val, {seriesIndex, w}) {
          // const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0);
          // const percent = (val / sum) * 100;
          return w.globals.series[seriesIndex];
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        fontSize: '11px',
        horizontalAlign: "center",
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    },
  }),

  watch: {
    communityAchievement(newVal) {
      // this.series = newVal.categoryPerBrandSeries
      this.brandList = newVal.communityBarCategories.map((datum) => ({name: datum}))
      this.brand = 'Super Family'
      this.updateBrandV2(this.brand)
      // this.updateBrand(this.brand.name)
      // const newChartOptions = this.chartOptions;
      // newChartOptions.xaxis.categories = newVal.categoryPerBrandSeries.map((item) => item.name)
      
      // Object.assign(this.chartOptions, newChartOptions)
      
      // this.$refs.stackedCommunityAchievement.refresh()
    },
    chosenBrand(value) {
      this.updateBrandV2(value)

    }
  },

  computed: {
    chosenBrand() {
      return this.brand;
    }
  },

  components: {
    VueApexCharts,
  },

  methods:{
    updateBrandV2(value) {
      this.series = []

      const dataSeries = this.communityAchievement.categoryPerBrand.filter(datum => datum.brand === value).map(datum => ({name: datum.category, data: [datum.value]}))
      this.series.push(...dataSeries)

      this.total = String(dataSeries.reduce((a, b) => a + b.data[0], 0))
      const newChartOptions = this.chartOptions
      newChartOptions.xaxis.categories = [value]
      Object.assign(this.chartOptions, newChartOptions)
      // this.updateBrand(value.name)

      // this.$refs.stackedCommunityAchievement.refresh()
    },
    updateBrand(brandI){
      const categoryPerBrand = this.communityAchievement.categoryPerBrand;
      this.total = categoryPerBrand.filter((datum) => datum.brand === brandI).reduce((a, b) => a + b, 0)

      categoryPerBrand.forEach((brand)=>{
        if(brand.brand==brandI){
          this.chartOptions.xaxis.categories.push(brand.category)

          this.series.push({name: brand.category, data: [brand.value]})
          
        }
      })
    },
  },
  // created(){
  //   this.brand = 'Super Family'
  //   this.updateBrand(this.brand)
  // }
};
</script>
