<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="6" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn
          v-if="getRole.role.create"
          class="float-right"
          depressed
          color="primary"
          @click="createAchievement"
          block
        >
          Tambahkan Prestasi
        </v-btn>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
          <v-autocomplete
            :items="allUserAM"
            v-model="dso"
            item-text="name"
            item-value="id"
            @change="pickArea"
            label="Pilih DSO"
            dense
            block
            right
            outlined
          ></v-autocomplete>
        </v-col>
    </v-row>

    <v-row v-if="!isLoadingAchievement">
      <v-col md="12" sm="12">
        <v-card v-if="errorCodeGetAchievement === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Prestasi</th>
                  <th class="text-left">Nama Komunitas</th>
                  <th class="text-left">Tahun</th>
                  <th class="text-left">Skala Kompetisi</th>
                  <th class="text-left">Nominasi</th>
                  <th class="text-left">Pernah Dikontrak</th>
                  <!-- <th class="text-left">Verifikasi</th> -->
                  <th class="text-left">View</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in achievements" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td v-if="item.community">{{ item.community.name }}</td>
                  <td v-else>-</td>
                  <td>{{ item.year }}</td>
                  <td>{{ item.scale }}</td>
                  <td>{{ item.nomination }}</td>
                  <td>
                    <v-icon 
                    v-if="item.isContract"
                    small
                    color="success"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon 
                    v-else
                    small
                    color="error">
                      mdi-close-thick
                    </v-icon>
                  </td>

                  <!-- <td v-if="item.isVerified">
                    <v-chip color="success"> Terverifikasi </v-chip>
                  </td>
                  <td v-else>
                    <v-btn
                      :disabled="!getRole.role.verify ? true : false"
                      small
                      outlined
                      color="success"
                      dark
                      @click="verified(item.id)"
                    >
                      Verifikasi
                    </v-btn>
                  </td> -->
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn
                      small
                      icon
                      color="warning"
                      dark
                      @click="edit(item.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deleteAchievement(item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="verify" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Masukan Score </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="score"
            label="Score"
            type="number"
            required
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="disVerify">Batal</v-btn>
          <v-btn
            color="primary"
            text
            @click="putVerify(verifyId)"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Detail :isShow="isDetail" :data="achievementDetail" @hide="updateModal" />

    <Alert
      :status="isErrorGetAchievement && errorCodeGetAchievement !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAchievement"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { AchievementControllers } from "../../controllers/AchievementControllers.js";
import { UserControllers } from "../../controllers/UserControllers";
import Swal from "sweetalert2";
import Detail from "./achievement-detail";
import { getRole} from "../../external/functions/Cookies";

export default {
  name: "achievement-list",
  data: () => ({
    achievement: new AchievementControllers(),
    userControllers: new UserControllers(),
    dso:'',
    title: "Prestasi",

    verify: false,
    verifyId: "",
    score: "",
    verifyDisable: true,

    achievementTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },
    searchField: ""
  }),

  components: {
    Alert,
    Loading,
    Detail,
  },

  created() {
    if(getRole() !== 'admin.dso') {
      this.userControllers.getUserAMList('admin.dso', 1, 1000, '')  
    }
    this.getAchievement();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    allUserAM() {
      let arrDso = this.userControllers.userListAM
      arrDso.unshift({name:'Semua DSO',id:''})
      return arrDso
    },

    // achievement
    achievements() {
      return this.achievement.achievementList;
    },

    errorCodeGetAchievement() {
      return this.achievement.errorCode;
    },

    isErrorGetAchievement() {
      return this.achievement.isError;
    },

    errorCauseGetAchievement() {
      return this.achievement.errorCause;
    },

    isLoadingAchievement() {
      return this.achievement.isLoading;
    },

    achievementDetail() {
      return this.achievement.achievementDetail;
    },
  },

  watch:{
    dso(){
      this.getAchievement()
    }
  },

  methods: {
    async getAchievement() {
      return this.achievement.getAchievementList(
        this.achievementTable.page,
        this.achievementTable.limit,
        this.achievementTable.keyword,
        this.dso

      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingAchievement;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.achievementTable.page -= 1;

        await this.getAchievement(
          this.achievementTable.page,
          this.achievementTable.limit,
          this.achievementTable.keyword,
          this.dso
        );
        if (this.isErrorGetAchievement) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingAchievement;
      this.pages.start += 10;
      this.pages.end += 10;

      this.achievementTable.page += 1;

      await this.getAchievement(
        this.achievementTable.page,
        this.achievementTable.limit,
        this.achievementTable.keyword,
        this.dso
      );
      if (this.isErrorGetAchievement) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createAchievement() {
      this.$router.push({
        name: "Create Achievement",
      });
    },

    getdetail(id) {
      this.isDetail = true;
      return this.achievement.getAchievementDetail(id);
    },

    edit(id) {
      this.$router.push({
        name: "Edit Achievement",
        params: {
          id: id,
        },
      });
    },

    deleteAchievement(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.achievement.deleteAchievement(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.achievement.getAchievementList(
            this.achievementTable.page,
            this.achievementTable.limit,
            this.achievementTable.keyword,
            this.dso
          );
        });
    },

    updateModal(status) {
      this.isDetail = status;
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.achievementTable.page = 1;
      this.achievementTable.limit = 10;
      this.achievementTable.keyword = this.searchField;

      await this.getAchievement(
        this.achievementTable.page,
        this.achievementTable.limit,
        this.achievementTable.keyword,
        this.dso
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.achievementTable.page = 1;
          this.achievementTable.limit = 10;
          this.achievementTable.keyword = event;

          await this.getAchievement(
            this.achievementTable.page,
            this.achievementTable.limit,
            this.achievementTable.keyword,
            this.dso
          );
        }
      }, 500);
    },

    disVerify() {
      this.verify = false;
    },

    verified(id) {
      this.verifyId = id;
      this.verify = true;
    },

    putVerify(id) {
      if(this.score === "") {
        Swal.fire({
          icon: "warning",
          text: "Score wajib diisi",
        });
      } else {
        Swal.fire({
          title: "Yakin akan melakukan verifikasi?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya, verifikasi",
          cancelButtonText: "Batal",
        })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.verify = false;
              return this.achievement.verifyAchievement(id, this.score);
            }
          })
          .finally(() => {
            this.isLoading = false;
            return this.achievement.getAchievementList(
              this.achievementTable.page,
              this.achievementTable.limit,
              this.achievementTable.keyword,
              this.dso
            );
          });
      }
    },
  },
};
</script>