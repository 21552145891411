<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0 me-n1">
          <v-col md="8" sm="12">
            <span class="text--secondary text-caption">Tahun</span>
            <v-autocomplete :items="years" 
            v-model="chosenYear" item-text="name" item-value="id"
              dense></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12">
            <!-- <v-btn class="success ms-n2" small block>Unduh Excel </v-btn> -->
          </v-col>
          <v-col align="center" md="12" ms="12" class="text-wrap mt-n5">
            <h2>{{ title }}</h2>
          </v-col>
          <v-col md="12" sm="12" class="px-0 py-0 mt-n8 me-0">
            <VueApexCharts ref="consumptionPerCategoryChartKPI" type="bar" height="400" :options="chartOptions"
              :series="series" />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { InputConsumptionControllers } from "../../../../controllers/InputConsumptionControllers";
Vue.use(VueApexCharts);

export default {
  name: "community-achievement",
  props: {
    dso: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    title: "KONSUMSI PER KATEGORI KOMUNITAS",
    inputConsumption: new InputConsumptionControllers(),
    year: new Date().getFullYear()-1,
    chosenYear: new Date().getFullYear(),
    series: [
      {
        name: "LA BOLD",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "DJARUM SUPER",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "DJARUM 76",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "LA LIGHT",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "OTHERS",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 400,
        stacked: true,
        stackType: "100%",
        // barHeight: "40%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ["#000000","#e2a63d", "#e23d3d",  "#d99494", "#34bf41"],
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "Fans Club", "Pecinta Alam", "Suporter", "Motor Bold Riders", "Motor", "Heppiii Community", "Musik", "E-Sport", "Fotografi", "Basketball", "Others"
        ],
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        // floating: true,
        labels: {
          show: true,
          align: "left",
          maxWidth: "auto",
          style: {
            fontWeight: "bold",
          },
          //   style: {
          //     colors: [],
          //     fontSize: "12px",
          //     fontFamily: "Helvetica, Arial, sans-serif",
          //     fontWeight: 400,
          //     cssClass: "apexcharts-yaxis-label",
          //   },
          offsetX: 5,
          //   offsetY: 0,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val).toString();
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        fontSize: 8,
        fontWeight: 900,
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    },
  }),

  computed: {
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },
    
    isOldKPI(){
      console.log(this.chosenYear < 2023)
      return this.chosenYear < 2023;
    },
    
    consumptionCategoryKPIChart() {
      const categoryChart = this.inputConsumption.consumptionCategoryKPI;

      return categoryChart
    },
  },

  watch: {
    dso() {
      this.getConsumptionCategoryChart()
    },
    chosenYear() {
      this.getConsumptionCategoryChart()
    },
    consumptionCategoryKPIChart(newVal) {
      this.series = []
      const seriesMapped = []
      const seriesLabels = []
      for (const category of newVal.consumptionCategorySeries) {
        seriesLabels.push(category.value)
        for (const detail of category.detail) {
          const isExists = seriesMapped.findIndex(el => el.name.toLowerCase() === detail.name.toLowerCase())
          if (isExists >= 0) {
            seriesMapped[isExists].data.push(Number(detail.value.toFixed(1)))
          } else {
            seriesMapped.push({
              name: detail.name,
              data: [Number(detail.value.toFixed(1))]
            })
          }
        }
      }
      const newChartOptions = this.chartOptions;
      newChartOptions.xaxis.categories = seriesLabels;

      Object.assign(this.chartOptions, newChartOptions)

      Object.assign(this.series, seriesMapped)
      this.$refs.consumptionPerCategoryChartKPI.refresh()

    }
  },

  components: {
    VueApexCharts,
  },

  methods: {
    getConsumptionCategoryChart() {
      return this.inputConsumption.getConsumptionCategoryKPIChart(this.dso, this.chosenYear);
    }
  }
};
</script>