<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on"
        style="margin-right: 12px; z-index: 1"
      >
        <v-icon @click="showNotifs">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list v-if="isErrorCode === 404">
          <v-list-item link @click="changePassword">
            <v-list-item-icon>
              <v-icon>mdi-account-key</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Ubah Password</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item link @click="$emit('logOut')">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title >Keluar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
// import { NotificationControllers } from "../../controllers/NotificationControllers.js";
import moment from "moment";
moment.suppressDeprecationWarnings = true;

export default {
  name: "Notif",
  data: () => ({
    lengthNotifications: 0
  }),

  filters: {
    createdDate(val) {
      return moment(val).startOf("day").fromNow();
    },
  },

  computed: {
    isErrorCode() {
      return 404;
    },

    isLength: {
      get: function() {
        return 0;
      },

      set: function() {
        this.lengthNotifications = 0;
      }
    }
  },

  created() {
    this.getNotifications();
  },

  methods: {
    changePassword() {
      this.$router.push({
        name: "Change Password",
      });
    },


    async showNotifs() {
      this.isLength = 0;
    },
  },
};
</script>
<style scoped>
.more {
  padding: 16px;
  font-size: 12px;
  z-index: auto;
}

.more a {
  text-decoration: none;
}

.more a:hover {
  text-decoration: underline;
}
</style>