<template>
  <div>
    <v-row class="pb-0">
      <v-col md="12" sm="12" class="mt-2">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!isLoadingTable">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404 || ongoings.length === 0">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>

        <v-card v-else>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Komunitas</th>
                    <th class="text-left">Area</th>
                    <th class="text-left">Tanggal Mulai</th>
                    <th class="text-left">Tanggal Akhir</th>
                    <th class="text-left">Target</th>
                    <th class="text-left">Jangkauan</th>
                    <th class="text-left">Rincian</th>
                    <th class="text-center" v-if="getRole.role.set">Verifikasi</th>
                    <th class="text-left" v-if="getRole.role.update">Ubah</th>
                    <th class="text-left" v-if="getRole.role.del">Hapus</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in ongoings" :key="item.id">
                    <td>{{ item.community.name }}</td>
                    <td>{{ item.community.area }}</td>
                    <td>{{ item.startDate }}</td>
                    <td>{{ item.endDate }}</td>
                    <td>{{ item.goal }}</td>
                    <td>{{ item.reach }}</td>
                    <td>
                      <v-btn
                        small
                        icon
                        color="success"
                        dark
                        @click="getdetail(item.id)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </td>
                    <td class="text-center" v-if="getRole.role.set">
                      <v-btn
                        small
                        icon
                        color="teal"
                        dark
                        @click="setTarget(item.community.pic.id)"
                      >
                        <v-icon>mdi-lock</v-icon>
                      </v-btn>
                    </td>
                    <td v-if="getRole.role.update">
                      <v-btn
                        small
                        icon
                        color="warning"
                        dark
                        @click="edit(item.id)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </td>
                    <td v-if="getRole.role.del">
                      <v-btn
                        small
                        icon
                        color="error"
                        dark
                        @click="deleteSales(item.id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }} 
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Detail :isShow="isDetail" :data="dataDetail" @hide="updateModal" />
    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </div>
</template>
<script>
import Alert from "../../../components/alert";
import { SalesControllers } from "../../../controllers/SalesControllers";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import Detail from "./sales-detail";

export default {
  name: "sales",
  props: {
    tab: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    sales: new SalesControllers(),
    salesTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,
    searchField: ""
  }),

  created() {
    this.getOngoings();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    ongoings() {
      return this.sales.salesTargetList;
    },

    isErrorCode() {
      return this.sales.errorCode;
    },

    isError() {
      return this.sales.isError;
    },

    errorCause() {
      return this.sales.errorCause;
    },

    isLoadingTable() {
      return this.sales.isLoading;
    },

    dataDetail() {
      return this.sales.salesDetail;
    }
  },

  components: {
    Loading,
    Alert,
    Detail,
  },

  methods: {
    async getOngoings() {
      return this.sales.getSalesTargetList(
        "ongoing",
        this.salesTable.page,
        this.salesTable.limit,
        this.salesTable.keyword
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingTable;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.salesTable.page -= 1;

        await this.getOngoings(
          "ongoing",
          this.salesTable.page,
          this.salesTable.limit,
          this.salesTable.keyword
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingTable;
      this.pages.start += 10;
      this.pages.end += 10;

      this.salesTable.page += 1;

      await this.getOngoings(
        "ongoing",
        this.salesTable.page,
        this.salesTable.limit,
        this.salesTable.keyword
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    getdetail(id) {
      this.isDetail = true;
      return this.sales.getSalesDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },
    
    edit(id) {
      this.$router.push({
        name: "Edit Sales",
        params: {
          status: "ongoing",
          tab: this.tab.slice(4, 5),
          id: id
        }
      });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.salesTable.page = 1;
      this.salesTable.limit = 10;
      this.salesTable.keyword = this.searchField;

      await this.getOngoings(
        this.salesTable.page,
        this.salesTable.limit,
        this.salesTable.keyword
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.salesTable.page = 1;
          this.salesTable.limit = 10;
          this.salesTable.keyword = event;

          await this.getOngoings(
            this.salesTable.page,
            this.salesTable.limit,
            this.salesTable.keyword
          );
        }
      }, 500);
    },

    setTarget(id) {
      this.$router.push({
        name: "Set Sales Target",
        params: {
          tab: this.tab.slice(4, 5),
          id: id
        }
      });
    },

    deleteSales(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.sales.deleteSalesTarget(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.sales.getSalesTargetList(
            "ongoing",
            this.salesTable.page,
            this.salesTable.limit,
            this.salesTable.keyword
          );
        });
    },
  },
};
</script>