<template>
  <div>
    <v-container class="pt-8">
      <v-row justify="space-between">
        <v-col md="6" sm="12">
          <h2>
            <v-btn icon color="primary" @click="back">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            Medsos Performance
          </h2>
        </v-col>
        <v-col md="5" sm="12">
          <v-btn class="float-right" depressed color="primary" @click="createPerformance" v-if="getRole.role.create">
            Buat Performance
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3" sm="4">
          <v-autocomplete v-model="yearChosen" :items="years" label="Tahun" dense outlined></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pt-3">
      <v-row v-if="!isLoadingPerformanceComputed">
        <v-col md="12" sm="12">
          <v-card v-if="errorCodeGetPerformanceComputed === 404">
            <v-card-text>Data Tidak Tersedia</v-card-text>
          </v-card>
          <v-card v-else>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Bulan</th>
                    <th class="text-left">Tahun</th>
                    <th class="text-left">Post Qty</th>
                    <th class="text-left" v-if="getRole.role.edit">Ubah</th>
                    <th class="text-left" v-if="getRole.role.delete">Hapus</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in PerformanceComputed" :key="index">
                    <td>{{ getMonthName(item.month) }}</td>
                    <td>{{ item.year }}</td>
                    <td>{{ item.postQty }}</td>
                    <td v-if="getRole.role.edit">
                      <v-btn small icon color="warning" dark @click="editPerformance(item.id)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </td>
                    <td v-if="getRole.role.delete">
                      <v-btn small icon color="error" dark @click="deletePerformance(item.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isErrorGetPerformanceComputed && errorCauseGetPerformanceComputed" icon="error" title="DATA KOSONG" :msg="errorCauseGetPerformanceComputed" />
  </div>
</template>
<script>
import { PerformanceControllers } from "../../../controllers/PerformanceControllers";
import Loading from "vue-loading-overlay";
import Alert from "../../../components/alert";
import Swal from "sweetalert2";

export default {
  data: () => ({
    performance: new PerformanceControllers(),
    yearChosen: new Date().getFullYear(),
    communityId: "",

    isLoading: false,
    fullPage: true,
    searchField: "",
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    getRole() {
      console.log(this.$route.meta)
      return this.$route.meta;
    },
    years() {
      const start = 2019;
      const end = new Date().getFullYear();

      let year = [];

      for (let i = start; i <= end; i++) {
        year.push(i);
      }

      return year;
    },
    PerformanceComputed() {
      const performance = this.performance.PerformanceList.filter((datum) => datum != null);
      console.log(performance);

      return performance;
    },
    errorCodeGetPerformanceComputed() {
      return this.performance.errorCode;
    },

    isErrorGetPerformanceComputed() {
      return this.performance.isError;
    },

    errorCauseGetPerformanceComputed() {
      return this.performance.errorCause;
    },

    isLoadingPerformanceComputed() {
      return this.performance.isLoading;
    },

    //Delete
    isErrorDelete() {
      return this.performance.isErrorDelete;
    },

    errorCauseDelete() {
      return this.performance.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.performance.isLoading;
    },
  },
  created() {
    this.communityId = this.$route.params.id;
    this.performance.getPerformanceList(this.communityId, this.yearChosen);
  },
  watch: {
    yearChosen(newVal) {
      this.performance.setEmptyPerformance();
      this.performance.getPerformanceList(this.communityId, newVal);
    },
  },
  methods: {
    getMonthName(monthNumber) {
      const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

      return monthNames[monthNumber - 1] || ""; // Untuk menghindari error jika monthNumber tidak valid
    },
    back() {
      this.$router.go(-1);
    },

    createPerformance() {
      this.$router.push({
        name: "Create Performance",
        params: {
          communityId: this.communityId,
        },
      });
    },
    editPerformance(id) {
      this.$router.push({
        name: "Update Performance",
        params: {
          id: id,
          communityId: this.communityId,
        },
      });
    },
    deletePerformance(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.performance
            .deletePerformance(id)
            .then(() => {
              Swal.fire("Berhasil", "Data berhasil dihapus", "success");
            })
            .catch((error) => {
              Swal.fire("Gagal", "Terjadi kesalahan saat menghapus data", "error");
              console.error("Error deleting performance:", error);
            })
            .finally(() => {
              this.isLoading = false;
              setTimeout(() => {
                this.performance.getPerformanceList(this.communityId, this.yearChosen);
              }, 300);
            });
        }
      });
    },
  },
};
</script>
