<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-autocomplete
                v-model="form.project"
                :items="projects"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Project"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.brandName"
                :rules="brandNameRules"
                label="Nama Brand"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="form.communityName"
                :items="communitiesDefault"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand Komunitas"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-textarea
                v-model="form.description"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Deskripsi"
                outlined
                required
                dense
              ></v-textarea>

              <v-autocomplete
                v-model="form.product"
                :items="products"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Produk"
                required
                outlined
                dense
                @change="onProductSelect"
              ></v-autocomplete>

            </v-col>
            <v-col md="4" sm="12" class="p-5" v-if="isSku">
              <v-alert text type="info">
                Informasi SKU
                <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
                <p class="grey--text">
                 Tekan enter jika akan menambahkan banyak SKU setiap selesai ketik
                </p>
              </v-alert>
              <multiselect
                v-model="tag.value"
                tag-placeholder="Tambah SKU baru"
                placeholder="Cari atau tambah SKU"
                :options="tag.options"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isProjectListError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseProjects"
    />

    <Alert
      :status="isErrorProduct && isErrorCodeProduct !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseProduct"
    />
  </v-container>
</template>
<script>
import { ProjectControllers } from "../../controllers/ProjectControllers.js";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import Alert from "../../components/alert";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "vue-multiselect";

export default {
  name: "create-brand",
  data: () => ({
    project: new ProjectControllers(),
    brand: new BrandControllers(),
    title: "Buat Brand",
    valid: true,
    brandNameRules: [(v) => !!v || "Kolom wajib diisi"],
    communitiesDefault: ['Super Friends', 'Bold Riders', 'Heppiii Community', 'Kill The LAst', 'Inmild', 'Others'],
    form: {
      project: null,
      brandName: "",
      communityName: "",
      description: "",
      product: ""
    },
    projectScope: {
      page: 1,
      limit: 10,
    },
    isLoading: false,
    fullPage: true,
    tag: {
      value: [],
      options: [],
    },
    isSku: true
  }),

  components: {
    Alert,
    Loading,
    Multiselect,
  },

  created() {
    this.getProjects();
    this.getProducts();
  },

  computed: {
    // product
    projects() {
      return this.project.projectList;
    },

    isProjectListError() {
      return this.project.isError;
    },

    errorCauseProjects() {
      return this.project.errorCause;
    },

    // product
    products() {
      return this.brand.productList;
    },

    isErrorProduct() {
      return this.brand.errorProduct;
    },

    isErrorCauseProduct() {
      return this.brand.errorCauseProduct;
    },

    isErrorCodeProduct() {
      return this.brand.errorCodeProduct;
    },

    // brand
    isErrorCreateBrand() {
      return this.brand.isError;
    },

    errorCauseCreateBrand() {
      return this.brand.errorCause;
    },

    isLoadingCreateBrand() {
      return this.brand.isLoading;
    },
  },

  methods: {
    getProjects() {
      return this.project.getProjectsList(
        this.projectScope.page,
        this.projectScope.limit
      );
    },

    getProducts() {
      return this.brand.getProductList();
    },

    back() {
      this.$router.go(-1);
    },

    addTag(newTag) {
      this.tag.options.push(newTag);
      this.tag.value.push(newTag);
    },

    async submitProcess(project, brandName, description, communityName, skus, product) {
      return this.brand.createBrandList(
        project,
        brandName,
        description,
        communityName,
        skus,
        product
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.project,
          this.form.brandName,
          this.form.description,
          this.form.communityName,
          this.tag.value,
          this.form.product
        );

        if (this.isErrorCreateBrand) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateBrand,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.$refs.observer.reset();
        }
      }
    },

    onProductSelect(val) {
      if(val === 'Rokok') {
        this.isSku = true;
      } else {
        this.isSku = false;
      }
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>