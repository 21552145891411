<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <!-- <v-autocomplete v-model="form.brandId" :items="allBrand" item-text="name" item-value="id" label="Brand"
                dense outlined left></v-autocomplete>

              <v-autocomplete v-model="form.skuId" :items="allSKU" item-text="name" item-value="name"
                label="SKU" dense outlined left></v-autocomplete> -->

              <v-autocomplete
                v-model="form.brandId"
                :items="allBrand"
                item-text="name"
                item-value="id"
                label="Brand"
                @change="setSku"
                outlined
                dense
                left
              ></v-autocomplete>

              <v-autocomplete
                :disabled="!isBrand"
                v-model="form.skuId"
                :items="skus.skus"
                label="SKU"
                outlined
                dense
                left
              ></v-autocomplete>

              <!-- <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="form.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Tanggal Pembelian"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.date" no-title scrollable :min="nowDate">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(form.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu> -->

              <v-text-field v-model="form.total" label="Jumlah Konsumsi"
                required outlined dense></v-text-field>
            </v-col>

            <!-- <v-col md="4" sm="12" class="p-5">
              <v-alert text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card>
                <v-card-title>
                  <p>Unggah Nota/Bukti Konsumsi</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload">Batal</v-btn>
                </v-card-text>
                <v-card-text v-else>
                  <input @change="changeMedia" ref="myFileInput" type="file" accept="image/*" />
                </v-card-text>
                <v-img :src="logoDisplay" v-if="thereIsImage"></v-img>
              </v-card>
            </v-col> -->
          </v-row>

          <v-row v-if="!isEdit">
            <v-col md="2" sm="12">
              <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit">
                Submit
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col md="2" sm="12">
              <v-btn :disabled="!valid" color="success" class="mr-4" @click="submitEdit">
                Edit
              </v-btn>
            </v-col>
            <v-col md="2" sm="12">
              <v-btn color="primary" class="mr-4" @click="cancleEdit">
                Batal
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="!isLoadingConsumption">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Brand</th>
                  <th class="text-left">SKU</th>
                  <th class="text-left">Jumlah Konsumsi</th>
                  <th class="text-left">Tanggal Input</th>
                  <!-- <th class="text-left" >Bukti Konsumsi</th> -->
                  <th class="text-left" >Ubah</th>
                  <th class="text-left" >Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in allInputConsumption" :key="item.id">
                  <td v-if="item.brand">
                    {{ item.brand.name }}
                  </td>
                  <td v-else>-</td>
                  <td v-if="item.sku">
                    {{ item.sku}}
                  </td>
                  <td v-else>-</td>
                  <td v-if="item.total">
                    {{ item.total }}
                  </td>
                  <td v-else>-</td>
                  <td>{{ item.createdAt | getNormalDate }}</td>
                  <!-- <td>
                    <v-btn small icon color="success" dark @click="targetList(item.id)">
                      <v-icon>mdi-bullseye</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn small icon color="success" dark @click="inputConsumption(item.id)">
                      <v-icon>mdi-notebook-edit</v-icon>
                    </v-btn>
                  </td> -->
                  <!-- <td v-if="item.images.length>0">
                    <v-btn small icon color="success" dark @click="showImage(item.images[0])">
                      <v-icon>mdi-image</v-icon>
                    </v-btn>
                  </td> -->
                  <!-- <td v-else>-</td> -->
                  <td >
                    <v-btn small icon color="warning" dark @click="edit(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td >
                    <v-btn small icon color="error" dark @click="deleteInput(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <v-dialog v-model="isZoom" width="500" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2"> Bukti Konsumsi </v-card-title>
        <v-img :src="imageZoomed"></v-img>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="isZoom = false"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Error Provinces -->
    <Alert :status="isErrorGetProvinces && errorCodeGetProvinces !== 404" icon="error" title="KESALAHAN"
      :msg="errorCauseGetProvinces" />

    <!-- Error Districts -->
    <Alert :status="isErrorGetDistricts && errorCodeGetDistricts !== 404" icon="error" title="KESALAHAN"
      :msg="errorCauseGetDistricts" />

    <!-- Error Sub Districts -->
    <Alert :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404" icon="error" title="KESALAHAN"
      :msg="errorCauseGetSubDistricts" />

    <!-- Error Villages -->
    <Alert :status="isErrorGetVillages && errorCodeGetVillages !== 404" icon="error" title="KESALAHAN"
      :msg="errorCauseGetVillages" />

    <!-- Error All Districts -->
    <Alert :status="isErrorGetAllDistricts && errorCodeGetAllDistricts !== 404" icon="error" title="KESALAHAN"
      :msg="errorCauseGetAllDistricts" />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventControllers } from "../../controllers/EventControllers";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import Swal from "sweetalert2";
import { InputConsumptionControllers } from "../../controllers/InputConsumptionControllers";
import { MediaControllers } from "../../controllers/MediaControllers";
import { BrandControllers } from "../../controllers/BrandControllers";
import { formatDate } from "../../external/functions/Utilization.js";

export default {
  name: "create-event",
  data: () => ({
    event: new EventControllers(),
    area: new AreaControllers(),
    inputConsumption: new InputConsumptionControllers(),
    brand: new BrandControllers(),
    media: new MediaControllers(),
    valid: true,
    title: "Input Konsumsi",

    isLoading: false,
    fullPage: true,
    isBrand: false,
    editId: '',

    form: {
      brandId: "",
      skuId: '',
      total: null,
      images: null,
      logoTemp: [],
    },

    isEdit: false,
    inputId: null,

    menu: false,
    menu2: false,

    nowDate: new Date().toISOString().slice(0, 10),

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
      allDistricts: null,
    },

    selectScope: {
      page: 1,
      limit: 600,
    },
    pages: {
      start: 1,
      end: 10,
    },

    logoDisplay: null,
    thereIsImage: false,
    isImageUploaded: false,

    isZoom : false,
    imageZoomed : '',

  }),

  components: {
    Loading,
    Alert,
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  computed: {
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },

    // consumption section
    isLoadingConsumption() {
      return this.inputConsumption.isLoading;
    },

    isErrorCode() {
      return this.inputConsumption.errorCode;
    },

    // create consumption
    isErrorCreateConsumption() {
      return this.inputConsumption.isErrorCreate;
    },

    errorCauseCreateConsumption() {
      return this.inputConsumption.errorCauseCreate;
    },

    isLoadingCreateConsumption() {
      return this.inputConsumption.loadingCreate;
    },


    allInputConsumption() {
      return this.inputConsumption.consumptionList;
    },
    // end consumption section

    allBrand() {
      return this.brand.brandList;
    },

    skus(){
      if(this.isBrand){
        return this.brand.brandList.find((item) => item.id === this.form.brandId)
      }
      return []
    },

    // allSKU() {
    //   return [{ name: 'SKU 10' }, { name: 'SKU 12' }, { name: 'SKU 16' }]
    // },
    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },

    // all districts
    allDistricts() {
      return this.area.allDistrictList;
    },

    errorCodeGetAllDistricts() {
      return this.area.errorCodeAllDistrict;
    },

    isErrorGetAllDistricts() {
      return this.area.isErrorAllDistrict;
    },

    errorCauseGetAllDistricts() {
      return this.area.errorCauseAllDistrict;
    },

    // event create
    isErrorCreateEvent() {
      return this.event.isError;
    },

    errorCauseCreateEvent() {
      return this.event.errorCause;
    },

    isLoadingCreateCreate() {
      return this.event.isLoading;
    },
  },

  created() {
    this.getProvinces();
    this.getAllDistricts();
    this.getAllBrands();
    this.getListConsumption({
      page: this.pages.start,
      size: this.pages.end,
      keyword: "",
      targetId: this.$route.params.id
    });
  },

  methods: {
    getAllBrands() {
      return this.brand.getBrandList(1, 999, "")
    },

    setSku(){
      this.isBrand=true
    },

    getListConsumption({page, size, keyword, targetId}) {
      return this.inputConsumption.getConsumptionList({
        page, size, keyword, targetId
      })
    },

    showImage(image){
      this.isZoom = true
      this.imageZoomed = image
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingConsumption;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.attributeTable.page -= 1;

        await this.getListConsumption({
          page: this.pages.start,
          size: this.pages.end,
          keyword: "",
          targetId: this.$route.params.id
        });
        if (this.isErrorGetAttribute) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingConsumption;
      this.pages.start += 10;
      this.pages.end += 10;

      this.attributeTable.page += 1;

      await this.getListConsumption({
          page: this.pages.start,
          size: this.pages.end,
          keyword: "",
          targetId: this.$route.params.id
        });
      if (this.isErrorGetAttribute) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    edit(datum){
      this.editId = datum.id
      this.isEdit = true
      this.form.brandId = datum.brand.id
      this.isBrand = true
      this.form.skuId = datum.sku
      this.form.total = datum.total
      this.logoDisplay = datum.images[0]
      this.thereIsImage = true
    },

    cancleEdit(){
      this.editId = ''
      this.isEdit = false
      this.form.brandId = ''
      this.isBrand= false
      this.form.skuId = ''
      this.form.total = null
      this.logoDisplay = null
      this.thereIsImage = false
    },


    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.isImageUploaded = true;
          this.thereIsImage = true;
          this.logoDisplay = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.logoDisplay = null;
      this.thereIsImage = false;
      this.isImageUploaded = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    back() {
      this.$router.go(-1);
    },

    getProvinces() {
      return this.area.getProvinceList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find(
        (item) => item.provinceId === provinceId
      );
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(
        provinceId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find(
        (item) => item.districtId === districtId
      );
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(
        districtId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find(
        (item) => item.subDistrictId === subDistrictId
      );
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(
        subDistrictId,
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getAllDistricts() {
      return this.area.getAllDistrictList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getVillagesValue(villageId) {
      const { villageName, postalCode } = this.villages.find(
        (item) => item.villageId === villageId
      );
      this.addressFiltered.village = villageName;
      this.form.address.zipCode = postalCode;
    },

    deleteInput(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.inputConsumption.deleteInputConsumption(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.getListConsumption({
            page: this.pages.start,
            size: this.pages.end,
            keyword: "",
            targetId: this.$route.params.id
          });
        });
    },

    async submitEditProcess(
      brandId,
      skuId,
      total,
      images
    ) {
      return this.inputConsumption.updateInputConsumption(
        this.editId,
        brandId,
        skuId,
        total,
        images        
      );
    },

    async submitEdit() {
      const validate = this.$refs.observer.validate();

      if (this.isImageUploaded) {
        this.isLoading = true;
        await this.uploadMedia("bigEventCommunity", this.form.logoTemp);

        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isLoading = false;
          this.isErrorUploadMedia = false;
        } else {
          this.isLoading = false;
          this.form.images = this.medias[0];
        }
      }

      if (validate && !this.isErrorUploadMedia) {
        console.log("disisni")
        console.log(this.form.brandId,
          this.form.skuId,
          Number(this.form.total),
          this.isImageUploaded ? this.form.images != undefined? [this.form.images]: [] : this.logoDisplay != undefined? [this.logoDisplay] : [])
        this.isLoading = true;
        await this.submitEditProcess(
          this.form.brandId,
          this.form.skuId,
          Number(this.form.total),
          this.isImageUploaded ? this.form.images != undefined? [this.form.images]: [] : this.logoDisplay != undefined? [this.logoDisplay] : [],
        )
        .finally(() => {
          return this.getListConsumption({
            page: this.pages.start,
            size: this.pages.end,
            keyword: "",
            targetId: this.$route.params.id
          });
        });

        if (this.isErrorUpdateConsumption) {
          this.isLoading = false;
          this.isErrorUpdateConsumption = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateAttribute,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diedit",
          });
          this.$refs.observer.reset();
          this.cancleEdit()
        }
      }
    },

    async submitProcess(
      brandId,
      skuId,
      total,
      targetId,
      images
    ) {
      return this.inputConsumption.createInputConsumption(
        targetId,
        brandId,
        skuId,
        total,
        images
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        if (this.thereIsImage) {
          this.isLoading = true;
          await this.uploadMedia("bigEventCommunity", this.form.logoTemp);

          if (this.isErrorUploadMedia) {
            Swal.fire({
              icon: "error",
              title: "KESALAHAN",
              text: this.errorCauseMediaUpload,
            });
            this.isLoading = false;
            this.isErrorUploadMedia = false;
          } else {
            this.isLoading = false;
            this.form.images = this.medias[0];
          }
          this.deleteImageUpload()
        }
        this.isLoadingCreateConsumption = true;
        this.isLoading = this.isLoadingCreateConsumption,
        await this.submitProcess(
          this.form.brandId,
          this.form.skuId,
          Number(this.form.total),
          this.$route.params.id,
          this.form.images ? [this.form.images] : [],
        )
        .finally(() => {
          return this.getListConsumption({
            page: this.pages.start,
            size: this.pages.end,
            keyword: "",
            targetId: this.$route.params.id
          });
        });

        if (this.isErrorCreateConsumption) {
          this.isLoadingCreateConsumption = false;
          this.isLoading = this.isLoadingCreateConsumption,
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateConsumption,
          })
        } else {
          this.isLoadingCreateConsumption = false;
          this.isLoading = this.isLoadingCreateConsumption,
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
          this.isBrand = false
        }
      }
    },
  },
};
</script>