export default{
    namespaced: true,
    state:{
        dsoId:'',
    },
    mutations:{
        setdsoId :(state,payload) => {
            state.dsoId = payload
            }
    },
    actions:{
        setdsoId:({commit},payload)=>{
            commit('setdsoId',payload)
        }
    },
    getters:{
        dsoId : state => state.dsoId
    },
    
}