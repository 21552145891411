import { PostReportListRequest, UserReportListRequest } from "../request/PostReportRequest.js";

export class PostReportControllers {
    postreportList = new Array();
    userreportList = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    postreportDownload = new Array();
    userreportDownload = new Array();
    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() { }

    getPostReportList(page, limit, keyword) {
        this.setLoading(true);
        const resp = PostReportListRequest(page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setPostReportList(response.data.data);
                if(!response.data.data){
                    this.setError(true);
                    this.setErrorcode(404);
                }
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadPostReportList(page, limit, keyword) {
        this.setLoadingDownload(true);
        const resp = PostReportListRequest(page, limit, keyword)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setPostReportListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    getUserReportList(page, limit, keyword) {
        this.setLoading(true);
        const resp = UserReportListRequest(page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setUserReportList(response.data.data);
                if(!response.data.data){
                    this.setError(true);
                    this.setErrorcode(404);
                }
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadUserReportList(page, limit, keyword) {
        this.setLoadingDownload(true);
        const resp = UserReportListRequest(page, limit, keyword)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setUserReportListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setPostReportListDownload(data) {
        this.postreportDownload = data;
    }

    setUserReportListDownload(data) {
        this.userreportDownload = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    setPostReportList(data) {
        this.postreportList = data;
    }

    setUserReportList(data) {
        this.userreportList = data;
    }
}

