<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="4" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="5" sm="12">
        <v-btn
          v-if="getRole.role.create"
          class="float-right"
          depressed
          color="primary"
          @click="createRSO"
        >
          Tambah RSO
        </v-btn>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row v-if="!isLoadingRSO">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>

        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama RSO</th>
                  <th class="text-left">Catatan</th>
                  <th class="text-left">Manajer</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in rsos" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.remark }}</td>
                  <td v-if="item.manager">{{ item.manager.name }}</td>
                  <td v-else>-</td>
                  <td v-if="getRole.role.update">
                    <v-btn
                      small
                      icon
                      color="warning"
                      dark
                      @click="edit(item.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deleteRSO(item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />

    <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    />

  </v-container>
</template>
<script>
import { AreaManagerControllers } from "../../controllers/AreaManagerControllers.js";
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  name: "rso-list",
  data: () => ({
    am: new AreaManagerControllers(),
    title: "Daftar RSO",

    amTable: {
      page: 1,
      limit: 10,
      keyword: ""
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    search: {
      debounce: null,
    },
    searchField: ""
  }),

  components: {
    Alert,
    Loading,
  },

  created() {
    this.getRSOList();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    // AMS
    rsos() {
      return this.am.areaManagerRSOList;
    },

    isErrorCode() {
      return this.am.errorCode;
    },

    isError() {
      return this.am.isError;
    },

    errorCause() {
      return this.am.errorCause;
    },

    isLoadingRSO() {
      return this.am.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.am.isErrorDelete;
    },

    errorCauseDelete() {
      return this.am.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.am.isLoading;
    },
  },

  methods: {
    async getRSOList() {
      return this.am.getAreaManagerRSOList(
        this.amTable.page,
        this.amTable.limit,
        this.amTable.keyword
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingAM;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.amTable.page -= 1;

        await this.getRSOList(
          this.amTable.page,
          this.amTable.limit,
          this.amTable.keyword
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingAM;
      this.pages.start += 10;
      this.pages.end += 10;

      this.amTable.page += 1;

      await this.getRSOList(
        this.amTable.page,
        this.amTable.limit,
        this.amTable.keyword
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createRSO() {
      this.$router.push({
        name: "Create User RSO",
      });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.amTable.page = 1;
      this.amTable.limit = 10;
      this.amTable.keyword = this.searchField;

      await this.getRSOList(
        this.amTable.page,
        this.amTable.limit,
        this.amTable.keyword
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.amTable.page = 1;
          this.amTable.limit = 10;
          this.amTable.keyword = event;

          await this.getRSOList(
            this.amTable.page,
            this.amTable.limit,
            this.amTable.keyword
          );
        }
      }, 500);
    },

    edit(id) {
      this.$router.push({
        name: "Edit User RSO",
        params: {
          id: id,
        },
      });
    },

    deleteRSO(id) {
        Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.am.deleteAreaManagerRSO(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.am.getAreaManagerRSOList(
            this.amTable.page,
            this.amTable.limit,
            this.amTable.keyword
          );
        });
    }
  },
};
</script>