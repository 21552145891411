<template>
  <div>
    <v-row>
      <v-col md="2" sm="12" class="mt-3 mb-2">
        <h5 class="filter-title">Filter DSO</h5>
        <div class="filter">
          <input type="checkbox" @click="checkAll" v-model="isCheckAll" />
          Pilih Semua
          <ul class="filter-dso">
            <li v-for="area in dsos" :key="area.id">
              <input
                type="checkbox"
                v-bind:value="area.id"
                v-model="areaSelected"
                @change="updateCheckall"
              />
              {{ titleCase(area.name) }}
            </li>
          </ul>
        </div>
        <v-btn
          class="primary mt-2"
          :disabled="areaSelected.length <= 0"
          block
          @click="filter"
          small
          >Filter</v-btn
        >
      </v-col>
      <v-col md="10" sm="12">
        <div class="bar-chart">
          <VueApexCharts
            v-if="chartOptions.xaxis.categories.length > 0"
            type="bar"
            :width="widthChart"
            height="328"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </v-col>
    </v-row>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauase"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { DashboardV2Controller } from "../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { titleCase } from "../../../../external/functions/Utilization.js"

export default {
  name: "top-achievement-index",
  props: {
    dsos: Array,
  },
  data: () => ({
    dashboard: new DashboardV2Controller(),
    isCheckAll: false,
    areaSelected: [],

    // charts
    series: [],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
      },
      title: {
        text: "Pencapaian Atas Member",
        align: "left",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        type: "category",
        categories: ["labels"],
        labels: {
          show: true,
          rotate: -45,
          style: {
            colors: [],
            fontSize: "10px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
      },
    },

    // loading
    isLoading: false,
    fullPage: true,
  }),
  computed: {
    isError() {
      return this.dashboard.errorMemberAchievmentByDSO;
    },
    errorCauase() {
      return this.dashboard.errorCauseMemberAchievmentByDSO;
    },
    widthChart() {
      return this.chartOptions.xaxis.categories.length > 25 ? 1500 : '100%'
    }
  },
  methods: {
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.areaSelected = [];
      if (this.isCheckAll) {
        // Check all
        for (var key of this.dsos) {
          this.areaSelected.push(key.id);
        }
      }
    },
    updateCheckall() {
      if (this.areaSelected.length == this.dsos.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    async filter() {
      this.isLoading = true;
      this.series = [];
      this.chartOptions.xaxis.categories = [];
      const dsos = this.areaSelected
        .map((item, index) => (index === 0 ? `${item}` : `&dso=${item}`))
        .join("");
      await this.dashboard.GetMemberAchievmentByDSO(dsos);
      this.chartOptions.xaxis.categories =
        this.dashboard.memberAchievmentByDSO.dsos.map((item) => {
          const data = [];
          if (item !== null) {
            data.push(item.name);
          }

          return data;
        });

      const seriesGroup = [
        {
          name: "Member Sudah Join Aplikasi",
          prefix: "sumTotalActiveMember",
        },
        {
          name: "Member Belum Join Aplikasi",
          prefix: "sumTotalMember",
        },
      ];
      for (const item of seriesGroup) {
        const counts = [];
        for (const subitem of this.dashboard.memberAchievmentByDSO.dsos) {
          counts.push(subitem.reach[0][item.prefix]);
        }

        this.series.push({
          name: item.name,
          data: counts,
        });
      }
      this.isLoading = false;
    },

    titleCase(str) {
      return titleCase(str)
    }
  },

  created(){
    this.checkAll();
    this.filter();
  },

  components: {
    VueApexCharts,
    Alert,
    Loading,
  },
};
</script>
<style scoped>
/* Box styles */
.filter {
  border: none;
  padding: 5px;
  width: 100%;
  height: 285px;
  overflow-y: scroll;
  font-size: 11px;
}

.filter-dso {
  display: inline;
}

.filter-dso li {
  padding-top: 5px;
}

.bar-chart {
  border: none;
  padding: 5px;
  width: 100%;
  overflow-x: scroll;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border: 1px solid #bdc3c7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bdc3c7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bdc3c7;
}
</style>

