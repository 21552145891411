<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0">
          <v-col md="8" sm="12">
            <v-autocomplete
              :items="[2019, 2020, 2021, 2022]"
              @change="pickArea"
              item-text="name"
              item-value="id"
              label="Tahun"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12">
            <v-btn
              class="success"
              :loading="isLoadingDownload"
              small
              block
              @click="download"
              >Unduh Excel
            </v-btn>
          </v-col>
          <v-col md="12" ms="12" align="center">
            <h3 align="center">{{ title }}</h3>
          </v-col>
          <v-row>
            <v-col align="center" md="6" ms="12">
              <VueApexCharts
                type="pie"
                width="100%"
                :options="chartOptions"
                :series="series"
                class='mt-7 ms-n5'
              />
            </v-col>
            <v-col md="6" sm="12" align="center">
              <v-row>
                <v-col md="12" ms="12" justify="center">
                  <BarTarget/>
                </v-col>
                <v-col md="12" ms="12" class='mt-n10'>
                  <h3>KATEGORI MEDIA SOSIAL</h3>
                </v-col>
                <v-col md="6" sm="12" align="center">
                  <h1><b>29</b></h1>
                  <p style="font-size: 10px">INSTAGRAM</p>
                </v-col>
                <v-col md="6" sm="12" align="center">
                  <h1><b>18</b></h1>
                  <p style="font-size: 10px">FACEBOOK</p>
                </v-col>
                <v-col md="6" sm="12" align="center">
                  <h1><b>6</b></h1>
                  <p style="font-size: 10px">YOUTUBE</p>
                </v-col>
                <v-col md="6" sm="12" align="center">
                  <h1><b>4</b></h1>
                  <p style="font-size: 10px">TIKTOK</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>

<script>
import Alert from "../../../../components/alert/index";
import { DashboardControllers } from "../../../../controllers/DashboardControllers";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);
import BarTarget from "./bar-target";

export default {
  name: "event-report",
  data: () => ({
    title: "MEDIA SOSIAL KOMUNITAS",
    dashboard: new DashboardControllers(),
    year: 2021,
    years: [2020, 2021, 2022],

    series: [29, 18, 6, 5],
    chartOptions: {
      chart: {
        width: "100%",
        type: "pie",
      },
      colors: ['#d6336c', '#4e6cbf', '#c02727', '#343131'],
      labels: ["Instagram", "Facebook", "Youtube", "Tiktok"],
      legend: {
        show: false,
        position: "right",
        formatter: function (label, opts) {
          return label + " : " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"],
        },
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(0) + "%"];
        },
      },
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
    Alert,
    BarTarget
  },

  computed: {
    // sales
    sales() {
      return this.dashboard.salesDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    this.getDashboard();
    this.getSeries();
  },

  methods: {
    getDashboard() {
      return this.dashboard.getSalesDashboardList(this.year);
    },

    async getSeries() {
      await this.getDashboard(this.year);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    async pickYear(event) {
      this.series = [
        {
          data: [],
        },
      ];
      this.chartOptions.xaxis.categories = [];

      await this.getDashboard(event);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const sales = [];
      for (const item of this.sales) {
        sales.push({
          "comunity name": item._id,
          jumlah: item.count,
        });
      }
      const data = XLSX.utils.json_to_sheet(sales);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Sales Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>