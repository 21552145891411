<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card
            class="elevation-12"
            v-on:keyup.enter="login"
            :loading="isLoading"
          >
            <v-toolbar dark color="primary">
              <v-toolbar-title>Masuk Backoffice</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  outlined
                  v-model="email"
                  name="username"
                  label="Pengguna"
                  type="text"
                  prepend-inner-icon="mdi mdi-account-reactivate mdi-24px"
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="password"
                  id="password"
                  name="password"
                  label="Kata Kunci"
                  prepend-inner-icon="mdi mdi-account-key mdi-24px"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login" :loading="isLoading"
                >Masuk</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <loading
      :active.sync="isLoadingToDashboard"
      :is-full-page="fullPage"
    ></loading>
  </v-main>
</template>
<script>
import { AuthControllers } from "../../controllers/AuthControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";

export default {
  name: "auth",
  data: () => ({
    auth: new AuthControllers(),
    email: "",
    password: "",

    isLoadingToDashboard: false,
    fullPage: true,

    showPass: false,
  }),

  computed: {
    isError() {
      return this.auth.isError;
    },

    errorCause() {
      return this.auth.errorCause;
    },

    isLoading() {
      return this.auth.isLoading;
    },
  },

  components: {
    Loading,
  },

  methods: {
    async goLogin(email, password) {
      return this.auth.goLogin(email, password);
    },

    async login() {
      this.isLoading;
      await this.goLogin(this.email, this.password);
      if (this.isError === false) {
        this.isLoadingToDashboard = true;
        window.location.href = window.location.origin;
      } else {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: this.errorCause,
        });
      }
    },
  },
};
</script>
