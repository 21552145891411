<template>
  <v-card>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left font-weight-black">MATERI</th>
            <th class="text-left font-weight-black">NILAI</th>
            <th v-if="!isLoadingPDF" class="text-left font-weight-black">RINCIAN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.workshop">
            <td class="font-weight-black">{{ item.workshop }}</td>
            <td class="font-weight-black">{{ item.averageScore.toFixed(0) }}</td>
            <td v-if="!isLoadingPDF" class="font-weight-black">
              <v-btn small icon color="success" dark @click="getdetail(dso,year,item.workshop)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<style>
p {
  font-size: 0.8em;
}
</style>
<script>
export default {
  name: "total-key",
  props:{
    items: {
      type: Array(),
      default: []
    },
    dso:{
      type: String(),
      default: ''
    },
    year:{
      type: Number(),
      default: 2022
    },
    isLoadingPDF: {
      type: Boolean(),
      default: false 
    }
  },

  methods: {
    getdetail(dso,year,workshop) {
      this.$router.push({
        name: "Competency Result",
        params: {
          dsoId: dso,
          year: year,
          workshop: workshop,
        },
      });
    },
  }
};
</script>
