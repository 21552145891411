import {
    CreateFollowerMedsosRequest,
    DeleteFollowerMedsosRequest,
    UpdateFollowerMedsosRequest,
    FollowerMedsosListRequest,
    FollowerMedsosDetailRequest,
    FollowerMedsosListByMedfolRequest
} from "../request/FollowerMedsosRequest.js";

export class FollowerMedsosControllers {
    followermedsosList = new Array();
    followermedsosDetail = new Object();
    followermedsosDownload = new Array();
    followermedsosListByMedfol = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getFollowerMedsosList(page, limit, keyword) {
        this.setLoading(true);
        const resp = FollowerMedsosListRequest(page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setFollowerMedsosList(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getFollowerMedsosListByMedfol(medfol, page, limit, keyword) {
        this.setLoading(true);
        const resp = FollowerMedsosListByMedfolRequest(medfol, page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setFollowerMedsosListByMedfol(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getFollowerMedsosDetail(id) {
        this.setLoading(true);
        const resp = FollowerMedsosDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setFollowerMedsosDetail(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadFollowerMedsosList(page, limit, keyword) {
        this.setLoadingDownload(true);
        const resp = FollowerMedsosListRequest(page, limit, keyword)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setFollowerMedsosListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setFollowerMedsosListDownload(data) {
        this.followermedsosDownload = data;
    }

    setFollowerMedsosList(data) {
        this.followermedsosList = data;
    }

    setFollowerMedsosListByMedfol(data) {
        this.followermedsosListByMedfol = data;
    }

    setFollowerMedsosDetail(data) {
        this.followermedsosDetail = data;
    }

    createFollowerMedsosList(
        medfol,
        community,
        follower
    ) {
        this.setLoading(true);
        const resp = CreateFollowerMedsosRequest(
                medfol,
                community,
                follower
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteFollowerMedsos(id) {
        this.setLoading(true);
        const resp = DeleteFollowerMedsosRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateFollowerMedsosList(
        id,
        medfol,
        community,
        follower
    ) {
        this.setLoading(true);
        const resp = UpdateFollowerMedsosRequest(
                id,
                medfol,
                community,
                follower
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}