import {
    DRPListRequest,
    CreateDRPRequest,
    DeleteDRPRequest,
    UpdateDRPRequest,
    DRPDetailRequest
} from "../request/DRPRequest.js";

export class DRPControllers {
    DRPList = new Array();
    DRPDetail = new Object();
    DRPDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() { }

    getDRPList(page, limit, keyword, dsoId) {
        this.setLoading(true);
        const resp = DRPListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setDRPList(response.data.data);
            }).catch(err => {
                this.setDRPList([])
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getDRPDetailEdit(id) {
        this.setLoading(true);
        const resp = DRPDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setDRPDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadDRPList(page, limit, keyword) {
        this.setLoadingDownload(true);
        const resp = DRPListRequest(page, limit, keyword)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setDRPListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setDRPListDownload(data) {
        this.DRPDownload = data;
    }

    setDRPDetail(data) {
        this.DRPDetail = data;
    }

    setDRPList(data) {
        this.DRPList = data;
    }

    createDRPList(
        dso,
        areaId,
        name,
        excellence,
        category,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode
    ) {
        this.setLoading(true);
        const resp = CreateDRPRequest(
            dso,
            areaId,
            name,
            excellence,
            category,
            province,
            district,
            subDistrict,
            village,
            street,
            zipCode
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getDetail(id) {
        this.DRPDetail = this.DRPList.find(item => {
            return item.id === id
        });
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteDRP(id) {
        this.setLoading(true);
        const resp = DeleteDRPRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateDRPList(
        id,
        dso,
        areaId,
        name,
        excellence,
        category,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode
    ) {
        this.setLoading(true);
        const resp = UpdateDRPRequest(
            id,
            dso,
            areaId,
            name,
            excellence,
            category,
            province,
            district,
            subDistrict,
            village,
            street,
            zipCode
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}

