import { ReportScoreRequest, ReportKisiRequest, ReportKisiScoreRequest, ReportActivityKeyRequest, CommunityKeyKPIRequest, InputDtkKhkRequest, ReportActivityKeyByDateRequest } from "../request/ReportRequest.js";

export class ReportControllers {
  reportScoresList = new Array();
  reportActivityKeyList = new Array();
  reportActivityKeyByDateList = new Array();
  reportKisiList = new Object();
  reportKisiScoreList = new Object();
  communityKeyKPIList = new Object();
  isError = false;
  isLoading = false;
  errorCause = "";
  errorCode = 0;

  constructor() {}

  getReportScoresList(dso, brand, category) {
    this.setLoading(true);
    const resp = ReportScoreRequest(dso, brand, category)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setReportScoreList(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getReportKisiList(dso, brand, category, periodeScoring) {
    this.setLoading(true);
    const resp = ReportKisiRequest(dso, brand, category, periodeScoring)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setReportKisiList(response.data.data);
      })
      .catch((err) => {
        this.setReportKisiList(null);
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }
  getReportActivityKeyList(dso, brand, category, score, periodeScoring) {
    this.setLoading(true);
    const resp = ReportActivityKeyRequest(dso, brand, category, score, periodeScoring)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setReportActivityKeyList(response.data.data);
      })
      .catch((err) => {
        this.setReportActivityKeyList(null);
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getReportActivityKeyByDateList(dso, brand, category, score, date) {
    this.setLoading(true);
    const resp = ReportActivityKeyByDateRequest(dso, brand, category, score, date)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setReportActivityKeyByDateList(response.data.data);
      })
      .catch((err) => {
        this.setReportActivityKeyByDateList(null);
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getCommunityKeyKPIList(dsoId, year) {
    this.setLoading(true);
    const resp = CommunityKeyKPIRequest(dsoId, year)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setCommunityKeyKPIList(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getReportKisiScoreList(dso, brand, category, periodeScoring) {
    this.setLoading(true);
    const resp = ReportKisiScoreRequest(dso, brand, category, periodeScoring)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setReportKisiScoreList(response.data.data);
      })
      .catch((err) => {
        this.setReportKisiScoreList({});
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setReportScoreList(data) {
    this.reportScoresList = data;
  }

  setReportKisiList(data) {
    this.reportKisiList = data;
  }
  setReportActivityKeyList(data) {
    this.reportActivityKeyList = data;
  }

  setReportActivityKeyByDateList(data) {
    this.reportActivityKeyByDateList = data;
  }

  setCommunityKeyKPIList(data) {
    this.communityKeyKPIList = data;
  }

  setReportKisiScoreList(data) {
    this.reportKisiScoreList = data;
  }

  setLoading(status) {
    this.isLoading = status;
  }

  setError(status) {
    this.isError = status;
  }

  setErrorCause(err) {
    this.errorCause = err;
  }

  setErrorcode(code) {
    this.errorCode = code;
  }

  inputDtkKhk(year, dataInput) {
    this.setLoading(true);
    const resp = InputDtkKhkRequest(year, dataInput)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }
}
