<template>
  <div>
    <BarBrandMemberActive :dsos="dsos" />
    <BarInsightMember :dsos="dsos" />
    <PieCharts :dsos="dsos" />
  </div>
</template>
<script>
import BarBrandMemberActive from "./right-charts/bar-brand-member-active.vue"
import BarInsightMember from "./right-charts/bar-insight-member.vue"
import PieCharts from "./pie-chart-report.vue"

export default {
  name: "community-report-right",
  data: () => ({

  }),

  props: {
    dsos: Array,
  },

  components: {
    BarBrandMemberActive,
    BarInsightMember,
    PieCharts
  }
};
</script>