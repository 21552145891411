<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0 me-0">
          <v-col md="8" sm="12">
            <v-autocomplete
              :items="[2019, 2020, 2021, 2022]"
              item-text="name"
              item-value="id"
              label="Tahun"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12">
            <v-btn class="success" small block>Unduh Excel </v-btn>
          </v-col>
          <v-col align="center" justify='center' md="12" ms="12" class="mt-n5">
            <h2>{{ title }}</h2>
          </v-col>
          <v-col class="d-flex flex-row">
            <v-col align="center" md="4" ms="12" class="mt-0">
            <h2 class="display-1 font-weight-black">
              <b>786</b><span style="font-size: 10px">/969</span>
              </h2>
            <p style="font-size: 10px" class="font-weight-black mt-n3 green--text">
              <v-icon class="font-weight-black green--text mt-n1">
                mdi-arrow-up-bold</v-icon> INCREASE 30%
            </p>
          </v-col>
            <v-col md="8" ms="12" class="ms-n7">
              <BarTarget/>
            </v-col>
          </v-col>
          <v-col md="12" sm="12" class="px-0 py-0 mt-n5 me-0">
            <VueApexCharts
              type="bar"
              height="250"
              :options="chartOptions"
              :series="series"
            />
          </v-col>
          
          <!-- <v-col align="center" md="12" ms="12" class="mt-n8 me-0">
            <BarChart />
          </v-col> -->
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
// import BarChart from "./bar-chart";
import BarTarget from "./bar-target";

export default {
  name: "community-achievement",
  data: () => ({
    title: "CAPAIAN KONSUMSI",
    year: 2021,
    years: [2020, 2021, 2022],
    series: [
      {
        data: [314, 275, 133, 32, 32],
        sku:[{
          sku10 : 200,
          sku12 : 100,
          sku16 : 14,
        },{
          sku10 : 100,
          sku12 : 150,
          sku16 : 25,
        },{
          sku10 : 75,
          sku12 : 25,
          sku16 : 33,
        },{
          sku10 : 10,
          sku12 : 15,
          sku16 : 7,
        },{
          sku10 : 7,
          sku12 : 13,
          sku16 : 12,
        }]
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 175,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "50%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#000000", "#e2a63d", "#e23d3d", "#d99494", "#34bf41"],
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"],
          fontSize: "15px",
        },
        formatter: function (val, opt) {
          const series = opt.w.globals.series[0]
          const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0);
          const percent = (val / sum) * 100;

          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+` (${percent.toFixed(0)}%)`;
        },
        offsetX: '0px',
        offsetY: -18,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      tooltip:{
        custom: function({ seriesIndex, dataPointIndex, w}) {
        var sku = w.globals.initialSeries[seriesIndex].sku[dataPointIndex];
        
        return '<ul>' +
        '<li><b>SKU 10</b>: ' + sku.sku10 + '</li>' +
        '<li><b>SKU 12</b>: ' + sku.sku12 + '</li>' +
        '<li><b>SKU 16</b>: ' + sku.sku16 + '</li>' +
        '</ul>';
    }
      },
      xaxis: {
        categories: [
          "LA BOLD",
          "DJARUM 76",
          "DJARUM SUPER",
          "LA LIGHTS",
          "OTHERS",
        ],
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
    },
  }),

  components: {
    VueApexCharts,
    // BarChart,
    BarTarget,
  },
};
</script>