<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row v-if="getRole.role.filter">
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12">
              <v-autocomplete
                    v-model="workshop"
                    @change="workshopChange"
                    :items="workshops"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    label="Workshop"
                    required
                    outlined
                    dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5" sm="12" class="p-5">
              <v-text-field 
                v-model="form.name" 
                :rules="[(v) => !!v || 'Kolom wajib diisi']" 
                label="Nama Peserta"
                required outlined dense>
              </v-text-field>
            </v-col>
            <v-col md="5" sm="12" class="p-5">
              <v-text-field 
                v-model="form.score" 
                :rules="[(v) => !!v || 'Kolom wajib diisi']" 
                label="Nilai"
                type="number"
                required outlined dense>
              </v-text-field>
            </v-col>
            <v-col md="3" sm="12" class="p-5" v-if="!isEdit">
              <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit">
                Submit
              </v-btn>
            </v-col>
            <v-col md="6" sm="12" class="p-5 d-flex" v-else>
              <v-btn color="success" class="mr-4" @click="submitEdit">
                Ubah
              </v-btn>
              <v-btn color="error" class="mr-4" @click="cancleEdit">
                Batal
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="!isLoadingConsumption">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Peserta</th>
                  <th class="text-left">Nilai</th>
                  <!-- <th class="text-left">Daftar Target</th> -->

                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in competencies" :key="item.id">
                  <td v-if="item.name">
                    {{ item.name }}
                  </td>
                  <td v-else>-</td>
                  <td v-if="item.score">
                    {{ item.score}}
                  </td>
                  <td v-else>-</td>
                  <!-- <td>
                    <v-btn small icon color="success" dark @click="targetList(item.id)">
                      <v-icon>mdi-bullseye</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn small icon color="success" dark @click="inputConsumption(item.id)">
                      <v-icon>mdi-notebook-edit</v-icon>
                    </v-btn>
                  </td> -->
                  <!-- <td>
                    <v-btn small icon color="success" dark @click="getdetail(item.id)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td> -->
                  <td v-if="getRole.role.update">
                    <v-btn small icon color="warning" dark @click="edit(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn small icon color="error" dark @click="deleteCompetency(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Provinces -->
    <Alert :status="isErrorCompetency && errorCodeCompetency !== 404" icon="error" title="KESALAHAN"
      :msg="errorCauseCompetency" />

  </v-container>
</template>
<script>

import Alert from "../../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { CompetencyControllers } from "../../../../controllers/CompetencyControllers";
import Swal from "sweetalert2";

export default {
  name: "create-competency",
  data: () => ({
    competency: new CompetencyControllers(),
    valid: true,
    title: "Hasil Workshop",
    workshops :['Workshop I', 'Workshop II', 'Workshop III', 'Workshop IV'],


    isLoading: false,
    fullPage: true,

    workshop: '',
    keyword: '',

    form: {
      name: '',
      score: null,
      year: 2022,
    },

    selectScope: {
      page: 1,
      limit: 600,
    },
    pages: {
      start: 1,
      end: 10,
    },

    // edit
    editId:'',
    isEdit: false,


    logoDisplay: null,
    thereIsImage: false,
  }),


  components: {
    Loading,
    Alert,
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },
    // competency create
    competencies(){
      return this.competency.competencyList
    },

    isErrorCompetency() {
      return this.competency.isError;
    },

    errorCauseCompetency() {
      return this.competency.errorCause;
    },

    isErrorUpdateCompetency() {
      return this.competency.isErrorUpdate;
    },

    errorCauseUpdateCompetency() {
      return this.competency.errorCauseUpdate;
    },


  },

  created() {
    this.workshop = decodeURI(this.$route.params.workshop)
    this.getCompetencyList()
  },

  methods: {

    async getCompetencyList(){
      return this.competency.getCompetencyList(
        this.$route.params.dsoId,
        this.$route.params.year,
        this.workshop,
        this.keyword
      )
    },

    async workshopChange(){
      return this.competency.getCompetencyList(
        this.$route.params.dsoId,
        this.$route.params.year,
        this.workshop,
        this.keyword
      )
    },

    back() {
      this.$router.go(-1);
    },

    edit(datum){
      this.isEdit=true
      this.form.name = datum.name
      this.form.score = datum.score
      this.editId = datum.id
      this.workshop = datum.workshop
    },

    cancleEdit(){
      this.isEdit=false
      this.$refs.observer.reset()
    },

    deleteCompetency(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.competency.deleteCompetency(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.competency.getCompetencyList(
          this.$route.params.dsoId,
          this.$route.params.year,
          decodeURI(this.$route.params.workshop),
          this.keyword
      )
        });
    },

    async submitProcess(
      dso,
      name,
      workshop,
      score,
      year
    ) {
      return this.competency.createCompetencyList(
        dso,
        name,
        workshop,
        score,
        year
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.$route.params.dsoId,
          this.form.name,
          this.workshop,
          parseInt(this.form.score),
          this.$route.params.year,
        )
        .finally(() => {
          return this.competency.getCompetencyList(
            this.$route.params.dsoId,
            this.$route.params.year,
            this.workshop,
            this.keyword
          );
        });

        if (this.isErrorCreateCompetency) {
          this.isLoading = false;
          this.isErrorCreateCompetency = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateCompetency,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.isErrorCreateCompetency = false;
          this.$refs.observer.reset();
        }
      }
    },

    async editProcess(
      name,
      workshop,
      score,
      year
    ) {
      return this.competency.updateCompetencyList(
        this.editId,
        name,
        workshop,
        score,
        year
      );
    },

    async submitEdit() {
      const validate = this.$refs.observer.validate();

      if (validate) {
        this.isLoading = true;
        await this.editProcess(
          this.form.name,
          this.workshop,
          parseInt(this.form.score),
          this.$route.params.year,
        )
        .finally(() => {
          return this.competency.getCompetencyList(
            this.$route.params.dsoId,
            this.$route.params.year,
            decodeURI(this.$route.params.workshop),
            this.keyword
          );
        });

        if (this.isErrorUpdateCompetency) {
          this.isLoading = false;
          this.isErrorCreateCompetency = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateCompetency,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.isErrorUpdateCompetency = false;
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>