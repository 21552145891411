export default{
    namespaced: true,
    state:{
        medsos:false,
    },
    mutations:{
        setmedsos :(state) => {
            state.medsos = true
            }
    },
    actions:{
        setmedsos:({commit})=>{
            commit('setmedsos')
        }
    },
    getters:{
        medsos : state => state.medsos
    },
    
}