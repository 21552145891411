<template>
  <VueApexCharts
  ref="realtimeGlobalBar"
    v-if="chartOptions.xaxis.categories.length > 0"
    type="bar"
    height="95"
    max-width="310"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

export default {
  name: "community-achievement",

  props: {
    communityAchievement: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },

  created() {
    this.$refs.realtimeGlobalBar.updateSeries([{
        data: [0],
      }], false, true);
  },

  watch: {
    communityAchievement(newVal) {
      this.series[0].data = [newVal.reach]
      const newChartOptions = this.chartOptions;
      newChartOptions.xaxis.max = newVal.target
      newChartOptions.annotations.yaxis[0].label.text=`Target: ${newVal.target}`
      newChartOptions.annotations.xaxis[0].x2=newVal.target;
      
      Object.assign(this.chartOptions, newChartOptions)
      
      this.$refs.realtimeGlobalBar.refresh()
    }
  },

  data: (instance) => ({
    // year: 2021,
    // years: [2020, 2021, 2022],
    series: [
      {
        data: [instance.communityAchievement.reach],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors:['#424c58'],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "center", // top, center, bottom
          },
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontWeight: 900,
          colors: ["#fff"],
        },
      },

      xaxis: {
        categories: ["Capaian"],
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tickAmount: 2,
        crosshairs: {
          fill: {
            type: "solid",
          },
        },
        tooltip: {
          enabled: false,
        },
        max: instance.communityAchievement.target,
      },
      tooltip: {
          enabled: false,
        },
      grid: {
        show: false,
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 2,
        labels: {
          show: false,
        },
      },
      title: {
        show: false,
      },
      annotations: {
        position: 'back',
        xaxis: [
          {
            x: 0,
            x2: instance.communityAchievement.target,
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              show: false,
              text: "50",
              offsetY: 1,
              position:'right',
              orientation: 'horizontal',
              borderColor: 'transparent',
              style:{
                background: 'transparent',
                color: 'transparent'
              }
            },
          },
        ],
        yaxis: [
          {
            y: 0,
            y2: "capaian",
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              text: `Target: ${instance.communityAchievement.target}`,
              fontWeight:900,
              offsetY: 1,
              position:'right',
              orientation: 'horizontal',
              borderColor: 'transparent',
              style:{
                background: 'transparent',
              }
            },
          },
        ],
      },
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
  },
};
</script>
