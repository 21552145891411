<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-file-input
                v-model="file"
                accept=".xlsx"
                label="Data DTK KHK (Excel)"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                outlined
                dense
                required
                prepend-icon="mdi-file-excel"
                @change="onChange"
              ></v-file-input>
            </v-col>

            <!-- <v-col md="4" sm="12" class="p-5">
              <v-autocomplete
                v-model="form.year"
                :items="years"
                item-text="value"
                item-value="key"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Periode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col> -->
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="dataInput.length > 0">
      <v-col md="12">
        <h2>Review Data Input</h2>
      </v-col>
      <v-col md="12" sm="12">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">PIC</th>
                  <th class="text-left">DSO</th>
                  <th class="text-left">Brand</th>
                  <th class="text-left">Nama</th>
                  <th class="text-left">Tgl Dibentuk</th>
                  <th class="text-left">Deskripsi</th>
                  <th class="text-left">Kategori</th>
                  <th class="text-left">Tgl Join</th>
                  <th class="text-left">Provinsi</th>
                  <th class="text-left">Kota/Kab</th>
                  <th class="text-left">Kecamatan</th>
                  <th class="text-left">Desa</th>
                  <th class="text-left">Jalan</th>
                  <th class="text-left">Kode Pos</th>
                  <th class="text-left">Sosmed</th>
                  <th class="text-left">Total Member</th>
                  <th class="text-left">Total Smoker</th>
                  <th class="text-left">Aktif Member</th>
                  <th class="text-left">Villagers</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dataInput" :key="index">
                  <td>{{ index+1 }}</td>
                  <td>{{ item.pic  }}</td>
                  <td>{{ item.dso }}</td>
                  <td>{{ item.brand }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.dob }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.category }}</td>
                  <td>{{ item.joinedAt }}</td>
                  <td>{{ item.province }}</td>
                  <td>{{ item.district }}</td>
                  <td>{{ item.subDistrict  }}</td>
                  <td>{{ item.village }}</td>
                  <td>{{ item.street }}</td>
                  <td>{{ item.zipCode }}</td>
                  <td>{{ item.socialMedias }}</td>
                  <td>{{ item.totalMember }}</td>
                  <td>{{ item.totalSmoker }}</td>
                  <td>{{ item.totalActiveMember }}</td>
                  <td>{{ item.totalVillagers }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  </v-container>
</template>
<script>
// import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import { CommunityControllers } from '../../controllers/CommunityControllers';

export default {
  name: "insert-community",
  data: () => ({
    valid: true,
    title: "Insert Community",
    community: new CommunityControllers(),

    isLoading: false,
    fullPage: true,
    file: null,
    dataInput: [],

    // form: {
    //   year: null,
    // },
  }),

  components: {
    Loading,
    // Alert,
  },

  // computed: {
  //   years() {
  //     let yearArr = [];

  //     const start = 2019;
  //     const end = new Date().getFullYear() - 1;

  //     for (let i = start; i <= end; i++) {
  //       yearArr.push({ key: i, value: i });
  //     }

  //     return yearArr;
  //   },
  // },

  methods: {
    back() {
      this.$router.go(-1);
    },

    async submitProcess(
      // year,
      dataInput
    ) {
      return this.community.inputCommunityList(
        // year,
        dataInput
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          // this.form.year,
          this.dataInput
        );

        if (this.isErrorCreateUser) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateUser,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },

    onChange() {
      if (this.file != null) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          const dataInput = data.slice(1);

          this.dataInput = dataInput.map((datum) =>{
            return {
              'pic': datum[1],
              'dso': datum[2],
              'brand': datum[3],
              'name': datum[4],
              'dob': datum[5],
              'description': datum[6],
              'category': datum[7],
              'joinedAt': datum[8],
              'province': datum[9],
              'district': datum[10],
              'subDistrict': datum[11],
              'village': datum[12],
              'street': datum[13],
              'zipCode': datum[14],
              'socialMedias': datum[15],
              'totalMember': datum[16],
              'totalSmoker': datum[17],
              'totalActiveMember': datum[18],
              'totalVillagers': datum[19],
            }
          })
        };

        reader.readAsBinaryString(this.file);
      }
    },
  },
};
</script>