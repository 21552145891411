<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0 d-flex flew-row" justify="space-between" align="center">
          <v-col align="center" md="2" ms="12" class="mt-n5">
            <p class="text-h5 font-weight-black mt-6">AVERAGE</p>
            <h2 class="text-h2 font-weight-black mt-n5 ps-1 pe-0">
              <b class="text-h2 font-weight-black">{{parseFloat(CompetencyAchievementComputed.reach).toFixed(0)}}</b>
            </h2>
          </v-col>
          <v-col md="4" sm="12" class="ms-n4"> 
            <CompetencyCircle :capaian="CompetencyAchievementComputed.reach"/>
          </v-col>
          <v-col md="6" ms="12" class="mb-0 d-flex flex-column">
            <TableDSO :items="CompetencyAchievementComputed.competencyPerWorkshop" :dso="dso" :year="chosenYear" :isLoadingPDF="isLoadingPDF"/>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>
<script>
import Alert from "../../../components/alert/index";
import { CompetencyControllers } from "../../../controllers/CompetencyControllers";
import TableDSO from "../activity-report/competency/table-dso-competency.vue";
import CompetencyCircle from '../../../components/dashboard/competency-circle.vue'

export default {
  name: "competency-achievement",
  props: {
    dso: {
      type: String,
      default: ""
    },
    chosenYear:{
      type: Number,
      default:2022
    },
    isLoadingPDF: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    title: "CAPAIAN KOMPETENSI",
    competency: new CompetencyControllers(),
    series: [70],
    chartOptions: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        width: 250,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              fontSize: "15px",
              offsetY: -20,
            },
            value: {
              show: false,
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "solid",
        colors: ['#00fc00']
      },
      labels: [["VERY","GOOD"]],
    },

    isLoadingDownload: false,
  }),

  components: {
    Alert,
    TableDSO,
    CompetencyCircle
  },

  computed: {
    CompetencyAchievementComputed() {
      const achievement = this.competency.competencyAchievement.competencyAchievement;
      const competencyPerWorkshop = this.competency.competencyAchievement.competencyPerWorkshop;
      return {
        reach:Number(achievement?.split('/')?.[0] || 0),
        target: Number(achievement?.split('/')?.[1] || 0),
        competencyPerWorkshop: competencyPerWorkshop,
      }
    },

    getRole() {
      return this.$route.meta;
    },

    // // sales
    // sales() {
    //   return this.competency.CompetencyList;
    // },

    errorCodeGetCompetency() {
      return this.competency.errorCode;
    },

    isErrorGetCompetency() {
      return this.competency.isError;
    },

    errorCauseGetCompetency() {
      return this.competency.errorCause;
    },

    isLoadingCompetency() {
      return this.competency.isLoading;
    },
  },

  // created() {
  //   this.getCompetency();
  //   this.getSeries();
  // },

  watch: {
    chosenYear() {
      this.getCompetencyAchievement()
    }
  },

  created(){
    this.getCompetencyAchievement()

  },

  methods: {
    getCompetencyAchievement() {
      return this.competency.getCompetencyAchievement(this.dso, this.chosenYear)
    },
  },
};
</script>
