import axios from "axios";
import { EventCommunityList, DeleteEventCommunity, CreateEventCommunity, UpdateEventCommunity, EventCommunityDetail } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const BigEventListRequest = (page, limit, keyword, brand, category, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${EventCommunityList(page, limit, keyword, brand, category, dsoId)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateBigEventListRequest = (
    title,
    description,
    category,
    date,
    province,
    district,
    subDistrict,
    village,
    street,
    zipCode,
    locationLink,
    communityId,
    totalParticipant,
    images
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateEventCommunity()}`,
        data: {
            title: title,
            description: description,
            category,
            date: date,
            location: {
                province: province,
                district: district,
                subDistrict: subDistrict,
                village: village,
                street: street,
                zipCode: zipCode
            },
            locationLink: locationLink,
            communityId: communityId,
            totalParticipant: totalParticipant,
            images: images ? [images] : []
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteBigEventRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteEventCommunity(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateBigEventRequest = (
    id,
    title,
    totalParticipant,
    description,
    date,
    province,
    district,
    subDistrict,
    village,
    street,
    zipCode,
    locationLink
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateEventCommunity(id)}`,
        data: {
            title: title,
            totalParticipant: totalParticipant,
            description: description,
            date: date,
            location: {
                province: province,
                district: district,
                subDistrict: subDistrict,
                village: village,
                street: street,
                zipCode: zipCode
            },
            locationLink: locationLink
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const BigEventDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${EventCommunityDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}







