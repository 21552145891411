<template>
  <v-row>
    <v-col md="8" sm="12">
      <h4>{{ title }}</h4>
    </v-col>
    <v-col md="2" sm="12">
      <v-btn
        class="success"
        :loading="isLoadingDownload"
        small
        block
        @click="download"
        >Unduh Excel</v-btn
      >
    </v-col>
    <v-col md="6" sm="6">
      <v-select
        v-model="year"
        @change="pickYear"
        :items="years"
        label="Ganti Tahun"
        outlined
        dense
      ></v-select>
    </v-col>
    <v-col md="6" sm="6">
      <router-link to="consumption-list" style="text-decoration: none"
        >Lihat semua sales target</router-link
      >
    </v-col>
    <v-col md="12" sm="12" v-if="!isLoadingDashboard">
      <p v-if="sales.length === 0">Data Tidak Tersedia</p>
      <VueApexCharts
        v-if="chartOptions.xaxis.categories.length > 0"
        type="bar"
        height="250"
        :options="chartOptions"
        :series="series"
      />
    </v-col>
    <v-col md="12" sm="12" v-else>
      <p>Loading...</p>
    </v-col>
    <!-- Error Dashboard -->
    <Alert
      :status="isErrorGetDashboard && errorCodeGetDashboard !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDashboard"
    />
  </v-row>
</template>
<script>
import Alert from "../../../components/alert/index";
import { DashboardControllers } from "../../../controllers/DashboardControllers";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);

export default {
  name: "user-growth",
  data: () => ({
    title: "Sales",
    dashboard: new DashboardControllers(),
    year: 2021,
    series: [
      {
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 250,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      xaxis: {
        categories: [],
      },
      legend: {
        show: false,
      },

      toolbar: {
        export: {
          png: {
            filename: undefined,
          },
        },
      },
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
    Alert,
  },

  computed: {
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },
    // sales
    sales() {
      return this.dashboard.salesDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    this.getDashboard();
    this.getSeries();
  },

  methods: {
    getDashboard() {
      return this.dashboard.getSalesDashboardList(this.year);
    },

    async getSeries() {
      await this.getDashboard(this.year);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item.brand);
      });
    },

    async pickYear(event) {
      this.series = [
        {
          data: [],
        },
      ];
      this.chartOptions.xaxis.categories = [];

      await this.getDashboard(event);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item.brand);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const sales = [];
      for (const item of this.sales) {
        sales.push({
          "comunity name": item.brand,
          jumlah: item.count,
        });
      }
      const data = XLSX.utils.json_to_sheet(sales);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Sales Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>