import axios from "axios";
import { AchievementDetail, AchievementList, CreateAchievement, DeleteAchievementById, UpdateAchievementById, VerifyAchievement } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const AchievementListRequest = (page, limit, keyword, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AchievementList(page, limit, keyword, dsoId)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const VerifyAchievementRequest = (
    id,
    score
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${VerifyAchievement(id)}`,
        data: {
            score: score
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateAchievementRequest = (
    communityId,
    name,
    year,
    scale,
    nomination,
    image,
    isContract,
    score
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateAchievement()}`,
        data: {
            communityId: communityId,
            name: name,
            year: year,
            scale: scale,
            nomination: nomination,
            image: image,
            isContract: isContract,
            score: score
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateAchievementRequest = (
    id,
    name,
    year,
    scale,
    nomination,
    image,
    isContract,
    score
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateAchievementById(id)}`,
        data: {
            name: name,
            year: year,
            scale: scale,
            nomination: nomination,
            image: image,
            isContract: isContract,
            score: score
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteAchievementRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteAchievementById(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AchievementDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AchievementDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

