<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="7" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <!-- <v-col md="3" sm="12">
        <v-btn
          v-if="getRole.role.create"
          class="float-right"
          depressed
          color="primary"
          @click="createReport"
          block
        >
          Buat Report
        </v-btn>
      </v-col> -->
      <v-col md="2" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh Excel
        </v-btn>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
          block
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row v-if="!isLoadingReport">
      <v-col md="12" sm="12">
        <v-card v-if="isErrorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Pengguna</th>
                  <th class="text-left">Konten</th>
                  <th class="text-left">Laporan</th>
                  <!-- <th class="text-left">Rincian</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in reports" :key="item.id">
                  <td>{{ item.post.user.name }}</td>
                  <td>{{ item.post.content }}</td>
                  <td>{{ item.type }}</td>
                  
                  <!-- <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn
                      small
                      icon
                      color="warning"
                      dark
                      @click="edit(item.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deleteReport(item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Detail :isShow="isDetail" :data="reportDetail" @hide="updateModal" />

    <Alert
      :status="isError && isErrorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseReport"
    />

    <!-- <Alert
      :status="isErrorDelete"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseDelete"
    /> -->

    <Alert
      :status="isErrorDownload && isErrorCodeDownload !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseDownload"
    />
  </v-container>
</template>
<script>
import { PostReportControllers } from "../../../controllers/PostReportControllers.js";
import Alert from "../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import moment from "moment";
import XLSX from "xlsx";

export default {
  name: "report-list",
  data: () => ({
    report: new PostReportControllers(),
    title: "Laporan Postingan",
    dso:'',
    reportTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },

    dialogDownload: false,

    searchField: "",
  }),

  components: {
    Alert,
    Loading,

  },

  created() {
    this.getReports();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    reports() {
      return this.report.postreportList;
    },

    isErrorCode() {
      return this.report.errorCode;
    },

    isError() {
      return this.report.isError;
    },

    errorCauseReport() {
      return this.report.errorCause;
    },

    isLoadingReport() {
      return this.report.isLoading;
    },

    // delete
    isErrorDelete() {
      return this.report.isErrorDelete;
    },

    errorCauseDelete() {
      return this.report.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.report.isLoading;
    },

    // download
    dataDownload() {
      return this.report.postreportDownload;
    },

    isErrorDownload() {
      return this.report.errorDownload;
    },

    isErrorCauseDownload() {
      return this.report.errorCauseDownload;
    },

    isLoadingDownload() {
      return this.report.loadingDownload;
    },

    isErrorCodeDownload() {
      return this.report.errorCodeDownload;
    },
  },

  methods: {
    async getReports() {
      return this.report.getPostReportList(
        this.reportTable.page,
        this.reportTable.limit,
        this.reportTable.keyword
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingReport;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.reportTable.page -= 1;

        await this.getReports(
          this.reportTable.page,
          this.reportTable.limit,
          this.reportTable.keyword,
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingReport;
      this.pages.start += 10;
      this.pages.end += 10;

      this.reportTable.page += 1;

      await this.getReports(
        this.reportTable.page,
        this.reportTable.limit,
        this.reportTable.keyword,
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createReport() {
      this.$router.push({
        name: "Create Report",
      });
    },

    getdetail(id) {
      this.isDetail = true;
      return this.report.getReportDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    deleteReport(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.report.deleteReport(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.report.getReportList(
            this.reportTable.page,
            this.reportTable.limit,
            '',
            this.dso
          );
        });
    },

    edit(id) {
      this.$router.push({
        name: "Edit Report",
        params: {
          id: id,
        },
      });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.reportTable.page = 1;
      this.reportTable.limit = 10;
      this.reportTable.keyword = this.searchField;

      await this.getReports(
        this.reportTable.role,
        this.reportTable.page,
        this.reportTable.limit,
        this.reportTable.keyword,
        this.dso
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.reportTable.page = 1;
          this.reportTable.limit = 10;
          this.reportTable.keyword = event;

          await this.getReports(
            this.reportTable.page,
            this.reportTable.limit,
            this.reportTable.keyword,
            this.dso
          );
        }
      }, 500);
    },

    normalDate(val) {
      return moment(val).format("DD-MM-YYYY h:mm:ss");
    },

    async download() {
      await this.report.downloadReportList(
        this.reportTable.page,
        this.reportTable.limit,
        this.reportTable.keyword,
        this.dso
      );

      if (this.dataDownload.length > 0) {
        const dataDownload = [];
        for (const item of this.dataDownload) {
          dataDownload.push({
            "nama report": item.name,
            brand: item.brand.name,
            kota: item.address.district,
            alamat: item.address
              ? `${item.address.street}, ${item.address.village}, ${item.address.subDistrict}, ${item.address.district}, ${item.address.province}, ${item.address.zipCode}`
              : "",
            kategori: item.category,
            "login terakhir": this.normalDate(item.lastLogin),
            "dibuat pada": this.normalDate(item.createdAt),
            "diperbaharui pada": this.normalDate(item.updatedAt),
          });
        }

        const data = XLSX.utils.json_to_sheet(dataDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Report Report List.xlsx`);
      } else {
        Swal.fire({
          text: "Data Tidak ditemukan",
          icon: "warning",
        });
      }
    },
  },
};
</script>