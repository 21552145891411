import { UserListRequest, CreateUserRequest, DeleteUserRequest, UpdateUserRequest, FilterUserAMRequest, UserDetailRequest, InputUserRequest } from "../request/UserRequest.js";

export class UserControllers {
    userList = new Array();
    userDetail = new Object();
    userListAM = new Array();
    userDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() { }

    getUserList(page, limit, keyword, startDate, endDate, isReport, amId, rsoId, exPic, role) {
        this.setLoading(true);
        const resp = UserListRequest(page, limit, keyword, startDate, endDate, isReport, amId, rsoId, exPic, role)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setUserList(response.data.data);
                return true;
            }).catch(err => {
                this.setUserList([]);
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getUserDetailEdit(id) {
        this.setLoading(true);
        const resp = UserDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setUserDetail(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadUserList(page, limit, keyword, startDate, endDate, isReport, amId, rsoId, exPic, role) {
        this.setLoadingDownload(true);
        const resp = UserListRequest(page, limit, keyword, startDate, endDate, isReport, amId, rsoId, exPic, role)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setUserListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setUserListDownload(data) {
        this.userDownload = data;
    }

    setUserDetail(data) {
        this.userDetail = data;
    }

    setUserList(data) {
        this.userList = data;
    }

    createUserList(
        email,
        name,
        role,
        gender,
        mobileNumber,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        pob,
        dob,
        dso
    ) {
        this.setLoading(true);
        const resp = CreateUserRequest(
            email,
            name,
            role,
            gender,
            mobileNumber,
            province,
            district,
            subDistrict,
            village,
            street,
            zipCode,
            pob,
            dob,
            dso
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    inputUserList(
        dataInput
    ) {
        this.setLoading(true);
        const resp = InputUserRequest(
            dataInput
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getUserDetail(id) {
        this.userDetail = this.userList.find(item => {
            return item.id === id
        });
    }

    deleteUser(id) {
        this.setLoading(true);
        const resp = DeleteUserRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateUserList(
        id,
        email,
        name,
        role,
        gender,
        mobileNumber,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        pob,
        dob,
        dso
    ) {
        this.setLoading(true);
        const resp = UpdateUserRequest(
            id,
            email,
            name,
            role,
            gender,
            mobileNumber,
            province,
            district,
            subDistrict,
            village,
            street,
            zipCode,
            pob,
            dob,
            dso
        )
        .then(() => {
            this.setError(false);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    getUserAMList(role, page, limit, keyword) {
        this.setLoading(true);
        const resp = FilterUserAMRequest(role, page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setUserListAM(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setUserListAM(data) {
        this.userListAM = data;
    }

}

