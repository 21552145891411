import axios from "axios";
import { PostList, DeletePost, CreatePostList, PostCommentDetail, PostDetail, VerifyPost } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const PostListRequest = (page, limit, keyword, role, dsoId) => {

    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostList(page, limit, keyword, role, dsoId)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeletePostRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeletePost(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreatePostListRequest = (
    content, 
    images, 
    links, 
    type
) => {
const resp = axios({
    method: 'post',
    url: `${BaseUrl()}${CreatePostList()}`,
    data: {
        content: content, 
        images: images, 
        links: links, 
        type: type
    },
    headers: headers
}).then((response) => {
    return response;
});

return resp;
}

export const PostCommentDetailRequest = (id, page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostCommentDetail(id, page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const PostDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const VerifyPostRequest = (id) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${VerifyPost(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}


