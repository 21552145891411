import {
    PostCommunityListRequest,
    DeletePostCommunityRequest,
    CreatePostCommunityListRequest,
    PostCommunityCommentDetailRequest,
    PostCommunityDetailRequest
} from "../request/PostCommunityRequest.js";

export class PostCommunityControllers {
    postcommunityList = new Array();
    postcommunityCommentDetail = new Array();
    postcommunityDetail = new Object();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    constructor() {}

    getPostCommunityList(page, limit, keyword) {
        this.setLoading(true);
        const resp = PostCommunityListRequest(page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setPostCommunityList(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getPostCommunityCommentDetail(id, page, limit, keyword) {
        this.setLoading(true);
        const resp = PostCommunityCommentDetailRequest(id, page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setPostCommunityCommentDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getPostCommunityDetail(id) {
        this.setLoading(true);
        const resp = PostCommunityDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setPostCommunityDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getDetail(id) {
        this.postcommunityDetail = this.postcommunityList.find(item => {
            return item.id === id
        });
    }

    setPostCommunityDetail(data) {
        this.postcommunityDetail = data;
    }

    setPostCommunityCommentDetail(data) {
        this.postcommunityCommentDetail = data;
    }

    setPostCommunityList(data) {
        this.postcommunityList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deletePostCommunity(id) {
        this.setLoading(true);
        const resp = DeletePostCommunityRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    createPostCommunityList(
        content,
        images,
        links,
        type
    ) {
        this.setLoading(true);
        const resp = CreatePostCommunityListRequest(
                content,
                images,
                links,
                type
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}