<template>
  <v-row>
    <v-col md="4" sm="12">
      <PieMemberActiveArea />
    </v-col>
    <v-col md="4" sm="12">
      <PieMemberActiveDSO :dsos="dsos" />
    </v-col>
    <v-col md="4" sm="12">
      <PieAgeOfMember :dsos="dsos" />
    </v-col>
  </v-row>
</template>
<script>
import PieMemberActiveArea from "./left-charts/pie-member-active-area.vue";
import PieMemberActiveDSO from "./left-charts/pie-member-active-dso.vue";
import PieAgeOfMember from "./left-charts/pie-age-of-member.vue";

export default {
  name: "pie-chart-report",
  data: () => ({}),

  props: {
    dsos: Array,
  },

  components: {
    PieMemberActiveArea,
    PieMemberActiveDSO,
    PieAgeOfMember,
  },
};
</script>
