import axios from "axios";
import { PostCommentDetail, PostCommunityCommentDetail, PostDetail, CreatePostCommunityList, PostCommunityList, DeletePostCommunity, PostCommunityDetail } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const PostCommunityListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostCommunityList(page, limit, keyword)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeletePostCommunityRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeletePostCommunity(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreatePostCommunityListRequest = (
    content, 
    images, 
    links, 
    type
) => {
const resp = axios({
    method: 'post',
    url: `${BaseUrl()}${CreatePostCommunityList()}`,
    data: {
        content: content, 
        images: images, 
        links: links, 
        type: type
    },
    headers: headers
}).then((response) => {
    return response;
});

return resp;
}

export const PostCommentDetailRequest = (id, page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostCommentDetail(id, page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const PostCommunityCommentDetailRequest = (id, page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostCommunityCommentDetail(id, page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const PostDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const PostCommunityDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${PostCommunityDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}


