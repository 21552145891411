<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ `${title} ${title2}` }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <!-- :items="categories" -->
              <v-autocomplete v-model="form.dsoId" :items="dsos" item-text="name" item-value="id" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="DSO" required outlined dense></v-autocomplete>

              <v-autocomplete v-if="isRegulerLokal" v-model="form.category" :items="categories" item-text="value" item-value="key" :rules="regulerLokalRequiredRule" label="Jenis Kegiatan" required outlined dense></v-autocomplete>

              <v-autocomplete v-if="isRegulerLokal" v-model="form.scale" :items="scales" item-text="key" item-value="value" :rules="regulerLokalRequiredRule" label="Skala Kegiatan" required outlined dense></v-autocomplete>

              <v-autocomplete
                v-if="isRegulerLokal"

                v-model="form.scale"
                :items="scales"
                item-text="key"
                item-value="value"
                :rules="regulerLokalRequiredRule"
                label="Skala Kegiatan"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-if="isReguler"

                v-model="brandChosen"
                :items="allBrand"
                :rules="regulerRequiredRule"
                label="Brand"
                required
                outlined
                dense
              >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }}
              </template>
              </v-autocomplete>

              <v-checkbox v-if="isRegulerLokal" v-model="collabeWithCommunity" label="Berkolaborasi dengan komunitas" color="primary" outlined dense></v-checkbox>
              <v-autocomplete
                v-if="collabeWithCommunity && isRegulerLokal"
                :disabled="!isBrandChosen"
                v-model="form.communityId"
                :items="communities"
                item-text="name"
                item-value="id"
                :rules="regulerLokalRequiredRule"
                label="Komunitas"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field v-model="form.title" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Judul" required outlined dense></v-text-field>

              <v-textarea v-model="form.description" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Deskripsi" required outlined dense></v-textarea>

              <v-autocomplete
                v-if="!isRegulerLokal"
                v-model="form.method"
                :items="[
                  { key: 'Offline', value: 'offline' },
                  { key: 'Online', value: 'online' },
                ]"
                item-text="key"
                item-value="value"
                :rules="buddySystemRequiredRule"
                label="Metode Kegiatan"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="form.date" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.date" label="Tanggal Event" :rules="[(v) => !!v || 'Kolom wajib diisi']" v-bind="attrs" readonly v-on="on" required outlined dense></v-text-field>
                </template>
                <!-- <v-date-picker v-model="form.date" no-title scrollable :min="nowDate"> -->
                <v-date-picker v-model="form.date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Batal
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(form.date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40" :return-value.sync="form.time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.time" label="Waktu Event" :rules="[(v) => !!v || 'Kolom wajib diisi']" readonly v-bind="attrs" v-on="on" required outlined dense></v-text-field>
                </template>
                <v-time-picker v-if="menu2" format="24hr" v-model="form.time" full-width @click:minute="$refs.menu2.save(form.time)"></v-time-picker>
              </v-menu>

              <v-text-field v-model="form.locationLink" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Link Lokasi" required outlined dense></v-text-field>

              <v-checkbox v-if="isRegulerLokal" v-model="inviteCommunity" label="Undang Komunitas" color="primary" outlined dense></v-checkbox>
              <v-autocomplete v-if="inviteCommunity && isRegulerLokal" v-model="form.invitedCommunity" :items="communitiesInvite" filled chips label="Tambah Kommunitas" item-text="name" item-value="id" multiple>
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleInvite">
                    <v-list-item-action>
                      <v-icon :color="isToggleActive ? '#EF233C' : ''">{{ icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ isToggleActive ? "Unselect All" : "Select All" }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Alamat</v-alert>
              <v-autocomplete
                v-model="form.address.province"
                :items="provinces"
                item-text="provinceName"
                item-value="provinceId"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Provinsi"
                required
                outlined
                dense
                @change="getDistrict($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.district"
                :items="districts"
                item-text="districtName"
                item-value="districtId"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kabupaten/Kota"
                required
                outlined
                dense
                @change="getSubDistrict($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.subDistrict"
                :items="subDistricts"
                item-text="subDistrictName"
                item-value="subDistrictId"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kecamatan"
                required
                outlined
                dense
                @change="getVillages($event)"
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.address.village"
                :items="villages"
                item-text="villageName"
                item-value="villageId"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kelurahan/Desa"
                required
                outlined
                dense
                @change="getVillagesValue($event)"
              ></v-autocomplete>

              <v-text-field v-model="form.address.street" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Jalan" required outlined dense></v-text-field>

              <v-text-field v-model="form.address.zipCode" :rules="[(v) => Number.isInteger(Number(v)) || 'Isian harus angka', (v) => !!v || 'Kolom wajib diisi']" label="Kode Pos" :maxlength="5" required outlined dense></v-text-field>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card>
                <v-card-title>
                  <p>Unggah Poster Kegiatan</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload">Batal</v-btn>
                </v-card-text>
                <v-card-text v-else>
                  <input @change="changeMedia" ref="myFileInput" type="file" accept="image/*" />
                </v-card-text>
                <v-img :src="logoDisplay" v-if="thereIsImage"></v-img>
              </v-card>
            </v-col>
          </v-row>

          <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit">
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Provinces -->
    <Alert :status="isErrorGetProvinces && errorCodeGetProvinces !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetProvinces" />

    <!-- Error Districts -->
    <Alert :status="isErrorGetDistricts && errorCodeGetDistricts !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetDistricts" />

    <!-- Error Sub Districts -->
    <Alert :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetSubDistricts" />

    <!-- Error Villages -->
    <Alert :status="isErrorGetVillages && errorCodeGetVillages !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetVillages" />

    <!-- Error All Districts -->
    <Alert :status="isErrorGetAllDistricts && errorCodeGetAllDistricts !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetAllDistricts" />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { EventControllers } from "../../controllers/EventControllers";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import Swal from "sweetalert2";
import { BrandControllers } from "../../controllers/BrandControllers";
import { UserControllers } from "../../controllers/UserControllers.js";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { MediaControllers } from "../../controllers/MediaControllers";

export default {
  name: "create-event",
  data: () => ({
    event: new EventControllers(),
    area: new AreaControllers(),
    category: new CategoryControllers(),
    brand: new BrandControllers(),
    community: new CommunityControllers(),
    user: new UserControllers(),
    media: new MediaControllers(),
    valid: true,
    title: "Buat Kegiatan",

    isLoading: false,
    fullPage: true,
    brandChosen: null,
    isBrandChosen: false,
    collabeWithCommunity: false,
    inviteCommunity: false,
    isToggleActive: false,

    form: {
      title: "",
      dsoId: "",
      brandId: "",
      communityId: "",
      date: "",
      time: "",
      description: "",
      category: "",
      scale: "",
      method: "",
      locationLink: "",
      invitedCommunity: [],
      logoTemp: [],
      images: "",
      address: {
        province: null,
        district: null,
        subDistrict: null,
        village: null,
        street: "",
        zipCode: "",
      },
    },

    logoDisplay: null,
    thereIsImage: false,

    menu: false,
    menu2: false,

    nowDate: new Date().toISOString().slice(0, 10),

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
      allDistricts: null,
    },

    selectScope: {
      page: 1,
      limit: 600,
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    group() {
      return this.$route.params.group;
    },

    title2() {
      let title = "Reguler";
      if (this.group == null || this.group == "reguler") {
        title = "Reguler";
      } else if (this.group == "lokal") {
        title = "Lokal";
      }else if(this.group === "community-sharing"){  
        title = "Sharing Antar Komunitas"
      } else {
        title = "Buddy System";
      }
      return title;
    },

    scales() {
      return [
        { key: "Lokal", value: "local" },
        { key: "Regional", value: "regional" },
        { key: "Nasional", value: "national" },
      ];
    },

    isReguler(){
      return this.group==undefined||this.group==null||this.group=='reguler';
    },

    isRegulerLokal(){
      return this.group==undefined||this.group==null||this.group=='reguler'||this.group=='lokal';
    },

    regulerRequiredRule() {
      return [
        v => {
          if (this.group == 'buddy-system' || this.group == 'lokal' || this.group === "community-sharing") {
            return null;
          }

          if(v){
            return null;
          } else{
            return 'Kolom wajib diisi';
          }
        }
      ];
    },

    regulerLokalRequiredRule() {
      return [
        (v) => {
          if (this.group == "buddy-system" || this.group === "community-sharing") {
            return null;
          }

          if (v) {
            return null;
          } else {
            return "Kolom wajib diisi";
          }
        },
      ];
    },

    buddySystemRequiredRule() {
      return [
        (v) => {
          if (this.group == "buddy-system" || this.group === "community-sharing") {
            if (v) {
              return null;
            } else {
              return "Kolom wajib diisi";
            }
          }
          return null;
        },
      ];
    },

    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },
    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },

    // all districts
    allDistricts() {
      return this.area.allDistrictList;
    },

    errorCodeGetAllDistricts() {
      return this.area.errorCodeAllDistrict;
    },

    isErrorGetAllDistricts() {
      return this.area.isErrorAllDistrict;
    },

    errorCauseGetAllDistricts() {
      return this.area.errorCauseAllDistrict;
    },

    // event create
    isErrorCreateEvent() {
      return this.event.isError;
    },

    errorCauseCreateEvent() {
      return this.event.errorCause;
    },

    isLoadingCreateCreate() {
      return this.event.isLoading;
    },

    // Dso
    dsos() {
      return this.user.userListAM;
    },

    // community
    communities() {
      return this.community.communityList.filter((community) => community.brand.id == this.form.brandId && !community.isFreeze && community.isVerified);
    },

    communitiesInvite() {
      return this.community.communityList.filter((community) => community.id != this.form.communityId && !community.isFreeze && community.isVerified);
    },

    errorCodeGetCommunities() {
      return this.community.errorCode;
    },

    isErrorGetCommunities() {
      return this.community.isError;
    },

    errorCauseGetCommunities() {
      return this.community.errorCause;
    },

    isLoadingCommunities() {
      return this.community.isLoading;
    },

    // categories
    categories() {
      return this.category.categoryEventList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    // allbrand
    allBrand() {
      return this.brand.brandList.filter((brand) => brand.product == "Rokok");
    },

    icon() {
      if (this.isToggleActive) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  created() {
    this.getUsers();
    this.getProvinces();
    this.getAllDistricts();
    this.getCategories();
    this.getAllBrand();
    this.getCommunities();
  },

  watch: {
    brandChosen(newBrand) {
      if (newBrand.id) {
        this.isBrandChosen = true;
        this.form.brandId = newBrand.id;
      } else {
        this.isBrandChosen = false;
        this.form.brandId = "";
      }
    },
  },

  methods: {
    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.thereIsImage = true;
          this.logoDisplay = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.logoDisplay = null;
      this.thereIsImage = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    back() {
      this.$router.go(-1);
    },

    async getCommunities() {
      return this.community.getCommunityList(1, 999, "", "", "", false, "", "");
    },

    getAllBrand() {
      return this.brand.getBrandList(this.selectScope.page, this.selectScope.limit);
    },

    getUsers() {
      return this.user.getUserAMList("admin.dso", 1, 1000, "");
    },

    getCategories() {
      return this.category.getcategoryEventList();
    },

    getProvinces() {
      return this.area.getProvinceList(this.selectScope.page, this.selectScope.limit);
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find((item) => item.provinceId === provinceId);
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(provinceId, this.selectScope.page, this.selectScope.limit);
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find((item) => item.districtId === districtId);
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(districtId, this.selectScope.page, this.selectScope.limit);
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find((item) => item.subDistrictId === subDistrictId);
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(subDistrictId, this.selectScope.page, this.selectScope.limit);
    },

    getAllDistricts() {
      return this.area.getAllDistrictList(this.selectScope.page, this.selectScope.limit);
    },

    getVillagesValue(villageId) {
      const { villageName, postalCode } = this.villages.find((item) => item.villageId === villageId);
      this.addressFiltered.village = villageName;
      this.form.address.zipCode = postalCode;
    },

    async submitProcess(title, dsoId, brandId, communityId, invitedCommunity, description, category, date, province, district, subDistrict, village, street, zipCode, locationLink, images, group, scale, method) {
      return this.event.createEventList(title, dsoId, brandId, communityId, invitedCommunity, description, category, date, province, district, subDistrict, village, street, zipCode, locationLink, images, group, scale, method);
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;

        if (this.thereIsImage) {
          this.isLoading = true;
          await this.uploadMedia("event", this.form.logoTemp);

          if (this.isErrorUploadMedia) {
            Swal.fire({
              icon: "error",
              title: "KESALAHAN",
              text: this.errorCauseMediaUpload,
            });
            // this.isLoading = false;
            this.isErrorUploadMedia = false;
          } else {
            // this.isLoading = false;
            this.form.images = this.medias[0];
          }
          this.deleteImageUpload();
        }
        await this.submitProcess(
          this.form.title,
          this.form.dsoId,
          this.form.brandId,
          this.form.communityId,
          this.form.invitedCommunity.filter((community) => community != this.form.communityId),
          this.form.description,
          this.form.category,
          `${this.form.date}T${this.form.time}:00`,
          this.addressFiltered.province,
          this.addressFiltered.district,
          this.addressFiltered.subDistrict,
          this.addressFiltered.village,
          this.form.address.street,
          this.form.address.zipCode,
          this.form.locationLink,
          this.form.images,
          this.group != null ? this.group : "reguler",
          this.form.scale,
          this.form.method
        );

        if (this.isErrorCreateEvent) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateEvent,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          // this.$refs.observer.reset();
        }
      }
    },

    toggleInvite() {
      this.isToggleActive = !this.isToggleActive;
      const communities = this.community.communityList.filter((community) => community.id != this.form.communityId && !community.isFreeze && community.isVerified);
      if (this.isToggleActive == true) {
        this.form.invitedCommunity = communities.map((community) => community.id);
      } else {
        this.form.invitedCommunity = [];
      }
    },
  },
};
</script>
