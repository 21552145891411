<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>

              <v-text-field
                v-model="detailAM.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama RSO"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="detailAM.remark"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Catatan"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="detailAM.manager.id"
                :items="managers"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Manajer"
                item-text="name"
                item-value="id"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                  v-model="detailAM.heads"
                  :items="heads"
                  filled
                  chips
                  label="Tambah Pimpinan RSO"
                  item-text="name"
                  item-value="id"
                  multiple
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isUserListError"
      icon="error"
      title="ERROR"
      :msg="errorCauseUser"
    />
  </v-container>
</template>
<script>
import { AreaManagerControllers } from "../../controllers/AreaManagerControllers.js";
import { UserControllers } from "../../controllers/UserControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Alert from "../../components/alert";

export default {
  name: "create-am",
  data: () => ({
    am: new AreaManagerControllers(),
    user: new UserControllers(),
    title: "Ubah RSO",
    valid: true,
   
    tableScope: {
      page: 1,
      limit: 1000,
      keyword: ""
    },

    form: {
      name: "",
      remark: "",
      manager: "",
    },
    
    isLoading: false,
    fullPage: true,
    tag: {
      value: [],
      options: [],
    },
  }),

  components: {
    Loading,
    Alert
  },

  computed: {
    // am length
    amLength() {
      return this.am.areaManagerRSOList.length;
    },

    isErrorUpdateAM() {
      return this.am.isError;
    },

    errorCauseUpdateAM() {
      return this.am.errorCause;
    },

    isLoadingUpdateAM() {
      return this.am.isLoading;
    },

    // detail user am
    detailAM() {
      const am = this.am.rsoDetail;
      if (am) {
        return {
          ...am,
          manager: {
            id: am.manager ? am.manager.id : ""
          },
          heads: Array.isArray(am.heads) ? am.heads.map(head=>head.id) : []
        };
      }

      return null;
    },

    // get users
    managers() {
      return this.user.userListAM;
    },

    isUserListError() {
      return this.user.isError;
    },

    errorCauseUser() {
      return this.user.errorCause;
    },

    heads(){
      return this.user.userList
    },
  },
  
  created(){
      this.getAMList();
      this.getUserAM();
      this.getUser();
  },

  methods: {
    async getAMList() {
      return this.am.getAreaRSODetail(
        this.$route.params.id
      );
    },

    getUser(){
      return this.user.getUserList(1, 999,"", "", "", false,"", "", false,"admin.rso.low")
    },

    getUserAM() {
      return this.user.getUserAMList(
        "admin.rso",
        this.tableScope.page,
        this.tableScope.limit,
        this.tableScope.keyword
      );
    },

    back() {
      this.$router.go(-1);
    },

    async submitProcess(name, remark, manager, head) {
      return this.am.updateAreaManagerRSOList(
        this.$route.params.id,
        name, remark, manager, head
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailAM.name,
          this.detailAM.remark,
          this.detailAM.manager.id,
          this.detailAM.heads
        );

        if (this.isErrorUpdateAM) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateAM,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data Berhasil diubah",
          });
          
          this.$refs.observer.reset();
          this.back();
        }
      }
    },
  },
};
</script>