import { 
    CommunityCategoryListRequest, 
    CreateCommunityCategoryRequest, 
    DeleteCommunityCategoryRequest, 
    UpdateCommunityCategoryRequest, 
    CommunityCategoryDetailRequest 
} from "../request/CommunityCategoryRequest.js";

export class CommunityCategoryControllers {
    CommunityCategoryList = new Array();
    CommunityCategoryDetail = new Object();
    CommunityCategoryDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() { }

    getCommunityCategoryList(page, limit, keyword) {
        this.setLoading(true);
        const resp = CommunityCategoryListRequest(page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setCommunityCategoryList(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getCommunityCategoryDetailEdit(id) {
        this.setLoading(true);
        const resp = CommunityCategoryDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setCommunityCategoryDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadCommunityCategoryList(page, limit, keyword) {
        this.setLoadingDownload(true);
        const resp = CommunityCategoryListRequest(page, limit, keyword)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setCommunityCategoryListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setCommunityCategoryListDownload(data) {
        this.CommunityCategoryDownload = data;
    }

    setCommunityCategoryDetail(data) {
        this.CommunityCategoryDetail = data;
    }

    setCommunityCategoryList(data) {
        this.CommunityCategoryList = data;
    }

    createCommunityCategoryList(
        key,
        value,
        brand
    ) {
        this.setLoading(true);
        const resp = CreateCommunityCategoryRequest(
            key,
            value,
            brand
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getDetail(id) {
        this.CommunityCategoryDetail = this.CommunityCategoryList.find(item => {
            return item.id === id
        });
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deleteCommunityCategory(id) {
        this.setLoading(true);
        const resp = DeleteCommunityCategoryRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateCommunityCategoryList(
        id,
        key,
        value,
        brand
    ) {
        this.setLoading(true);
        const resp = UpdateCommunityCategoryRequest(
            id,
            key,
            value,
            brand
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}

