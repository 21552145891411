<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0">
          <v-col md="8" sm="12">
            <span class="text--secondary text-caption">Tahun</span>
            <v-autocomplete :items="years" 
            v-model="chosenYear" dense></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12">
            <!-- <v-btn
              class="success"
              :loading="isLoadingDownload"
              small
              block
              @click="download"
              >Unduh Excel
            </v-btn> -->
          </v-col>
          <v-col md="12" ms="12" align="center">
            <h3 align="center">{{ title }}</h3>
          </v-col>
          <v-col align="center" md="4" ms="12" class="mt-0">
            <h2 class="display-1 font-weight-black">
              <b>{{medsosDsoChartComputed.reachDso}}</b><span style="font-size: 10px" v-if="isOldKPI">/{{medsosDsoChartComputed.targetDso}}</span>
            </h2>
            <div v-if="isOldKPI">
              <p v-if="!medsosDsoChartComputed.targetDso || medsosDsoChartComputed.targetDso == 0" style="font-size: 10px" class="font-weight-black mt-n3 red--text">
                TARGET NOT SET
              </p>
              <p v-else-if="medsosDsoChartComputed.reachDso >= medsosDsoChartComputed.targetDso" style="font-size: 10px" class="font-weight-black mt-n3 green--text">
                ACHIEVED
              </p>
              <p v-else style="font-size: 10px" class="font-weight-black mt-n3 red--text">
                NOT ACHIEVED
              </p>
            </div>
            <p style="font-size: 10px" class="font-weight-black">
              MEDIA SOSIAL DSO
            </p>
          </v-col>
          <v-col md="7" ms="12">
            <h5 class="mt-n2">TARGET KONTEN MEDIA SOSIAL DSO</h5>
            <VueApexCharts ref="BarTargetMedsosDSO" type="bar" height="100" width="250" :options="chartOptions" :series="series" class="mt-n4" />
          </v-col>
          <RadialBarFollower :communityFollowedChartComputed="communityFollowedChartComputed" class="mt-n14" v-if="isOldKPI"/>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>

<script>
import Alert from "../../../../components/alert/index";

import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);
import RadialBarFollower from "./radialbar-follower"
import { MedsosDSOControllers } from "../../../../controllers/MedsosDSOControllers";

export default {
  name: "event-report",
  props: {
    dso: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    title: "MEDIA SOSIAL DSO",
    medsosDso: new MedsosDSOControllers(),
    year: new Date().getFullYear()-1,
    chosenYear: new Date().getFullYear(),
    series: [
      {
        data: [0],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ['#424c58'],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "center", // top, center, bottom
          },
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontWeight: 900,
          colors: ["#fff"],
        },
      },

      xaxis: {
        categories: ["Capaian"],
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tickAmount: 2,
        crosshairs: {
          fill: {
            type: "solid",
          },
        },
        tooltip: {
          enabled: false,
        },
        max: 120,
      },
      grid: {
        show: false,
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 2,
        labels: {
          show: false,
        },
      },
      title: {
        show: false,
      },
      annotations: {
        position: 'back',
        xaxis: [
          {
            x: 0,
            x2: 116,
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              show: false,
              text: "116",
              offsetY: 1,
              position: 'right',
              orientation: 'horizontal',
              borderColor: 'transparent',
              style: {
                background: 'transparent',
                color: 'transparent'
              }
            },
          },
        ],
        yaxis: [
          {
            y: 0,
            y2: "capaian",
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              text: "Target: 119",
              offsetY: 1,
              position: 'right',
              orientation: 'horizontal'
            },
          },
        ],
      },
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
    Alert,
    RadialBarFollower
  },

  // computed: {
  //   // sales

  //   errorCodeGetDashboard() {
  //     return this.dashboard.errorCode;
  //   },

  //   isErrorGetDashboard() {
  //     return this.dashboard.isError;
  //   },

  //   errorCauseGetDashboard() {
  //     return this.dashboard.errorCause;
  //   },

  //   isLoadingDashboard() {
  //     return this.dashboard.isLoading;
  //   },
  // },

  created() {
    // this.getDashboard();
    // this.getSeries();
  },

  computed: {
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },
    isOldKPI(){
      console.log(this.chosenYear < 2023)
      return this.chosenYear < 2023;
    },
    medsosDsoChartComputed() {
      const medsosDsoChart = this.medsosDso.medsosDsoChart;
      const targetMedsosDso = medsosDsoChart.targetMediaSosial
      const targetKonten = medsosDsoChart.targetKontentMediaSosial
      return {
        targetDso: (targetMedsosDso && targetMedsosDso.split('/')[1]) || 0,
        reachDso: (targetMedsosDso && targetMedsosDso.split('/')[0]) || 0,
        targetKonten: (targetKonten && targetKonten.split('/')[1]) || 0,
        reachKonten: (targetKonten && targetKonten.split('/')[0]) || 0,
      }
    },
    communityFollowedChartComputed() {
      const communityFollowedChart = this.medsosDso.communityFollowedChart;
      
      return communityFollowedChart
    }
  },

  watch: {
    dso() {
      this.getMedsosDSOChart();
      this.getCommunityFollowed();
    },
    chosenYear() {
      this.getMedsosDSOChart();
      this.getCommunityFollowed();
    },
    medsosDsoChartComputed(newVal) {
      this.series = []

      this.series.push({data: [Number(newVal.reachKonten)]})

      const newChartOptions = this.chartOptions
      newChartOptions.xaxis.max = Number(Number(newVal.targetKonten) < Number(newVal.reachKonten) ? Number(newVal.reachKonten) : Number(newVal.targetKonten))
      newChartOptions.annotations.xaxis[0].x2 = Number(newVal.targetKonten)
      newChartOptions.annotations.yaxis[0].label.text = `Target: ${Number(newVal.targetKonten)}`
      Object.assign(this.chartOptions, newChartOptions)
      
      this.$refs.BarTargetMedsosDSO.refresh()
    },
  },

  methods: {
    getMedsosDSOChart() {
      return this.medsosDso.getMedsosDSOChart(this.dso, this.chosenYear)
    },

    getCommunityFollowed() {
      return this.medsosDso.getCommunityFollowed(this.dso, this.chosenYear)
    },

    getDashboard() {
      return this.dashboard.getSalesDashboardList(this.year);
    },

    async getSeries() {
      await this.getDashboard(this.year);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    async pickYear(event) {
      this.series = [
        {
          data: [],
        },
      ];
      this.chartOptions.xaxis.categories = [];

      await this.getDashboard(event);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const sales = [];
      for (const item of this.sales) {
        sales.push({
          "comunity name": item._id,
          jumlah: item.count,
        });
      }
      const data = XLSX.utils.json_to_sheet(sales);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Sales Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>