import { InfoCommunityRequest,VerifyInfoCommunityRequest } from "../request/InfoCommunityRequest.js";

export class InfoCommunityControllers  {
    infoCommunityList = new Array();
    infoCommunityDetail = new Object();

    isError= false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorVerify = false;
    errorCauseVerify = "";

    constructor() {}

    getInfoCommunityList(type, page, limit, keyword, dsoId, startDate, endDate, isReport) {
        this.setLoading(true);
        const resp = InfoCommunityRequest(type, page, limit, keyword, dsoId, startDate, endDate, isReport)
        .then((response) => {
            this.setError(false);
            this.setErrorcode(response.data.code);
            this.setInfoCommunityList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        }).finally(() => {
            this.setLoading(false);
        });
       
        return resp;
    }

    setInfoCommunityList(data) {
        this.infoCommunityList = data;
    }

    getDetail(id) {
        this.infoCommunityDetail = this.infoCommunityList.find(item => {
            return item.id === id
        });
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    verifyInfoCommunity(
        id
    ) {
        const resp = VerifyInfoCommunityRequest(
                id
            )
            .then(() => {
                this.setErrorVerify(false);
            }).catch(err => {
                this.setErrorVerify(true);
                this.setErrorCauseVerify(err.response.data.message);
            });

        return resp;
    }

    setErrorVerify(status) {
        this.isErrorVerify = status;
    }

    setErrorCauseVerify(err) {
        this.errorCauseVerify = err;
    }
}

