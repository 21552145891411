<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="4" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn
          class="float-right"
          depressed
          color="primary"
          @click="createEvent"
          v-if="getRole.role.create"
          block
        >
          Buat Event
        </v-btn>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh Excel
        </v-btn>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini lalu enter/return"
          outlined
          dense
        ></v-text-field
      ></v-col>
      <v-col md="3" sm="6">
        <v-autocomplete
          v-model="brand"
          :items="brands"
          item-text="key"
          item-value="value"
          label="Brand"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col md="3" sm="6">
        <v-autocomplete
          v-model="categoryEvent"
          :items="categoriesEvent"
          item-text="value"
          item-value="key"
          label="Jenis Kegiatan"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
        <v-autocomplete
          :items="allUserAM"
          v-model="dso"
          item-text="name"
          item-value="id"
          @change="pickArea"
          label="Pilih DSO"
          dense
          block
          right
          outlined
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row v-if="!isLoadingEvent">
      <v-col md="12" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Komunitas</th>
                  <th class="text-left">Judul Kegiatan</th>
                  <th class="text-left">Jenis Kegiatan</th>
                  <th class="text-left">Brand Komunitas</th>
                  <th class="text-left">Tanggal Event</th>
                  <th class="text-left">Deskripsi</th>
                  <th class="text-left">Rincian</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in events" :key="item.id">
                  <td v-if="item.community">{{ item.community.name }}</td>
                  <td v-else>No data</td>
                  <td>{{ item.title }}</td>
                  <td>{{item.category? item.category:'No Data' }}</td>
                  <td>{{item.brand? item.brand.name:'No Data' }}</td>
                  <td>{{ item.date | getNormalDate }}</td>
                  <td>{{ item.description }}</td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="success"
                      dark
                      @click="getdetail(item.id)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn
                      small
                      icon
                      color="warning"
                      dark
                      @click="edit(item.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      small
                      icon
                      color="error"
                      dark
                      @click="deleteEvent(item.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Detail :isShow="isDetail" :data="eventDetail" @hide="updateModal" />
    <Alert
      :status="isError && errorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { BigEventControllers } from "../../controllers/BigEventControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { UserControllers } from "../../controllers/UserControllers";
import Swal from "sweetalert2";
import Detail from "./event-detail";
import { formatDate } from "../../external/functions/Utilization.js";
import { getUserId, getRole } from "../../external/functions/Cookies.js";
import moment from "moment";
import XLSX from "xlsx";

export default {
  name: "event-list",
  data: () => ({
    event: new BigEventControllers(),
    category: new CategoryControllers(),
    userControllers: new UserControllers(),
    title: "Kegiatan Besar Komunitas",
    brand: '',
    categoryEvent: '',
    dso:'',

    eventTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },
    searchField: "",
  }),

  components: {
    Alert,
    Loading,
    Detail,
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  created() {
    if(getRole() !== 'admin.dso') {
      this.userControllers.getUserAMList('admin.dso', 1, 1000, '')  
    }
    this.getEvents();
    this.getBrandCommunityList();
    this.getCategoryEventList();
  },

  watch:{
    brand(){
      this.getEvents()
    },
    categoryEvent(){
      this.getEvents()
    },
    dso(){
      this.getEvents()
    }

  },

  computed: {
    getCurrentUser() {
      return getUserId();
    },

    allUserAM() {
      let arrDso = this.userControllers.userListAM
      arrDso.unshift({name:'Semua DSO',id:''})
      return arrDso
    },

    getRole() {
      return this.$route.meta;
    },

    // events
    events() {
      return this.event.eventList;
    },

    errorCode() {
      return this.event.errorCode;
    },

    isError() {
      return this.event.isError;
    },

    errorCause() {
      return this.event.errorCause;
    },

    isLoadingEvent() {
      return this.event.isLoading;
    },

    eventDetail() {
      return this.event.eventDetail;
    },

    brands(){
      let brands = this.category.brandList;
      brands.unshift({
        key:'Semua Brand',
        value: ''
      })
      return brands
    },

    categoriesEvent(){
      let categories = this.category.categoryEventList;
      categories.unshift({
        key:'',
        value: 'Semua Kategori'
      })
      return categories
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    // download
    dataDownload() {
      return this.event.eventDownload;
    },

    isErrorDownload() {
      return this.event.errorDownload;
    },

    isErrorCauseDownload() {
      return this.event.errorCauseDownload;
    },

    isLoadingDownload() {
      return this.event.loadingDownload;
    },

    isErrorCodeDownload() {
      return this.event.errorCodeDownload;
    },

  },

  methods: {
    getBrandCommunityList() {
      return this.category.getBrandList();
    },

    getCategoryEventList() {
      return this.category.getcategoryEventList();
    },

    async getEvents() {
      return this.event.getEventList(
        this.eventTable.page,
        this.eventTable.limit,
        this.eventTable.keyword,
        this.brand,
        this.categoryEvent,
        this.dso
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingEvent;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.eventTable.page -= 1;

        await this.getEvents(
          this.eventTable.page,
          this.eventTable.limit,
          this.eventTable.keyword,
          this.brand,
          this.categoryEvent,
          this.dso
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingEvent;
      this.pages.start += 10;
      this.pages.end += 10;

      this.eventTable.page += 1;

      await this.getEvents(
        this.eventTable.page,
        this.eventTable.limit,
        this.eventTable.keyword,
        this.brand,
        this.categoryEvent,
        this.dso
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    getdetail(id) {
      this.isDetail = true;
      return this.event.getDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    createEvent() {
      this.$router.push({
        name: "Create Event Community",
      });
    },

    deleteEvent(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.event.deleteEvent(id); 
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.event.getEventList(
            this.eventTable.page,
            this.eventTable.limit,
            this.eventTable.keyword,
            this.brand,
            this.categoryEvent,
            this.dso
          );
        });
    },

    edit(id) {
      this.$router.push({
        name: "Edit Event Community",
        params: {
          id: id,
        },
      });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.eventTable.page = 1;
      this.eventTable.limit = 10;
      this.eventTable.keyword = this.searchField;

      await this.getEvents(
        this.eventTable.page,
        this.eventTable.limit,
        this.eventTable.keyword,
        this.brand,
        this.categoryEvent,
        this.dso
      );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.eventTable.page = 1;
          this.eventTable.limit = 10;
          this.eventTable.keyword = event;

          await this.getEvents(
            this.eventTable.page,
            this.eventTable.limit,
            this.eventTable.keyword,
            this.brand,
            this.categoryEvent,
            this.dso
          );
        }
      }, 500);
    },

        normalDate(val) {
      return moment(val).format("DD-MM-YYYY h:mm:ss");
    },

    async download() {
      await this.event.downloadEventList(
        this.eventTable.page,
        this.eventTable.limit,
        this.eventTable.keyword,
        this.brand,
        this.categoryEvent,
        this.dso
      );

      if (this.dataDownload.length > 0) {
        const dataDownload = [];
        for (const item of this.dataDownload) {
          dataDownload.push({
            title: item.title,
            category: item.category,
            // brand: item.brand? item.brand.brandCommunity: 'No data',
            community: item.community? item.community.name: 'No data',
            "tanggal kegiatan": this.normalDate(item.date),
            "dibuat pada": this.normalDate(item.createdAt),
            "diperbaharui pada": this.normalDate(item.updatedAt),
          });
        }

        const data = XLSX.utils.json_to_sheet(dataDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Report Event Komunitas List.xlsx`);
      } else {
        Swal.fire({
          text: "Data Tidak ditemukan",
          icon: "warning",
        });
      }
    },
  },
};
</script>