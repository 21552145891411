import { BrandListRequest, CreateBrandRequest, DeleteBrandRequest, UpdateBrandRequest, BrandDetailRequest, ProductListRequest } from "../request/BrandRequest.js";

export class BrandControllers {
  brandList = new Array();
  brandDetail = new Object();
  isError = false;
  isLoading = false;
  errorCause = "";
  errorCode = 0;

  isErrorDelete = false;
  errorCauseDelete = "";
  messageDelete = "";

  loadingProduct = false;
  productList = new Array();
  errorProduct = false;
  errorCauseProduct = "";
  errorCodeProduct = "";

  constructor() {}

  getBrandList(page, limit, keyword) {
    this.setLoading(true);
    const resp = BrandListRequest(page, limit, keyword)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setBrandList(response.data.data);
        if (!response.data.data) {
          this.setError(true);
          this.setErrorcode(404);
        }
        return true;
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
        return false;
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getBrandDetail(id) {
    this.setLoading(true);
    const resp = BrandDetailRequest(id)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setBrandDetail(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setBrandList(data) {
    this.brandList = data;
  }

  setBrandDetail(data) {
    this.brandDetail = data;
  }

  getDetail(id) {
    this.brandDetail = this.brandList.find((item) => {
      return item.id === id;
    });
  }

  createBrandList(projectId, brandName, description, brandCommunity, skus, product) {
    this.setLoading(true);
    const resp = CreateBrandRequest(projectId, brandName, description, brandCommunity, skus, product)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setLoading(status) {
    this.isLoading = status;
  }

  setError(status) {
    this.isError = status;
  }

  setErrorCause(err) {
    this.errorCause = err;
  }

  setErrorcode(code) {
    this.errorCode = code;
  }

  deleteBrand(id) {
    this.setLoading(true);
    const resp = DeleteBrandRequest(id)
      .then((response) => {
        this.setErrorDelete(false);
        this.setMsgDelete(response.data.message);
      })
      .catch((err) => {
        this.setErrorDelete(true);
        this.setErrorCauseDelete(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setMsgDelete(value) {
    this.messageDelete = value;
  }

  setErrorDelete(status) {
    this.isErrorDelete = status;
  }

  setErrorCauseDelete(err) {
    this.errorCauseDelete = err;
  }

  updateBrandList(id, brandName, description, brandCommunity, skus, product) {
    this.setLoading(true);
    const resp = UpdateBrandRequest(id, brandName, description, brandCommunity, skus, product)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getProductList() {
    this.setLoadingProduct(true);
    const resp = ProductListRequest()
      .then((response) => {
        this.setProductList(response.data.data);
        this.setErrorProduct(false);
        this.setErrorCodeProduct(response.data.code);
      })
      .catch((err) => {
        this.setErrorProduct(true);
        this.setErrorCauseProduct(err.response.data.message);
        this.setErrorCodeProduct(err.response.data.code);
      })
      .finally(() => {
        this.setLoadingProduct(false);
      });

    return resp;
  }

  setLoadingProduct(status) {
    this.loadingProduct = status;
  }

  setProductList(data) {
    this.productList = data;
  }

  setErrorProduct(status) {
    this.errorProduct = status;
  }

  setErrorCauseProduct(err) {
    this.errorCauseProduct = err;
  }

  setErrorCodeProduct(code) {
    this.errorCodeProduct = code;
  }
}
