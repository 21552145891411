import axios from "axios";
import { PerformanceList, CreatePerformance, DeletePerformance, UpdatePerformance, PerformanceDetail } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export const CreatePerformanceRequest = (communityId, year, month, postQty) => {
  const resp = axios({
    method: "post",
    url: `${BaseUrl()}${CreatePerformance()}`,
    data: {
      communityId,
      year,
      month,
      postQty,
    },
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};
export const performanceListRequest = (year, communityId) => {
  console.log(headers);
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${PerformanceList(year, communityId)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const DeletePerformanceRequest = (id) => {
  const resp = axios({
    method: "delete",
    url: `${BaseUrl()}${DeletePerformance(id)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const UpdatePerformanceRequest = (id, communityId, year, month, postQty) => {
  console.log({
    communityId,
    year,
    month,
    postQty,
  });
  const resp = axios({
    method: "put",
    url: `${BaseUrl()}${UpdatePerformance(id)}`,
    data: { communityId, year, month, postQty },
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};

export const PerformanceDetailRequest = (id) => {
  const resp = axios({
    method: "get",
    url: `${BaseUrl()}${PerformanceDetail(id)}`,
    headers: headers,
  }).then((response) => {
    return response;
  });

  return resp;
};
