<template>

  <v-row>
    <v-col justify='center' md="4" sm="12">
      <v-responsive min-width="200" class="ms-n8">
        <VueApexCharts type="radialBar" width="100%" :options="chartOptions" :series="series" />
      </v-responsive>
      <v-btn small text justify='center' block class="mt-n4" v-bind:color="medsos1 ? '#0388fc' : 'black'" depressed
        @click="setMedsos1">
        <p style="font-size:7px;font-weight:900">KOMUNITAS MENGIKUTI<br> MEDSOS DSO I</p>
      </v-btn>
    </v-col>
    <v-col justify='center' md="4" sm="12">
      <v-responsive min-width="200" class="ms-n8">
        <VueApexCharts type="radialBar" width="100%" :options="chartOptions2" :series="series2" />
      </v-responsive>
      <v-btn small text justify='center' block class="mt-n4" v-bind:color="medsos2 ? '#0388fc' : 'black'" depressed
        @click="setMedsos2">
        <p style="font-size:7px;font-weight:900">KOMUNITAS MENGIKUTI<br> MEDSOS DSO II</p>
      </v-btn>
    </v-col>
    <v-col justify='center' md="4" sm="12">
      <v-responsive min-width="200" class="ms-n8">
        <VueApexCharts type="radialBar" width="100%" :options="chartOptions3" :series="series3" />
      </v-responsive>
      <v-btn small text justify='center' block class="mt-n4" v-bind:color="medsos3 ? '#0388fc' : 'black'" depressed
        @click="setMedsos3">
        <p style="font-size:7px;font-weight:900">KOMUNITAS MENGIKUTI<br> MEDSOS KOLABORASI KITA</p>
      </v-btn>
    </v-col>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content v-if="medsos1 || medsos2 || medsos3">
        <h3 align="center" class="text-uppercase">FOLLOWER MEDIA SOSIAL {{ nameMedsos }}</h3>
        <v-row justify="center">
          <v-col align="center" md="12" ms="12" class="mt-0" :hidden="!medsos1">
            <DetailFollower :dataArr="data1" :member="member1"/>
          </v-col>
          <v-col align="center" md="12" ms="12" class="mt-0" :hidden="!medsos2">
            <DetailFollower2 :dataArr="data2" :member="member2"/>
          </v-col>
          <v-col align="center" md="12" ms="12" class="mt-0" :hidden="!medsos3">
            <DetailFollower3 :dataArr="data3" :member="member3"/>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DetailFollower from "./detail-follower.vue"
import DetailFollower2 from "./detail-follower2.vue"
import DetailFollower3 from "./detail-follower3.vue"
import {mapActions} from 'vuex'

export default {
  name: "total-key",
  props: {
    communityFollowedChartComputed: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    nameMedsos: '',
    medsos1: false,
    medsos2: false,
    medsos3: false,
    data1:[],
    data2:[],
    data3:[],
    member1:[],
    member2:[],
    member3:[],
    medsosdso: {
      medsos1: [{
        brand: "Bold Riders",
        follow: 65,
        member: 100,
        color: "#000000",

      },
      {
        brand: "Super Friends",
        follow: 70,
        member: 100,
        color: "#e2a63d",
      },
      {
        brand: "Heppiii Community",
        follow: 67,
        member: 100,
        color: "#e23d3d"
      },
      {
        brand: "Kill The LAst",
        follow: 40,
        member: 100,
        color: "#d99494"
      },
      {
        brand: "Other",
        follow: 40,
        member: 100,
        color: "#34bf41"
      }],
      medsos2: [{
        brand: "Bold Riders",
        follow: 60,
        member: 100,
        color: "#000000",

      },
      {
        brand: "Super Friends",
        follow: 55,
        member: 100,
        color: "#e23d3d",
      },
      {
        brand: "Heppiii Community",
        follow: 67,
        member: 100,
        color: "#e2a63d"
      },
      {
        brand: "Kill The LAst",
        follow: 40,
        member: 100,
        color: "#d99494"
      },
      {
        brand: "Other",
        follow: 30,
        member: 100,
        color: "#34bf41"
      }],
      medsos3: [{
        brand: "Bold Riders",
        follow: 50,
        member: 100,
        color: "#000000",

      },
      {
        brand: "Super Friends",
        follow: 50,
        member: 100,
        color: "#e23d3d",
      },
      {
        brand: "Heppiii Community",
        follow: 80,
        member: 100,
        color: "#e2a63d"
      },
      {
        brand: "Kill The LAst",
        follow: 35,
        member: 100,
        color: "#d99494"
      },
      {
        brand: "Other",
        follow: 35,
        member: 100,
        color: "#34bf41"
      }]
    },
    // charts 1
    series: [0],
    chartOptions: {
      chart: {
        width: "100%",
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -165,
          endAngle: 195,
          hollow: {
            size: "50%",
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: 70,
              show: false,
              color: "#888",
              fontSize: "7px",
              fontWeight: 900
            },
            value: {
              formatter: function (val) {
                return parseInt(val) + "%";
              },
              color: "#111",
              fontSize: "25px",
              show: true,
              offsetY: 10,
              fontWeight: 900
            },
          },
        },
      },
      fill: {
        type: "solid",
      },

      labels: [["KOMUNITAS MENGIKUTI", "MEDSOS DSO I"]],
    },
    // charts 2
    series2: [0],
    chartOptions2: {
      chart: {
        height: 175,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -165,
          endAngle: 195,
          hollow: {
            size: "50%",
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: 70,
              show: false,
              color: "#888",
              fontSize: "7px",
              fontWeight: 900
            },
            value: {
              formatter: function (val) {
                return parseInt(val) + "%";
              },
              color: "#111",
              fontSize: "25px",
              show: true,
              offsetY: 10,
              fontWeight: 900
            },
          },
        },
      },
      fill: {
        type: "solid",
      },

      labels: [["KOMUNITAS MENGIKUTI", "MEDSOS DSO II"]],
    },
    // charts 3
    series3: [0],
    chartOptions3: {
      chart: {
        width: "100%",
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -165,
          endAngle: 195,
          hollow: {
            size: "50%",
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: 70,
              show: false,
              color: "#888",
              fontSize: "7px",
              fontWeight: 900
            },
            value: {
              formatter: function (val) {
                return parseInt(val) + "%";
              },
              color: "#111",
              fontSize: "25px",
              show: true,
              offsetY: 10,
              fontWeight: 900
            },
          },
        },
      },
      fill: {
        type: "solid",
      },

      labels: [["KOMUNITAS MENGIKUTI", "MEDSOS KOLABORASI KITA"]],
    },

    // Bar Chart follower per brand
    // series4: [],
    // chartOptions4: {
    //   chart: {
    //     type: "bar",
    //     width: "100%",
    //     toolbar: {
    //       show: false,
    //     },
    //   },
    //   plotOptions: {
    //     bar: {
    //       barHeight: "50%",
    //       distributed: true,
    //       horizontal: true,
    //       dataLabels: {
    //         position: "bottom",
    //       },
    //     },
    //   },
    //   colors: [],
    //   legend: {
    //     show: false,
    //   },
    //   grid: {
    //     show: false,
    //   },
    //   dataLabels: {
    //     enabled: true,
    //     textAnchor: "start",
    //     style: {
    //       colors: ["#000"],
    //       fontSize: "15px",
    //     },
    //     formatter: function (val, opt) {
    //       const series = opt.w.globals.series[0]
    //       const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0);
    //       const percent = (val / sum) * 100;
    //       return opt.w.globals.labels[opt.dataPointIndex] + ":  " + `${percent.toFixed(0)}%`;
    //     },
    //     offsetX: '0px',
    //     offsetY: -18,
    //     dropShadow: {
    //       enabled: false,
    //     },
    //   },
    //   stroke: {
    //     width: 1,
    //     colors: ["#fff"],
    //   },
    //   tooltip: {
    //     custom: function ({ seriesIndex, dataPointIndex, w }) {
    //       var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
    //       var member = w.globals.initialSeries[seriesIndex].member[dataPointIndex];
    //       var persen = Math.round(data / member * 100)

    //       return '<ul class="pa-1 border">' +
    //         '<li><b>Follower</b>: ' + data + '</li>' +
    //         '<li><b>Member Aktif</b>: ' + member + '</li>' +
    //         '<li><b>Persentase</b>: ' + persen + '% </li>' +
    //         '</ul>';
    //     }
    //   },
    //   xaxis: {
    //     categories: [
    //     ],
    //     labels: {
    //       show: false,
    //     },
    //     lines: {
    //       show: false,
    //     },
    //     axisBorder: {
    //       show: false,
    //     },
    //     axisTicks: {
    //       show: false,
    //     },
    //   },
    //   yaxis: {
    //     labels: {
    //       show: false,
    //     },
    //   },
    // }
  }),

  watch: {
    communityFollowedChartComputed(newVal) {
      this.setSeries(newVal)
      this.setDetail(newVal)
    }
  },

  components: {
    VueApexCharts,
    DetailFollower,
    DetailFollower2,
    DetailFollower3,

    // RadialBar
  },
  methods: {
    ...mapActions({
      setMedsos:'setMedsos/setmedsos'
    }),
    setSeries({ percentageDSOI, percentageDSOII, percentageKolaborasiKita }) {
      this.series = [this._replaceValueWithMax(percentageDSOI)]
      this.series2 = [this._replaceValueWithMax(percentageDSOII)]
      this.series3 = [this._replaceValueWithMax(percentageKolaborasiKita)]
    },
    _replaceValueWithMax(value) {
      if (!value) {
        return 0
      }
      return value
    },
    setDetail(Obj){
      this.data1 = []
      this.data2 = []
      this.data3 = []
      this.member1 = []
      this.member2 = []
      this.member3 = []

      if(Obj.dsoI){
        Obj.dsoI.map(datum=>{
          this.data1.push(datum.follower)
          this.member1.push(datum.memberActive)
        })
      }
      if(Obj.dsoII){
        Obj.dsoII.map(datum=>{
          this.data2.push(datum.follower)
          this.member2.push(datum.memberActive)
        })
      }
      if(Obj.kolaborasiKita){
        Obj.kolaborasiKita.map(datum=>{
          this.data3.push(datum.follower)
          this.member3.push(datum.memberActive)
        })
      }
    },
    setMedsos1() {
      this.medsos2 = false
      this.medsos3 = false
      this.medsos1 = true
      this.nameMedsos = 'DSO I'
      this.setMedsos()
    },
    setMedsos2() {
      this.medsos1 = false
      this.medsos3 = false
      this.medsos2 = true
      this.nameMedsos = 'DSO II'
      this.setMedsos()
    },
    setMedsos3() {
      this.medsos1 = false
      this.medsos2 = false
      this.medsos3 = true
      this.nameMedsos = 'Kolaborasi Kita'
      this.setMedsos()
    },
  },

};
</script>
