<template>
  <div>
    <v-col md="12" sm="12" class="mt-n4 mx-0">
    <p style="font-size: 12px" class="font-weight-black text-uppercase wrap">TARGET EVENT DENGAN DRP dan VENUE
      (<span v-if="!eventDrpComputed.targetDrp || eventDrpComputed.targetDrp == 0" class="font-weight-black mt-n2 red--text">TARGET NOT SET</span>
        <sapan v-else-if="eventDrpComputed.reachDrp >= eventDrpComputed.targetDrp" class="font-weight-black green--text">ACHIEVED</sapan>
      <sapan v-else class="font-weight-black red--text">NOT ACHIEVED</sapan>)
      </p>
    <p v-if="eventDrpComputed.reviewActivitySeries.find(datum=>datum.name === 'Super Family').data[0]<0" style="font-size: 10px" class="font-weight-black mt-n2 ms-6 text-uppercase wrap">targetLKK E-SPORT SUPER : 2
      (<span  class="font-weight-black green--text">ACHIEVED</span>)
      </p>
    </v-col>
    <v-col md="12" sm="12" class="mt-n13 mx-0 ms-n4" justify="left">
    <VueApexCharts
    ref="stackedBarDrpVenue"
    type="bar"
    height="100"
    max-width="310"
    :options="chartOptions"
    :series="eventDrpComputed.eventDrpSeries"
    />
    </v-col>
    
  </div>
</template>
<style>
p {
  font-size: 0.8em;
}
</style>
<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "total-key",
  props: {
    eventDrpComputed: {
      type: Object,
      default: () => new Object()
    }
  },
  data: () => ({
    // charts
    brand : 'DSO Bekasi',
    total : '',
    allEvent:[
      {
        name : 'DSO Bekasi',
        total:20,
        data:[
          {
            category : 'Bold Riders',
            count: 4,
          },
          {
            category : 'Super Friend',
            count : 5
          },
          {
            category : 'Heppiii Community',
            count : 3
          },
          {
            category : 'Kill The LAst',
            count : 3
          },
          {
            category : 'Other',
            count : 3
          },
        ]
      },
    ],
    series: [
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 400,
        stacked: true,
        // barHeight: "40%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      colors: ["#000000", "#e2a63d",  "#e23d3d",  "#d99494", "#34bf41"],
      xaxis: {
        categories: ["Capaian"],
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tickAmount: 2,
        crosshairs: {
          fill: {
            type: "solid",
          },
        },
        tooltip: {
          enabled: false,
        },
        max: 0,
      },
      grid: {
        show: false,
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 2,
        labels: {
          show: false,
        },
      },
      title: {
        show: false,
      },
      annotations: {
        position: 'back',
        xaxis: [
          {
            x: 0,
            x2: 0,
            borderColor: "#000",
            fillColor: "#00ffff",
            offsetY: 1,
            label: {
              show: false,
              text: "4",
              offsetY: 1,
              position:'right',
              orientation: 'horizontal',
              borderColor: 'transparent',
              style:{
                background: 'transparent',
                color: 'transparent'
              }
            },
          },
        ],
        yaxis: [
          {
            y: 0,
            y2: "capaian",
            borderColor: "#000",
            fillColor: "#808080",
            offsetY: 1,
            label: {
              text: "Target: 0",
              fontWeight:900,
              offsetY: 1,
              position:'right',
              orientation: 'horizontal',
              borderColor: 'transparent',
              style:{
                background: 'transparent',
              }
            },
          },
        ],
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"],
          fontSize: "10px",
          fontWeight: 900,
        },
        formatter: function(val, {seriesIndex, w}) {
          // const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0);
          // const percent = (val / sum) * 100;
          return w.globals.series[seriesIndex];
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show:false,
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 0,
        offsetY: -10,
        fontSize: '8px',
        fontWeight: 900,
        onItemHover: {
          highlightDataSeries: true,
        },
        markers: {
          width: 8,
          height: 8,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 2,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
      },
      },
    },
  }),

  components: {
    VueApexCharts,
  },

  methods:{
    updateBrand(brandI){
      this.allEvent.forEach((brand)=>{
        if(brand.name==brandI){
          this.chartOptions.xaxis.categories.push(brandI)
          this.total = brand.total
          const data = brand.data
          if(data.length>0){
            data.forEach(datai=>{
              const form = {
                name: datai.category,
                data: [datai.count]
              }
              this.series.push(form)
            })
          }
        }
      })
    },
    pickBrand(event){
      this.total = ''
      this.series = []
      this.chartOptions.xaxis.categories = []
      this.updateBrand(event)
    },
  },
  created(){
    // this.updateBrand(this.brand)
  },
  watch: {
    eventDrpComputed(newVal) {
      const newOptions = this.chartOptions;
      newOptions.xaxis.max = newVal.reachDrp;
      newOptions.annotations.xaxis[0].x2 = newVal.targetDrp;
      newOptions.annotations.yaxis[0].label.text = `Target: ${newVal.targetDrp}`
      if(newVal.reachDrp>newVal.targetDrp){
        newOptions.xaxis.max = newVal.reachDrp
      }else{
        newOptions.xaxis.max = newVal.targetDrp
      }

      Object.assign(this.chartOptions, newOptions)
      this.$refs.stackedBarDrpVenue.refresh()
    }
  },
};
</script>
