<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="9" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn depressed color="primary" block @click="createSubProject" v-if="getRole.role.create">
          Buat Sub Project
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="!isLoadingSubProject">
      <v-col md="12" sm="12">
        <v-card v-if="errorCode === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Sub Project</th>
                  <th class="text-left">Nama Project</th>
                  <th class="text-left">Dibuat Pada</th>
                  <th class="text-left">Diperbaharui Pada</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in subprojects" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.project.name }}</td>
                  <td>{{ item.createdAt | getNormalDate }}</td>
                  <td>{{ item.updatedAt | getNormalDate}}</td>
                  <td v-if="getRole.role.update">
                    <v-btn small icon color="warning" dark @click="edit(item.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    
    <Alert
      :status="isError && errorCode !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { SubprojectControllers } from "../../controllers/SubprojectControllers.js";
import Swal from "sweetalert2";
import { formatDate } from "../../external/functions/Utilization.js";

export default {
  name: "project-list",
  data: () => ({
    subproject: new SubprojectControllers(),
    title: "Sub Project",
    subprojectTable: {
      page: 1,
      limit: 10
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

  }),

  components: {
    Alert,
    Loading
  },

  created() {
    this.getSubProjects();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },
    // projects
    subprojects() {
      return this.subproject.subProjectList;
    },

    errorCode() {
      return this.subproject.errorCode;
    },

    isError() {
      return this.subproject.isError;
    },

    errorCause() {
      return this.subproject.errorCause;
    },

    isLoadingSubProject() {
      return this.subproject.isLoading;
    }
  },

  methods: {
    async getSubProjects() {
      return this.subproject.getSubProjectsList(
        this.subprojectTable.page,
        this.subprojectTable.limit
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingSubProject;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.subprojectTable.page -= 1;

        await this.getSubProjects(
          this.subprojectTable.page,
          this.subprojectTable.limit
        );
        if (this.isError) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingSubProject;
      this.pages.start += 10;
      this.pages.end += 10;

      this.subprojectTable.page += 1;

      await this.getSubProjects(
        this.subprojectTable.page,
        this.subprojectTable.limit
      );
      if (this.isError) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    createSubProject() {
      this.$router.push({
        name: "Create Sub Project",
      });
    },

    deleteSubProject(id) {
      return id;
    },

    edit(id) {
      this.$router.push({
        name: "Edit Subroject",
        params: {
          id: id
        }
      });
    }
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },
};
</script>