<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>

              <v-autocomplete
                v-model="form.brands"
                :items="brands"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Brand"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="form.communities"
                :items="communities"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Komunitas"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="form.startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.startDate"
                    label="Tanggal Mulai"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.startDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(form.startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="form.endDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.endDate"
                    label="Tanggal Akhir"
                    :rules="[(v) => !!v || 'Kolom wajib diisi']"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.endDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Batal
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(form.endDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field
                v-model.number="form.goal"
                :rules="[
                  (v) =>
                    Number.isInteger(Number(v)) ||
                    'Isian harus angka',
                  (v) => !!v || 'Kolom wajib diisi',
                ]"
                label="Target"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Brands -->
    <Alert
      :status="isErrorGetBrands && errorCodeGetBrands !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetBrands"
    />

    <!-- Error Community -->
    <Alert
      :status="isErrorGetCommunity && errorCodeGetCommunity !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetCommunity"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import { SalesControllers } from "../../controllers/SalesControllers.js";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import Swal from "sweetalert2";

export default {
  name: "create-user",
  data: () => ({
    sales: new SalesControllers(),
    community: new CommunityControllers(),
    brand: new BrandControllers(),
    valid: true,
    title: "Buat Penjualan",

    isLoading: false,
    fullPage: true,

    form: {
      brands: "",
      communities: "",
      startDate: "",
      endDate: "",
      goal: null,
    },

    menu: false,
    menu2: false,

    selectScope: {
      page: 1,
      limit: 1000,
    },
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // community
    communities() {
      return this.community.communityList.filter(community=>!community.isFreeze && community.isVerified);
    },

    isErrorGetCommunity() {
      return this.community.isError;
    },

    errorCauseGetCommunity() {
      return this.community.errorCause;
    },

    errorCodeGetCommunity() {
      return this.community.errorCode;
    },

    // brands
    brands() {
      return this.brand.brandList;
    },

    errorCodeGetBrands() {
      return this.brand.errorCode;
    },

    isErrorGetBrands() {
      return this.brand.isError;
    },

    errorCauseGetBrands() {
      return this.brand.errorCause;
    },

    // sales
    isErrorCreateSales() {
      return this.sales.isError;
    },

    errorCauseCreateSales() {
      return this.sales.errorCause;
    },

    isLoadingCreateSales() {
      return this.sales.isLoading;
    },
  },

  created() {
    this.getBrands();
    this.getCommunities();
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getBrands() {
      return this.brand.getBrandList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    getCommunities() {
      return this.community.getCommunityList(
        this.selectScope.page,
        this.selectScope.limit
      );
    },

    async submitProcess(communityId, brandId, startDate, endDate, goal) {
      return this.sales.createSalesTargetList(
        communityId,
        brandId,
        startDate,
        endDate,
        goal
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (validate) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.communities,
          this.form.brands,
          this.form.startDate,
          this.form.endDate,
          parseInt(this.form.goal)
        );

        if (this.isErrorCreateSales) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateSales,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
        }
      }
    },
  },
};
</script>