<template>
  <v-container class="pt-8">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card
          class="elevation-12"
          v-on:keyup.enter="login"
          :loading="isLoading"
        >
          <v-toolbar dark color="primary">
            <v-toolbar-title>Ubah Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                outlined
                v-model="passwordOld"
                id="password-old"
                name="passwordOld"
                label="Kata Kunci Lama"
                prepend-inner-icon="mdi mdi-account-key mdi-24px"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
              ></v-text-field>
              <v-text-field
                outlined
                v-model="passwordNew"
                id="password-new"
                name="passwordNew"
                label="Kata Kunci Baru"
                prepend-inner-icon="mdi mdi-account-key mdi-24px"
                :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass2 ? 'text' : 'password'"
                @click:append="showPass2 = !showPass2"
              ></v-text-field>
              <v-text-field
                outlined
                v-model="passwordNew2"
                id="password-new2"
                name="passwordNew2"
                label="Konfirmasi Kata Kunci Baru"
                prepend-inner-icon="mdi mdi-account-key mdi-24px"
                :append-icon="showPass3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass3 ? 'text' : 'password'"
                @click:append="showPass3 = !showPass3"
                v-bind="{
                  error: isDifferent,
                  ...(isDifferent && { 'error-messages': ['Konfirmasi Password Tidak Sama'] })
                }"
              ></v-text-field>
              <div></div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="isAddButtonDisabled"
              @click="submit"
              :loading="isLoading"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <loading
      :active.sync="isLoadingToDashboard"
      :is-full-page="fullPage"
    ></loading>
  </v-container>
</template>
<script>
import { AuthControllers } from "../../controllers/AuthControllers.js";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";

export default {
  name: "change-password",
  data: () => ({
    auth: new AuthControllers(),
    email: "",
    passwordOld: "",
    passwordNew: "",
    passwordNew2: "",

    isLoadingToDashboard: false,
    fullPage: true,

    showPass: false,
    showPass2: false,
    showPass3: false,
  }),

  computed: {
    isError() {
      return this.auth.isError;
    },

    errorCause() {
      return this.auth.errorCause;
    },

    isLoading() {
      return this.auth.isLoading;
    },

    isDifferent(){
      return (this.passwordNew != this.passwordNew2 && (this.passwordNew!='' && this.passwordNew2!=''))
    },

    isAddButtonDisabled() {
      return !(
        this.passwordOld != "" &&
        this.passwordNew != "" &&
        this.passwordNew2 != "" &&
        this.passwordNew == this.passwordNew2
      );
      // means u have neither name nor site
    },
  },

  components: {
    Loading,
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    async submitChangePassword(passwordOld, passwordNew, passwordNew2) {
      return this.auth.changePassword(passwordOld, passwordNew, passwordNew2);
    },

    async submit() {
      this.isLoading;
      await this.submitChangePassword(
        this.passwordOld,
        this.passwordNew,
        this.passwordNew2
      );
      if (this.isError === false) {
        Swal.fire({
          icon: "success",
          title: "Sukses",
          text: "Password berhasil diubah",
        });
        this.isLoadingToDashboard = true;
        window.location.href = window.location.origin;
      } else {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: this.errorCause,
        });
        this.$refs.observer.reset();
        this.back();
      }
    },
  },
};
</script>
