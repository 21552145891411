import { RolesListRequest } from "../request/RolesRequest.js";

export class RolesControllers  {
    rolesList = new Array();
    isError= false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    constructor() {}

    getRolesList() {
        const resp = RolesListRequest()
        .then((response) => {
            this.setRolesList(response.data.data);
        }).catch(err => {
            this.setError(true);
            this.setErrorCause(err.response.data.message);
            this.setErrorcode(err.response.data.code);
        });
       
        return resp;
    }

    setRolesList(data) {
        this.rolesList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }
}

