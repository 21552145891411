import axios from "axios";
import { MedsosFollowerList, CreateMedsosFollower, DeleteMedsosFollower, UpdateMedsosFollower, MedsosFollowerDetail } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const MedsosFollowerListRequest = (page, limit, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${MedsosFollowerList(page, limit, dsoId)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateMedsosFollowerRequest = (
    dso,
    medsos,
    category,
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateMedsosFollower()}`,
        data: {
            dso,
            medsos,
            category,
    
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteMedsosFollowerRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteMedsosFollower(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateMedsosFollowerRequest = (
    id,
    dso,
    medsos,
    category,
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateMedsosFollower(id)}`,
        data: {
            dso,
            medsos,
            category,
    
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const MedsosFollowerDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${MedsosFollowerDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}





