import {
    CreateVenueRequest,
    DeleteVenueRequest,
    UpdateVenueRequest,
    VenueDetailRequest,
    VenueListRequest
} from "../request/VenueRequest.js";

export class VenueControllers {
    venueList = new Array();
    venueDetail = new Object();
    venueDownload = new Array();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getVenueList(page, limit, keyword, dsoId) {
        this.setLoading(true);
        const resp = VenueListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setVenueList(response.data.data);
                return true;
            }).catch(err => {
                this.setVenueList([])
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getVenueDetailEdit(id) {
        this.setLoading(true);
        const resp = VenueDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setVenueDetail(response.data.data);
                return true;
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
                return false;
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    downloadVenueList(page, limit, keyword, dsoId) {
        this.setLoadingDownload(true);
        const resp = VenueListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setVenueListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setVenueListDownload(data) {
        this.venueDownload = data;
    }

    setVenueDetail(data) {
        this.venueDetail = data;
    }

    setVenueList(data) {
        this.venueList = data;
    }

    createVenueList(
        dso,
        areaId,
        brands,
        name,
        excellence,
        category,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode
    ) {
        this.setLoading(true);
        const resp = CreateVenueRequest(
                dso,
                areaId,
                brands,
                name,
                excellence,
                category,
                province,
                district,
                subDistrict,
                village,
                street,
                zipCode
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    getVenueDetail(id) {
        this.venueDetail = this.venueList.find(item => {
            return item.id === id
        });
    }

    deleteVenue(id) {
        this.setLoading(true);
        const resp = DeleteVenueRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateVenueList(
        id,
        dso,
        areaId,
        brands,
        name,
        excellence,
        category,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode
    ) {
        this.setLoading(true);
        const resp = UpdateVenueRequest(
                id,
                dso,
                areaId,
                brands,
                name,
                excellence,
                category,
                province,
                district,
                subDistrict,
                village,
                street,
                zipCode
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }
}