<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0 me-n1">
          <v-col md="8" sm="12">
            <v-autocomplete
              :items="[2019, 2020, 2021, 2022]"
              item-text="name"
              item-value="id"
              label="Tahun"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12">
            <v-btn class="success ms-n2" small block>Unduh Excel </v-btn>
          </v-col>
          <v-col align="center" md="12" ms="12" class="text-wrap mt-n5">
            <h2>{{ title }}</h2>
          </v-col>
          <v-col md="12" sm="12" class="px-0 py-0 mt-n me-0">
            <VueApexCharts
              type="bar"
              height="400"
              :options="chartOptions"
              :series="series"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

export default {
  name: "community-achievement",
  data: () => ({
    title: "KONSUMSI PER KATEGORI KOMUNITAS",
    year: 2021,
    years: [2020, 2021, 2022],

    series: [
      {
        data: [50, 275, 75, 32, 32, 50, 30, 40, 30]
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 175,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "50%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#e2a63d"],
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"],
          fontSize: "15px",
        },
        formatter: function (val, opt) {
          const series = opt.w.globals.series[0]
          const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0);
          const percent = (val / sum) * 100;

          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val+` (${percent.toFixed(0)}%)`;
        },
        offsetX: '0px',
        offsetY: -18,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "Bold Riders",
          "Heppiii Community",
          "Super Spccer",
          "Kill the LAst",
          "Super Adventure",
          "Mobil",
          "Motor",
          "Fotografi",
          "Musik"
        ],
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
    },
  }),

  components: {
    VueApexCharts,
  },
};
</script>