<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-select
                v-model="form.type"
                :items="types"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tipe"
                item-text="text"
                item-value="value"
                required
                outlined
                dense
              ></v-select>
              <vue-editor v-model="form.content"></vue-editor>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert text type="info">
                Maksimal File adalah 2 MB
              </v-alert>
              <v-card>
                <v-card-title> Unggah Foto </v-card-title>
                <v-card-text>
                  <input @change="changeMedia" ref="myFileInput" type="file" accept="image/*" />
                  <br /><br />
                  <v-row>
                    <v-col
                      v-for="(item, index) in pics"
                      :key="index"
                      class="d-flex child-flex"
                      cols="4"
                    >
                      <v-img
                        :src="item"
                        :lazy-src="item"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                      <v-icon color="error" style="cursor: pointer;" @click="deletePic(index)">mdi-close</v-icon>
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-card>
                <v-card-title>
                  <v-btn small outlined color="primary" @click="addLink">
                    <v-icon>mdi-plus</v-icon>
                    Tambah Link
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <div v-for="(item, index) in form.links" :key="index">
                    <strong>Link {{ index + 1 }}</strong>
                    <v-text-field
                      label="Tag"
                      required
                      outlined
                      dense
                      v-model="item.tag"
                    ></v-text-field>
                    <v-text-field
                      label="Url"
                      required
                      outlined
                      dense
                      v-model="item.link"
                    ></v-text-field>
                    <v-btn
                      small
                      block
                      color="error"
                      @click="removeLink(index)"
                      v-if="index > 0"
                    >
                      Hapus
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <br />
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  </v-container>
</template>
<script>
import { PostControllers } from "../../controllers/PostControllers";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { VueEditor } from "vue2-editor";
import { MediaControllers } from "../../controllers/MediaControllers.js";

export default {
  name: "create-brand",
  data: () => ({
    post: new PostControllers(),
    media: new MediaControllers(),
    title: "Buat Post",
    valid: true,

    isLoading: false,
    fullPage: true,

    types: [
      {
        text: "Common (General/Umum)",
        value: "common",
      },
      {
        text: "Event",
        value: "event",
      },
    ],

    form: {
      content: "",
      type: null,
      pics: [],
      links: [
        {
          tag: "",
          link: ""
        }
      ],
    },

    pics: [],
  }),

  components: {
    Loading,
    VueEditor,
  },

  created() {},

  computed: {
    // create post
    isErrorCreatePost() {
      return this.post.isError;
    },

    errorCauseCreatePost() {
      return this.post.errorCause;
    },

    isLoadingCreatePost() {
      return this.post.isLoading;
    },

    // media
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    async submitProcess(content, images, links, type) {
      return this.post.createPostList(content, images, links, type);
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if(this.pics.length > 0) {
        this.isLoading = true;
        await this.uploadMedia("post", this.pics);
        this.isLoading = false;
        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "ERROR",
            text: this.errorCauseMediaUpload,
          });
          this.isErrorUploadMedia = false;
        } else {
          this.form.pics = this.medias;
        }
      }

      if (validate && !this.isErrorUploadMedia) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.content,
          this.form.pics,
          this.form.links,
          this.form.type
        );

        if (this.isErrorCreatePost) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreatePost,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.$refs.observer.reset();
          this.pics = [];
        }
      }
    },

    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size/1000;
      if(sizeLimit > 1000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = '';
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.pics.push(e.target.result);
        };
      }
    },

    deletePic(index) {
      this.pics.splice(index, 1);
    },

    addLink() {
      this.form.links.push({
        tag: "",
        link: ""
      });
    },

    removeLink(index) {
      if (index > -1) {
        this.form.links.splice(index, 1); 
      }
    },
  },
};
</script>