import {
    PostListRequest,
    DeletePostRequest,
    CreatePostListRequest,
    PostCommentDetailRequest,
    PostDetailRequest,
    VerifyPostRequest
} from "../request/PostRequest.js";

export class PostControllers {
    postList = new Array();
    postCommentDetail = new Array();
    postDetail = new Object();
    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    isErrorVerify = false;

    constructor() {}

    getPostList(page, limit, keyword, role, dsoId) {
        this.setLoading(true);
        const resp = PostListRequest(page, limit, keyword, role, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setPostList(response.data.data);
            }).catch(err => {
                this.setPostList([]);
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getPostCommentDetail(id, page, limit, keyword) {
        this.setLoading(true);
        const resp = PostCommentDetailRequest(id, page, limit, keyword)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setPostCommentDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getPostDetail(id) {
        this.setLoading(true);
        const resp = PostDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setPostDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getDetail(id) {
        this.postDetail = this.postList.find(item => {
            return item.id === id
        });
    }

    setPostDetail(data) {
        this.postDetail = data;
    }

    setPostCommentDetail(data) {
        this.postCommentDetail = data;
    }

    setPostList(data) {
        this.postList = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    deletePost(id) {
        this.setLoading(true);
        const resp = DeletePostRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    createPostList(
        content,
        images,
        links,
        type
    ) {
        this.setLoading(true);
        const resp = CreatePostListRequest(
                content,
                images,
                links,
                type
            )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    verifyPost(
        id
    ) {
        const resp = VerifyPostRequest(
                id
            )
            .then(() => {
                this.setErrorVerify(false);
            }).catch(err => {
                this.setErrorVerify(true);
                this.setErrorCauseVerify(err.response.data.message);
            });

        return resp;
    }

    setErrorVerify(status) {
        this.isErrorVerify = status;
    }
}