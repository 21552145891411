import axios from "axios";
import { FollowerMedsosList, CreateFollowerMedsos, DeleteFollowerMedsos, UpdateFollowerMedsos, FollowerMedsosDetail, FollowerMedsosListByMedfol } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js";
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const FollowerMedsosListRequest = (page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${FollowerMedsosList(page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateFollowerMedsosRequest = (
    medfol,
    community,
    follower
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateFollowerMedsos()}`,
        data: {
            medfol: medfol,
            community: community,
            follower: follower
    
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteFollowerMedsosRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteFollowerMedsos(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateFollowerMedsosRequest = (
    id,
    medfol,
    community,
    follower,
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateFollowerMedsos(id)}`,
        data: {
            medfol: medfol,
            community: community,
            follower: follower,
    
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const FollowerMedsosDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${FollowerMedsosDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const FollowerMedsosListByMedfolRequest = (medfol, page, limit, keyword) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${FollowerMedsosListByMedfol(medfol, page, limit, keyword)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}





