import { AchievementListRequest, VerifyAchievementRequest, AchievementDetailRequest, CreateAchievementRequest, UpdateAchievementRequest, DeleteAchievementRequest } from "../request/AchievementRequest.js";

export class AchievementControllers {
    achievementList = new Array();
    achievementDetail = new Object();
    achievementDownload = new Array();

    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    isErrorVerify = false;
    errorCauseVerify = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getAchievementList(page, limit, keyword, dsoId) {
        this.setLoading(true);
        const resp = AchievementListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setAchievementList(response.data.data);
            }).catch(err => {
                this.setAchievementList([]);
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getAchievementDetailEdit(id) {
        this.setLoading(true);
        const resp = AchievementDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setAchievementDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setAchievementList(data) {
        this.achievementList = data;
    }

    setAchievementDetail(data) {
        this.achievementDetail = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    verifyAchievement(
        id
    ) {
        const resp = VerifyAchievementRequest(
                id
            )
            .then(() => {
                this.setErrorVerify(false);
            }).catch(err => {
                this.setErrorVerify(true);
                this.setErrorCauseVerify(err.response.data.message);
            });

        return resp;
    }

    setErrorVerify(status) {
        this.isErrorVerify = status;
    }

    setErrorCauseVerify(err) {
        this.errorCauseVerify = err;
    }

    getAchievementDetail(id) {
        this.achievementDetail = this.achievementList.find(item => {
            return item.id === id
        });
    }

    downloadAchievementList(page, limit, keyword, dsoId) {
        this.setLoadingDownload(true);
        const resp = AchievementListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setAchievementListDownload(response.data.data);
            }).catch(err => {
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setAchievementListDownload(data) {
        this.achievementDownload = data;
    }

    createAchievementList(
        communityId,
        name,
        year,
        scale,
        nomination,
        image,
        isContract,
        score
    ) {
        this.setLoading(true);
        const resp = CreateAchievementRequest(
            communityId,
            name,
            year,
            scale,
            nomination,
            image,
            isContract,
            score
            
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    deleteAchievement(id) {
        this.setLoading(true);
        const resp = DeleteAchievementRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateAchievementList(
        id,
        name,
        year,
        scale,
        nomination,
        image,
        isContract,
        score
    ) {
        this.setLoading(true);
        const resp = UpdateAchievementRequest(
            id,
            name,
            year,
            scale,
            nomination,
            image,
            isContract,
            score
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }


}

