export const defineMenu = (role) => {
    const menusStored = {
        "admin.inspire": [
            {
                title: "Performa KPI",
                icon: "mdi-google-circles-communities",
                haveChild: true,
                submenus: [{
                    title: "Performa KPI",
                    icon: "mdi-google-circles-communities",
                    children: [{
                            title: "Rincian KPI",
                            path: "/summary-kpi",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Aktivitas",
                            path: "/kpi-activity",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Komunikasi",
                            path: "/kpi-communication",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Konsumsi",
                            path: "/kpi-consumption",
                            haveChild: false,
                            submenusChild: [],
                        }
                    ],
                }, ],
            },
            {
                title: "Data Master",
                icon: "mdi-camera-iris",
                haveChild: true,
                submenus: [{
                    title: "Data Master",
                    icon: "mdi-camera-iris",
                    children: [
                        {
                            title: "Brand",
                            path: "/brand",
                            haveChild: false,
                            submenusChid: [],
                        },
                        {
                            title: "Kategori Komunitas",
                            path: "/community-category",
                            haveChild: false,
                            submenusChid: [],
                        },
                        {
                            title: "Manajemen",
                            haveChild: true,
                            submenusChild: [{
                                title: "Manajemen",
                                grandChildren: [
                                    {
                                        title: "Daftar RSO",
                                        path: "/rso-list",
                                    },
                                    {
                                        title: "Daftar AM",
                                        path: "/am-list"
                                    },
                                    {
                                        title: "Daftar DSO",
                                        path: "/dso-list"
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Data Pengguna",
                            haveChild: true,
                            submenusChild: [{
                                title: "Data Pengguna",
                                grandChildren: [
                                    {
                                        title: "Buat Pengguna",
                                        path: "/create-user"
                                    },
                                    {
                                        title: "PIC Manajemen",
                                        path: "/user-list"
                                    },
                                    {
                                        title: "PIC Komunitas",
                                        path: "/user-pic-list"
                                    },
                                    {
                                        title: "Member",
                                        path: "/user-member-list"
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Input Target KPI DSO",
                            haveChild: true,
                            submenusChild: [{
                                title: "Input Target KPI DSO",
                                grandChildren: [{
                                        title: "Target Aktivitas",
                                        path: "/input-target-kpi-activity",
                                    },
                                    {
                                        title: "Target Komunikasi",
                                        path: "/input-target-kpi-communication",
                                    },
                                    {
                                        title: "Target Konsumsi",
                                        path: "/input-target-kpi-consumption",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunitas",
                            path: "/community-list",
                            haveChild: false,
                            submenusChid: [],
                        },
                        {
                            title: "DRP dan Venue",
                            haveChild: true,
                            submenusChild: [{
                                title: "DRP dan Venue",
                                grandChildren: [{
                                        title: "Daftar DRP",
                                        path: "/drp-list",
                                    },
                                    {
                                        title: "Daftar Venue",
                                        path: "/venue",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Aktivitas",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Aktivitas",
                                grandChildren: [{
                                        title: "Kegiatan DSO",
                                        path: "/event-dso",
                                    },
                                    {
                                        title: "Kegiatan Komunitas",
                                        path: "/event-list-community",
                                    },
                                    {
                                        title: "Verifikasi Berbagi Informasi",
                                        path: "/info-community-list",
                                    },
                                    {
                                        title: "Prestasi",
                                        path: "/achievement-list",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunikasi",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Komunikasi",
                                grandChildren: [{
                                        title: "Postingan",
                                        path: "/post-list",
                                    },
                                    {
                                        title: "Media Sosial DSO",
                                        path: "/social-media-list",
                                    },
                                    // {
                                    //     title: "Follower Medsos DSO",
                                    //     path: "/follower-social-media",
                                    // },
                                    {
                                        title: "Atribut",
                                        path: "/attribute-list",
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Konsumsi",
                            path: "/consumption-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Laporan",
                            haveChild: true,
                            submenusChild: [{
                                title: "Laporan",
                                grandChildren: [{
                                        title: "Laporan Pengguna",
                                        path: "/user-report",
                                    },
                                    {
                                        title: "Laporan Postingan",
                                        path: "/post-report",
                                    }
                                ]
                            }],
                        },
                    ],
                }, ],
            },
            {
                title: "Matrik KiSi",
                icon: "mdi-file",
                path: "/report-list",
                haveChild: false,
                submenus: [],
            },
            {
                title: "Project",
                icon: "mdi-projector-screen",
                path: "/project",
                haveChild: false,
                submenus: [],
            },
            {
                title: "Subproject",
                icon: "mdi-source-branch",
                path: "/subproject",
                haveChild: false,
                submenus: [],
            },
            // {
            //     title: "Dashboard",
            //     icon: "mdi-google-circles-communities",
            //     haveChild: true,
            //     submenus: [{
            //         title: "Dashboard",
            //         icon: "mdi-google-circles-communities",
            //         children: [{
            //                 title: "Laporan Komunitas",
            //                 path: "/community-report",
            //             },
            //             {
            //                 title: "Laporan Member",
            //                 path: "/member-report",
            //             },
            //             {
            //                 title: "Laporan Pencapaian",
            //                 path: "achievement-report",
            //             },
            //             {
            //                 title: "Laporan Aktivitas",
            //                 path: "activity-report",
            //             },
            //             {
            //                 title: "Laporan Komunikasi",
            //                 path: "communication-report",
            //             }
            //         ],
            //     }, ],
            // },
            // {
            //     title: "Brand",
            //     icon: "mdi-camera-iris",
            //     haveChild: true,
            //     submenus: [{
            //         title: "Data Master",
            //         icon: "mdi-camera-iris",
            //         children: [{
            //                 title: "Brand",
            //                 path: "/brand",
            //             },
            //             {
            //                 title: "Venue",
            //                 path: "/venue",
            //             },
            //             {
            //                 title: "Info Komunitas",
            //                 path: "/info-community-list",
            //             },
            //             {
            //                 title: "Penjualan",
            //                 path: "/sales-list/1",
            //             },
            //             {
            //                 title: "DRP",
            //                 path: "/drp-list",
            //             },
            //             {
            //                 title: "Mapping Pengguna RSO",
            //                 path: "/rso-list",
            //             },
            //             {
            //                 title: "Mapping Pengguna AM",
            //                 path: "/am-list",
            //             },
            //             {
            //                 title: "Mapping Pengguna DSO",
            //                 path: "/dso-list",
            //             },
            //         ],
            //     }, ],
            // },
            // {
            //     title: "Pengguna",
            //     icon: "mdi-account-supervisor-circle-outline",
            //     path: "/user-list",
            //     haveChild: false,
            //     submenus: [],
            // },
            // {
            //     title: "Komunitas",
            //     icon: "mdi-google-circles-communities",
            //     haveChild: true,
            //     submenus: [{
            //         title: "Komunitas",
            //         icon: "mdi-google-circles-communities",
            //         children: [{
            //                 title: "Daftar Komunitas",
            //                 path: "/community-list",
            //             },
            //             {
            //                 title: "Post",
            //                 path: "/post-list",
            //             },
            //             {
            //                 title: "Attribute",
            //                 path: "attribute-list",
            //             },
            //             {
            //                 title: "Prestasi",
            //                 path: "achievement-list",
            //             }
            //         ],
            //     }, ],
            // },
            // {
            //     title: "Event",
            //     icon: "mdi-calendar",
            //     haveChild: true,
            //     submenus: [{
            //         title: "Event",
            //         icon: "mdi-calendar",
            //         children: [{
            //                 title: "Daftar Event",
            //                 path: "/event-dso",
            //             },
            //             {
            //                 title: "Event Evidence",
            //                 path: "/event-evidence-list",
            //             },
            //         ],
            //     }, ],
            // },
            // {
            //     title: "Matrik KiSi",
            //     icon: "mdi-file",
            //     path: "/report-list",
            //     haveChild: false,
            //     submenus: [],
            // },
            // {
            //     title: "Project",
            //     icon: "mdi-projector-screen",
            //     path: "/project",
            //     haveChild: false,
            //     submenus: [],
            // },
            // {
            //     title: "Subproject",
            //     icon: "mdi-source-branch",
            //     path: "/subproject",
            //     haveChild: false,
            //     submenus: [],
            // },
        ],
        "admin.rso": [{
                title: "Dashboard",
                icon: "mdi-speedometer",
                haveChild: true,
                submenus: [{
                    title: "Dashboard",
                    icon: "mdi-speedometer",
                    children: [{
                            title: "Laporan Komunitas",
                            path: "/community-report",
                        },
                        {
                            title: "Laporan Member",
                            path: "/member-report",
                        },
                        {
                            title: "Laporan Pencapaian",
                            path: "achievement-report",
                        },
                        {
                            title: "Laporan Aktivitas",
                            path: "activity-report",
                        },
                        {
                            title: "Laporan Komunikasi",
                            path: "communication-report",
                        }
                    ],
                }, ],
            },
            {
                title: "Performa KPI",
                icon: "mdi-google-circles-communities",
                haveChild: true,
                submenus: [{
                    title: "Performa KPI",
                    icon: "mdi-google-circles-communities",
                    children: [{
                            title: "Rincian KPI",
                            path: "/summary-kpi",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Aktivitas",
                            path: "/kpi-activity",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Komunikasi",
                            path: "/kpi-communication",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Konsumsi",
                            path: "/kpi-consumption",
                            haveChild: false,
                            submenusChild: [],
                        }
                    ],
                }, ],
            },
            {
                title: "Data Master DSO",
                icon: "mdi-camera-iris",
                haveChild: true,
                submenus: [{
                    title: "Data Master DSO",
                    icon: "mdi-camera-iris",
                    children: [
                        {
                            title: "Input Target KPI DSO",
                            haveChild: true,
                            submenusChild: [{
                                title: "Input Target KPI DSO",
                                grandChildren: [{
                                        title: "Target Aktivitas",
                                        path: "/input-target-kpi-activity",
                                    },
                                    {
                                        title: "Target Komunikasi",
                                        path: "/input-target-kpi-communication",
                                    },
                                    {
                                        title: "Target Konsumsi",
                                        path: "/input-target-kpi-consumption",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Manajemen",
                            haveChild: true,
                            submenusChild: [{
                                title: "Manajemen",
                                grandChildren: [
                                    {
                                        title: "Daftar AM",
                                        path: "/am-list"
                                    },
                                    {
                                        title: "Daftar DSO",
                                        path: "/dso-list"
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Pengguna",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Pengguna",
                                grandChildren: [
                                    {
                                        title: "PIC Manajemen",
                                        path: "/user-list"
                                    },
                                    {
                                        title: "PIC",
                                        path: "/user-pic-list"
                                    },
                                    {
                                        title: "Member",
                                        path: "/user-member-list"
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunitas",
                            path: "/community-list",
                            haveChild: false,
                            submenusChid: [],
                        },
                        {
                            title: "DRP dan Venue",
                            haveChild: true,
                            submenusChild: [{
                                title: "DRP dan Venue",
                                grandChildren: [{
                                        title: "Daftar DRP",
                                        path: "/drp-list",
                                    },
                                    {
                                        title: "Daftar Venue",
                                        path: "/venue",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Aktivitas",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Aktivitas",
                                grandChildren: [{
                                        title: "Kegiatan DSO",
                                        path: "/event-dso",
                                    },
                                    {
                                        title: "Kegiatan Komunitas",
                                        path: "/event-list-community",
                                    },
                                    {
                                        title: "Verifikasi Berbagi Informasi",
                                        path: "/info-community-list",
                                    },
                                    {
                                        title: "Prestasi",
                                        path: "/achievement-list",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunikasi",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Komunikasi",
                                grandChildren: [{
                                        title: "Post",
                                        path: "/post-list",
                                    },
                                    {
                                        title: "Media Sosial DSO",
                                        path: "/social-media-list",
                                    },
                                    // {
                                    //     title: "Follower Medsos DSO",
                                    //     path: "/follower-social-media",
                                    // },
                                    {
                                        title: "Atribut",
                                        path: "/attribute-list",
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Konsumsi",
                            path: "/consumption-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                    ],
                }, ],
            },
            {
                title: "Matrik KiSi",
                icon: "mdi-file",
                path: "/report-list",
                haveChild: false,
                submenus: [],
            }
        ],
        "admin.am": [{
            title: "Dashboard",
            icon: "mdi-speedometer",
            path: "/dashboard",
            haveChild: false,
            submenus: [],
        },
        {
            title: "Performa KPI",
            icon: "mdi-google-circles-communities",
            haveChild: true,
            submenus: [{
                title: "Performa KPI",
                icon: "mdi-google-circles-communities",
                children: [{
                        title: "Rincian KPI",
                        path: "/summary-kpi",
                        haveChild: false,
                        submenusChild: [],
                    },
                    {
                        title: "Aktivitas",
                        path: "/kpi-activity",
                        haveChild: false,
                        submenusChild: [],
                    },
                    {
                        title: "Komunikasi",
                        path: "/kpi-communication",
                        haveChild: false,
                        submenusChild: [],
                    },
                    {
                        title: "Konsumsi",
                        path: "/kpi-consumption",
                        haveChild: false,
                        submenusChild: [],
                    }
                ],
            }, ],
        },
        {
            title: "Data Master DSO",
            icon: "mdi-camera-iris",
            haveChild: true,
            submenus: [{
                title: "Data Master DSO",
                icon: "mdi-camera-iris",
                children: [
                    {
                        title: "Manajemen",
                        haveChild: true,
                        submenusChild: [{
                            title: "Manajemen",
                            grandChildren: [
                                {
                                    title: "Daftar DSO",
                                    path: "/dso-list"
                                }
                            ]
                        }],
                    },
                    {
                        title: "Daftar Pengguna",
                        haveChild: true,
                        submenusChild: [{
                            title: "Daftar Pengguna",
                            grandChildren: [
                                {
                                    title: "PIC Manajemen",
                                    path: "/user-list"
                                },
                                {
                                    title: "PIC Komunitas",
                                    path: "/user-pic-list"
                                },
                                {
                                    title: "Member",
                                    path: "/user-member-list"
                                }
                            ]
                        }],
                    },
                    {
                        title: "Daftar Komunitas",
                        path: "/community-list",
                        haveChild: false,
                        submenusChid: [],
                    },
                    {
                        title: "DRP dan Venue",
                        haveChild: true,
                        submenusChild: [{
                            title: "DRP dan Venue",
                            grandChildren: [{
                                    title: "Daftar DRP",
                                    path: "/drp-list",
                                },
                                {
                                    title: "Daftar Venue",
                                    path: "/venue",
                                },
                            ]
                        }],
                    },
                    {
                        title: "Daftar Aktivitas",
                        haveChild: true,
                        submenusChild: [{
                            title: "Daftar Aktivitas",
                            grandChildren: [{
                                    title: "Kegiatan DSO",
                                    path: "/event-dso",
                                },
                                {
                                    title: "Kegiatan Komunitas",
                                    path: "/event-list-community",
                                },
                                {
                                    title: "Verifikasi Berbagi Informasi",
                                    path: "/info-community-list",
                                },
                                {
                                    title: "Prestasi",
                                    path: "/achievement-list",
                                },
                            ]
                        }],
                    },
                    {
                        title: "Daftar Komunikasi",
                        haveChild: true,
                        submenusChild: [{
                            title: "Daftar Komunikasi",
                            grandChildren: [{
                                    title: "Post",
                                    path: "/post-list",
                                },
                                {
                                    title: "Media Sosial DSO",
                                    path: "/social-media-list",
                                },
                                // {
                                //     title: "Follower Medsos DSO",
                                //     path: "/follower-social-media",
                                // },
                                {
                                    title: "Atribut",
                                    path: "/attribute-list",
                                }
                            ]
                        }],
                    },
                    {
                        title: "Daftar Konsumsi",
                        path: "/consumption-list",
                        haveChild: false,
                        submenusChild: [],
                    },
                ],
            }, ],
        },
        {
            title: "Matrik KiSi",
            icon: "mdi-file",
            path: "/report-list",
            haveChild: false,
            submenus: [],
        }
        ],
        "admin.dso": [{
                title: "Performa KPI",
                icon: "mdi-google-circles-communities",
                haveChild: true,
                submenus: [{
                    title: "Performa KPI",
                    icon: "mdi-google-circles-communities",
                    children: [
                        {
                            title: "Rincian KPI",
                            path: "/summary-kpi-dso",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Aktivitas",
                            path: "/kpi-activity",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Komunikasi",
                            path: "/kpi-communication",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Konsumsi",
                            path: "/kpi-consumption",
                            haveChild: false,
                            submenusChild: [],
                        }
                    ],
                }, ],
            },
            {
                title: "Data Master DSO",
                icon: "mdi-camera-iris",
                haveChild: true,
                submenus: [{
                    title: "Data Master DSO",
                    icon: "mdi-camera-iris",
                    children: [{
                            title: "Daftar Pengguna",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Pengguna",
                                grandChildren: [{
                                        title: "PIC",
                                        path: "/user-pic-list"
                                    },
                                    {
                                        title: "Member",
                                        path: "/user-member-list"
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunitas",
                            path: "/community-list",
                            haveChild: false,
                            submenusChid: [],
                        },
                        {
                            title: "DRP dan Venue",
                            haveChild: true,
                            submenusChild: [{
                                title: "DRP dan Venue",
                                grandChildren: [{
                                        title: "Daftar DRP",
                                        path: "/drp-list",
                                    },
                                    {
                                        title: "Daftar Venue",
                                        path: "/venue",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Aktivitas",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Aktivitas",
                                grandChildren: [{
                                        title: "Kegiatan DSO",
                                        path: "/event-dso",
                                    },
                                    {
                                        title: "Kegiatan Komunitas",
                                        path: "/event-list-community",
                                    },
                                    {
                                        title: "Verifikasi Berbagi Informasi",
                                        path: "/info-community-list",
                                    },
                                    {
                                        title: "Prestasi",
                                        path: "/achievement-list",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunikasi",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Komunikasi",
                                grandChildren: [{
                                        title: "Post",
                                        path: "/post-list",
                                    },
                                    {
                                        title: "Media Sosial DSO",
                                        path: "/social-media-list",
                                    },
                                    // {
                                    //     title: "Follower Medsos DSO",
                                    //     path: "/follower-social-media",
                                    // },
                                    {
                                        title: "Atribut",
                                        path: "/attribute-list",
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Konsumsi",
                            path: "/consumption-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                    ],
                }, ],
            },
            {
                title: "Matrik KiSi",
                icon: "mdi-file",
                path: "/report-list",
                haveChild: false,
                submenus: [],
            }, 
        ],
        "admin.dso.low": [{
                title: "Performa KPI",
                icon: "mdi-google-circles-communities",
                haveChild: true,
                submenus: [{
                    title: "Performa KPI",
                    icon: "mdi-google-circles-communities",
                    children: [
                        {
                            title: "Rincian KPI",
                            path: "/summary-kpi-dso",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Aktivitas",
                            path: "/kpi-activity",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Komunikasi",
                            path: "/kpi-communication",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Konsumsi",
                            path: "/kpi-consumption",
                            haveChild: false,
                            submenusChild: [],
                        }
                    ],
                }, ],
            },
            {
                title: "Data Master DSO",
                icon: "mdi-camera-iris",
                haveChild: true,
                submenus: [{
                    title: "Data Master DSO",
                    icon: "mdi-camera-iris",
                    children: [{
                            title: "Daftar Pengguna",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Pengguna",
                                grandChildren: [
                                    {
                                        title: "PIC",
                                        path: "/user-pic-list"
                                    },
                                    {
                                        title: "Member",
                                        path: "/user-member-list"
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunitas",
                            path: "/community-list",
                            haveChild: false,
                            submenusChid: [],
                        },
                        {
                            title: "DRP dan Venue",
                            haveChild: true,
                            submenusChild: [{
                                title: "DRP dan Venue",
                                grandChildren: [
                                    {
                                        title: "Daftar DRP",
                                        path: "/drp-list",
                                    },
                                    {
                                        title: "Daftar Venue",
                                        path: "/venue",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Aktivitas",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Aktivitas",
                                grandChildren: [{
                                        title: "Kegiatan DSO",
                                        path: "/event-dso",
                                    },
                                    {
                                        title: "Kegiatan Komunitas",
                                        path: "/event-list-community",
                                    },
                                    {
                                        title: "Verifikasi Berbagi Informasi",
                                        path: "/info-community-list",
                                    },
                                    {
                                        title: "Prestasi",
                                        path: "/achievement-list",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunikasi",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Komunikasi",
                                grandChildren: [{
                                        title: "Post",
                                        path: "/post-list",
                                    },
                                    {
                                        title: "Media Sosial DSO",
                                        path: "/social-media-list",
                                    },
                                    // {
                                    //     title: "Follower Medsos DSO",
                                    //     path: "/follower-social-media",
                                    // },
                                    {
                                        title: "Atribut",
                                        path: "/attribute-list",
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Konsumsi",
                            path: "/consumption-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                    ],
                }, ],
            },
            {
                title: "Matrik KiSi",
                icon: "mdi-file",
                path: "/report-list",
                haveChild: false,
                submenus: [],
            }, 
        ],
        "admin.rso.low": [{
                title: "Dashboard",
                icon: "mdi-speedometer",
                haveChild: true,
                submenus: [{
                    title: "Dashboard",
                    icon: "mdi-speedometer",
                    children: [{
                            title: "Laporan Komunitas",
                            path: "/community-report",
                        },
                        {
                            title: "Laporan Member",
                            path: "/member-report",
                        },
                        {
                            title: "Laporan Pencapaian",
                            path: "achievement-report",
                        },
                        {
                            title: "Laporan Aktivitas",
                            path: "activity-report",
                        },
                        {
                            title: "Laporan Komunikasi",
                            path: "communication-report",
                        }
                    ],
                }, ],
            },
            {
                title: "Performa KPI",
                icon: "mdi-google-circles-communities",
                haveChild: true,
                submenus: [{
                    title: "Performa KPI",
                    icon: "mdi-google-circles-communities",
                    children: [{
                            title: "Rincian KPI",
                            path: "/summary-kpi",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Aktivitas",
                            path: "/kpi-activity",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Komunikasi",
                            path: "/kpi-communication",
                            haveChild: false,
                            submenusChild: [],
                        },
                        {
                            title: "Konsumsi",
                            path: "/kpi-consumption",
                            haveChild: false,
                            submenusChild: [],
                        }
                    ],
                }, ],
            },
            {
                title: "Data Master DSO",
                icon: "mdi-camera-iris",
                haveChild: true,
                submenus: [{
                    title: "Data Master DSO",
                    icon: "mdi-camera-iris",
                    children: [
                        {
                            title: "Target KPI DSO",
                            haveChild: true,
                            submenusChild: [{
                                title: "Target KPI DSO",
                                grandChildren: [{
                                        title: "Target Aktivitas",
                                        path: "/input-target-kpi-activity",
                                    },
                                    {
                                        title: "Target Komunikasi",
                                        path: "/input-target-kpi-communication",
                                    },
                                    {
                                        title: "Target Konsumsi",
                                        path: "/input-target-kpi-consumption",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Manajemen",
                            haveChild: true,
                            submenusChild: [{
                                title: "Manajemen",
                                grandChildren: [
                                    {
                                        title: "Daftar AM",
                                        path: "/am-list"
                                    },
                                    {
                                        title: "Daftar DSO",
                                        path: "/dso-list"
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Pengguna",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Pengguna",
                                grandChildren: [
                                    {
                                        title: "PIC Manajemen",
                                        path: "/user-list"
                                    },
                                    {
                                        title: "PIC",
                                        path: "/user-pic-list"
                                    },
                                    {
                                        title: "Member",
                                        path: "/user-member-list"
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunitas",
                            path: "/community-list",
                            haveChild: false,
                            submenusChid: [],
                        },
                        {
                            title: "DRP dan Venue",
                            haveChild: true,
                            submenusChild: [{
                                title: "DRP dan Venue",
                                grandChildren: [{
                                        title: "Daftar DRP",
                                        path: "/drp-list",
                                    },
                                    {
                                        title: "Daftar Venue",
                                        path: "/venue",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Aktivitas",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Aktivitas",
                                grandChildren: [{
                                        title: "Kegiatan DSO",
                                        path: "/event-dso",
                                    },
                                    {
                                        title: "Kegiatan Komunitas",
                                        path: "/event-list-community",
                                    },
                                    {
                                        title: "Verifikasi Berbagi Informasi",
                                        path: "/info-community-list",
                                    },
                                    {
                                        title: "Prestasi",
                                        path: "/achievement-list",
                                    },
                                ]
                            }],
                        },
                        {
                            title: "Daftar Komunikasi",
                            haveChild: true,
                            submenusChild: [{
                                title: "Daftar Komunikasi",
                                grandChildren: [{
                                        title: "Post",
                                        path: "/post-list",
                                    },
                                    {
                                        title: "Media Sosial DSO",
                                        path: "/social-media-list",
                                    },
                                    // {
                                    //     title: "Follower Medsos DSO",
                                    //     path: "/follower-social-media",
                                    // },
                                    {
                                        title: "Atribut",
                                        path: "/attribute-list",
                                    }
                                ]
                            }],
                        },
                        {
                            title: "Daftar Konsumsi",
                            path: "/consumption-list",
                            haveChild: false,
                            submenusChild: [],
                        },
                    ],
                }, ],
            },
            {
                title: "Matrik KiSi",
                icon: "mdi-file",
                path: "/report-list",
                haveChild: false,
                submenus: [],
            }
        ],
        "admin.brand76": [{
            title: "Performa KPI",
            icon: "mdi-google-circles-communities",
            haveChild: true,
            submenus: [{
                title: "Performa KPI",
                icon: "mdi-google-circles-communities",
                children: [{
                        title: "Aktivitas",
                        path: "/activity-report-76",
                        haveChild: false,
                        submenusChild: [],
                    },
                    {
                        title: "Komunikasi",
                        path: "/communication-report-76",
                        haveChild: false,
                        submenusChild: [],
                    },
                    {
                        title: "Konsumsi",
                        path: "/consumption-report-76",
                        haveChild: false,
                        submenusChild: [],
                    }
                ],
            }, ],
        },
        {
            title: "Matrik KiSi",
            icon: "mdi-file",
            path: "/report-list",
            haveChild: false,
            submenus: [],
        }
    ],
        "default": []
    }

    return menusStored[role];
}