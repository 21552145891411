<template>
  <VueApexCharts ref="barPerCategory" v-if="series[0].data.reduce((a, b) => a + b, 0) > 0" type="bar" height="200"
    :options="chartOptions" :series="series" />
</template>
<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

export default {
  name: "chart-per-category",

  props: {
    communityAchievement: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },

  watch: {
    communityAchievement(newVal) {
      this.series[0].data = newVal.communityBarSeries;
      const newChartOptions = this.chartOptions;
      newChartOptions.xaxis.categories = newVal.communityBarCategories;

      Object.assign(this.chartOptions, newChartOptions)

      this.$refs.barPerCategory.refresh()
    }
  },

  data: () => ({
    series: [
      {
        data: [0, 0, 0, 0, 0],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 380,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "50%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#000000", "#e2a63d", "#e23d3d", "#d99494", "#34bf41"],
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"],
          fontSize: "10px",
          fontWeight: 900,
        },
        formatter: function (val, opt) {
          const series = opt.w.globals.series[0]
          const sum = series.reduce((a, b) => parseInt(a) + parseInt(b), 0);
          const percent = (val / sum) * 100;
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + ` (${percent.toFixed(0)}%)`;
        },
        offsetX: "10px",
        offsetY: -11,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "Bold Riders",
          "Heppiii Community",
          "Super Family",
          "Kill the LAst",
          "Other",
        ],
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
  },
};
</script>