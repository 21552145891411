<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="7" sm="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn
          class="float-right"
          @click="download"
          :loading="isLoadingDownload"
          depressed
          color="warning"
          block
        >
          Unduh Excel
        </v-btn>
      </v-col>
      <v-col md="3" sm="12">
        <v-text-field
          @keyup.enter="debounceSearch"
          @input="debounceSearchEmpty"
          v-model="searchField"
          label="Pencarian"
          placeholder="Ketik di sini"
          outlined
          dense
        ></v-text-field
      ></v-col>
    </v-row>

    <v-row v-if="!isLoadingMember">
      <v-col md="12" sm="12">
        <v-card v-if="errorCodeGetMember === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Role</th>
                  <th class="text-left">Terverifikasi</th>
                  <th class="text-left">Aktif</th>
                  <th class="text-left">Dibuat Oleh</th>
                  <th class="text-left">Login Terakhir</th>
                  <th class="text-left">Dibuat Pada</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in members" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.role }}</td>
                  <td v-if="item.isVerified">
                    <v-chip color="warming" x-small> Terverifikasi </v-chip>
                  </td>
                  <td v-else>
                    -
                  </td>
                  <td v-if="item.isActive">
                    <v-chip color="success" x-small> Aktif </v-chip>
                  </td>
                  <td v-else>
                    -
                  </td>
                  <td>{{ item.createdBy ? item.createdBy.name : ''  }}</td>
                  <td>{{ item.lastLogin | getNormalDate  }}</td>
                  <td>{{ item.createdAt | getNormalDate  }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text">
          Tampil {{ pages.start }} - {{ pages.end }}
        </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isErrorGetMember && errorCodeGetMember !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetMember"
    />
    <Alert
      :status="isErrorDownload && isErrorCodeDownload !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="isErrorCauseDownload"
    />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { MemberControllers } from "../../controllers/MemberControllers.js";
import Swal from "sweetalert2";
import { formatDate } from "../../external/functions/Utilization.js";
import XLSX from "xlsx";

export default {
  name: "attribute-list",
  data: () => ({
    member: new MemberControllers(),
    title: "Anggota",
    memberTable: {
      page: 1,
      limit: 10,
      keyword: "",
      isReport: false
    },
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    search: {
      debounce: null,
    },
    searchField: ""
  }),

  components: {
    Alert,
    Loading
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  created() {
    this.getMembers();
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    // members
    members() {
      return this.member.memberList;
    },

    errorCodeGetMember() {
      return this.member.errorCode;
    },

    isErrorGetMember() {
      return this.member.isError;
    },

    errorCauseGetMember() {
      return this.member.errorCause;
    },

    isLoadingMember() {
      return this.member.isLoading;
    },

    // download
    dataDownload() {
      return this.member.memberDownload;
    },

    isErrorDownload() {
      return this.member.errorDownload;
    },

    isErrorCauseDownload() {
      return this.member.errorCauseDownload;
    },

    isLoadingDownload() {
      return this.member.loadingDownload;
    },

    isErrorCodeDownload() {
      return this.member.errorCodeDownload;
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    async getMembers() {
      return this.member.getMemberList(
        this.$route.params.id,
        this.memberTable.page,
        this.memberTable.limit,
        this.memberTable.keyword,
        this.memberTable.isReport
      );
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingMember;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.memberTable.page -= 1;

        await this.getMembers(
          this.$route.params.id,
          this.memberTable.page,
          this.memberTable.limit,
          this.memberTable.keyword,
          this.memberTable.isReport
        );
        if (this.isErrorGetMember) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingMember;
      this.pages.start += 10;
      this.pages.end += 10;

      this.memberTable.page += 1;

      await this.getMembers(
        this.$route.params.id,
        this.memberTable.page,
        this.memberTable.limit,
        this.memberTable.keyword,
        this.memberTable.isReport
      );
      if (this.isErrorGetMember) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.memberTable.page = 1;
        this.memberTable.limit = 10;
        this.memberTable.keyword = this.searchField;

        await this.getMembers(
          this.$route.params.id,
          this.memberTable.page,
          this.memberTable.limit,
          this.memberTable.keyword,
          this.memberTable.isReport
        );
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.memberTable.page = 1;
          this.memberTable.limit = 10;
          this.memberTable.keyword = event;

          await this.getMembers(
            this.$route.params.id,
            this.memberTable.page,
            this.memberTable.limit,
            this.memberTable.keyword,
            this.memberTable.isReport
          );
        }
      }, 500);
    },

    async download() {
      await this.member.downloadMemberList(
        this.$route.params.id,
        this.memberTable.page,
        this.memberTable.limit,
        this.memberTable.keyword,
        true
      );

      if(this.dataDownload.length > 0) {
          const dataDownload = []
          for(const item of this.dataDownload) {
              dataDownload.push({
                "name": item.name,
                "email": item.email,
                "role": item.role,
                "terverifikasi": item.isVerified ? 'Yes' : 'No',
                "aktif": item.isActive ? 'Yes' : 'No',
                "dibuat oleh": item.createdBy ? item.createdBy.name : '',
                "login terakhir": item.lastLogin,
                "dibuat pada": item.createdAt
            });
          }

          const data = XLSX.utils.json_to_sheet(dataDownload);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, data, "data");
          XLSX.writeFile(wb, `Report Member Community List.xlsx`);
        } else {
          Swal.fire({
            text: "Data Tidak ditemukan",
            icon: "warning",
          });
        }
    }

  },
};
</script>