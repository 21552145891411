export default{
    namespaced: true,
    state:{
        medfol : '',
    },
    mutations:{
        setmedfol :(state,payload) => {
            state.medfol = payload.id
            }
    },
    actions:{
        setmedfol:({commit},payload)=>{
            commit('setmedfol',payload)
        }
    },
    getters:{
        medfol : state => state.medfol
    },
    
}