<template>
  <div>
    <v-dialog v-model="isShowEdit" width="600" scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Unggah Gambar Sebagai Background
        </v-card-title>
        <v-card-text>
          <h2 class="mt-2">Code: {{ data.code }}</h2>
          <v-btn
            v-if="form.logoTemp.length > 0"
            depressed
            color="error"
            small
            class="mt-2"
            @click="deleteImageUpload"
            >Batal</v-btn
          >
          <br />
          <input
            @change="changeMedia"
            ref="myFileInput"
            type="file"
            accept="image/*"
            class="mt-2"
          />
          <v-img
            :src="logoDisplay"
            v-if="thereIsImage"
            width="40%"
            class="ml-4 mb-4 mt-4"
          ></v-img>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="success" class="ml-4 mb-4 mt-4" @click="submit">
            Proses
          </v-btn>

          <v-btn color="error" depressed @click="closeModalEdit"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="errorCreateProjectSettings"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseProjectSettings"
    />
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { MediaControllers } from "../../controllers/MediaControllers.js";
import { ProjectControllers } from "../../controllers/ProjectControllers.js";
import Loading from "vue-loading-overlay";
import Alert from "../../components/alert";

export default {
  name: "project-settings-edit",
  props: {
    isShowEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data: () => ({
    project: new ProjectControllers(),
    media: new MediaControllers(),
    form: {
      logoTemp: [],
    },
    logoDisplay: null,
    thereIsImage: false,
    isLoading: false,
    fullPage: true,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // medias
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },

    // create
    loadingProjectSettings() {
      return this.project.isLoading;
    },

    errorCreateProjectSettings() {
      return this.project.isError;
    },

    errorCauseProjectSettings() {
      return this.project.errorCause;
    },
  },

  methods: {
    closeModalEdit() {
      this.deleteImageUpload();
      this.$emit("hide", false);
    },
    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.thereIsImage = true;
          this.logoDisplay = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.logoDisplay = null;
      this.thereIsImage = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submitProcess(projectId, id, key, value) {
      return this.project.updateProjectSettings(projectId, id, key, value);
    },

    async submit() {
      if (this.thereIsImage) {
        this.isLoading = true;
        await this.uploadMedia("community", this.form.logoTemp);

        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isLoading = false;
          this.isErrorUploadMedia = false;
        } else {
          this.isLoading = false;
          this.form.logo = this.medias[0];
        }
      }

      if (!this.isErrorUploadMedia) {
        this.isLoading = true;
        await this.submitProcess(
          this.$route.params.id,
          this.data.id,
          "header-background-second",
          this.form.logo
        );

        if (this.errorCreateProjectSettings) {
          this.isLoading = false;
          this.project.setErrorProjectSettings(false);
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseProjectSettings,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Gambar berhasil diunggah",
          });
          this.project.setErrorProjectSettings(false);
          this.$emit("hide", false);
        }
      }
    },
  },
};
</script>