<template>
  <div>
    <v-row>
      <v-col md="3" sm="12">
        <LeftReport :dsos="dsos" />
      </v-col>
      <v-col md="3" sm="12">
        <TotalBrand :dsos="dsos" />
      </v-col>
      <v-col md="3" sm="12">
        <AgeCommunity :dsos="dsos" />
      </v-col>
      <v-col md="3" sm="12">
        <LongJoinCommunity :dsos="dsos" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TotalBrand from "./pie-charts-components/total-brand.vue"
import AgeCommunity from "./pie-charts-components/age-community.vue"
import LongJoinCommunity from "./pie-charts-components/long-join-community.vue"
import LeftReport from "../left-report.vue";
export default {
  name: "pie-charts",
  props: {
    dsos: Array
  },
  data: () => ({
    
  }),
  components: {
    LeftReport,
    TotalBrand,
    AgeCommunity,
    LongJoinCommunity
  }
};
</script>
