<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row >
          <v-col md="6" sm="12">
            <v-autocomplete
              :items="[2019, 2020, 2021, 2022]"
              @change="pickArea"
              item-text="name"
              item-value="id"
              label="Tahun"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12"></v-col>
          <v-col md="2" sm="12">
            <v-btn
              class="success"
              :loading="isLoadingDownload"
              small
              block
              @click="download"
              >Unduh Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <!-- Title -->
          <v-col align="center" md="12" ms="12" class="mt-n5">
            <h4>{{ title }}</h4>
          </v-col>
          <!-- Text total community -->
          <v-col md="6" sm="12" align="center" justify="center" class="mt-10">
            <h1
              style="font-size: 60px"
              class="display-2 font-weight-black"
            >
              <b>26</b><span style="font-size: 20px">/50</span>
            </h1>
            <h3 class="mt-n3 green--text">INCREASE</h3>
          </v-col>
          <!-- Radial Bar -->
          <v-col md="6" sm="12" class="px-0 py-0 mt-0 ms-n3">
            <VueApexCharts
              type="radialBar"
              height="200"
              :options="chartOptions"
              :series="series"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>

<script>
import Alert from "../../../../components/alert/index";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);

export default {
  name: "community-achievement",
  data: () => ({
    title: "CAPAIAN KOMUNITAS",
    year: 2021,
    years: [2020, 2021, 2022],
    brand: 'Super',
    series: [52],
    chartOptions: {
      chart: {
        height: 175,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -165,
          endAngle: 225,
          hollow: {
            size: "50%",
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: 70,
              show: false,
              color: "#888",
              fontSize: "7px",
              fontWeight: 900
            },
            value: {
              formatter: function (val) {
                return parseInt(val) + "%";
              },
              color: "#111",
              fontSize: "25px",
              show: true,
              offsetY: 10,
              fontWeight: 900
            },
          },
        },
      },
      colors: ["#e2a63d"],
      fill: {
        type: "solid",
      },

      labels: [["KOMUNITAS MENGIKUTI", "MEDSOS DSO I"]],
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
    Alert,
  },

  computed: {
    getRole() {
      return this.$route.meta
    },
    // sales
    sales() {
      return this.dashboard.salesDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    this.getDashboard();
    this.getSeries();
  },

  methods: {
    getDashboard() {
      return this.dashboard.getSalesDashboardList(this.year);
    },

    async getSeries() {
      await this.getDashboard(this.year);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    async pickYear(event) {
      this.series = [
        {
          data: [],
        },
      ];
      this.chartOptions.xaxis.categories = [];

      await this.getDashboard(event);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const sales = [];
      for (const item of this.sales) {
        sales.push({
          "comunity name": item._id,
          jumlah: item.count,
        });
      }
      const data = XLSX.utils.json_to_sheet(sales);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Sales Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>
