<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="10" sm="12">
             {{ title }} 
          </v-col>
          <v-col md="2" sm="12">
             <v-btn class="success" :loading="isLoadingDownload" small block @click="download" :disabled="brand==='' || errorCodeGetReports === 404">Unduh Excel</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- <v-row>
          <v-col md="4" sm="12">
            <v-autocomplete
              v-model="filter.dso"
              @change="reportByDso"
              :items="dsos"
              item-text="name"
              item-value="id"
              label="DSO"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="4" sm="12">
            <v-autocomplete
              v-model="filter.brand"
              @change="reportByBrand"
              :items="brands"
              item-text="name"
              item-value="id"
              label="Brand"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col md="4" sm="12">
            <v-autocomplete
              v-model="filter.category"
              @change="reportByCategory"
              :items="categories"
              item-text="value"
              item-value="key"
              label="Kategori Komunitas"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-row> -->
        <v-row v-if="!isLoadingReports">
          <v-col md="12" sm="12">
            <v-card v-if="errorCodeGetReports === 404">
              <v-alert outlined type="error">Data tidak ditemukan</v-alert>
            </v-card>
            <v-alert outlined type="warning" v-else-if="!Array.isArray(reports) && reports.length === 0">Data kosong</v-alert>
            <v-card v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center" colspan="2"></th>
                      <th class="text-center" :colspan="category==='heppiii'|| category==='e-sport'? 7:6">Indikator DTK</th>
                      <th class="text-center" colspan="6">Indikator KHK</th>
                    </tr>
                    <tr>
                      <th class="text-left">Komunitas</th>
                      <!-- <th class="text-left">Area</th> -->
                      <!-- <th class="text-left">Total Member</th> -->
                      <th class="text-left">Member Aktif</th>
                      <th class="text-left">Perokok Aktif</th>
                      <th class="text-left" v-if="year<2023">Jumlah Kegiatan</th>
                      <th class="text-left">Skala Massa</th>
                      <th class="text-left" v-if="parseInt(year)<2023">Media Sosial</th>
                      <th class="text-left" v-else>Performa Medsos</th>
                      <th class="text-left">Atribut Offline</th>
                      <th class="text-left" v-if="category==='heppiii' && parseInt(year)<2023">Warga Desa</th>
                      <th class="text-left" v-if="category==='heppiii' && parseInt(year)>=2023">Target Konsumsi Rasional</th>
                      <th class="text-left" v-if="category==='e-sport'">Prestasi</th>
                      <th class="text-left">Realisasi Konsumsi Rasional</th>
                      <th class="text-left">Kolaborasi Event</th>
                      <th class="text-left">Partisipasi Aktif</th>
                      <th class="text-left">Sharing Informasi</th>
                      <th class="text-left">CoBranding Offline</th>
                      <th class="text-left">Viralisasi Medsos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in reports" :key="item.id">
                      <td>{{ item.name}}</td>
                      <!-- <td>{{ item.area }}</td> -->
                      <!-- <td>{{ item.scoreDTK.scoreTotalMember?item.scoreDTK.scoreTotalMember:'0' }}</td> -->
                      <td>{{ item.scoreDTK.scoreTotalActiveMember?item.scoreDTK.scoreTotalActiveMember:'0' }}</td>
                      <td>{{ item.scoreDTK.scoreTotalSmoker? item.scoreDTK.scoreTotalSmoker:'0'}}</td>
                      <td v-if="year<2023">{{ item.scoreDTK.scoreTotalActivity? item.scoreDTK.scoreTotalActivity: '0' }}</td>
                      <td>{{ item.scoreDTK.scoreActivityScale? item.scoreDTK.scoreActivityScale:'0' }}</td>
                      <td v-if="category===parseInt(year)<2023">{{ item.scoreDTK.scoreTotalSocialMedia? item.scoreDTK.scoreTotalSocialMedia:'0' }}</td>
                      <td v-else>{{ item.scoreDTK.scoreMedsosPerformance? item.scoreDTK.scoreMedsosPerformance:'0' }}</td>
                      <td>{{ item.scoreDTK.scoreTotalAttribute? item.scoreDTK.scoreTotalAttribute:'0'}}</td>
                      <td v-if="category==='heppiii' && parseInt(year)<2023">{{ item.scoreDTK.scoreTotalVillagers? item.scoreDTK.scoreTotalVillagers:'0'}}</td>
                      <td v-if="category==='heppiii' && parseInt(year)>=2023">{{ item.scoreDTK.scoreRationalConsumptionTarget? item.scoreDTK.scoreRationalConsumptionTarget:'0'}}</td>
                      <td v-if="category==='e-sport'">{{ item.scoreDTK.scoreAchievement? item.scoreDTK.scoreAchievement:'0'}}</td>
                      <td>{{ item.scoreKHK.scoreConsumptionTarget? item.scoreKHK.scoreConsumptionTarget:'0'}}</td>
                      <td>{{ item.scoreKHK.scoreCollaboration? item.scoreKHK.scoreCollaboration:'0'}}</td>
                      <td>{{ item.scoreKHK.scoreActiveParticipation? item.scoreKHK.scoreActiveParticipation: '0' }}</td>
                      <td>{{ item.scoreKHK.scoreInformationSharing? item.scoreKHK.scoreInformationSharing: '0' }}</td>
                      <td>{{ item.scoreKHK.scoreBrandingOffline? item.scoreKHK.scoreBrandingOffline:'0' }}</td>
                      <td>{{ item.scoreKHK.scoreSocialMediaViralisation? item.scoreKHK.scoreSocialMediaViralisation:'0' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col md="12" sm="12">
            <v-card>
              <v-card-text>Loading Data...</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <!-- Error Reports -->
    <Alert
      :status="isErrorGetReports && errorCodeGetReports !== 404"
      icon="error"
      title="ERROR"
      :msg="errorCauseGetReports"
    />
  </div>
</template>
<script>
import Alert from "../../../components/alert/index";
import { ReportControllers } from "../../../controllers/ReportControllers.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import XLSX from "xlsx";

export default {
  name: "report-score",
  props: {
    category: {
      type: String(),
      default:''},
    dso: {
      type: String(),
      default:''},
    brand: {
      type: String(),
      default:''},
    year: {
      type: String(),
      default: new Date().getFullYear().toString
    }
  },

  data: () => ({
    title: "Score",
    report: new ReportControllers(),

    isLoading: false,
    fullPage: true,
    isLoadingDownload: false
  }),

  components: {
    Alert,
    Loading,
  },

  computed: {
    // reports
    reports() {
      const array = this.report.reportKisiScoreList.tableOfScoring;
      console.log(array)
      if (array){
        return array
      }
      return []
    },

    errorCodeGetReports() {
      return this.report.errorCode;
    },

    isErrorGetReports() {
      return this.report.isError;
    },

    errorCauseGetReports() {
      return this.report.errorCause;
    },

    isLoadingReports() {
      return this.report.isLoading;
    },
  },

  watch:{
    category(){
      this.getReports()
    },
    dso(){
      this.getReports()
    },
    brand(){
      this.getReports()
    },
    year(){
      this.getReports()
    },

  },

  methods: {
    async getReports() {
      return this.report.getReportKisiScoreList(
        this.dso,
        this.brand,
        this.category,
        this.year
      );
    },

    // async reportByDso(event) {
    //   if(this.filter.dso && this.filter.brand && this.filter.category) {
    //     await this.getReports(
    //       event,
    //       this.filter.brand,
    //       this.filter.category
    //     );
    //   }
    // },

    // async reportByBrand(event) {
    //   if(this.filter.dso && this.filter.brand && this.filter.category) {
    //     await this.getReports(
    //       this.filter.dso,
    //       event,
    //       this.filter.category
    //     );
    //   }
    // },

    // async reportByCategory(event) {
    //   if(this.filter.dso && this.filter.brand && this.filter.category) {
    //     await this.getReports(
    //       this.filter.dso,
    //       this.filter.brand,
    //       event
    //     );
    //   }
    // },

    download() {
      this.isLoadingDownload = true;
      const scores = []
      for(const item of this.reports) {
          scores.push({
              "name": item.name,
              "area": item.area,
              "member aktif": item.scoreDTK.scoreTotalActiveMember,
              "total kegiatan": item.scoreDTK.scoreTotalActivity,
              "total preokok": item.scoreDTK.scoreTotalSmoker,
              "media sosial": item.scoreDTK.scoreTotalSocialMedia,
              "skala aktifitas": item.scoreDTK.scoreActivityScale,
              "atribut": item.scoreDTK.scoreTotalAttribute,
              "target konsumsi": item.scoreKHK.scoreConsumptionTarget,
              "target kolaborasi": item.scoreKHK.scoreCollaboration,
              "aktif partisipasi": item.scoreKHK.scoreActiveParticipation,
              "berbagi informasi": item.scoreKHK.scoreInformationSharing,
              "branding offline": item.scoreKHK.scoreBrandingOffline,
              "viralisasi sosmed": item.scoreKHK.scoreSocialMediaViralisation
          });
      }
      const data = XLSX.utils.json_to_sheet(scores);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Report Score.xlsx");
      this.isLoadingDownload = false;
    }
  },
};
</script>