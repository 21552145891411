<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-row class="px-1 py-0">
          <v-col md="8" sm="12">
            <span class="text--secondary text-caption">Tahun</span>
            <v-autocomplete :items="years" v-model="chosenYear" dense></v-autocomplete>
          </v-col>
          <v-col md="2" sm="12">
            <!-- <v-btn
              class="success"
              :loading="isLoadingDownload"
              small
              block
              @click="download"
              >Unduh Excel
            </v-btn> -->
          </v-col>
          <v-col md="12" ms="12" align="center" v-if="isOldKPI">
            <h3 align="center">{{ title }}</h3>
          </v-col>
          <v-col md="12" ms="12" align="center" v-else>
            <h3 align="center">KONTEN MEDIA SOSIAL KOMUNITAS</h3>
            <BarTarget :targetMedsosComputed="postCommunityKPIComputed" />
          </v-col>
          <v-row v-if="isOldKPI">
            <v-col align="center" md="6" ms="12" class="ps-0">
              <VueApexCharts ref="pieChartTargetMedsos" type="pie" width="300" :options="chartOptions" :series="series" class="mt-7 ms-n5" />
            </v-col>
            <v-col md="6" sm="12" align="center">
              <v-row>
                <v-col md="12" ms="12" justify="center">
                  <BarTarget :targetMedsosComputed="targetMedsosDsoChartComputed" />
                </v-col>
                <v-col md="12" ms="12" class="mt-n10" v-if="isOldKPI">
                  <h4>KATEGORI MEDIA SOSIAL</h4>
                </v-col>
                <v-col md="6" sm="12" align="center">
                  <h1>
                    <b>{{ targetMedsosDsoChartComputed.instagramReach }}</b>
                  </h1>
                  <p style="font-size: 10px">INSTAGRAM</p>
                </v-col>
                <v-col md="6" sm="12" align="center">
                  <h1>
                    <b>{{ targetMedsosDsoChartComputed.facebookReach }}</b>
                  </h1>
                  <p style="font-size: 10px">FACEBOOK</p>
                </v-col>
                <v-col md="6" sm="12" align="center">
                  <h1>
                    <b>{{ targetMedsosDsoChartComputed.youtubeReach }}</b>
                  </h1>
                  <p style="font-size: 10px">YOUTUBE</p>
                </v-col>
                <v-col md="6" sm="12" align="center">
                  <h1>
                    <b>{{ targetMedsosDsoChartComputed.tiktokReach }}</b>
                  </h1>
                  <p style="font-size: 10px">TIKTOK</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert :status="isError" icon="error" title="KESALAHAN" :msg="errorCause" />
  </div>
</template>

<script>
import Alert from "../../../../components/alert/index";
import { DashboardControllers } from "../../../../controllers/DashboardControllers";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import XLSX from "xlsx";
Vue.use(VueApexCharts);
import BarTarget from "./bar-target";
import { MedsosDSOControllers } from "../../../../controllers/MedsosDSOControllers";

export default {
  name: "event-report",
  props: {
    dso: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    title: "MEDIA SOSIAL KOMUNITAS",
    dashboard: new DashboardControllers(),
    medsosDso: new MedsosDSOControllers(),
    year: new Date().getFullYear() - 1,
    chosenYear: new Date().getFullYear(),
    series: [0],
    chartOptions: {
      chart: {
        width: "100%",
        type: "pie",
      },
      colors: ["#d6336c", "#4e6cbf", "#c02727", "#343131"],
      labels: ["Instagram", "Facebook", "Youtube", "Tiktok"],
      legend: {
        show: false,
        position: "right",
        formatter: function(label, opts) {
          return label + " : " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"],
        },
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(0) + "%"];
        },
      },
    },

    isLoadingDownload: false,
  }),

  components: {
    VueApexCharts,
    Alert,
    BarTarget,
  },

  computed: {
    years() {
      const start = 2019;
      const end = new Date().getFullYear();

      let year = [];

      for (let i = start; i <= end; i++) {
        year.push(i);
      }

      return year;
    },

    isOldKPI() {
      console.log(this.chosenYear < 2023);
      return this.chosenYear < 2023;
    },

    targetMedsosDsoChartComputed() {
      const medsosDsoChart = this.medsosDso.targetMedsosChart;
      const medsosTarget = medsosDsoChart.medsosTarget;

      const detailTarget = medsosDsoChart.detailTarget;
      const reachTargetMapped = this._mapReachTarget(detailTarget);
      return {
        targetMedsos: (medsosTarget && medsosTarget.split("/")[1]) || 0,
        reachMedsos: (medsosTarget && medsosTarget.split("/")[0]) || 0,
        medsosTarget,
        detailTarget,
        ...reachTargetMapped,
      };
    },

    postCommunityKPIComputed() {
      const postCommunity = this.medsosDso.postCommunityChart;
      return postCommunity;
    },
    // sales
    sales() {
      return this.dashboard.salesDashboardList;
    },

    errorCodeGetDashboard() {
      return this.dashboard.errorCode;
    },

    isErrorGetDashboard() {
      return this.dashboard.isError;
    },

    errorCauseGetDashboard() {
      return this.dashboard.errorCause;
    },

    isLoadingDashboard() {
      return this.dashboard.isLoading;
    },
  },

  created() {
    // this.getDashboard();
    // this.getSeries();
  },

  watch: {
    dso() {
      this.getPostCommunityKPI();
      this.getTargetMedsosChart();
    },
    chosenYear() {
      this.getPostCommunityKPI();
      this.getTargetMedsosChart();
    },
    targetMedsosDsoChartComputed(newVal) {
      this.series = [];

      const detailSeries = newVal.detailTarget.map((datum) => datum.value);
      const detailLabels = newVal.detailTarget.map((datum) => datum.name);
      this.series.push(...detailSeries);

      const newChartOptions = this.chartOptions;
      newChartOptions.labels = detailLabels;
      // newChartOptions.annotations.xaxis[0].x2 = Number(newVal.targetKonten)
      // newChartOptions.annotations.yaxis[0].label.text = `Target: ${Number(newVal.targetKonten)}`
      Object.assign(this.chartOptions, newChartOptions);

      this.$refs.pieChartTargetMedsos.refresh();
    },
  },

  methods: {
    _mapReachTarget(detailTarget) {
      const baseReturn = {
        tiktokReach: 0,
        facebookReach: 0,
        youtubeReach: 0,
        instagramReach: 0,
      };

      if (detailTarget) {
        baseReturn.tiktokReach = detailTarget.find((el) => /tiktok/i.test(el.name)).value || 0;
        baseReturn.facebookReach = detailTarget.find((el) => /facebook/i.test(el.name)).value || 0;
        baseReturn.youtubeReach = detailTarget.find((el) => /youtube/i.test(el.name)).value || 0;
        baseReturn.instagramReach = detailTarget.find((el) => /instagram/i.test(el.name)).value || 0;
      }

      return baseReturn;
    },

    getTargetMedsosChart() {
      return this.medsosDso.getTargetMedsosChart(this.dso, this.chosenYear);
    },

    getPostCommunityKPI() {
      return this.medsosDso.getPostCommunityKPI(this.dso, this.chosenYear);
    },

    getDashboard() {
      return this.dashboard.getSalesDashboardList(this.year);
    },

    async getSeries() {
      await this.getDashboard(this.year);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    async pickYear(event) {
      this.series = [
        {
          data: [],
        },
      ];
      this.chartOptions.xaxis.categories = [];

      await this.getDashboard(event);
      this.dashboard.salesDashboardList.map((item) => {
        this.series[0].data.push(item.count);
        this.chartOptions.xaxis.categories.push(item._id);
      });
    },

    download() {
      this.isLoadingDownload = true;
      const sales = [];
      for (const item of this.sales) {
        sales.push({
          "comunity name": item._id,
          jumlah: item.count,
        });
      }
      const data = XLSX.utils.json_to_sheet(sales);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Dashboard Sales Tahun ${this.year}.xlsx`);
      this.isLoadingDownload = false;
    },
  },
};
</script>
