import axios from "axios";
import { EngagementList } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const EngagementListRequest = (year, type, size) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${EngagementList(year, type, size)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}


